import { formatCurrency } from "@angular/common";
import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { ISuccessTextService } from "../../services/success.text.service";

@Injectable({ providedIn: "any" })
export class PaymentsSuccessTextService implements ISuccessTextService {
  successHeader = $localize`:@@pay-loan.success.success-header:Success!`;
  restartButton = $localize`:@@pay-loan.success.restart-btn:Make Another Payment`;
  accountsButton = $localize`:@@pay-loan.success.accounts-btn:View My Accounts`;
  memopostMessage = $localize`:@@pay-loan.success.memo-post: We got your request, but can't process it just yet. Check back in a bit and it should be processed for you`;
  immediateSuccessMessage = (amount: number, toAccount: string) =>
    $localize`:@@payments.success.immediate-message: Your payment of ${formatCurrency(
      amount,
      "en-US",
      "$",
    )} to ${toAccount} was successful`;
  scheduledLaterSuccessMessage = (amount: number, toAccount: string, scheduledDate: string) =>
    $localize`:@@payments.success.scheduled-later-message: Your payment of ${formatCurrency(
      amount,
      "en-US",
      "$",
    )} to ${toAccount} has been scheduled for ${DateTime.fromISO(scheduledDate).toFormat("MMMM dd, yyyy")}`;
  scheduledRecurringSuccessMessage = (frequency: string, amount: number, toAccount: string) =>
    $localize`:@@payments.success.scheduled-recurring-message: Your ${frequency} recurring payment of ${formatCurrency(
      amount,
      "en-US",
      "$",
    )} to ${toAccount} has been scheduled`;
}
