import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-to-record-display",
  templateUrl: "./to-record.component.html",
})
export class ToRecordDisplayComponent {
  @Input()
  toLabel!: string;

  @Input()
  name!: string;

  @Input()
  accountNumber!: string;
}
