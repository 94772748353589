import { Component, OnInit } from "@angular/core";
import { UserManagementService } from "@backbase/user-http-ang";

@Component({
  selector: "ent-data-dog",
  template: `<div style="display:none"></div>`,
})
export class DataDogComponent implements OnInit {
  legalEntityId: string;
  userName: string;
  constructor(private readonly userManagementService: UserManagementService) {}

  getUserInfo = () => {
    this.userManagementService.getOwnUser().subscribe(({ legalEntityId, externalId }) => {
      this.legalEntityId = legalEntityId;
      this.userName = externalId;
      if (window["DD_RUM"]) {
        window["DD_RUM"].setUser({
          id: this.legalEntityId,
          name: this.userName,
        });
      }
    });
  };

  ngOnInit(): void {
    this.getUserInfo();
  }
}
