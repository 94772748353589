import { FormStyle, TranslationWidth, getLocaleDayNames } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FrecuencySelectorTextService } from "./frequency-selector.text.service";

export enum FREQUENCY_OPTIONS {
  DAILY,
  WEEKLY,
  MONTHLY,
}
@Component({
  selector: "ent-frequency-selector",
  styleUrls: ["frequency-selector.component.scss"],
  templateUrl: "./frequency-selector.component.html",
  providers: [FrecuencySelectorTextService],
})
export class FrequencySelectorComponent implements OnInit {
  frequencyOptions = FREQUENCY_OPTIONS;
  frequencyOptionsData: { value: string | number | boolean; label: string }[];
  weekdays: readonly string[];
  weekdaysOptions: { value: string | number | boolean; label: string }[];

  @Input()
  controlFormGroup!: FormGroup;

  constructor(public textService: FrecuencySelectorTextService) {}

  ngOnInit(): void {
    this.setDefaults();
    this.initForm();
  }

  initForm() {
    this.frequencyOptionsData = [
      { value: FREQUENCY_OPTIONS.DAILY, label: this.textService.frequencySelectorDaily },
      { value: FREQUENCY_OPTIONS.WEEKLY, label: this.textService.frequencySelectorWeekly },
      { value: FREQUENCY_OPTIONS.MONTHLY, label: this.textService.frequencySelectorMonthly },
    ];

    this.weekdays = getLocaleDayNames("en-US", FormStyle.Standalone, TranslationWidth.Wide); // TODO: replace the local code once we have a way of getting it.
    this.weekdaysOptions = this.weekdays.map((day, index) => {
      return { value: index, label: day };
    });
  }

  setDefaults() {
    const date = new Date();
    const { frequencyPicker, dayOfWeekPicker, dayOfMonthPicker, timePicker } = this.controlFormGroup.controls;
    if (!frequencyPicker.value) frequencyPicker.setValue(0);
    if (!dayOfWeekPicker.value && dayOfWeekPicker.value !== 0) dayOfWeekPicker.setValue(date.getDay());
    if (!dayOfMonthPicker.value) dayOfMonthPicker.setValue(date.getDate());
    if (!timePicker.value) timePicker.setValue("08:00:00");
  }
}
