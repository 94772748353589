import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PAYMENT_ORDER_ERROR_CODES, PaymentErrorBannerTextService } from "./payment-error-banner.text.service";

@Component({
  selector: "ent-payment-error-banner",
  templateUrl: "./payment-error-banner.component.html",
  styleUrls: ["./payment-error-banner.component.scss"],
})
export class PaymentErrorBannerComponent implements OnChanges {
  @Input()
  errorCode?: PAYMENT_ORDER_ERROR_CODES;

  @Input()
  defaultErrorMessage!: string;

  errorText: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined);

  constructor(protected readonly textService: PaymentErrorBannerTextService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const errorText =
      this.textService.getErrorTextByCode(changes.errorCode.currentValue) ?? changes.defaultErrorMessage.currentValue;
    this.errorText.next(errorText);
  }
}
