import { Component, OnChanges } from "@angular/core";
import { BaseSuccessComponent } from "../../components/base-success/base-success.component";
import { PaymentsSuccessTextService } from "../services/payments-success.text.service";

@Component({
  selector: "ent-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.scss"],
})
export class PaymentSuccessComponent extends BaseSuccessComponent implements OnChanges {
  constructor(public readonly paymentsSuccessTextService: PaymentsSuccessTextService) {
    super();
  }

  getSuccessTextService = () => {
    return this.paymentsSuccessTextService;
  };
}
