import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { InputNumberModule } from "@backbase/ui-ang/input-number";
import { InputRadioGroupModule } from "@backbase/ui-ang/input-radio-group";
import { InputValidationMessageModule } from "@backbase/ui-ang/input-validation-message";
import { AlertModule } from "@backbase/ui-ang/alert";
import { EntUIModule } from "@ent/ui";
import { BaseFormComponent } from "./components/base-form/base-form.component";
import { BaseReviewComponent } from "./components/base-review/base-review.component";
import { BaseSuccessComponent } from "./components/base-success/base-success.component";
import { ScheduleComponent } from "./components/schedule/schedule.component";
import { PaymentErrorBannerComponent } from "./components/payment-error-banner/payment-error-banner.component";
import { PaymentErrorBannerTextService } from "./components/payment-error-banner/payment-error-banner.text.service";

@NgModule({
  declarations: [
    BaseFormComponent,
    ScheduleComponent,
    BaseReviewComponent,
    BaseSuccessComponent,
    PaymentErrorBannerComponent,
  ],
  imports: [
    EntUIModule,
    InputValidationMessageModule,
    InputNumberModule,
    CommonModule,
    ReactiveFormsModule,
    InputRadioGroupModule,
    AlertModule,
  ],

  exports: [
    BaseFormComponent,
    ScheduleComponent,
    BaseReviewComponent,
    BaseSuccessComponent,
    PaymentErrorBannerComponent,
  ],
  providers: [PaymentErrorBannerTextService],
})
export class MoneyMovementModule {}
