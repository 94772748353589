<ng-container *ngIf="route.subRoutes.length === 0">
  <ng-container *ngTemplateOutlet="singleRouteLink"> </ng-container>
</ng-container>
<ng-container *ngIf="route.subRoutes.length > 0">
  <ng-container *ngTemplateOutlet="subRouteLink"> </ng-container>
</ng-container>

<ng-template #singleRouteLink>
  <li class="bb-layout__horizontal-nav-item" *bbIfEntitlements="route.permissionRequired">
    <a
      class="bb-layout__horizontal-nav-link"
      [routerLink]="route.link"
      routerLinkActive="bb-layout__horizontal-nav-link--active"
      #pocketsRouterLink="routerLinkActive"
      [attr.aria-current]="pocketsRouterLink.isActive ? 'page' : undefined"
      role="link"
    >
      <span class="bb-layout__horizontal-nav-item-description">{{route.label}}</span>
    </a>
  </li>
</ng-template>

<ng-template #subRouteLink>
  <li
    class="bb-layout__horizontal-nav-item"
    ngbDropdown
    display="static"
    bbLayoutNavDropdown
    *bbIfEntitlements="route.permissionRequired"
    (openChange)="isOpenChange($event)"
  >
    <a
      ngbDropdownToggle
      class="bb-layout__horizontal-nav-link"
      href="javascript:;"
      tabindex="0"
      [ngClass]="{
        'bb-layout__horizontal-nav-link--expanded': (isDropDownOpen | async) || (isActive | async)
      }"
      [attr.aria-expanded]="(isDropDownOpen | async)"
      id="bb-menu-header-button-1"
      role="button"
    >
      <span class="bb-layout__horizontal-nav-item-description">{{route.label}}</span>
      <em
        class="bb-layout__horizontal-nav-expand-marker bb-layout__horizontal-nav-expand-marker--vertical fa-solid"
        [ngClass]="{'fa-chevron-up': (isDropDownOpen | async), 'fa-chevron-down': (isDropDownOpen | async) === false}"
      ></em>
      <em
        class="bb-layout__horizontal-nav-expand-marker bb-layout__horizontal-nav-expand-marker--horizontal fa-solid fa-chevron-right"
      ></em>
    </a>
    <ent-navmenu-item-submenu [parentRoute]="route" (closeSubMenu)="closeDropDown()"></ent-navmenu-item-submenu>
  </li>
</ng-template>
