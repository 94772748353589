export const texts = {
  mortgage: {
    monthlyMortgagePaymentSection: {
      title: $localize`:Mortgage info monthly payment details title@@ent.mortgage-loans.loan-info.payment-details.title:Monthly Mortgage Payment`,
      principal: $localize`:Mortgage info principal title@@ent.mortgage-loans.loan-info.payment-details.principal.subtitle:Principal`,
      interest: $localize`:Mortgage info interest title@@ent.mortgage-loans.loan-info.payment-details.interest.subtitle:Interest`,
      totalRegularPayment: $localize`:Mortgage info total Regular Payment title@@ent.mortgage-loans.loan-info.payment-details.total-regular-payment.subtitle:Total Regular Payment`,
      escrowPayment: $localize`:Mortgage info escrow Payment title@@ent.mortgage-loans.loan-info.payment-details.escrow-payment.subtitle:Escrow Payment`,
      paymentDue: $localize`:Mortgage info payment Due title@@ent.mortgage-loans.loan-info.payment-details.payment-due.subtitle:Payment Due`,
      paymentDueDate: $localize`:Mortgage info payment Due Date title@@ent.mortgage-loans.loan-info.payment-details.payment-due-date.subtitle:Payment Due Date`,
      lastPayment: $localize`:Mortgage info last payment title@@ent.mortgage-loans.loan-info.last-payment-details.last-payment.subtitle:Last Payment`,
      lastPaymentDate: $localize`:Mortgage info last payment Due Date title@@ent.mortgage-loans.loan-info.last-payment-details.last-payment-date.subtitle:Last Payment Date`,
    },
    additionalInformationSection: {
      title: $localize`:Mortgage info additional Information title@@ent.mortgage-loans.loan-info.additional-information.title:Additional Information`,
      address: $localize`:Mortgage info addition information address title@@ent.mortgage-loans.loan-info.additional-information.address.subtitle:Address`,
      interestRate: $localize`:Mortgage info addition information interest rate title@@ent.mortgage-loans.loan-info.additional-information.interest-rate.subtitle:Interest Rate`,
      balance: $localize`:Mortgage info addition information balance title@@ent.mortgage-loans.loan-info.additional-information.balance.subtitle:Balance`,
      escrowBalance: $localize`:Mortgage info addition information escrow balance title@@ent.mortgage-loans.loan-info.additional-information.escrow-balance.subtitle:Escrow Balance`,
      ytdInterest: $localize`:Mortgage info addition information year to date Interest title@@ent.mortgage-loans.loan-info.additional-information.ytd-interest.subtitle:Year To Date Interest`,
      previousYearInterest: $localize`:Mortgage info addition information previous year interest title@@ent.mortgage-loans.loan-info.additional-information.previous-year-interest.subtitle: Previous Year Interest`,
      ytdTax: $localize`:Mortgage info addition information year to date tax title@@ent.mortgage-loans.loan-info.additional-information.ytd-tax.subtitle:Year To Date Tax`,
      previousYearTax: $localize`:Mortgage info addition information previous year tax title@@ent.mortgage-loans.loan-info.additional-information.previous-year-tax.subtitle: Previous Year Tax`,
    },
  },
  termLoanAndLineOfCredit: {
    paymentSection: {
      title: $localize`:Term loan info payment details title@@ent.term-loans.loan-info.payment-details.title:Payment`,
      regularPayment: $localize`:Term loan info regular payment title@@ent.term-loans.loan-info.payment-details.regular-payment.subtitle:Regular Payment`,
      paymentDue: $localize`:Term loan info payment due title@@ent.term-loans.loan-info.payment-details.payment-due.subtitle:Payment Due`,
      paymentDueDate: $localize`:Term loan info payment due date title@@ent.term-loans.loan-info.payment-details.payment-due-date.subtitle:Payment Due Date`,
      lastPaymentDate: $localize`:Term loan info last payment date title@@ent.term-loans.loan-info.payment-details.last-payment-date.subtitle:Last Payment Date`,
    },
    additionalInformationSection: {
      title: $localize`:Term loan info additional information title@@ent.term-loans.loan-info.additional-information.title:Additional Information`,
      ytdInterest: $localize`:Term loan info year to date interest title@@ent.term-loans.loan-info.additional-information.ytd-interest.subtitle:Year To Date Interest`,
      previousYearInterest: $localize`:Term loan info previous year interest title@@ent.term-loans.loan-info.additional-information.previous-year-interest.subtitle:Previous Year Interest`,
      accountNumber: $localize`:Term loan info account number title@@ent.term-loans.loan-info.additional-information.account-number.subtitle:Account Number`,
      routingNumber: $localize`:Term loan info routing number title@@ent.term-loans.loan-info.additional-information.routing-number.subtitle:Routing Number`,
    },
    otherSection: {
      title: $localize`:Term loan info other title@@ent.term-loans.loan-info.other.title:Other`,
      loanOpeningDate: $localize`:Term loan info loan opening date title@@ent.term-loans.loan-info.other.loan-opening-date.subtitle:Loan Opening Date`,
      jointOwners: $localize`:Term loan info joint owners title@@ent.term-loans.loan-info.other.joint-owners.subtitle:Joint Owner(s)`,
    },
  },
};
