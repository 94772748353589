import { NgModule, Provider } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import {
  CardsListViewComponent,
  CardsManagementJourneyComponent,
  CardsManagementJourneyConfiguration,
  CardsManagementJourneyConfigurationToken,
  CardsManagementJourneyModule,
  CardsManagementTravelNoticeGuard,
  CardsTravelNoticeViewComponent,
  DestinationsServiceConfigToken,
} from "@backbase/cards-management-journey-ang";
import { HeaderModule } from "@backbase/ui-ang/header";
import { LoadingIndicatorModule } from "@backbase/ui-ang/loading-indicator";
import { SwitchModule } from "@backbase/ui-ang/switch";
import { InputTextModule } from "@backbase/ui-ang/input-text";
import { ModalModule } from "@backbase/ui-ang/modal";
import { ButtonModule } from "@backbase/ui-ang/button";
import { InputValidationMessageModule } from "@backbase/ui-ang/input-validation-message";
import { Route } from "@angular/router";
import { PaymentCardModule } from "@backbase/ui-ang/payment-card";
import { CardListsUiModule } from "@backbase/internal-cards-management-journey-ui";
import { NgxMaskModule } from "ngx-mask";
import { LoadingService, CardsHTTPService, TravelNoticesService } from "@ent/data";
import { EntUIModule } from "@ent/ui";
import { CardDetailViewComponent } from "./card-details/card-detail-view.component";
import { CardControlsComponent } from "./card-details/card-controls/card-controls.component";
import { CardServicesComponent } from "./card-details/card-services/card-services.component";
import { CardActivationComponent } from "./card-details/card-activation/card-activation.component";
import { ResetPinModalComponent } from "./card-details/reset-pin-modal/reset-pin-modal.component";
import { TextService } from "./services/text.service";
import { CvvFormComponent } from "./components/cvv-form/cvv-form.component";
import { PinFormComponent } from "./components/pin-form/pin-form.component";
import { PinConfirmFormComponent } from "./components/pin-confirm-form/pin-confirm-form.component";
import { BasePinFormComponent } from "./components/base-pin-form/base-pin-form.component";
import { CardActivationModalComponent } from "./card-details/card-activation-modal/card-activation-modal.component";

export const cardsJourneyConfigProvider: Provider = {
  provide: CardsManagementJourneyConfigurationToken,
  useValue: <Partial<CardsManagementJourneyConfiguration>>{
    groupByPaymentCardTypes: "Debit",
  },
};

const cardRoutes: Route = {
  path: "",
  component: CardsManagementJourneyComponent,
  children: [
    {
      path: "",
      redirectTo: "list",
      pathMatch: "full",
    },
    {
      path: "list",
      component: CardsListViewComponent,
    },
    {
      path: "details",
      component: CardDetailViewComponent,
    },
    {
      path: "travel-notice",
      component: CardsTravelNoticeViewComponent,
      canActivate: [CardsManagementTravelNoticeGuard],
    },
  ],
};

@NgModule({
  declarations: [
    CardDetailViewComponent,
    CardControlsComponent,
    CardServicesComponent,
    CardActivationComponent,
    CardActivationModalComponent,
    ResetPinModalComponent,
    CvvFormComponent,
    PinFormComponent,
    PinConfirmFormComponent,
    BasePinFormComponent,
  ],
  imports: [
    CardListsUiModule,
    EntUIModule,
    CommonModule,
    CardsManagementJourneyModule.forRoot({ route: cardRoutes }),
    HeaderModule,
    LoadingIndicatorModule,
    SwitchModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    ModalModule,
    InputValidationMessageModule,
    PaymentCardModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    cardsJourneyConfigProvider,
    LoadingService,
    TextService,
    CardsHTTPService,
    {
      provide: DestinationsServiceConfigToken,
      useClass: TravelNoticesService,
    },
  ],
})
export class CardsModule {}
