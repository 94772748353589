<div class="card card-body">
  <div class="bb-text-align-center bb-block--xl">
    <ent-success-svg></ent-success-svg>
    <div></div>
    <bb-header-ui
      headingClasses="bb-text-semi-bold"
      headingType="h2"
      [heading]="memberTransferSuccessTextService.successHeader"
    ></bb-header-ui>
    <span class="bb-text-support">
      <div [ngSwitch]="occurrence">
        <ng-container *ngSwitchCase="occurrences.NOW">
          {{ memberTransferSuccessTextService | translationFunctions:
          'immediateSuccessMessage':formModel.amount:accountName }}
        </ng-container>
        <ng-container *ngSwitchCase="occurrences.LATER">
          {{ memberTransferSuccessTextService | translationFunctions : 'scheduledLaterSuccessMessage' : formModel.amount
          : accountName : formModel.executionDate }}
        </ng-container>
        <ng-container *ngSwitchCase="occurrences.RECURRING">
          {{ memberTransferSuccessTextService | translationFunctions:
          'scheduledRecurringSuccessMessage':frequency:formModel.amount:accountName }}
        </ng-container>
      </div>
    </span>
  </div>
  <div class="bb-stack bb-stack--center btn-container">
    <div class="bb-stack__item">
      <button bbButton color="primary" (click)="onRestart()">
        {{ memberTransferSuccessTextService.restartButton }}
      </button>
    </div>
    <div class="bb-stack__item">
      <button bbButton color="link" (click)="onNavigateToAccounts()">
        {{ memberTransferSuccessTextService.accountsButton }}
      </button>
    </div>
  </div>
</div>
