import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { COMPONENT_STATE, MoneyInsightHttpService, MoneyInsightUrl } from "@ent/data";
import { BehaviorSubject } from "rxjs";
import { MoneyInsightsTextService } from "../../services/money-insights.text.service";

@Component({
  selector: "ent-money-insight",
  templateUrl: "./money-insight.component.html",
})
export class MoneyInsightComponent implements OnInit {
  url: string;
  frameTitle: string;
  isRegistered: boolean;
  errorMessage: string;
  componentState = new BehaviorSubject(COMPONENT_STATE.LOADED);

  constructor(
    private service: MoneyInsightHttpService,
    public readonly textService: MoneyInsightsTextService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.frameTitle = this.textService.moneyInsightTitle;
    this.title.setTitle(this.frameTitle);
    this.getMoneyInsightUrl();
  }

  getMoneyInsightUrl = async () => {
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      const response = await this.service.getMoneyInsightUrl();
      if (this.isMoneyInsightUrl(response)) {
        this.url = response.url;
        this.isRegistered = response.isRegistered;
        this.componentState.next(COMPONENT_STATE.LOADED);
      }
    } catch {
      this.errorMessage = this.textService.moneyInsightErrorSubText;
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  };

  onUserEnroll(event: MoneyInsightUrl | ErrorEvent) {
    if (this.isMoneyInsightUrl(event)) {
      this.url = event.url;
      this.isRegistered = event.isRegistered;
    } else if (this.isErrorEvent(event)) {
      this.errorMessage = event.message;
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  }

  private isMoneyInsightUrl(getUrlResponse?: any): getUrlResponse is MoneyInsightUrl {
    return getUrlResponse && "url" in getUrlResponse && typeof getUrlResponse.isRegistered === "boolean";
  }

  private isErrorEvent(event?: any): event is ErrorEvent {
    return event && typeof event.message === "string";
  }
}
