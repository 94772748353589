import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-from-record-display",
  templateUrl: "./from-record.component.html",
})
export class FromRecordDisplayComponent {
  @Input()
  name!: string;

  @Input()
  accountNumber!: string;

  @Input()
  fromLabel!: string;
}
