import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "ent-page-error",
  templateUrl: "./page-error.component.html",
})
export class PageErrorComponent {
  @Input()
  errorHeaderText!: string;

  @Input()
  errorText!: string;

  @Input()
  dismissButtonText?: string;

  @Output()
  clickDismiss = new EventEmitter();
}
