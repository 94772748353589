import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-amount-display",
  templateUrl: "./amount.component.html",
})
export class AmountDisplayComponent {
  @Input()
  amountLabel!: string;

  @Input()
  amount!: string;
}
