<div class="mb-3 bb-dynamic-input form-group" [formGroup]="controlFormGroup" id="test__memo-text-field">
  <label class="bb-dynamic-input__label form-label"> {{ memoLabel }} </label>
  <span class="bb-dynamic-input__helper-text bb-text-support"> {{ memoHelperText }}</span>
  <bb-textarea-ui
    [label]="''"
    [placeholder]="memoPlaceholder"
    [formControlName]="'memo'"
    [showCharCounter]="true"
    [minLength]="3"
    [maxLength]="40"
    [rows]="3"
  >
  </bb-textarea-ui>
</div>
