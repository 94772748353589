<div class="bb-block bb-block--xl" [id]="'test__' + testId">
  <div class="bb-label">{{ label }}</div>
  <bb-product-selector-ui
    [autoClose]="true"
    #productSelector
    [class.bb-product-selector--invalid-border]="isInvalid && !isDropdownOpen"
  >
    <ng-container bbDropdownToggle>
      <bb-product-item-basic-account-ui
        class="text-left"
        *ngIf="!!selectedProduct"
        [title]="selectedProduct.displayName"
        [productNumber]="selectedProduct | stringOrDefault: 'BBAN':''"
        [amount]="selectedProduct | numberOrDefault: amountKey:undefined"
        [currency]="selectedProduct.currency"
        [productNumberFormat]="numberFormat"
        [showCurrencySymbol]="true"
        [active]="true"
      >
      </bb-product-item-basic-account-ui>
      <div *ngIf="!selectedProduct" class="bb-text-support bb-product-selector__placeholder">
        <span class="align-items-left py-2">{{ noItemSelectedText }}</span>
      </div>
    </ng-container>
    <ng-container bbDropdownMenu>
      <ng-container *ngIf="products?.length > 0">
        <ng-container *ngTemplateOutlet="dropdownPrependTemplate"></ng-container>
      </ng-container>
      <ng-container *ngIf="products?.length === 0">
        <ng-container *ngTemplateOutlet="dropdownEmptyTemplate"></ng-container>
      </ng-container>
      <button
        *ngFor="let product of products"
        (click)="onSelectProduct(product)"
        type="button"
        role="option"
        class="bb-product-selector__dropdown-item btn-unstyled btn btn-md"
      >
        <bb-product-item-basic-account-ui
          [title]="product.displayName"
          [productNumber]="product | stringOrDefault: 'BBAN':''"
          [amount]="product | numberOrDefault: amountKey:undefined"
          [currency]="product.currency"
          [productNumberFormat]="numberFormat"
          [active]="!!selectedProduct && product.id === selectedProduct.id"
        ></bb-product-item-basic-account-ui>
      </button>
    </ng-container>
  </bb-product-selector-ui>
  <bb-input-validation-message-ui [showErrors]="isInvalid && !isDropdownOpen">
    <span>{{ error }}</span>
  </bb-input-validation-message-ui>
</div>
