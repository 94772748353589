import { Injectable } from "@angular/core";
import { ExternalAccount } from "@ent/data";

@Injectable({
  providedIn: "root",
})
export class CustomConnectExternalAccountsTextService {
  headerTitle = $localize`:@@connect-accounts.headerTitle:Add External Account`;
  loadingText = $localize`:@@connect-accounts.loading:Loading...`;
  errorSubText = $localize`:@@connect-accounts.error.text:Please click Refresh and try again. `;
  errorHeader = $localize`:@@connect-accounts.error.header:Hmm... we couldn’t load the screen.`;
  errorButton = $localize`:@@connect-accounts.error.button:Refresh`;

  connectErrorSubText = $localize`:@@connect-accounts.connect.error.text:We weren't able to connect your external account. We can try establishing the connection again if you'd like.`;
  connectErrorHeader = $localize`:@@connect-accounts.connect.error.header:We ran into a problem`;
  connectErrorButton = $localize`:@@connect-accounts.connect.error.button.text:Try again`;

  existingAccountErrorHeader = $localize`:@@connect-accounts.existing.error.header:This account already exists`;
  existingAccountErrorSubText = $localize`:@@connect-accounts.existing.error.text:We were unable to add your your external account, because you already added it.`;

  verifyErrorSubText = $localize`:@@connect-accounts.verify.error.text:We weren't able to verify your external account. We can try establishing the connection again if you'd like.`;

  successHeader = $localize`:@@connect-accounts.success.header:Success!`;
  successMicrodepositsHeader = $localize`:@@connect-accounts.success.microdeposits.header:One more step!`;
  repeatButton = $localize`:@@connect-accounts.success.repeat.button:Add another account`;
  cancelButton = $localize`:@@connect-accounts.success.cancel.button:Done`;

  headerActivateTitle = $localize`:@@connect-accounts.headerActivateTitle:Activate External Account`;

  confirmationTitle = $localize`:@@connect-accounts.confirmation.title:Trying to exit?`;
  confirmationMessage = $localize`:@@connect-accounts.confirmation.message:We won't be able to add your external account.`;
  confirmationVerifyMessage = $localize`:@@connect-accounts.confirmation.verify.message:We won't be able to verify your external account.`;
  confirmationYesButton = $localize`:@@connect-accounts.confirmation.yes.button:Yes, Exit`;
  confirmationNoButton = $localize`:@@connect-accounts.confirmation.no.button:Back`;

  getSuccessSubText = (account: ExternalAccount) =>
    $localize`:@@connect-accounts.success.text:You have successfully added your ${account.bankName} ${account.accountType} ending in ${account.maskedAccountNumber}`;
  getSuccessMicrodepositSubText = (account: ExternalAccount) =>
    $localize`:@@connect-accounts.microdeposits.success.text: We've sent two small deposits, each less than a dollar, to your  ${account.bankName} ${account.accountType} account ending in ${account.maskedAccountNumber}. Once you receive and see the deposits, usually within 2-4 business days, enter the amounts here to finalize your activation.`;
  getSuccessVerificationSubText = (account: ExternalAccount) =>
    $localize`:@@connect-accounts.verify.success.text:You have successfully verified your ${account.bankName} ${account.accountType} ending in ${account.maskedAccountNumber}`;
}
