import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconModule } from "@backbase/ui-ang/icon";
import { HeaderModule } from "@backbase/ui-ang/header";
import { ReactiveFormsModule } from "@angular/forms";
import { EntUIModule } from "@ent/ui";
import { SwitchModule } from "@backbase/ui-ang/switch";
import { AmountModule } from "@backbase/ui-ang/amount";
import { CollapsibleModule } from "@backbase/ui-ang/collapsible";
import { InputCheckboxModule } from "@backbase/ui-ang/input-checkbox";
import { TooltipModule } from "@backbase/ui-ang/tooltip-directive";
import { InputInlineEditModule } from "@backbase/ui-ang/input-inline-edit";
import { NotificationCardComponent } from "./notification-card.component";
import { CanDisplayChannelsPipe } from "./pipes/can-display-channels.pipe";

@NgModule({
  declarations: [NotificationCardComponent, CanDisplayChannelsPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AmountModule,
    InputInlineEditModule,
    CollapsibleModule,
    TooltipModule,
    HeaderModule,
    SwitchModule,
    InputCheckboxModule,
    IconModule,
    EntUIModule,
  ],
  exports: [NotificationCardComponent],
})
export class NotificationCardModule {}
