/* eslint-disable @typescript-eslint/naming-convention */
import { Provider } from "@angular/core";
import {
  ACCOUNT_STATEMENT_RETAIL_JOURNEY_CONFIGURATION_TOKEN,
  ACCOUNT_STATEMENT_TABLE_CONFIG_TOKEN,
  ACCOUNT_STATEMENT_CATEGORIES_TOKEN,
  AccountStatementRetailJourneyConfiguration,
} from "@backbase/account-statement-retail-journey-ang";

export const AccountStatementRetailJourneyProviders: Provider[] = [
  {
    provide: ACCOUNT_STATEMENT_RETAIL_JOURNEY_CONFIGURATION_TOKEN,
    useValue: {
      showManageStatementsNavigation: true,
      paginationType: "LOAD_MORE",
      manageStatementsNavigationUrl: "/self-service/manage-statements",
      hideManageStatementsNavigationWhenMissingPermissions: true,
    } as Partial<AccountStatementRetailJourneyConfiguration>,
  },
  {
    provide: ACCOUNT_STATEMENT_TABLE_CONFIG_TOKEN,
    useValue: [
      {
        key: "date",
        name: $localize`:@@account-statement.table.header.date:Date`,
        sortable: true,
      },
      {
        key: "category",
        name: $localize`:@@account-statement.table.header.category:Category`,
        sortable: true,
      },
      {
        key: "description",
        name: $localize`:@@account-statement.table.header.description:Description`,
        sortable: true,
      },
    ],
  },
  {
    provide: ACCOUNT_STATEMENT_CATEGORIES_TOKEN,
    useValue: {
      Statement: $localize`:@@account-statements.category.statement:Statement`,
      "Tax Document": $localize`:@@account-statements.category.taxDocument:Tax Document`,
      "Billing Statement": $localize`:@@account-statements.category.billingStatement:Billing Statement`,
    },
  },
];
