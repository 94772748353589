import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { HeaderModule } from "@backbase/ui-ang/header";
import { TabModule } from "@backbase/ui-ang/tab";
import { LoadButtonModule } from "@backbase/ui-ang/load-button";
import { EntUIModule } from "@ent/ui";
import { ButtonModule } from "@backbase/ui-ang/button";
import { InputRadioGroupModule } from "@backbase/ui-ang/input-radio-group";
import { BadgeModule } from "@backbase/ui-ang/badge";
import { AlertModule } from "@backbase/ui-ang/alert";
import { ReactiveFormsModule } from "@angular/forms";
import { AmountInputModule } from "@backbase/ui-ang/amount-input";
import { InputValidationMessageModule } from "@backbase/ui-ang/input-validation-message";
import { ExploreProductsComponent } from "./components/explore-products/explore-products.component";
import { ExploreProductsTextService } from "./components/explore-products/services/explore-products.text.service";
import { ProductCardComponent } from "./components/explore-products/components/product-card/product-card.component";
import { ProductsComponent } from "./products.component";
import { ProductsBaseTextService } from "./services/products-base.text.service";
import { ProductsBaseStepsService } from "./services/products-base.steps.service";
import { ProductInformationComponent } from "./components/product-information/product-information.component";
import { InsertValuePipe } from "./components/product-information/pipes/insert-value.pipe";
import { ProductReviewComponent } from "./components/product-review/product-review.component";
import { MembershipSelectorComponent } from "./components/membership-selector/membership-selector.component";
import { OpenShareCardComponent } from "./components/open-share-card/open-share-card.component";
import { OpenShareBaseComponent } from "./components/open-share-base/open-share-base.component";
import { ProductConsiderationsComponent } from "./components/product-considerations/product-considerations.component";
import { ProductErrorComponent } from "./components/product-error/product-error.component";
import { ProductSuccessComponent } from "./components/product-success/product-success.component";
import { SupportTextModalComponent } from "./components/support-text-modal/support-text-modal.component";
import { ProductDepositComponent } from "./components/product-deposit/product-deposit.component";

const routes: Routes = [
  {
    path: "",
    component: ProductsComponent,
  },
];

@NgModule({
  declarations: [
    ExploreProductsComponent,
    ProductCardComponent,
    ProductsComponent,
    ProductInformationComponent,
    ProductConsiderationsComponent,
    InsertValuePipe,
    ProductReviewComponent,
    MembershipSelectorComponent,
    OpenShareCardComponent,
    OpenShareBaseComponent,
    ProductErrorComponent,
    ProductSuccessComponent,
    SupportTextModalComponent,
    ProductDepositComponent,
  ],
  providers: [ExploreProductsTextService, ProductsBaseTextService, ProductsBaseStepsService],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    HeaderModule,
    TabModule,
    BadgeModule,
    AlertModule,
    EntUIModule,
    ButtonModule,
    ReactiveFormsModule,
    LoadButtonModule,
    InputRadioGroupModule,
    AmountInputModule,
    InputValidationMessageModule,
  ],
  exports: [ExploreProductsComponent],
})
export class ProductsModule {}
