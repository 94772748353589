<div class="d-flex justify-content-between">
  <bb-header-ui
    headingType="h1"
    [heading]="textService.titleText"
    headingClasses="bb-heading-widget__heading my-4"
  ></bb-header-ui>
  <button
    *ngIf="formState === formStates.EDITING"
    bbButton
    [color]="'secondary'"
    [buttonSize]="'sm'"
    class="my-auto py-2"
    (click)="isOpen.next(true)"
  >
    <em class="fa-sharp fa-solid fa-plus me-2 mb-1 fa-xl"></em>
    {{ textService.addRecipient }}
  </button>
</div>

<ent-add-contact-modal
  [isOpen]="isOpen | async"
  [isAddingContactLoading]="isLoadingAddContact"
  (close)="isOpen.next(false)"
  (save)="createContact($event)"
></ent-add-contact-modal>

<ent-stateful-component
  [componentState]="componentState"
  [loadingText]="textService.loadingText"
  [errorHeaderText]="textService.loadingErrorHeader"
  [errorText]="textService.loadingErrorText"
  [contentTemplate]="loadedForm"
>
</ent-stateful-component>

<ng-template #loadedForm>
  <ng-container [ngSwitch]="formState">
    <ng-container *ngSwitchCase="formStates.ACTION_NEEDED">
      <div class="card card-body">
        <div class="text-center">
          <em class="fa-regular fa-id-card-clip fa-5x mt-3"></em>
          <h3 class="my-3">{{textService.noContactsTitle}}</h3>
          <p>{{textService.noContactsDescription}}</p>
          <button bbButton [color]="'secondary'" [buttonSize]="'sm'" class="mt-3 py-2 mb-5" (click)="isOpen.next(true)">
            <em class="fa-sharp fa-solid fa-plus me-2 mb-1 fa-xl"></em>
            {{ textService.addARecipientButton }}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="formStates.EDITING">
      <ent-member-transfer-form
        [formModel]="formModel"
        [isLoading]="isLoading"
        (validateForm)="validateForm($event)"
      ></ent-member-transfer-form>
    </ng-container>
    <ng-container *ngSwitchCase="formStates.REVIEW">
      <ent-member-transfer-review
        [formModel]="formModel"
        [isLoading]="isLoading"
        [isSubmitError]="isSubmitError | async"
        [paymentOrderPostResponse]="paymentOrderPostResponse"
        (edit)="setFormState(formStates.EDITING)"
        (cancel)="restartForm()"
        (submit)="postPayment()"
      ></ent-member-transfer-review>
    </ng-container>
    <ng-container *ngSwitchCase="formStates.SUCCESS">
      <ent-member-transfer-success
        [formModel]="formModel"
        (navigateToAccounts)="goToAccounts()"
        (restart)="restartForm()"
      ></ent-member-transfer-success>
    </ng-container>
  </ng-container>
</ng-template>
