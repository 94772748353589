export interface EligibleProduct {
  type?: string;
  subCode?: string;
  description?: string;
  rate?: string;
  rates?: string[];
  accounts?: string[];
  url?: string;
  minimumDeposit?: string;
}

export interface CreateProductPayload {
  shareType: string;
  accountNumber: string;
  amount?: number;
  transferSource?: string;
}

export interface ConvertProductPayload {
  arrangementId: string;
}
export interface ShareEligibilityPayload {
  shareTypes: EligibleProduct[];
}

export interface LoanEligibilityPayload {
  loanTypes: EligibleProduct[];
}

export const NO_STRINGS_CHECKING_KEY = "NO STRINGS CHECKING";

export enum PRODUCT_ERROR_KEY {
  VERIFICATION_DENIED = "applicant.verification.denied",
}

export enum PRODUCT_FLOW_CODES {
  DEFAULT,
  BRONCOS_CONVERTION,
}

export enum PRODUCT_SUBSECTIONS {
  CHECKING = "checking",
  SAVINGS_CERTIFICATES = "savingsCertificates",
  HOME = "home",
  AUTO = "auto",
  PERSONAL = "personal",
  CARDS = "cards",
  OTHER = "other",
}

export enum PRODUCT_SECTIONS {
  BANK = "bank",
  BORROW = "borrow",
}

export enum FORM_CONTROL_NAMES {
  SHARE_TYPE = "shareType",
  SUB_CODE = "subCode",
  ACCOUNT_NUMBER = "accountNumber",
  ARRANGEMENT_ID = "arrangementId",
  NEW_BRONCOS_NAME = "newBroncosName",
  DEPOSIT_AMOUNT = "amount",
  DEPOSIT_ACCOUNT = "transferSource",
}

export enum TERM_LINKS_KEYS {
  MEMBERSHIP_SERVICE_AGREEMENT = "membershipServiceAgreement",
  PRIVACY_POLICY = "privacyPolicy",
  FEE_SCHEDULE = "feeSchedule",
  RATE_SHEET = "rateSheet",
  DEPOSIT_AVAILABILITY = "depositAvailability",
  CERTIFICATE_DISCLOSURE = "certificateDisclosure",
}

export enum SUBCODES {
  BRONCOS = "BRONCOS",
}

export enum PRODUCTS_FORM_STATE {
  EXPLORE = "EXPLORE",
  INFORMATION = "INFORMATION",
  CHECKING_ACCOUNT_SELECTION = "CHECKING_ACCOUNT_SELECTION",
  CONSIDERATIONS = "CONSIDERATIONS",
  MEMBERSHIP_SELECTION = "MEMBERSHIP_SELECTION",
  REVIEW = "REVIEW",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  DEPOSIT = "DEPOSIT",
}

export interface InformationItem {
  iconClass: string;
  title: string;
  subtitle: string;
}

export interface ReviewItem {
  title: string;
  iconClass?: string;
  formName?: string;
  value?: string;
}

export interface ProductCard extends EligibleProduct {
  iconClass: string;
  subsection: PRODUCT_SUBSECTIONS;
  section: PRODUCT_SECTIONS;
  types?: Set<string>;
  subtitle?: string;
  rateLabel?: string;
  fromAccountLabel?: string;
  fromAccountBalance?: number;
  informationLink?: string;
  successText?: string;
  successHeaderText?: string;
  benefits?: InformationItem[];
  goodToKnow?: InformationItem[];
  considerations?: InformationItem[];
  informationImageUrl?: string;
}

export interface ProductReviewAssets {
  types: Set<string>;
  section: PRODUCT_SECTIONS;
  reviewItems: ReviewItem[];
  terms: { prefixLabel: string; suffixLabel?: string };
}

export interface ProductReviewLinks {
  types: Set<string>;
  section: PRODUCT_SECTIONS;
  links?: string[];
}

export interface TermLink {
  text: string;
  url: string;
}

export interface ProductCardLayout {
  [x: string]: { [x: string]: ProductCard[] };
}

export interface SelectionItem {
  value: string;
  label: string;
}
