import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  private loaders = new Map();
  private loaderCountObservable = new BehaviorSubject<Map<string, number>>(new Map());

  getLoaderCount = (key: string) => this.loaders.get(key) ?? 0;

  startLoading = (loadingKey: string) => {
    this.loaders.set(loadingKey, this.getLoaderCount(loadingKey) + 1);
    this.loaderCountObservable.next(this.loaders);
  };

  stopLoading = (loadingKey: string) => {
    this.loaders.set(loadingKey, Math.max(this.getLoaderCount(loadingKey) - 1, 0));
    this.loaderCountObservable.next(this.loaders);
  };

  isLoading = (loadingKey: string) => {
    return this.loaderCountObservable.pipe(map((loaders) => (loaders.get(loadingKey) ?? 0) > 0));
  };
}
