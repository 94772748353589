import { Component, Input } from "@angular/core";
import { InformationItem } from "@ent/data";
import { OpenShareBaseComponent } from "../open-share-base/open-share-base.component";

@Component({
  selector: "ent-product-considerations",
  templateUrl: "./product-considerations.component.html",
})
export class ProductConsiderationsComponent extends OpenShareBaseComponent {
  @Input() considerations: InformationItem[] = [];
  @Input() showCancelButton = true;
  @Input() informationImageUrl?: string;
}
