import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  COMPONENT_STATE,
  AlertSettings,
  AlertSettingsHttpService,
  AlertSettingPayload,
  AlertSetting,
  CardData,
} from "@ent/data";
import { BehaviorSubject } from "rxjs";
import { Notification, NotificationService } from "@backbase/ui-ang/notification";
import { ManageNotificationsTextService } from "../services/manage-notifications.text.service";

/* eslint-disable @typescript-eslint/naming-convention */
export enum CARD_ORDER {
  "account-balance-low",
  "new-transaction-occurred",
  "new-deposit-occurred",
  "new-withdrawal-occurred",
  "balance-on-schedule",
}

export enum CHANNELS_ORDER {
  "sms",
  "email",
  "in-app-notification",
  "push",
}
/* eslint-enable @typescript-eslint/naming-convention */

@Component({
  selector: "ent-custom-product-settings",
  templateUrl: "./custom-product-settings.component.html",
})
export class CustomProductSettingsComponent implements OnInit {
  entityId: string;
  alertSettings: AlertSettings;
  isSaving = false;
  componentState = new BehaviorSubject(COMPONENT_STATE.LOADING);
  readonly cardOrder = CARD_ORDER;
  readonly channelsOrder = CHANNELS_ORDER;

  readonly successNotificationConfig: Notification = {
    message: this.textService.notificationSuccess,
    modifier: "success",
    dismissible: true,
  };

  readonly errorNotificationConfig: Notification = {
    message: this.textService.notificationError,
    modifier: "error",
    dismissible: true,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: AlertSettingsHttpService,
    private notificationService: NotificationService,
    public textService: ManageNotificationsTextService,
  ) {}

  ngOnInit(): void {
    this.getAlertSettings();
  }

  async getAlertSettings() {
    try {
      this.route.params.subscribe((params) => {
        this.entityId = params.notificationSettingsAccountId || "";
      });
      this.alertSettings = await this.service.getAlertSettings(this.entityId, "arrangement");
      this.sortCards();
      this.sortChannels();
      this.componentState.next(COMPONENT_STATE.LOADED);
    } catch {
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  }

  async submit(cardData: CardData) {
    try {
      this.isSaving = true;
      const result = await this.saveOrCreateAlert(cardData.id, cardData.formData);
      if (result.id) {
        this.notificationService.showNotification(this.successNotificationConfig);
      } else throw new Error();
    } catch {
      this.notificationService.showNotification(this.errorNotificationConfig);
    } finally {
      this.isSaving = false;
      // Refresh card changes
      this.getAlertSettings();
    }
  }

  private saveOrCreateAlert(cardId: string, data: AlertSettingPayload): Promise<AlertSetting> {
    return cardId ? this.service.updateAlertSetting(cardId, data) : this.service.createAlertSetting(data);
  }

  navigateBack() {
    this.router.navigate(["../manage-notifications"], { relativeTo: this.route });
  }

  private sortCards() {
    this.alertSettings.notificationPreferences.sort((a, b) =>
      this.cardOrder[a.generalNotificationId] > this.cardOrder[b.generalNotificationId] ? 1 : -1,
    );
  }

  private sortChannels() {
    this.alertSettings.notificationPreferences.forEach((preference) => {
      preference.channels.sort((a, b) => (this.channelsOrder[a.channel] > this.channelsOrder[b.channel] ? 1 : -1));
    });
  }
}
