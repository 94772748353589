import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { PaymentMode, PaymentOrdersPost } from "@backbase/payment-order-http-ang";
import {
  PaymentOrderHTTPService,
  ArrangementsHTTPService,
  LoadingService,
  FORM_STATE,
  PRODUCT_KINDS,
  COMPONENT_STATE,
  ExternalTransfersHTTPService,
} from "@ent/data";
import { NotificationService } from "@backbase/ui-ang/notification";
import { DateTime } from "luxon";
import { IFormModel } from "../models/form-model";
import { decorateExternalAccountPaymentOrder } from "../models/helpers";
import { BaseWrapperComponent } from "../components/base-wrapper/base-wrapper.component";
import { TransferTextService } from "./services/transfer.text.service";
import { APIErrorTextService } from "../services/api-error.text.service";

@Component({
  selector: "ent-transfer",
  templateUrl: "transfer.component.html",
  styleUrls: ["transfer.component.scss"],
})
export class TransferComponent extends BaseWrapperComponent implements OnInit {
  formState = FORM_STATE.EDITING;
  formStates = FORM_STATE;
  componentState = COMPONENT_STATE.LOADING;
  isSubmitError = new BehaviorSubject<boolean>(false);
  isLoading = false;
  formModel: IFormModel;
  today = DateTime.now().startOf("day");

  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor(
    router: Router,
    loadingService: LoadingService,
    paymentOrderHTTPService: PaymentOrderHTTPService,
    arrangementHTTPService: ArrangementsHTTPService,
    notificationService: NotificationService,
    apiErrorTextService: APIErrorTextService,
    protected readonly externalTransfersHTTPService: ExternalTransfersHTTPService,
    public readonly textService: TransferTextService,
  ) {
    super(
      router,
      loadingService,
      paymentOrderHTTPService,
      arrangementHTTPService,
      notificationService,
      apiErrorTextService,
    );
  }

  loadProducts = async (fromProductKinds: PRODUCT_KINDS[], toProductKinds: PRODUCT_KINDS[]) => {
    this.componentState = COMPONENT_STATE.LOADING;

    const externalTransfersPromise = this.externalTransfersHTTPService.getExternalTransferAccounts().catch(() => {
      this.notificationService.showNotification({
        header: this.textService.externalTransferErrorHeader,
        message: this.textService.externalTransferErrorBody,
        dismissible: true,
        modifier: "error",
        ttl: 0,
      });
      return Promise.resolve();
    });
    try {
      await Promise.all([
        this.arrangementHTTPService.getFromProducts(fromProductKinds),
        this.arrangementHTTPService.getToProducts(toProductKinds),
        externalTransfersPromise,
      ]);
      this.componentState = COMPONENT_STATE.LOADED;
    } catch (err) {
      this.componentState = COMPONENT_STATE.ERROR;
    }
  };

  getInitialFormModel = () => ({
    fromAccount: undefined,
    toAccount: undefined,
    amount: 0,
    paymentMode: PaymentMode.SINGLE,
    executionDate: this.today.toISO(),
    memo: undefined,
    frequency: "ONCE",
  });

  ngOnInit(): void {
    this.initialize();
    this.loadProducts(
      [
        PRODUCT_KINDS.CREDIT_CARD,
        PRODUCT_KINDS.DEBIT_CARD,
        PRODUCT_KINDS.INVESTMENT_ACCOUNT,
        PRODUCT_KINDS.TERM_DEPOSIT,
      ],
      [
        PRODUCT_KINDS.LOAN,
        PRODUCT_KINDS.CREDIT_CARD,
        PRODUCT_KINDS.DEBIT_CARD,
        PRODUCT_KINDS.INVESTMENT_ACCOUNT,
        PRODUCT_KINDS.TERM_DEPOSIT,
      ],
    );
  }

  createPaymentOrderFormRequest(formModel: IFormModel): PaymentOrdersPost {
    let paymentOrderRequest = super.createPaymentOrderFormRequest(formModel);
    if (formModel.fromAccount?.isExternal || formModel.toAccount?.isExternal) {
      if (formModel.fromAccount?.isExternal) {
        paymentOrderRequest = decorateExternalAccountPaymentOrder(formModel, paymentOrderRequest);
      }
      if (formModel.toAccount?.isExternal) {
        paymentOrderRequest.paymentType = "EXTERNAL_A2A";
        paymentOrderRequest.originatorAccount.identification.identification = formModel.fromAccount.id;
        paymentOrderRequest.originatorAccount.identification.schemeName = "ID";
        paymentOrderRequest.originatorAccount.name = formModel.fromAccount.name;

        paymentOrderRequest.transferTransactionInformation.counterparty.name = formModel.toAccount.name;
        paymentOrderRequest.transferTransactionInformation.counterparty.role = "CREDITOR";
        paymentOrderRequest.transferTransactionInformation.counterpartyAccount.identification.identification =
          formModel.toAccount.id;
        paymentOrderRequest.transferTransactionInformation.counterpartyAccount.identification.schemeName =
          "EXTERNAL_ID";
      }
    }
    return paymentOrderRequest;
  }

  restartForm() {
    super.restartForm();
    this.loadProducts(
      [
        PRODUCT_KINDS.CREDIT_CARD,
        PRODUCT_KINDS.DEBIT_CARD,
        PRODUCT_KINDS.INVESTMENT_ACCOUNT,
        PRODUCT_KINDS.TERM_DEPOSIT,
      ],
      [
        PRODUCT_KINDS.LOAN,
        PRODUCT_KINDS.CREDIT_CARD,
        PRODUCT_KINDS.DEBIT_CARD,
        PRODUCT_KINDS.INVESTMENT_ACCOUNT,
        PRODUCT_KINDS.TERM_DEPOSIT,
      ],
    );
  }
}
