import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import {
  END_TYPE,
  FREQUENCY_SELECTOR,
  getEndTypeFromDuration,
  getOccurrenceFromPaymentMode,
  OCCURRENCE,
} from "@ent/data";
import { PaymentOrdersPostResponse } from "@backbase/payment-order-http-ang";
import { DateTime } from "luxon";
import { BehaviorSubject, Subject } from "rxjs";
import { IFormModel } from "../../models/form-model";
import { IReviewTextService } from "../../services/review.text.service";
import {
  A2A_ERROR_CODES,
  PAYMENT_ORDER_ERROR_CODES,
  SYMITAR_ERROR_CODES,
} from "../payment-error-banner/payment-error-banner.text.service";

@Component({
  selector: "ent-base-review",
  template: "<div style='display:none;'>DO NOT RENDER</div>",
})
export class BaseReviewComponent implements OnChanges, OnInit {
  @Input()
  formModel!: IFormModel;

  @Input()
  isLoading!: boolean;

  @Input()
  isSubmitError?: boolean;

  @Input()
  paymentOrderPostResponse?: PaymentOrdersPostResponse = undefined;

  @Output()
  edit = new EventEmitter();

  @Output()
  submit = new EventEmitter();

  @Output()
  cancel = new EventEmitter();

  isDiscardModalOpen = new Subject<boolean>();

  errorCode: BehaviorSubject<PAYMENT_ORDER_ERROR_CODES | undefined> = new BehaviorSubject(undefined);

  occurrencesEnum = OCCURRENCE;
  transferDateFormatted = "";
  endType = END_TYPE.NEVER;
  frequencyTypeText: string;
  occurrence = OCCURRENCE.NOW;

  ngOnInit(): void {
    this.frequencyTypeText = this.getReviewTextService().frequencyMonthlyLabel;
    this.setFrequencyType();
  }

  ngOnChanges(): void {
    this.setOccurrence();
    this.setTransferDateFormatted();
    this.setEndType();
    this.setFrequencyType();
    this.setErrorCode();
  }

  onEdit = () => this.edit.emit();
  onSubmit = () => this.submit.emit();
  onCancel = () => this.cancel.emit();

  openDiscardModal = () => this.isDiscardModalOpen.next(true);
  closeDiscardModal = () => this.isDiscardModalOpen.next(false);

  setOccurrence = () => {
    this.occurrence = getOccurrenceFromPaymentMode(this.formModel.paymentMode, this.formModel.executionDate);
  };

  setTransferDateFormatted = () => {
    this.transferDateFormatted = DateTime.fromISO(this.formModel.executionDate).toFormat("MMMM dd, yyyy");
  };

  setEndType = () => {
    this.endType = getEndTypeFromDuration(this.formModel.repeat, this.formModel.endDate);
  };

  setFrequencyType = () => {
    switch (this.formModel.frequency) {
      case FREQUENCY_SELECTOR.WEEKLY:
        this.frequencyTypeText = this.getReviewTextService().frequencyWeeklyLabel;
        break;
      case FREQUENCY_SELECTOR.BIWEEKLY:
        this.frequencyTypeText = this.getReviewTextService().frequencyBiWeeklyLabel;
        break;
      case FREQUENCY_SELECTOR.BIMONTHLY:
        this.frequencyTypeText = this.getReviewTextService().frequencyBiMonthlyLabel;
        break;
      case FREQUENCY_SELECTOR.QUARTERLY:
        this.frequencyTypeText = this.getReviewTextService().frequencyQuarterlyLabel;
        break;
      case FREQUENCY_SELECTOR.SEMIANNUALLY:
        this.frequencyTypeText = this.getReviewTextService().frequencySemiAnnuallyLabel;
        break;
      case FREQUENCY_SELECTOR.ANNUALLY:
        this.frequencyTypeText = this.getReviewTextService().frequencyAnnuallyLabel;
        break;
      case FREQUENCY_SELECTOR.MONTHLY:
      default:
        this.frequencyTypeText = this.getReviewTextService().frequencyMonthlyLabel;
        break;
    }
  };

  setErrorCode = () => {
    const reasonCode = this.paymentOrderPostResponse?.reasonCode;
    if (SYMITAR_ERROR_CODES.includes(reasonCode) && !A2A_ERROR_CODES.includes(reasonCode)) {
      this.errorCode.next(`sym.${reasonCode}` as PAYMENT_ORDER_ERROR_CODES);
    } else if (A2A_ERROR_CODES.includes(reasonCode) && !SYMITAR_ERROR_CODES.includes(reasonCode)) {
      this.errorCode.next(`a2a.${reasonCode}` as PAYMENT_ORDER_ERROR_CODES);
    } else if (A2A_ERROR_CODES.includes(reasonCode) && SYMITAR_ERROR_CODES.includes(reasonCode)) {
      throw new Error("Found an error that was both in Symitar and A2A and was not handled");
    } else {
      this.errorCode.next(undefined);
    }
  };

  getReviewTextService = (): IReviewTextService => {
    throw new Error("Not implemented");
  };
}
