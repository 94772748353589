import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProductSummaryHttpService } from "@backbase/arrangement-manager-http-ang";
import { BehaviorSubject, combineLatest } from "rxjs";
import { PRODUCT_KINDS } from "../../models/product-kinds";
import { LOADING_KEYS } from "../../models/loading-keys";
import { LoadingService } from "../loading.service";
import { ArrangementItemWithAdditions } from "../../models/arrangements-with-additions";

@Injectable({ providedIn: "root" })
export class ArrangementsHTTPService {
  initialLoading = true;
  validateLoading = false;
  submitLoading = false;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly productSummaryHttpService: ProductSummaryHttpService,
  ) {
    // add in the base path to fix upgrade 2023.06 issue where this base path was removed
    // productSummaryHttpService.configuration.basePath = "/api/arrangement-manager";
  }

  fromProductsObservable = new BehaviorSubject<ArrangementItemWithAdditions[]>([]);
  toProductsObservable = new BehaviorSubject<ArrangementItemWithAdditions[]>([]);

  setupLoaders = () => {
    combineLatest([
      this.loadingService.isLoading(LOADING_KEYS.INITIAL_LOADING),
      this.loadingService.isLoading(LOADING_KEYS.VALIDATE_LOADING),
      this.loadingService.isLoading(LOADING_KEYS.SUBMIT_LOADING),
    ]).subscribe(this.setLoadingState);
  };

  setLoadingState = ([initialLoading, validateLoading, submitLoading]: boolean[]) => {
    this.initialLoading = initialLoading;
    this.validateLoading = validateLoading;
    this.submitLoading = submitLoading;
  };

  getFromProducts = (ignoredProductKindNames: PRODUCT_KINDS[]) => {
    return new Promise((resolve, reject) => {
      this.loadingService.startLoading(LOADING_KEYS.INITIAL_LOADING);
      this.productSummaryHttpService
        .getArrangementsByBusinessFunction({
          businessFunction: "A2A Transfer",
          resourceName: "Payments",
          privilege: "create",
          ignoredProductKindNames,
          debitAccount: true,
          size: 100,
        })
        .subscribe({
          next: (data) => {
            this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
            this.fromProductsObservable.next(data as ArrangementItemWithAdditions[]);
            resolve(data);
          },
          error: (err) => {
            this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
            reject(err);
          },
        });
    });
  };

  getToProducts = (ignoredProductKindNames: PRODUCT_KINDS[]) => {
    return new Promise((resolve, reject) => {
      this.loadingService.startLoading(LOADING_KEYS.INITIAL_LOADING);
      this.productSummaryHttpService
        .getArrangementsByBusinessFunction({
          businessFunction: "A2A Transfer",
          resourceName: "Payments",
          privilege: "create",
          ignoredProductKindNames,
          creditAccount: true,
          size: 100,
        })
        .subscribe({
          next: (data) => {
            this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
            if (!(data instanceof HttpErrorResponse)) {
              this.toProductsObservable.next(data as ArrangementItemWithAdditions[]);
            }
            resolve(data);
          },
          error: (err) => {
            this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
            reject(err);
          },
        });
    });
  };
}
