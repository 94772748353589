import { END_TYPE, FREQUENCY_SELECTOR, getMinimumEndDateFromStartDate, OCCURRENCE } from "@ent/data";
import { DateTime } from "luxon";
import * as yup from "yup";
import { ADD_CONTACTS_FORM_ERROR_KEY, MEMBER_TRANSFER_FORM_ERROR_KEY } from "./member-transfer-errors";

export const memberTransferFormSchema = yup.object().shape({
  fromAccountId: yup.string().required(MEMBER_TRANSFER_FORM_ERROR_KEY.FROM_REQUIRED),
  fromAccountBalance: yup.number().nullable(),
  toAccountId: yup.string().required(MEMBER_TRANSFER_FORM_ERROR_KEY.TO_REQUIRED),
  amount: yup
    .number()
    .required(MEMBER_TRANSFER_FORM_ERROR_KEY.AMOUNT_REQUIRED)
    .min(0.01, MEMBER_TRANSFER_FORM_ERROR_KEY.AMOUNT_REQUIRED)
    .when("fromAccountBalance", (fromAccountBalance: number | undefined, schema) => {
      return schema.max(fromAccountBalance ?? Number.MAX_SAFE_INTEGER, MEMBER_TRANSFER_FORM_ERROR_KEY.AMOUNT_MAX);
    })
    .when("occurrence", {
      is: (occurrence: OCCURRENCE) => occurrence !== OCCURRENCE.NOW,
      then: yup.number().max(Number.MAX_SAFE_INTEGER, MEMBER_TRANSFER_FORM_ERROR_KEY.AMOUNT_MAX),
    }),
  occurrence: yup
    .string()
    .required(MEMBER_TRANSFER_FORM_ERROR_KEY.OCCURRENCE_REQUIRED)
    .oneOf(
      [OCCURRENCE.NOW, OCCURRENCE.LATER, OCCURRENCE.RECURRING],
      MEMBER_TRANSFER_FORM_ERROR_KEY.OCCURRENCE_REQUIRED,
    ),
  executionDate: yup
    .date()
    .required(MEMBER_TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_REQUIRED)
    .when("occurrence", (occurrence: OCCURRENCE, schema) => {
      if (occurrence === OCCURRENCE.NOW) {
        return schema.min(DateTime.now().startOf("day").toJSDate(), MEMBER_TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_MIN);
      }

      return schema.min(
        DateTime.now().plus({ day: 1 }).startOf("day").toJSDate(),
        MEMBER_TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_MIN,
      );
    }),
  frequency: yup.string().when("occurrence", {
    is: OCCURRENCE.RECURRING,
    then: yup
      .string()
      .required(MEMBER_TRANSFER_FORM_ERROR_KEY.FREQUENCY_REQUIRED)
      .oneOf(
        [
          FREQUENCY_SELECTOR.WEEKLY,
          FREQUENCY_SELECTOR.BIWEEKLY,
          FREQUENCY_SELECTOR.MONTHLY,
          FREQUENCY_SELECTOR.BIMONTHLY,
          FREQUENCY_SELECTOR.QUARTERLY,
          FREQUENCY_SELECTOR.SEMIANNUALLY,
          FREQUENCY_SELECTOR.ANNUALLY,
        ],
        MEMBER_TRANSFER_FORM_ERROR_KEY.FREQUENCY_REQUIRED,
      ),
    otherwise: yup
      .string()
      .required(MEMBER_TRANSFER_FORM_ERROR_KEY.FREQUENCY_REQUIRED)
      .oneOf([FREQUENCY_SELECTOR.ONCE], MEMBER_TRANSFER_FORM_ERROR_KEY.FREQUENCY_REQUIRED),
  }),
  endType: yup.string().when("occurrence", {
    is: OCCURRENCE.RECURRING,
    then: yup
      .string()
      .oneOf([END_TYPE.AFTER, END_TYPE.NEVER, END_TYPE.ON], MEMBER_TRANSFER_FORM_ERROR_KEY.END_TYPE_REQUIRED)
      .required(MEMBER_TRANSFER_FORM_ERROR_KEY.END_TYPE_REQUIRED),
    otherwise: yup.string().optional().nullable(),
  }),
  endDate: yup.date().when(["occurrence", "endType", "frequency", "executionDate"], (...rest) => {
    const [occurrence, endType, frequency, executionDate, schema] = rest;
    if (occurrence === OCCURRENCE.RECURRING && endType === END_TYPE.ON) {
      const luxonExecutionDate = DateTime.fromJSDate(executionDate);
      const minDate = getMinimumEndDateFromStartDate(
        frequency,
        luxonExecutionDate.isValid ? luxonExecutionDate.toISO() : undefined,
      );
      if (minDate) {
        return schema
          .required(MEMBER_TRANSFER_FORM_ERROR_KEY.END_DATE_REQUIRED)
          .min(minDate, MEMBER_TRANSFER_FORM_ERROR_KEY.END_DATE_MIN);
      }
      return schema.required(MEMBER_TRANSFER_FORM_ERROR_KEY.END_DATE_REQUIRED);
    }
    return schema.optional().nullable();
  }),
  repeat: yup.number().when(["occurrence", "endType"], {
    is: (occurrence: OCCURRENCE, endType: END_TYPE) =>
      occurrence === OCCURRENCE.RECURRING && endType === END_TYPE.AFTER,
    then: yup
      .number()
      .required(MEMBER_TRANSFER_FORM_ERROR_KEY.REPEAT_REQUIRED)
      .min(2, MEMBER_TRANSFER_FORM_ERROR_KEY.REPEAT_REQUIRED),
    otherwise: yup.number().optional().nullable(),
  }),
  memo: yup.string().nullable(),
});

export const addContactsSchema = yup.object().shape({
  lastNameOrBusinessName: yup.string().required(ADD_CONTACTS_FORM_ERROR_KEY.NAME_REQUIRED),
  accountNumber: yup.string().required(ADD_CONTACTS_FORM_ERROR_KEY.ACCOUNT_NUMBER_REQUIRED),
});
