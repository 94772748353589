/* eslint-disable @typescript-eslint/naming-convention */
import { GetPhoneAddress, UserProfile } from "@backbase/user-http-ang";
import { PHONE_TYPE } from "../services";

export const getTypeFromPhoneAddress = (phoneAddress: GetPhoneAddress) => {
  switch (phoneAddress.type.toLowerCase()) {
    case "work":
      return PHONE_TYPE.WORK;
    case "home":
      return PHONE_TYPE.HOME;
    case "mobile":
      return PHONE_TYPE.MOBILE;
    default:
      return "";
  }
};

export const normalizePhoneAddressFromAPICall = (profile: UserProfile): UserProfile => {
  const phoneAddresses = (profile["phone-addresses"] ?? []).map((phoneAddress) => ({
    ...phoneAddress,
    type: getTypeFromPhoneAddress(phoneAddress),
  }));
  return {
    ...profile,
    "phone-addresses": phoneAddresses,
  };
};
