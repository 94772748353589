import { Component, OnChanges } from "@angular/core";
import { BaseReviewComponent } from "../../components/base-review/base-review.component";
import { TransferReviewTextService } from "../services/transfer-review.text.service";

@Component({
  selector: "ent-transfer-review",
  templateUrl: "./transfer-review.component.html",
})
export class TransferReviewComponent extends BaseReviewComponent implements OnChanges {
  constructor(public readonly transferReviewTextService: TransferReviewTextService) {
    super();
  }
  getReviewTextService = () => {
    return this.transferReviewTextService;
  };
}
