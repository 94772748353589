/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MxWidgetService } from "@ent/data";
import { OAuthService } from "angular-oauth2-oidc";
import { Subscription, fromEvent } from "rxjs";

const WIDGET_NAME = "mx-money-budget-widget";

@Component({
  selector: "ent-money-desktop-widget",
  templateUrl: "./money-desktop-widget.component.html",
  styleUrls: ["./money-desktop-widget.component.scss"],
})
export class MoneyDesktopWidgetComponent implements OnDestroy, OnInit {
  @Input() className = "md-widget";
  @Input() title!: string;
  @Input() url: string;
  loaded = false;
  unsubscribeFromMessages: Subscription;
  widgetSubscription: Subscription;

  constructor(private readonly oAuthService: OAuthService) {}

  ngOnInit(): void {
    this.widgetSubscription = MxWidgetService.forInsightsWidget(this.url, WIDGET_NAME).subscribe();
    this.unsubscribeFromMessages = fromEvent(window, "message").subscribe(this.handleMessage);
  }

  ngOnDestroy(): void {
    this.widgetSubscription.unsubscribe();
    this.unsubscribeFromMessages.unsubscribe();
  }

  handleMessage(event): void {
    try {
      const json = JSON.parse(event.data);
      if (json.moneyDesktop) {
        Object.assign(event, { json });
        switch (event.json.type) {
          case "load":
            this.loaded = true;
            break;
          case "ping":
            // NOTE: refresh token to keep session alive.
            this.oAuthService.refreshToken();
            break;
          default:
            break;
        }
      }
    } catch (e) {
      // Only interested in moneyDesktop events, just ignore errors
    }
  }
}
