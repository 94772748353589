import { Injectable } from "@angular/core";
import {
  entFormatCurrency,
  FlatProductItem,
  isProductAMortgage,
  getRegularPayment,
  isProductAPLOC,
  isProductAHELOC,
  isPastDue,
  formatDateTimeString,
  hasPartialPayment,
  getPartialPaymentAmount,
  isProductALOC,
} from "@ent/data";

import { IFormTextService } from "../../services/form.text.service";

@Injectable({ providedIn: "any" })
export class PaymentsFormTextService implements IFormTextService {
  fromLabel = $localize`:@@default-config.initiator-title:From`;
  toLabel = $localize`:@@default-config.beneficiary-label:To`;
  titleText = $localize`:@@loan-payments.page-title.payments.loan-payment:Pay a Loan`;
  occurrenceSelectLabel = $localize`:@@pay-loan.form.when-dropdown-label:When`;
  frequencySelectLabel = $localize`:@@pay-loan.form.frequency-dropdown-label:Frequency`;
  executionDateLabel = $localize`:@@pay-loan.form.execution-date-label: Date`;
  recurringExecutionDateLabel = $localize`:@@pay-loan.form.recurring-execution-date-label: Start Date`;
  nowLabel = $localize`:@@pay-loan.form.dropdown-option-1:Now`;
  laterLabel = $localize`:@@pay-loan.form.dropdown-option-2:Later`;
  recurringLabel = $localize`:@@pay-loan.form.dropdown-option-3:Recurring`;
  selectAccount = $localize`:@@pay-loan.form.select-account:Select an account`;
  noAccounts = $localize`:@@pay-loan.form.no-accounts:No accounts to transfer to`;
  amount = $localize`:@@pay-loan.form.amount:Amount`;
  memoLabel = $localize`:@@pay-loan.form.memo-label:Personal Note`;
  memoHelperText = $localize`:@@pay-loan.form.memo-helper-text:(Optional)`;
  memoPlaceholder = $localize`:@@pay-loan.form.memo-placeholder:Enter transfer description`;

  continueBtn = $localize`:@@pay-loan.form.continue-btn:Continue`;
  clearBtn = $localize`:@@pay-loan.form.clear-btn:Clear All Fields`;

  frequencyWeeklyLabel = $localize`:@@pay-loan.form.frequency.weekly: Every Week`;
  frequencyBiWeeklyLabel = $localize`:@@pay-loan.form.frequency.bi-weekly: Every 2 weeks`;
  frequencyMonthlyLabel = $localize`:@@pay-loan.form.frequency.monthly: Every Month`;
  frequencyBiMonthlyLabel = $localize`:@@pay-loan.form.frequency.bi-monthly: Every 2 months`;
  frequencyQuarterlyLabel = $localize`:@@pay-loan.form.frequency.quarterly: Every 3 months`;
  frequencySemiAnnuallyLabel = $localize`:@@pay-loan.form.frequency.semi-annually: Every 6 months`;
  frequencyAnnuallyLabel = $localize`:@@pay-loan.form.frequency.annually: Every Year`;

  endTypeHeaderLabel = $localize`:@@pay-loan.form.duration.header: Duration`;
  endTypeNeverEndsLabel = $localize`:@@pay-loan.form.duration.never-ends: Never Ends`;
  endTypeLastTransferDateLabel = $localize`:@@pay-loan.form.duration.last-transfer-date: Last Payment Date`;
  endTypeNumberOfTransfersLabel = $localize`:@@pay-loan.form.duration.number-of-transfers: Number of Payments`;

  /** Errors */
  fromAccountRequiredError = $localize`:@@pay-loan.form.error.from-account-required-message: Please select an account from the list`;
  toAccountRequiredError = $localize`:@@pay-loan.form.error.to-account-required-message: Please select an account from the list`;
  dateRequiredErrorMessage = $localize`:@@pay-loan.form.error.date-required:Please select a date`;
  dateInvalidFormatErrorMessage = $localize`:@@pay-loan.form.error.date-invalid:Invalid date format`;
  datePastErrorMessage = $localize`:@@pay-loan.form.error.date-past:Start date cannot be in the past`;
  executionDatePastError = $localize`:@@pay-loan.form.error.start-date-past-message: Start date cannot be in the past`;
  executionDateRequiredError = $localize`:@@pay-loan.form.error.start-date-required-message: Please select a date`;
  endDateRequiredError = $localize`:@@pay-loan.form.error.end-date-required-message: Please select an end date`;
  repeatRequiredError = $localize`:@@pay-loan.form.error.occurrence-required-error-message: Please select more than one occurrence`;
  insufficientFundsError = $localize`:@@pay-loan.form.error.insufficient-funds-message: The dollar amount is more than the payment source. Choose another payment type or a different payment source.`;

  /** payment radio group */
  paymentDueLabel = $localize`:@@pay-loan.form.payment-due-label: Payment Due`;
  pastDueLabel = $localize`:@@pay-loan.form.past-due: Past Due`;
  paymentDuePastDueHelperText = $localize`:@@pay-loan.form.payment-due-past-due-helper-text: The past due payment and the monthly payment`;
  otherAmountLabel = $localize`:@@payloan.form.other-amount-label:Other Amount`;
  principalOnlyLabel = $localize`:@@pay-loan.form.principal-only-label: Principal Only`;
  amountToMaxError = $localize`:@@pay-loan.form.error.amount-to-max-message: This exceeds the loan amount. Enter a lower dollar amount.`;
  principalAmountMinimumError = $localize`:@@pay-loan.form.amount-minimum:Enter an amount that is greater than the minimum payment of $0.99`;
  principalAmountRequiredError = $localize`:@@pay-loan.form.principal-amount-invalid:Enter the amount you would like to apply to your principal`;
  otherAmountRequiredError = $localize`:@@pay-loan.form.error.amount-required-message: Enter the amount you would like to pay`;
  fullBalanceSubText = $localize`:@@pay-loan.form.full-balance-subtext:Includes principal and all accrued interest`;

  paymentDueDateHelperText = (toAccount?: FlatProductItem) => {
    return hasPartialPayment(toAccount)
      ? $localize`:@@pay-loan.form.partial-payment:You paid ${entFormatCurrency(
          getPartialPaymentAmount(toAccount),
        )} of your ${entFormatCurrency(
          getRegularPayment(toAccount),
        )} bill. The remaining is due on ${formatDateTimeString(toAccount.paymentDueDate, "LLLL dd")}:INTERPOLATION:`
      : $localize`:@@pay-loan.payment-due-date: Payment is due on ${formatDateTimeString(
          toAccount.paymentDueDate,
          "LLLL dd",
        )}:INTERPOLATION:`;
  };

  otherAmountMinimumError = (toAccount: FlatProductItem) => {
    if (isProductAMortgage(toAccount)) {
      return $localize`:@@pay-loan.form.amount-minimum-mortgage: Enter an amount equal to or greater than the minimum payment of ${entFormatCurrency(
        getRegularPayment(toAccount),
      )}:INTERPOLATION:.`;
    }
    return $localize`:@@pay-loan.form.amount-minimum: Enter an amount that is greater than the minimum payment of $0.99`;
  };

  principalSubTextLabel = (toAccount: FlatProductItem) => {
    if (isProductAMortgage(toAccount) && isPastDue(toAccount)) {
      return $localize`:@@pay-loan.form.principal-only-subtext-mortgage-past-due:Need to make the monthly payment before you can make a principal only payment`;
    }
    return $localize`:@@pay-loan.form.principal-only-subtext:The amount of the loan without interest. This will not satisfy your monthly payment.`;
  };

  otherAmountSubTextLabel = (toAccount: FlatProductItem) => {
    if (isProductAPLOC(toAccount)) {
      return $localize`:@@pay-loan.form.other-amount-subtext-loc:Additional principal after interest is satisfied`;
    }
    if (isProductAHELOC(toAccount)) {
      return $localize`:@@pay-loan.form.other-amount-subtext-heloc:Additional principal after billed interest is satisfied`;
    }

    return $localize`:@@pay-loan.form.other-amount-subtext:Additional payment after the interest of the loan is satisfied`;
  };

  endDateLessThanMinDateError = (minDate: string) =>
    $localize`:@@pay-loan.form.error.end-date-less-than-min-date: Please select a date on or later than ${minDate}`;

  amountFromMaxError = (fromAccount?: FlatProductItem) =>
    $localize`:@@pay-loan.form.error.amount-more-than-from-account-balance: Dollar amount exceeds payment source. Max payment amount is ${entFormatCurrency(
      fromAccount?.availableBalance ?? 0,
    )}:INTERPOLATION:.`;

  pastDueDateLabel = (toAccount?: FlatProductItem) => {
    if (hasPartialPayment(toAccount)) {
      return $localize`:@@pay-loan.form.past-due-with-partial-payment:You paid ${entFormatCurrency(
        getPartialPaymentAmount(toAccount),
      )} of your ${entFormatCurrency(
        getRegularPayment(toAccount),
      )} bill. The remaining was due on ${formatDateTimeString(toAccount.paymentDueDate, "LLLL dd")}:INTERPOLATION:`;
    }
    return $localize`:@@pay-loan.form.past-due-date:The payment that was due on ${formatDateTimeString(
      toAccount.paymentDueDate,
      "LLLL dd",
    )}:INTERPOLATION:`;
  };

  fullBalanceLabel = (toAccount?: FlatProductItem) => {
    if (isProductALOC(toAccount)) {
      return $localize`:@@pay-loan.form.full-balance-loc:Full Balance`;
    }
    return $localize`:@@pay-loan.form.full-balance:Payoff`;
  };
}
