import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { AlertModule } from "@backbase/ui-ang/alert";
import { HeaderModule } from "@backbase/ui-ang/header";
import { AmountModule } from "@backbase/ui-ang/amount";
import { LoadButtonModule } from "@backbase/ui-ang/load-button";
import { ButtonModule } from "@backbase/ui-ang/button";
import { InputRadioGroupModule } from "@backbase/ui-ang/input-radio-group";
import { InputNumberModule } from "@backbase/ui-ang/input-number";
import { InputValidationMessageModule } from "@backbase/ui-ang/input-validation-message";
import { ModalModule } from "@backbase/ui-ang/modal";
import { ContactFormModule } from "@backbase/internal-contact-manager-journey-ui";
import { EntUIModule } from "@ent/ui";
import { LoadingIndicatorModule } from "@backbase/ui-ang/loading-indicator";
import { MemberTransfersComponent } from "./member-transfers.component";
import { MemberTransferFormComponent } from "./member-transfer-form/member-transfer-form.component";
import { MemberTransferSuccessComponent } from "./member-transfer-success/member-transfer-success.component";
import { MemberTransferReviewComponent } from "./member-transfer-review/member-transfer-review.component";
import { MoneyMovementModule } from "../money-movement.module";
import { AddContactModalComponent } from "./add-contact-modal/add-contact-modal.component";

@NgModule({
  declarations: [
    MemberTransfersComponent,
    MemberTransferFormComponent,
    MemberTransferSuccessComponent,
    MemberTransferReviewComponent,
    AddContactModalComponent,
  ],
  imports: [
    ContactFormModule,
    CommonModule,
    RouterModule.forChild([{ path: "", component: MemberTransfersComponent, pathMatch: "full" }]),
    EntUIModule,
    AlertModule,
    HeaderModule,
    AmountModule,
    LoadButtonModule,
    ButtonModule,
    InputRadioGroupModule,
    InputNumberModule,
    ReactiveFormsModule,
    InputValidationMessageModule,
    MoneyMovementModule,
    ModalModule,
    LoadingIndicatorModule,
  ],
  providers: [],
})
export class MemberTransfersModule {}
