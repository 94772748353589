<bb-retail-layout [displayNotificationSettingsButton]="displayNotificationSettingsButton$ | async">
  <!-- <bb-navigation-menu></bb-navigation-menu> This is intentionally commented out -->
  <div
    #mainContent
    tabindex="-1"
    class="sr-only-foccusable"
    aria-label="Main Content Focus"
    i18n-aria-label="Main Content Focus aria-label@@bb-layout.main-content.focus-content.aria-label"
  ></div>
  <router-outlet (activate)="onActivate()"></router-outlet>
  <ng-container *bbIfEntitlements="permissions.canViewRealTimeCommunicationChat">
    <bb-ewa-rtc-client></bb-ewa-rtc-client>
  </ng-container>
</bb-retail-layout>
