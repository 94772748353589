import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountsCommunicationService as AccountsCommunicationServiceAPI } from "@backbase/accounts-transactions-journey-ang";

@Injectable({
  providedIn: "root",
})
export class AccountsCommunicationService implements AccountsCommunicationServiceAPI {
  constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}

  navigateToExternalAccountDetails(navigationInfo: { id: string; kind: string }) {
    if (navigationInfo.kind.toLowerCase().includes("loan")) {
      this.navigateToLoansInfoPage(navigationInfo.id);
    } else if (navigationInfo.kind.toLowerCase().includes("credit card")) {
      this.navigateToCreditCardsInfoPage(navigationInfo.id);
    } else {
      /* eslint-disable-next-line */
      console.error("Not configured navigation to account external details: ", navigationInfo);
    }
  }

  navigateToLoansInfoPage(accountId: string) {
    this.router.navigate([`my-accounts/loans/details/${accountId}/transactions`], { relativeTo: this.route });
    window.scrollTo(0, 0);
  }

  navigateToCreditCardsInfoPage(accountId: string) {
    this.router.navigateByUrl(`/my-accounts/transactions;selectedAccount=${accountId}/details`);
    window.scrollTo(0, 0);
  }
}
