<ng-container [formGroup]="controlFormGroup">
  <div class="mb-3 bb-dynamic-input form-group">
    <bb-input-radio-group-ui
      [horizontal]="false"
      [required]="true"
      name="paymentOption"
      formControlName="paymentOption"
      inputSelector=".bb-input-radio-group"
    >
      <bb-input-radio-ui
        value="PAST_DUE"
        [labelTemplate]="this.pastDueDateLabel"
        *ngIf="this.isPastDue"
      ></bb-input-radio-ui>
      <bb-input-radio-ui
        value="MONTHLY_DUE"
        [labelTemplate]="this.dueDateLabel"
        *ngIf="
          !(
            this.isPastDue &&
            (toAccount.arrangementType === arrangementTypesEnum.LOC ||
              toAccount.arrangementType === arrangementTypesEnum.MORTGAGE)
          )
        "
      ></bb-input-radio-ui>
      <bb-input-radio-ui
        value="PRINCIPAL"
        [labelTemplate]="this.principalLabel"
        [disabled]="this.isPrincipalOnlyDisabled"
        *ngIf="toAccount.arrangementType !== arrangementTypesEnum.LOC"
      ></bb-input-radio-ui>
      <bb-input-radio-ui value="OTHER_AMOUNT" [labelTemplate]="this.otherAmountLabel"></bb-input-radio-ui>
      <bb-input-radio-ui
        value="PAY_OFF"
        [labelTemplate]="this.fullBalanceLabel"
        *ngIf="toAccount.arrangementType !== arrangementTypesEnum.MORTGAGE"
      ></bb-input-radio-ui>
    </bb-input-radio-group-ui>
  </div>

  <ng-template #pastDueDateLabel>
    <div class="radio-btn__label--container" id="test__past-due-radio-option">
      <div class="radio-btn__label--header">
        <span>{{ textService.pastDueLabel }}</span>
        <bb-amount-ui class="text-danger" [currency]="'USD'" [amount]="pastDueAmount"></bb-amount-ui>
      </div>
      <div class="radio-btn__label--sub-text">
        <span> {{ pastDueLabelSubText }} </span>
      </div>
      <ng-container
        *ngIf="
          errors?.paymentOption && controlFormGroup.controls.paymentOption.value === paymentOptionsEnum.PAST_DUE;
          then amountsError
        "
      >
      </ng-container>
    </div>
    <hr class="my-3" />
  </ng-template>

  <ng-template #dueDateLabel>
    <div class="radio-btn__label--container" id="test__monthly-due-radio-option">
      <div class="radio-btn__label--header">
        <span>{{ textService.paymentDueLabel }}</span>
        <bb-amount-ui [currency]="'USD'" [amount]="monthlyDueAmount"> </bb-amount-ui>
      </div>
      <div class="radio-btn__label--sub-text">
        <span *ngIf="!this.isPastDue"> {{ paymentDueLabelSubText }} </span>
        <span *ngIf="this.isPastDue">{{ textService.paymentDuePastDueHelperText }}</span>
      </div>
      <ng-container
        *ngIf="
          !!errors?.paymentOption && controlFormGroup.controls.paymentOption.value === paymentOptionsEnum.MONTHLY_DUE;
          then amountsError
        "
      >
      </ng-container>
    </div>
    <hr class="my-3" />
  </ng-template>

  <ng-template #principalLabel>
    <div class="radio-btn__label--container" id="test__principal-radio-option">
      <div class="radio-btn__label--header">
        <span>{{ textService.principalOnlyLabel }}</span>
      </div>
      <div class="radio-btn__label--sub-text">
        <span>{{ principalLabelSubText }}</span>
      </div>
      <div
        class="input__left-aligned mt-1"
        *ngIf="controlFormGroup.controls.paymentOption.value === paymentOptionsEnum.PRINCIPAL"
      >
        <ent-amount-form-control
          [controlFormGroup]="controlFormGroup"
          [isInvalid]="!!controlFormGroup.controls.amount.touched && !!errors?.amount"
          [error]="errors?.amount"
          [testId]="'amount'"
        ></ent-amount-form-control>
      </div>
    </div>
    <hr
      [ngClass]="{
        'my-3': controlFormGroup.controls.paymentOption.value !== paymentOptionsEnum.PRINCIPAL,
        'mt-1 mb-3': controlFormGroup.controls.paymentOption.value === paymentOptionsEnum.PRINCIPAL
      }"
    />
  </ng-template>

  <ng-template #otherAmountLabel>
    <div class="radio-btn__label--container mb-0" id="test__other-radio-option">
      <div class="radio-btn__label--header">
        <span>{{ textService.otherAmountLabel }}</span>
      </div>
      <div class="radio-btn__label--sub-text">
        <span>{{ otherLabelSubText }}</span>
      </div>
      <div
        class="input__left-aligned mt-1"
        *ngIf="controlFormGroup.controls.paymentOption.value === paymentOptionsEnum.OTHER_AMOUNT"
      >
        <ent-amount-form-control
          [controlFormGroup]="controlFormGroup"
          [isInvalid]="!!controlFormGroup.controls.amount.touched && !!errors?.amount"
          [error]="errors?.amount"
          [testId]="'amount'"
        ></ent-amount-form-control>
      </div>
    </div>
    <hr
      *ngIf="toAccount?.arrangementType !== '20'"
      [ngClass]="{
        'my-3': controlFormGroup.controls.paymentOption.value !== paymentOptionsEnum.OTHER_AMOUNT,
        'mt-1 mb-3': controlFormGroup.controls.paymentOption.value === paymentOptionsEnum.OTHER_AMOUNT
      }"
    />
  </ng-template>

  <ng-template #fullBalanceLabel>
    <div class="radio-btn__label--container" id="test__full-balance-radio-option">
      <div class="radio-btn__label--header">
        <span>{{ this.fullBalanceLabelText }}</span>
        <bb-amount-ui [currency]="'USD'" [amount]="fullBalanceAmount"> </bb-amount-ui>
      </div>
      <div class="radio-btn__label--sub-text">
        <span>{{ textService.fullBalanceSubText }}</span>
      </div>
      <ng-container
        *ngIf="
          errors?.paymentOption && controlFormGroup.controls.paymentOption.value === paymentOptionsEnum.PAY_OFF;
          then amountsError
        "
      >
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template #amountsError>
  <small role="alert" class="bb-input-validation-message mt-0">
    <span data-role="required-error"> {{ errors?.paymentOption }} </span>
  </small>
</ng-template>
