import { Component, Input, OnChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  ARRANGEMENT_TYPE,
  FlatProductItem,
  getFullBalance,
  getPaymentDue,
  getPaymentDueDate,
  getPastDuePayment,
  isFutureDatedMortgagePayment,
  isInGracePeriod,
  isPastDue,
  PAYMENT_OPTION,
} from "@ent/data";
import { PaymentsFormErrors } from "../../../models/payment-errors";
import { PaymentsFormTextService } from "../../services/payments-form.text.service";

@Component({
  selector: "ent-payment-type-radio-group",
  templateUrl: "./payment-type-radio-group.component.html",
  styleUrls: ["./payment-type-radio-group.component.scss"],
})
export class PaymentTypeRadioGroupComponent implements OnChanges {
  @Input()
  controlFormGroup!: FormGroup;

  @Input()
  toAccount!: FlatProductItem;

  @Input()
  fromAccount?: FlatProductItem;

  @Input()
  errors?: PaymentsFormErrors;

  paymentOptionsEnum = PAYMENT_OPTION;
  arrangementTypesEnum = ARRANGEMENT_TYPE;

  isPastDue = false;
  isInGracePeriod = false;
  isPrincipalOnlyDisabled = false;
  pastDueAmount = 0;
  pastDueLabelSubText = "";
  monthlyDueAmount = 0;
  paymentDueDate = "";
  paymentDueLabelSubText = "";
  fullBalanceAmount = 0;
  fullBalanceLabelText = "";
  principalLabelSubText = "";
  otherLabelSubText = "";

  constructor(public readonly textService: PaymentsFormTextService) {}

  ngOnChanges(): void {
    this.isPastDue = isPastDue(this.toAccount);
    this.isInGracePeriod = isInGracePeriod(this.toAccount);
    this.monthlyDueAmount = getPaymentDue(this.toAccount);
    this.paymentDueDate = getPaymentDueDate(this.toAccount).toISO();
    this.paymentDueLabelSubText = this.textService.paymentDueDateHelperText(this.toAccount);
    this.pastDueAmount = getPastDuePayment(this.toAccount);
    this.pastDueLabelSubText = this.textService.pastDueDateLabel(this.toAccount);
    this.fullBalanceAmount = getFullBalance(this.toAccount);
    this.fullBalanceLabelText = this.textService.fullBalanceLabel(this.toAccount);
    this.principalLabelSubText = this.textService.principalSubTextLabel(this.toAccount);
    this.otherLabelSubText = this.textService.otherAmountSubTextLabel(this.toAccount);
    this.isPrincipalOnlyDisabled =
      isPastDue(this.toAccount) ||
      isFutureDatedMortgagePayment(this.toAccount, this.controlFormGroup.controls.occurrence.value);
  }
}
