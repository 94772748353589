import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DateTime } from "luxon";
import {
  OCCURRENCE,
  ArrangementsHTTPService,
  FREQUENCY_SELECTOR,
  END_TYPE,
  getMinimumEndDateFromStartDate,
  FlatProductItem,
  ARRANGEMENT_TYPE,
  formatDateTimeString,
  RetailAppRemoteConfig,
  getMinimumStartDateFromCutoffTime,
  RestrictedDatesService,
  PRODUCT_KINDS,
} from "@ent/data";
import { RemoteConfigService } from "@backbase/remote-config-ang";
import { IFormModel } from "../../models/form-model";
import { IFormErrors } from "../../models/form-errors";
import { IFormTextService } from "../../services/form.text.service";

@Component({
  selector: "ent-base-form",
  template: "<div style='display:none;'>DO NOT RENDER</div>",
})
export class BaseFormComponent implements OnInit {
  @Input()
  formModel: IFormModel;

  @Input()
  isLoading: boolean;

  @Output()
  validateForm = new EventEmitter<IFormModel>();

  form: FormGroup = new FormGroup({});
  fromAccount?: FlatProductItem;
  toAccount?: FlatProductItem;
  occurrencesEnum = OCCURRENCE;
  arrangementTypesEnum = ARRANGEMENT_TYPE;
  todayISO = DateTime.now().startOf("day").toISO();
  errors?: IFormErrors;
  minEndDate?: string;
  restrictedDates?: string[] = [];
  frequencies: { label: string; value: FREQUENCY_SELECTOR }[];
  minExecutionDate: Date;

  constructor(
    public readonly arrangementsHTTPService: ArrangementsHTTPService,
    public readonly remoteConfigService?: RemoteConfigService<RetailAppRemoteConfig>,
    public readonly restrictedDatesService?: RestrictedDatesService,
  ) {}

  ngOnInit(): void {
    this.frequencies = [
      { label: this.getFormTextService().frequencyWeeklyLabel, value: FREQUENCY_SELECTOR.WEEKLY },
      { label: this.getFormTextService().frequencyBiWeeklyLabel, value: FREQUENCY_SELECTOR.BIWEEKLY },
      { label: this.getFormTextService().frequencyMonthlyLabel, value: FREQUENCY_SELECTOR.MONTHLY },
      { label: this.getFormTextService().frequencyBiMonthlyLabel, value: FREQUENCY_SELECTOR.BIMONTHLY },
      { label: this.getFormTextService().frequencyQuarterlyLabel, value: FREQUENCY_SELECTOR.QUARTERLY },
      { label: this.getFormTextService().frequencySemiAnnuallyLabel, value: FREQUENCY_SELECTOR.SEMIANNUALLY },
      { label: this.getFormTextService().frequencyAnnuallyLabel, value: FREQUENCY_SELECTOR.ANNUALLY },
    ];
  }

  onOccurrenceChange = (value: string) => {
    const { endType, frequency, executionDate } = this.form.controls;
    let newFrequency = FREQUENCY_SELECTOR.ONCE;
    if (value === OCCURRENCE.NOW) {
      executionDate.setValue(this.todayISO);
    } else {
      executionDate.reset("");
    }
    if (value === OCCURRENCE.RECURRING) {
      newFrequency = FREQUENCY_SELECTOR.MONTHLY;
      endType.setValue(END_TYPE.NEVER);
    }
    frequency.setValue(newFrequency);
  };

  onEndTypeChange = (value: string) => {
    const { endDate, repeat, frequency, executionDate } = this.form.controls;
    switch (value) {
      case END_TYPE.AFTER:
        endDate.reset(undefined);
        repeat.reset(2);
        break;
      case END_TYPE.ON:
        repeat.reset(undefined);
        endDate.reset(getMinimumEndDateFromStartDate(frequency.value, executionDate.value));
        break;
      default:
        repeat.reset(undefined);
        endDate.reset(undefined);
        break;
    }
  };

  onFrequencyChange = (value: FREQUENCY_SELECTOR) => {
    const { endDate, executionDate } = this.form.controls;
    const minEndDate = getMinimumEndDateFromStartDate(value, executionDate.value);
    endDate.reset(minEndDate);
    this.minEndDate = minEndDate;
  };

  onExecutionDateChange = (value: string) => {
    const { endDate, frequency, endType } = this.form.controls;
    const minEndDate = getMinimumEndDateFromStartDate(frequency.value, value);
    if (endType.value === END_TYPE.ON) {
      endDate.reset(minEndDate);
    }
    this.minEndDate = minEndDate;
  };

  onFromAccountChange = () => {
    const { occurrence } = this.form.controls;
    occurrence.reset(OCCURRENCE.NOW);
  };

  getRepeat = (endType: string, repeat: number) => {
    return endType === END_TYPE.AFTER ? repeat : undefined;
  };

  getEndDate = (endType: string, endDate: string) => {
    return endType === END_TYPE.ON ? formatDateTimeString(endDate, "yyyy-LL-dd") : undefined;
  };

  getCutoffTime = () => {
    const cutoffTime = this.remoteConfigService.getValue("a2a_cutoff_time") || "15:00:00";
    this.minExecutionDate = getMinimumStartDateFromCutoffTime(cutoffTime as string).toJSDate();
  };

  getRestrictedDates = async () => {
    const today = DateTime.now().toISODate();
    const twoYearsFromNow = DateTime.now().plus({ years: 2 }).toISODate();
    this.restrictedDatesService.getHolidays(today, twoYearsFromNow).subscribe({
      next: (result) => {
        if (result.restrictedDates) {
          this.restrictedDates = result.restrictedDates;
        }
      },
    });
  };

  onSelectFromAccount = (fromAccount: FlatProductItem) => {
    if (
      this.toAccount?.id === fromAccount.id ||
      this.areBothExternal(fromAccount, this.toAccount) ||
      (this.toAccount?.isExternal && fromAccount.productKindName === PRODUCT_KINDS.LOAN)
    ) {
      this.form.controls.toAccount.reset();
      this.toAccount = undefined;
    }
    this.fromAccount = fromAccount;
    this.form.controls.fromAccount.setValue(fromAccount.id);
  };

  onSelectToAccount = (toAccount: FlatProductItem) => {
    if (
      this.fromAccount?.id === toAccount.id ||
      this.areBothExternal(this.fromAccount, toAccount) ||
      (toAccount.isExternal && this.fromAccount?.productKindName === PRODUCT_KINDS.LOAN)
    ) {
      this.form.controls.fromAccount.reset();
      this.fromAccount = undefined;
    }
    this.toAccount = toAccount;
    this.form.controls.toAccount.setValue(toAccount.id);
  };

  areBothExternal = (accountA?: FlatProductItem, accountB?: FlatProductItem) =>
    !!accountA?.isExternal && !!accountB?.isExternal;

  isOneExternal = (accountA?: FlatProductItem, accountB?: FlatProductItem) =>
    !!accountA?.isExternal || !!accountB?.isExternal;

  accountSelectorIsOpenChange = (accountSelector: "toAccount" | "fromAccount") => {
    this.form.controls[accountSelector].markAsTouched();
  };

  getFormTextService = (): IFormTextService => {
    throw new Error("Not implemented");
  };
}
