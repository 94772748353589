<div class="input__left-aligned mb-3" [id]="'test__' + testId" [formGroup]="controlFormGroup">
  <div>
    <label *ngIf="!!amountLabel && amountLabel.length > 0" class="bb-dynamic-input__label form-label">
      {{ amountLabel }}
    </label>
    <bb-amount-input-ui
      currency="USD"
      formControlName="amount"
      placeholder="0.00"
      [autoDecimal]="true"
      [inputClassName]="'bb-text-align-start'"
    ></bb-amount-input-ui>
    <bb-input-validation-message-ui [showErrors]="isInvalid">
      <span>{{ error }}</span>
    </bb-input-validation-message-ui>
  </div>
</div>
