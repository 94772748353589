export interface PaymentCard {
  /**
   * Card uuid.
   */
  id: string;
  /**
   * Unformatted payment card number.
   */
  number: number | string;
  /**
   * Payment card vendor.
   */
  vendor: string;
  /**
   * Holder name to be displayed on the payment card.
   */
  name: string;
  /**
   * Date the payment card expires.
   */
  expirationDate: Date;
  /**
   * Payment card type.
   */
  type: string;
  /**
   * Payment card subtype.
   */
  subType?: string;
  /**
   * Payment card status.
   */
  status: string;
  /**
   * Payment card lock status.
   */
  lockStatus?: "LOCKED" | "UNLOCKED";
  /**
   * Payment card currency.
   */
  currency?: string;
  /**
   * Payment card name.
   */
  cardName: string;
}

export enum CARD_STATE {
  ACTIVE,
  INACTIVE,
  CANCELLED,
  ERROR,
}
