import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "ent-amount-form-control",
  templateUrl: "./amount.component.html",
  styleUrls: ["amount.component.scss"],
})
export class AmountFormControlComponent {
  @Input()
  amountLabel?: string;

  @Input()
  testId!: string;

  @Input()
  controlFormGroup!: FormGroup;

  @Input()
  isInvalid!: boolean;

  @Input()
  error?: string;
}
