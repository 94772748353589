/* eslint-disable @typescript-eslint/naming-convention */
import { Route } from "@angular/router";
import {
  AccountAliasDisplayingLevel,
  AccountsTransactionsJourneyConfiguration,
  ProductKindUri,
  AccountInfoPropertyType,
  AccountsTransactionsJourneyComponent,
  ArrangementsListComponent,
  AccountsManageComponent,
  AccountsManageGuardService,
  AccountInfoViewComponent,
  AccountsDetailsTabComponent,
  TransactionDetailsComponent,
  TransactionsListComponent,
  AccountInfoPropertyGroup,
  AvailableFilterEnum,
} from "@backbase/accounts-transactions-journey-ang";
import { PRODUCT_KINDS_WITH_EXTERNAL_DETAILS } from "./PRODUCT_KINDS_WITH_EXTERNAL_DETAILS";

const availableFiltersConfig: AccountsTransactionsJourneyConfiguration["availableFiltersConfig"] = {
  "Current Account": [
    AvailableFilterEnum.Date,
    AvailableFilterEnum.CreditDebitIndicator,
    AvailableFilterEnum.Amount,
    AvailableFilterEnum.Types,
    AvailableFilterEnum.CheckSerialNumber,
  ],
  "Savings Account": [
    AvailableFilterEnum.Date,
    AvailableFilterEnum.CreditDebitIndicator,
    AvailableFilterEnum.Amount,
    AvailableFilterEnum.Types,
  ],
  "Credit Card": [
    AvailableFilterEnum.Date,
    AvailableFilterEnum.CreditDebitIndicator,
    AvailableFilterEnum.Amount,
    AvailableFilterEnum.Types,
  ],
  Loan: [AvailableFilterEnum.Date, AvailableFilterEnum.CreditDebitIndicator, AvailableFilterEnum.Amount],
  "Term Deposit": [AvailableFilterEnum.Date, AvailableFilterEnum.CreditDebitIndicator, AvailableFilterEnum.Amount],
  "Debit Card": [AvailableFilterEnum.Date, AvailableFilterEnum.CreditDebitIndicator, AvailableFilterEnum.Amount],
  "Investment Account": [
    AvailableFilterEnum.Date,
    AvailableFilterEnum.CreditDebitIndicator,
    AvailableFilterEnum.Amount,
  ],
};

const currentAndSavingsConfig: AccountInfoPropertyGroup[] = [
  {
    title: $localize`:@@product.details.current.account.label.general.section:General`,
    properties: [
      {
        type: AccountInfoPropertyType.TEXT,
        label: $localize`:@@product.details.credit.card.label.productTypeName:Account Type`,
        key: "productTypeName",
      },
      {
        type: AccountInfoPropertyType.AMOUNT,
        label: $localize`:@@product.details.current.account.label.availableBalance:Balance`,
        key: "availableBalance",
      },
      {
        type: AccountInfoPropertyType.TEXT,
        label: $localize`:@@product.details.current.account.label.iban:Account Number`,
        key: "additions.micrAcctNumber",
      },
      {
        type: AccountInfoPropertyType.TEXT,
        label: $localize`:@@product.details.current.account.label.routingNumber:Routing Number`,
        key: "additions.routingNumber",
      },
    ],
  },
  {
    title: $localize`:@@product.details.current.account.label.interest.section:Dividends`,
    properties: [
      {
        type: AccountInfoPropertyType.TEXT,
        label: $localize`:@@product.details.current.account.label.interestRate:Rate`,
        key: "additions.divRate",
      },
      {
        type: AccountInfoPropertyType.AMOUNT,
        label: $localize`:@@product.details.current.account.label.accruedInterest:Year To Date Dividends`,
        key: "additions.divYtd",
      },
      {
        type: AccountInfoPropertyType.AMOUNT,
        label: $localize`:@@product.details.current-account.label.account.lastYearAccruedInterest:Previous Year Dividends`,
        key: "additions.divLastYear",
      },
    ],
  },
  {
    title: $localize`:@@product.details.current.account.label.other.section:Other`,
    properties: [
      {
        type: AccountInfoPropertyType.DATE,
        label: $localize`:@@product.details.current.account.label.accountOpeningDate:Date Opened`,
        key: "accountOpeningDate",
        dateFormat: "longDate",
      },
      {
        type: AccountInfoPropertyType.TEXT,
        label: $localize`:@@product.details.current.account.label.jointOwners:Joint Owner(s)`,
        key: "additions.joints",
      },
      {
        type: AccountInfoPropertyType.TEXT,
        label: $localize`:@@product.details.deposits.label.beneficiaries:Beneficiaries`,
        key: "additions.beneficiaries",
      },
    ],
  },
];

export const AccountsTransactionsConfigProvider = ({
  googleApiKey,
}: {
  googleApiKey: string;
}): Partial<AccountsTransactionsJourneyConfiguration> => ({
  apiKey: googleApiKey,
  paginationType: "infinite-scroll",
  pendingOnTop: true,
  showChangeCategory: false,
  showCheckImages: true,
  disputeTopicId: "",
  inquireTopicId: "",
  productKindsWithExternalDetailsPage: PRODUCT_KINDS_WITH_EXTERNAL_DETAILS,
  productKindsWithGraphicalRepresentation: ProductKindUri.CREDIT_CARD,
  enableDisputeAndInquiry: false,
  availableFiltersConfig,
  showAccountIcons: false,
  enableTransferButton: false,
  accountAliasDisplayLevel: AccountAliasDisplayingLevel.USER,
  arrangementViewsName: "account-overview",
  creditLimitViewMode: "filled",
  accountInfoProperties: {
    default: currentAndSavingsConfig,
    [ProductKindUri.CREDIT_CARD]: [
      {
        title: $localize`:@@product.details.credit.card.label.general.section:General`,
        properties: [
          {
            type: AccountInfoPropertyType.TEXT,
            label: $localize`:@@product.details.credit.card.label.productTypeName:Account Type`,
            key: "productTypeName",
          },
          {
            type: AccountInfoPropertyType.TEXT,
            label: $localize`:@@product.details.credit.card.label.number:Account Number`,
            key: "BBAN",
          },
          {
            type: AccountInfoPropertyType.TEXT,
            label: $localize`:@@product.details.credit.card.label.accountHolderNames:Account Holder(s)`,
            key: "additions.primaryAccountHolderName",
          },
          {
            type: AccountInfoPropertyType.TEXT,
            label: $localize`:@@product.details.credit.card.label.cardProvider:Card Provider`,
            key: "displayName",
            tooltip: $localize`:@@product.details.credit.card.tooltip.cardProvider:Card Provider`,
          },
          {
            type: AccountInfoPropertyType.DATE,
            label: $localize`:@@product.details.credit.card.label.validThru:Valid thru`,
            key: "validThru",
            tooltip: $localize`:@@product.details.credit.card.tooltip.validThru:Expiration date of a credit card, after which is no longer valid`,
          },
        ],
      },
    ],
    [ProductKindUri.CURRENT_ACCOUNT]: currentAndSavingsConfig,
    [ProductKindUri.SAVINGS_ACCOUNT]: currentAndSavingsConfig,
    [ProductKindUri.TERM_DEPOSIT]: [
      {
        title: $localize`:@@product.details.term.deposit.label.general.section:General`,
        properties: [
          {
            type: AccountInfoPropertyType.AMOUNT,
            label: $localize`:@@product.details.term.deposit.label.balance:Balance`,
            key: "bookedBalance",
          },
        ],
      },
      {
        title: $localize`:@@product.details.term.deposit.label.interest.section:Interest`,
        properties: [
          {
            type: AccountInfoPropertyType.PERCENT,
            label: $localize`:@@product.details.term.deposit.label.account.interestRate:Rate`,
            key: "accountInterestRate",
          },
          {
            type: AccountInfoPropertyType.DATE,
            label: $localize`:@@product.details.term.deposit.label.account.maturityDate:Maturity Date`,
            key: "maturityDate",
            dateFormat: "longDate",
          },
          {
            type: AccountInfoPropertyType.AMOUNT,
            label: $localize`:@@product.details.term.deposit.label.account.yearToDateDividends:Year To Date Dividends`,
            key: "additions.divYtd",
          },
          {
            type: AccountInfoPropertyType.AMOUNT,
            label: $localize`:@@product.details.term.deposit.label.account.previousYearDividends:Previous Year Dividends`,
            key: "additions.divLastYear",
          },
        ],
      },
      {
        title: $localize`:@@product.details.term.deposit.label.other.section:Other`,
        properties: [
          {
            type: AccountInfoPropertyType.DATE,
            label: $localize`:@@product.details.term.deposit.label.dateOpened:Date Opened`,
            key: "accountOpeningDate",
            dateFormat: "longDate",
          },
          {
            type: AccountInfoPropertyType.TEXT,
            label: $localize`:@@product.details.term.deposit.label.joints:Joint(s)`,
            key: "additions.joints",
          },
          {
            type: AccountInfoPropertyType.TEXT,
            label: $localize`:@@product.details.term.deposit.label.beneficiaries:Beneficiaries`,
            key: "additions.beneficiaries",
          },
        ],
      },
    ],
  },
});

const tabTitles = {
  myAccounts: () => $localize`:My Accounts title@@accounts.transactions.journey.myAccounts.title:My Accounts`,
  transactions: () => $localize`:Transactions title@@accounts.transactions.journey.transactions.title:Transactions`,
  list: () => $localize`:List tab title@@accounts.transactions.journey.list.tab.title:List`,
  details: () => $localize`:Details tab title@@accounts.transactions.journey.details.tab.title:Details`,
};

export const accountsTransactionsOverrideRoutes: Route = {
  path: "",
  component: AccountsTransactionsJourneyComponent,
  children: [
    {
      path: "",
      redirectTo: "list",
      pathMatch: "full",
    },
    {
      path: "list",
      component: ArrangementsListComponent,
      data: { title: tabTitles.myAccounts() },
    },
    {
      path: "manage",
      component: AccountsManageComponent,
      canActivate: [AccountsManageGuardService],
    },
    {
      path: "transactions",
      component: AccountsDetailsTabComponent,
      data: { title: tabTitles.transactions() },
      children: [
        { path: "", redirectTo: "list", pathMatch: "full" },
        {
          path: "list",
          component: TransactionsListComponent,
          data: { title: tabTitles.list() },
          children: [
            {
              path: "detail",
              component: TransactionDetailsComponent,
            },
          ],
        },
        {
          path: "details",
          component: AccountInfoViewComponent,
          data: { title: tabTitles.details() },
        },
      ],
    },
  ],
};
