import { NotificationService } from "@backbase/ui-ang/notification";
import { IBillpayEventHandler } from "./billpay-event-handler";
import { BillpayTextService } from "../service/billpay.text.service";

export class SuccessEditPayeeEventHandler implements IBillpayEventHandler {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly textService: BillpayTextService,
  ) {}

  public handleEvent = () => {
    this.notificationService.showNotification({
      header: this.textService.editPayeeSuccesseHeader,
      message: "",
      modifier: "success",
    });
  };
}
