export enum PRODUCT_KINDS {
  CURRENT_ACCOUNT = "Current Account",
  SAVINGS_ACCOUNT = "Savings Account",
  CREDIT_CARD = "Credit Card",
  DEBIT_CARD = "Debit Card",
  LOAN = "Loan",
  CONNECTED_ACCOUNT = "Connected Account",
  INVESTMENT_ACCOUNT = "Investment Account",
  TERM_DEPOSIT = "Term Deposit",
}
