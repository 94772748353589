import { Component } from "@angular/core";
import { RemoteConfigService } from "@backbase/remote-config-ang";
import { PERMISSIONS, ClassInjectorService, IRoute, RetailAppRemoteConfig } from "@ent/data";
import { NavmenuTextService } from "./navmenu.text.service";

@Component({
  selector: "ent-navmenu",
  templateUrl: "navmenu.component.html",
  styleUrls: ["navmenu.component.scss"],
})
export class NavmenuComponent {
  permissions = PERMISSIONS;
  remoteConfigParameters = {
    showMoneyInsightsLink: this.remoteConfigService.getValue("money_insights_link"),
    externalAccounts: this.remoteConfigService.getValue("external_accounts"),
    showManageNotificationsLink: this.remoteConfigService.getValue("show_manage_notifications_link"),
    showBillpay: this.remoteConfigService.getValue("bill_pay_enabled"),
  };

  readonly showContacts = false;
  routes: IRoute[];

  constructor(
    public readonly classInjectorService: ClassInjectorService,
    private readonly textService: NavmenuTextService,
    private remoteConfigService: RemoteConfigService<RetailAppRemoteConfig>,
  ) {
    this.routes = this.buildRoutes();
  }

  buildRoutes = (): IRoute[] => {
    const allRoutes: IRoute[] = [
      {
        link: "/my-accounts",
        label: this.textService.myAccountsLabel,
        permissionRequired: PERMISSIONS.canViewMyAccounts,
        subMenuLabel: "",
        subRoutes: [],
      },
      this.buildTransfersRoutes(),
      this.buildSelfServiceRoutes(),
    ];

    if (this.remoteConfigParameters.showMoneyInsightsLink) {
      allRoutes.push({
        link: "/money-insights",
        label: this.textService.moneyInsightsLabel,
        permissionRequired: PERMISSIONS.canViewMoneyInsights,
        subMenuLabel: "",
        subRoutes: [],
      });
    }
    return allRoutes;
  };

  buildTransfersRoutes = (): IRoute => {
    const subRoutes: IRoute[] = [
      {
        link: "/transfers/make-a-transfer",
        label: this.textService.makeTransferLabel,
        permissionRequired: PERMISSIONS.canViewMakeTransfer,
        subMenuLabel: "",
        subRoutes: [],
      },
      {
        link: "/transfers/pay-loan",
        label: this.textService.payLoanLabel,
        permissionRequired: PERMISSIONS.canViewMakeTransfer,
        subMenuLabel: "",
        subRoutes: [],
      },
    ];
    if (this.remoteConfigParameters.showBillpay) {
      subRoutes.push({
        link: "/billpay",
        label: this.textService.billpayLabel,
        permissionRequired: PERMISSIONS.canViewBillPay,
        subMenuLabel: "",
        subRoutes: [],
      });
    }
    subRoutes.push(
      {
        link: "/transfers/money-to-member",
        label: this.textService.memberTransferLabel,
        permissionRequired: PERMISSIONS.canViewMakeTransfer,
        subMenuLabel: "",
        subRoutes: [],
      },
      {
        link: "/transfers/activity",
        label: this.textService.moveMoneyActivityLabel,
        permissionRequired: PERMISSIONS.canViewScheduledTransfers,
        subMenuLabel: "",
        subRoutes: [],
      },
    );
    if (this.remoteConfigParameters.externalAccounts) {
      subRoutes.push({
        link: "/transfers/connected-accounts",
        label: this.textService.connectedAccountsLabel,
        permissionRequired: PERMISSIONS.canViewConnectedAccounts,
        subMenuLabel: "",
        subRoutes: [],
      });
    }
    return {
      link: "",
      label: this.textService.transfersLabel,
      permissionRequired: PERMISSIONS.canViewTransfers,
      subMenuLabel: this.textService.subMenuLabel,
      subRoutes,
    };
  };

  buildSelfServiceRoutes = (): IRoute => {
    const subRoutes: IRoute[] = [
      {
        link: "/self-service/profile",
        label: this.textService.myProfileLabel,
        permissionRequired: PERMISSIONS.canViewMyProfile,
        subMenuLabel: "",
        subRoutes: [],
      },
      // { We are not ready to include this menu item
      //   link: "/self-service/authorized-users",
      //   label: this.textService.authorizedUsersLabel,
      //   permissionRequired: PERMISSIONS.canViewAuthorizedUsers,
      //   subMenuLabel: "",
      //   subRoutes: [],
      // },
      {
        link: "/self-service/manage-cards",
        label: this.textService.manageCardsLabel,
        permissionRequired: PERMISSIONS.canViewManageCards,
        subMenuLabel: "",
        subRoutes: [],
      },
    ];
    if (this.remoteConfigParameters.showManageNotificationsLink) {
      subRoutes.push({
        link: "/self-service/product-list",
        label: this.textService.manageNotificationsLabel,
        permissionRequired: PERMISSIONS.canViewManageNotifications,
        subMenuLabel: "",
        subRoutes: [],
      });
    }
    subRoutes.push(
      ...[
        {
          link: "/self-service/manage-contacts",
          label: this.textService.manageContactsLabel,
          permissionRequired: PERMISSIONS.canViewManageContacts,
          subMenuLabel: "",
          subRoutes: [],
        },
        // { We are not ready to include this menu item
        //   link: "/self-service/stop-checks",
        //   label: this.textService.stopChecksLabel,
        //   permissionRequired: PERMISSIONS.canViewStopChecks,
        //   subMenuLabel: "",
        //   subRoutes: [],
        // },
        {
          link: "/self-service/download-statements",
          label: this.textService.downloadStatementsLabel,
          permissionRequired: PERMISSIONS.canViewAccountStatements,
          subMenuLabel: "",
          subRoutes: [],
        },
        {
          link: "/more/messages/inbox/list",
          label: this.textService.messagesLabel,
          permissionRequired: PERMISSIONS.canViewManageNotifications,
          subMenuLabel: "",
          subRoutes: [],
        },
        {
          link: "https://entcu.notion.site/Digital-Banking-Help-Center-6e1fa64ea1d7442091094b2951526926",
          label: this.textService.helpCenterLabel,
          subMenuLabel: "",
          permissionRequired: PERMISSIONS.canViewAccountStatements,
          subRoutes: [],
        },
      ],
    );
    return {
      link: "",
      label: this.textService.selfServiceLabel,
      permissionRequired: PERMISSIONS.canViewSelfService,
      subMenuLabel: this.textService.subMenuLabel,
      subRoutes,
    };
  };
}
