<div class="card">
  <div class="d-flex card-header">
    <bb-header-ui headingType="h2" [heading]="textService.title" headingClasses="bb-heading-widget__heading my-4">
    </bb-header-ui>
    <span [bbTooltip]="textService.toolTip" placement="right" class="align-self-center pl-2">
      <bb-icon-ui name="info" color="info" size="md"></bb-icon-ui>
    </span>
  </div>
  <ng-container *ngIf="(this.isFormOpen | async); then Form else Closed "> </ng-container>
</div>

<ng-template #Closed>
  <div class="card-section">
    <div class="bb-stack">
      <strong class="bb-stack__item bb-stack__item--fill">{{ textService.title }}</strong>
      <div class="bb-stack__item">
        <bb-button-ui color="link" (click)="openForm()">{{ textService.openFormButton }}</bb-button-ui>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Form>
  <div class="card-section card-section--highlight" [formGroup]="form">
    <p>{{ textService.paragraph1 }}</p>
    <p>{{ textService.paragraph2 }}</p>
    <div class="call-center-password-fields">
      <div class="mb-3 form-group">
        <div class="input-group">
          <bb-input-text-ui
            size="40"
            [label]="textService.passwordField"
            formControlName="password"
            [maxLength]="10"
            [required]="true"
            autoComplete="off"
          ></bb-input-text-ui>
        </div>
      </div>
      <div class="mb-3 form-group">
        <div class="input-group">
          <bb-input-text-ui
            size="40"
            [label]="textService.confirmPasswordField"
            formControlName="confirm"
            [maxLength]="10"
            [required]="true"
            autoComplete="off"
          ></bb-input-text-ui>
        </div>
      </div>
      <div class="mb-3 form-group">
        <div class="input-group">
          <bb-input-text-ui
            size="40"
            [label]="textService.reminderField"
            formControlName="reminder"
            [maxLength]="28"
            [required]="true"
            autoComplete="off"
          ></bb-input-text-ui>
        </div>
      </div>
    </div>
    <div class="bb-button-bar--reverse">
      <bb-button-ui class="call-center-password-submit-button" type="submit" color="primary" (click)="submitClick()"
        >{{ textService.submitFormButton }}</bb-button-ui
      >
      <bb-button-ui color="secondary" (click)="closeForm()">{{ textService.closeFormButton }}</bb-button-ui>
    </div>
  </div>
</ng-template>
