import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EntUIModule } from "@ent/ui";
import { HeaderModule } from "@backbase/ui-ang/header";
import { NotificationCardModule } from "./notification-card/notification-card.module";
import { CustomProductSettingsComponent } from "./custom-product-settings/custom-product-settings.component";

@NgModule({
  declarations: [CustomProductSettingsComponent],
  imports: [CommonModule, NotificationCardModule, HeaderModule, EntUIModule],
})
export class ManageNotificationsModule {}
