import { Injectable } from "@angular/core";

@Injectable({ providedIn: "any" })
export class CallCenterPasswordTextService {
  title = $localize`:@@call-center-password.title: Call Center Password`;
  paragraph1 = $localize`:@@call-center-password.paragraph1: Your Call Center Password, currently on file at Ent.`;
  paragraph2 = $localize`:@@call-center-password.paragraph2: A Call Center Password provides an added measure of security when accessing your Accounts through our telephone call center. Just as your photo identification verifies you in person, your Call Center Password will help verify your identity when you contact us by phone.`;
  passwordField = $localize`:@@call-center-password.passwordField: Password`;
  confirmPasswordField = $localize`:@@call-center-password.confirmPasswordField: Confirm`;
  reminderField = $localize`:@@call-center-password.reminderField: Reminder`;
  toolTip = $localize`:@@call-center-password.toolTip: Your Call Center Password is used to validate your account access when you contact Ent by phone.`;
  openFormButton = $localize`:@@call-center-password.openFormButton: Change`;
  submitFormButton = $localize`:@@call-center-password.submitFormButton: Confirm changes`;
  closeFormButton = $localize`:@@call-center-password.closeFormButton: Cancel`;

  /** Loading Texts  */
  loadingText = $localize`:@@call-center-password.loading:Loading...`;
  /** Error  */
  callCenterPasswordErrorHeader = $localize`:@@call-center-password.error.header:We have a little problem`;
  callCenterPasswordErrorSubText = $localize`:@@call-center-password.error.subText: We couldn't load the page. Refresh the screen to try again.`;
}
