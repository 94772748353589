import { Component } from "@angular/core";
import { TextService } from "../../services/text.service";
import { BasePinFormComponent } from "../base-pin-form/base-pin-form.component";

@Component({
  selector: "ent-cvv-form",
  templateUrl: "./cvv-form.component.html",
})
export class CvvFormComponent extends BasePinFormComponent {
  inputControlName = "cvv";

  constructor(public readonly textService: TextService) {
    super();
  }
}
