/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { RoutableModalParamName, SharedRoutableModalService } from "@backbase/shared/feature/routable-modal";
import { CreditCardsHttpService } from "@ent/data";
import { TextService } from "../../services/text.service";

@Component({
  selector: "ent-leaving-ent",
  templateUrl: "./leaving-ent-modal.component.html",
})
export class LeavingEntModalComponent implements OnInit, OnDestroy {
  public subscription!: Subscription;
  public modalOpenName: string;
  public selectedAccount: string;
  public redirectUrl: string;
  public errorGettingSSOUrl = false;
  public isLoading: Subject<boolean> = new Subject<boolean>();
  public isError: Subject<boolean> = new Subject<boolean>();
  public samlResponse: Subject<string> = new Subject<string>();
  public formAction: Subject<string> = new Subject<string>();

  constructor(
    public readonly textService: TextService,
    private readonly route: ActivatedRoute,
    private readonly modalService: SharedRoutableModalService,
    private readonly creditCardsHttpService: CreditCardsHttpService,
  ) {}

  getSamlResponse = (response: string) => {
    const samlRegex = /<input(.*)\/>/gi;
    const results = samlRegex.exec(response);
    results?.forEach((result) => {
      if (result.includes(`name="SAMLResponse"`)) {
        const samlResponse = result.split('value="')[1].split('"')[0];
        this.samlResponse.next(samlResponse);
      }
    });
  };

  getFormAction = (response: string) => {
    const formActionRegex = /<form(.*)\/>/gi;
    const formResults = formActionRegex.exec(response);
    formResults?.forEach((result) => {
      const actionResponse = result.split('action="')[1].split('"')[0];
      this.formAction.next(actionResponse);
    });
  };

  submitAndClose = () => {
    document.forms["samlForm"]?.submit();
    this.modalService.closeModal();
  };

  ngOnDestroy(): void {
    try {
      this.subscription.unsubscribe();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  ngOnInit(): void {
    // eslint-disable-next-line prefer-destructuring
    this.selectedAccount = this.route.snapshot["_routerState"].url?.split("selectedAccount=")[1]?.split("/")[0];
    this.subscription = this.route.queryParams.subscribe((params: Params) => {
      const modalName = params[RoutableModalParamName];
      this.modalOpenName = modalName;
    });
    this.isLoading.next(true);
    this.isError.next(false);
    this.samlResponse.next("");
    this.formAction.next("");
    (this.modalOpenName === "make-a-payment"
      ? this.creditCardsHttpService.getPaymentsSSO(this.selectedAccount)
      : this.creditCardsHttpService.getAccountInfoSSO(this.selectedAccount)
    ).subscribe({
      next: (response) => {
        this.getSamlResponse(response);
        this.getFormAction(response);
        this.isLoading.next(false);
      },
      error: () => {
        this.isLoading.next(false);
        this.isError.next(true);
      },
    });
  }
}
