import { Injectable } from "@angular/core";
import { Router, RouterEvent } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ClassInjectorService {
  currentRoute = "";

  constructor(private readonly router: Router) {
    this.router.events.pipe(filter((e): e is RouterEvent => e instanceof RouterEvent)).subscribe(this.onRouteChange);
  }

  getWindowLocation = () => window.location;

  getDocumentBody = () => document.body;

  onRouteChange = () => {
    const urlClass = this.transformUrlToCssClass(this.getWindowLocation().pathname);
    const currentUrlClass = this.transformUrlToCssClass(this.currentRoute);
    if (currentUrlClass.length > 0) {
      currentUrlClass.forEach((cssClass) => {
        this.getDocumentBody().classList.remove(cssClass);
      });
    }
    urlClass.forEach((cssClass) => {
      this.getDocumentBody().classList.add(cssClass);
    });

    this.currentRoute = this.getWindowLocation().pathname;
  };

  transformUrlToCssClass = (url: string) => {
    const mappedCSSClass = url
      .toLowerCase()
      .split("/")
      .map((urlPart) => {
        const subParts = urlPart.split(/[;?&=]/);
        if (subParts.length > 1) return subParts[0];
        if (/^\d/.test(urlPart) || /\w{8}-(\w{4}-){3}\w{12}/.test(urlPart)) return "";
        return urlPart;
      })
      .filter((urlPart) => urlPart.length > 0);
    return mappedCSSClass;
  };
}
