export enum EVENT_TYPE {
  NAVIGATE_OPEN_NEW_ACCOUNT = "NAVIGATE_OPEN_NEW_ACCOUNT",
  NAVIGATE_UPDATE_PROFILE = "NAVIGATE_UPDATE_PROFILE",
  ADD_MANUAL_BILLER_SUCCESS = "ADD_MANUAL_BILLER_SUCCESS",
  ADD_MANUAL_BILLER_ERROR = "ADD_MANUAL_BILLER_ERROR",
  EDIT_BILLER_SUCCESS = "EDIT_BILLER_SUCCESS",
  EDIT_BILLER_ERROR = "EDIT_BILLER_ERROR",
  ADD_BILLER_SUCCESS = "ADD_BILLER_SUCCESS",
  ADD_BILLER_ERROR = "ADD_BILLER_ERROR",
  VALIDATE_BILLER_ERROR = "VALIDATE_BILLER_ERROR",
  DELETE_PAYEE_SUCCESS = "DELETE_PAYEE_SUCCESS",
  DELETE_PAYEE_ERROR = "DELETE_PAYEE_ERROR",
  URL_CHANGED = "URL_CHANGED",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  NAVIGATE_TO_ACCOUNTS = "NAVIGATE_TO_ACCOUNTS",
  DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS",
  DELETE_PAYMENT_ERROR = "DELETE_PAYMENT_ERROR",
  GET_PAYMENT_SUMMARY_ERROR = "GET_PAYMENT_SUMMARY_ERROR",
  EDIT_PAYMENT_ERROR = "EDIT_PAYMENT_ERROR",
  EBILL_ENROLL_ERROR = "EBILL_ENROLL_ERROR",
  EBILL_AUTOPAYMENT_ERROR = "EBILL_AUTOPAYMENT_ERROR",
  EBILL_CANCEL_AUTOPAYMENT_ERROR = "EBILL_CANCEL_AUTOPAYMENT_ERROR",
  EBILL_CANCEL_AUTOPAYMENT_SUCCESS = "EBILL_CANCEL_AUTOPAYMENT_SUCCESS",
  EBILL_DISCONNECT_SUCCESS = "EBILL_DISCONNECT_SUCCESS",
  EBILL_DISCONNECT_ERROR = "EBILL_DISCONNECT_ERROR",
}

export interface BillpayAppEventType<T> {
  type: EVENT_TYPE;
  source: string;
  path: string;
  payload?: T;
}
