import { Injectable } from "@angular/core";
import { SESSION_TIMEOUT_TIME_FORMAT } from "../models/session-timeout.interface";

@Injectable({ providedIn: "any" })
export class SessionTimeoutTextService {
  confirmationModalTitle = $localize`:@@session-timeout-modal.title:Are you still there?`;
  confirmationModalYes = $localize`:@@session-timeout-modal.yes:Continue`;
  confirmationModalNo = $localize`:@@session-timeout-modal.no:Log Out`;

  confirmationModalBody = $localize`:@@session-timeout-modal.body:Choose to continue or log out. Otherwise, for your security we will automatically log you out in`;

  minute = $localize`:@@session-timeout-modal.minute:minute`;
  minutes = $localize`:@@session-timeout-modal.minutes:minutes`;
  second = $localize`:@@session-timeout-modal.second:second`;
  seconds = $localize`:@@session-timeout-modal.seconds:seconds`;

  confirmationModalBodyTimeLeft = (
    format: SESSION_TIMEOUT_TIME_FORMAT,
    minutesRemaining: number,
    secondsRemaining: number,
  ) => {
    const minuteText = minutesRemaining === 1 ? this.minute : this.minutes;
    const secondText = secondsRemaining === 1 ? this.second : this.seconds;
    switch (format) {
      case SESSION_TIMEOUT_TIME_FORMAT.FULL:
        return `${minutesRemaining} ${minuteText} ${secondsRemaining} ${secondText}`;
      case SESSION_TIMEOUT_TIME_FORMAT.MINUTES:
        return `${minutesRemaining} ${minuteText}`;
      case SESSION_TIMEOUT_TIME_FORMAT.SECONDS:
        return `${secondsRemaining} ${secondText}`;
      default:
        return "";
    }
  };
}
