import { Injectable } from "@angular/core";

@Injectable({ providedIn: "any" })
export class PaymentTextService {
  titleText = $localize`:@@loan-payments.page-title.payments.loan-payment:Pay a Loan`;
  unknownErrorHeader = $localize`:@@initiate-payment-unknown-error-header:Server error`;
  unknownError = $localize`:@@initiate-payment-unknown-error:Unknown error occured.`;
  validateFailed = $localize`:@@initiate-payment-validate-failed:Payment data error`;
  externalTransferErrorHeader = $localize`:@@external-transfer.error-header:Sorry`;
  externalTransferErrorBody = $localize`:@@external-transfer.error-body:External transfers are currently unavailable, please try again later.`;
}
