import { DateTime } from "luxon";

export const getValidDateTimeOrUndefined = (dateTimeString: string | undefined): DateTime | undefined => {
  const dateTime = DateTime.fromISO(dateTimeString ?? "");
  return dateTime.isValid ? dateTime : undefined;
};

export const getLocalDateTimeFromUTC = (dateTimeString: string | undefined): DateTime | undefined => {
  const dateTime = DateTime.fromISO(dateTimeString ?? "", { zone: "utc" }).setZone("default", { keepLocalTime: true });
  return dateTime.isValid ? dateTime : undefined;
};

export const formatDateTimeString = (dateTimeString: string | undefined, format: string) => {
  const dateTime = getValidDateTimeOrUndefined(dateTimeString);
  return dateTime ? dateTime.toFormat(format) : undefined;
};
