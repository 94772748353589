<ent-open-share-card
  [heading]="card?.description"
  [iconClass]="card?.iconClass"
  [title]="textService.informationTitleLabel"
  [subtitle]="textService.informationSubtitleLabel"
>
  <button navigation class="btn btn-link ps-0 ms-0 mb-3" (click)="onBack()">
    <i aria-hidden="true" class="bb-icon bb-icon-arrow-back"></i>
    <span>{{textService.informationBackButtonLabel}}</span>
  </button>
  <div card-content>
    <div *ngFor="let benefit of card?.benefits" class="d-flex align-items-center my-3">
      <i aria-hidden="true" class="bb-icon sub-icon ent-fa-regular" [ngClass]="benefit.iconClass"></i>
      <div class="item-information">
        <div class="item-header">{{benefit.title | insertValue: card?.rateLabel}}</div>
        <div class="bb-subtitle bb-text-support">{{benefit.subtitle}}</div>
      </div>
    </div>
    <ng-container *ngIf="card?.goodToKnow">
      <div class="bb-subtitle bb-text-support mt-3">{{textService.informationGoodToKnowLabel}}</div>
      <div *ngFor="let goodToKnow of card?.goodToKnow" class="d-flex align-items-center my-3">
        <i aria-hidden="true" class="bb-icon sub-icon ent-fa-regular" [ngClass]="goodToKnow.iconClass"></i>
        <div class="item-information">
          <div class="item-header">{{goodToKnow.title}}</div>
          <div class="bb-subtitle bb-text-support">{{goodToKnow.subtitle | insertValue: card?.minimumDeposit}}</div>
        </div>
      </div>
    </ng-container>
  </div>
  <div footer class="footer">
    <div class="d-flex pt-5" [class.justify-content-end]="canConvertChecking">
      <div *ngIf="canConvertChecking" class="pr-2">
        <button bbButton [color]="'secondary'" (click)="onConvert()">
          {{textService.informationConvertButtonLabel}}
        </button>
      </div>
      <button bbButton [class.footer-button]="!canConvertChecking" [color]="'primary'" (click)="onNext()">
        {{textService.informationNextButtonLabel}}
      </button>
    </div>
    <div class="bb-subtitle bb-text-support mt-3">
      {{textService.informationLegendLabel}}
      <a class="hyperlink mx-0" [href]="card?.informationLink" target="”_blank”">{{card?.description}}.</a>
    </div>
  </div>
</ent-open-share-card>
