import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UserManagementService } from "@backbase/user-http-ang";
import { COMPONENT_STATE } from "@ent/data";
import { BillpayTextService } from "../../service/billpay.text.service";

@Component({
  selector: "ent-billpay-wrapper",
  templateUrl: "./wrapper.component.html",
})
export class WrapperComponent implements OnInit {
  legalEntityId: string;
  componentState = new BehaviorSubject(COMPONENT_STATE.LOADING);
  componentStates = COMPONENT_STATE;

  constructor(
    private readonly userManagementService: UserManagementService,
    public readonly textService: BillpayTextService,
  ) {}

  ngOnInit(): void {
    this.userManagementService.getOwnUser().subscribe({
      next: ({ legalEntityId }) => {
        this.legalEntityId = legalEntityId;
        this.componentState.next(COMPONENT_STATE.LOADED);
      },
      error: () => {
        this.componentState.next(COMPONENT_STATE.ERROR);
      },
    });
  }
}
