<bb-modal-ui
  [isOpen]="isOpen"
  (cancel)="onCloseModal()"
  [aria-describedby]="'ariaDescribedById'"
  [aria-labelledby]="'ariaLabelledByID'"
>
  <bb-modal-header-ui [title]="title"></bb-modal-header-ui>
  <div [ngClass]="{ hide: isLoading }">
    <div [ngClass]="{ hide: modalState !== modalStates.CVV }">
      <ent-cvv-form [form]="form" (cancel)="onCloseModal()" (confirm)="confirmCVV()"></ent-cvv-form>
    </div>
    <div [ngClass]="{ hide: modalState !== modalStates.PIN }">
      <ent-pin-form
        [form]="form"
        (cancel)="onCloseModal()"
        (back)="setModalState(0)"
        (confirm)="confirmPIN()"
      ></ent-pin-form>
    </div>
    <div [ngClass]="{ hide: modalState !== modalStates.CONFIRM }">
      <ent-pin-confirm-form
        [form]="form"
        (cancel)="onCloseModal()"
        (back)="setModalState(1)"
        (confirm)="onFormSubmit()"
        [confirmButtonText]="confirmButtonText"
      ></ent-pin-confirm-form>
    </div>
  </div>
  <div [ngClass]="{ hide: !isLoading }">
    <bb-modal-body-ui>
      <ng-template bbCustomModalBody>
        <bb-loading-indicator-ui loaderSize="lg" [text]="loadingText"></bb-loading-indicator-ui>
      </ng-template>
    </bb-modal-body-ui>
  </div>
</bb-modal-ui>
