import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-memo-display",
  templateUrl: "./memo.component.html",
})
export class MemoDisplayComponent {
  @Input()
  memoLabel!: string;

  @Input()
  memo?: string;
}
