import { Injectable } from "@angular/core";
import { entFormatCurrency } from "@ent/data";
import { DateTime } from "luxon";
import { ISuccessTextService } from "../../services/success.text.service";

@Injectable({ providedIn: "any" })
export class MemberTransferSuccessTextService implements ISuccessTextService {
  successHeader = $localize`:@@member-transfer.success.success-header:Success!`;
  restartButton = $localize`:@@member-transfer.success.restart-btn: Make Another Transfer`;
  accountsButton = $localize`:@@member-transfer.success.link-btn: View My Accounts`;

  immediateSuccessMessage = (amount: number, toAccount: string) =>
    $localize`:@@member-transfer.success.immediate-message: You sent ${entFormatCurrency(amount)} to ${toAccount}`;
  scheduledLaterSuccessMessage = (amount: number, toAccount: string, scheduledDate: string) =>
    $localize`:@@member-transfer.success.scheduled-later-message: Your transfer of ${entFormatCurrency(
      amount,
    )} to ${toAccount} has been scheduled for ${DateTime.fromISO(scheduledDate).toFormat("MMMM dd, yyyy")}`;
  scheduledRecurringSuccessMessage = (frequency: string, amount: number, toAccount: string) =>
    $localize`:@@member-transfer.success.scheduled-recurring-message: Your ${frequency} recurring transfer of ${entFormatCurrency(
      amount,
    )} to ${toAccount} has been scheduled`;
}
