import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest } from "rxjs";
import { A2aClientHttpService, DetailedExternalAccount, AccountStatus } from "@backbase/payment-order-a2a-http-ang";
import { RemoteConfigService } from "@backbase/remote-config-ang";
import { ConditionsService } from "@backbase/foundation-ang/entitlements";
import { PERMISSIONS } from "../../helpers/permissions.helper";
import { LoadingService } from "../loading.service";
import { LOADING_KEYS } from "../../models";
import { RetailAppRemoteConfig } from "../../remote-config";

@Injectable({ providedIn: "root" })
export class ExternalTransfersHTTPService {
  initialLoading = true;
  validateLoading = false;
  submitLoading = false;
  remoteConfigParameters = {
    callExternalTransfers: this.remoteConfigService.getValue("external_accounts"),
  };

  constructor(
    private readonly loadingService: LoadingService,
    private readonly a2AClientHttpService: A2aClientHttpService,
    private remoteConfigService: RemoteConfigService<RetailAppRemoteConfig>,
    private readonly conditionService: ConditionsService,
  ) {}

  externalTransferAccountsObservable = new BehaviorSubject<DetailedExternalAccount[]>([]);

  setupLoaders = () => {
    combineLatest([
      this.loadingService.isLoading(LOADING_KEYS.INITIAL_LOADING),
      this.loadingService.isLoading(LOADING_KEYS.VALIDATE_LOADING),
      this.loadingService.isLoading(LOADING_KEYS.SUBMIT_LOADING),
    ]).subscribe(this.setLoadingState);
  };

  setLoadingState = ([initialLoading, validateLoading, submitLoading]: boolean[]) => {
    this.initialLoading = initialLoading;
    this.validateLoading = validateLoading;
    this.submitLoading = submitLoading;
  };

  getExternalTransferAccounts = async () => {
    this.loadingService.startLoading(LOADING_KEYS.INITIAL_LOADING);
    const canCreateA2aExternal = await this.conditionService.resolveEntitlements(PERMISSIONS.canCreateA2AExternal);
    if (!this.remoteConfigParameters.callExternalTransfers || !canCreateA2aExternal) {
      this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
      return Promise.resolve([]);
    }
    return new Promise((resolve, reject) => {
      this.a2AClientHttpService.getAll().subscribe({
        next: (data) => {
          this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
          if (!(data instanceof HttpErrorResponse)) {
            this.externalTransferAccountsObservable.next(
              (data.accounts ?? []).filter(
                (account) => account.status === AccountStatus.Active,
              ) as DetailedExternalAccount[],
            );
          }
          resolve(data);
        },
        error: (err) => {
          this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
          reject(err);
        },
      });
    });
  };
}
