import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CardItem } from "@backbase/cards-http-ang";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { normalizeCardItemFromAPICall } from "../helpers/card-status";

@Injectable()
export class GetCardsHttpInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (/\/cards$/i.test(httpRequest.url)) {
      const params = new HttpParams({
        fromObject: {
          types: "DEBIT",
        },
      });
      const newRequest = httpRequest.clone({ params });
      return next.handle(newRequest).pipe(
        filter((event: any) => event instanceof HttpResponse),
        map(this.normalizeAPIBody),
      );
    }
    return next.handle(httpRequest);
  }

  normalizeAPIBody = (event: HttpResponse<any>) => {
    let newBody = event.body;
    if (newBody && newBody.length > 0) {
      newBody = newBody.map(normalizeCardItemFromAPICall);
    }
    return event.clone<CardItem[]>({
      body: newBody,
    });
  };
}
