import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { EntitlementsModule } from "@backbase/foundation-ang/entitlements";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { IconModule } from "@backbase/ui-ang/icon";
import { LayoutModule } from "@backbase/ui-ang/layout";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { EntUIModule } from "@ent/ui";
import {
  ClassInjectorService,
  GetCardHttpInterceptor,
  GetCardsHttpInterceptor,
  GetCustomizeAccountsHttpInterceptor,
  GetPhoneAddressHttpInterceptor,
} from "@ent/data";

import { NavmenuComponent } from "./navmenu/navmenu.component";
import { QuickActionsComponent } from "./quick-actions/quick-actions.component";
import { GoogleTagComponent } from "./google-tag/google-tag.component";
import { DataDogComponent } from "./data-dog/data-dog.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    EntitlementsModule,
    IconModule,
    NgbDropdownModule,
    LayoutModule,
    EntitlementsModule,
    EntUIModule,
  ],
  declarations: [QuickActionsComponent, NavmenuComponent, GoogleTagComponent, DataDogComponent],
  exports: [QuickActionsComponent, NavmenuComponent, GoogleTagComponent, DataDogComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GetCardHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GetPhoneAddressHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GetCardsHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GetCustomizeAccountsHttpInterceptor, multi: true },
    ClassInjectorService,
  ],
})
export class EntAppModule {}
