import { Injectable } from "@angular/core";
import { CardItem } from "@backbase/cards-http-ang";

@Injectable({ providedIn: "any" })
export class TextService {
  updatePINATMSubtext = $localize`:@@cards.details.card-controls.update-pin.ATM.sub-text:Update the PIN number for your ATM card`;
  updatePINDebitSubText = $localize`:@@cards.details.card-controls.update-pin.debit.sub-text:Update the PIN number for your debit card`;

  resetPinModalHeader = $localize`:@@cards.details.reset-pin.modal.title:Change Your PIN`;
  resetPinLoadingText = $localize`:@@cards.details.reset-pin.loading:Updating the PIN...`;
  resetPinSetPinText = $localize`:@@cards.details.reset-pin.set-pin.btn:Set PIN`;
  resetPinSuccessNotificationHeader = $localize`:@@cards.notification-popup.reset-pin.success.header:Success! Your PIN was updated`;
  resetPinFailureNotificationHeader = $localize`:@@cards.notification-popup.reset-pin.failure.header:We couldn't change your PIN`;

  /** Card Detail View */
  cardDetailsHeader = $localize`:@@cards.details.title:Card Details`;
  cardDetailsBackButton = $localize`:@@cards.details.back-btn: Back to Manage Cards`;
  cardDetailsErrorHeader = $localize`:@@cards.details.error.header:We have a little problem`;
  cardDetailsErrorSubText = $localize`:@@cards.details.error.sub-text: We couldn't load the page. Refresh the screen to try again.`;

  /** Card Controls */
  cardControlsHeader = $localize`:@@cards.details.card-controls.header:Card Controls`;
  cardControlsPinUpdateHeader = $localize`:@@cards.details.card-controls.update-pin.header:Change PIN`;
  cardControlsPinUpdateButton = $localize`:@@cards.details.card-controls.update-pin-btn:Update`;
  cardControlsLockHeader = $localize`:@@cards.details.card-controls.lock-card.header: Lock Card`;
  cardControlsLockSubHeader = $localize`:@@cards.details.card-controls.lock-card.sub-text:Freeze your card temporarily`;
  cardControlsLockSwitchLabel = $localize`:@@card.controls.lock.switch.aria-label:lock/unlock aria-label`;

  /** Card Services  */
  cardServicesHeader = $localize`:@@cards.details.card-services.header: Card Services`;
  cardServicesTravelNoticeHeader = $localize`:@@cards.details.card-services.travel-notice.header: Set a Travel Notice`;
  cardServicesTravelNoticeSubtext = $localize`:@@cards.details.card-services.travel-notice.sub-text: Plan ahead to set a travel notice on your card`;
  cardServicesTravelNoticeButton = $localize`:@@cards.details.card-services.travel-notice-btn: Manage`;

  /** Base Pin Modal */
  pinContinueText = $localize`:@@cards.details.base-pin-modal.continue.btn:Continue`;
  pinCancelText = $localize`:@@cards.details.base-pin-modal.cancel.btn:Cancel`;
  pinBackText = $localize`:@@cards.details.base-pin-modal.back.btn:Back`;

  /** Card Activation */
  cardActivationModalHeader = $localize`:@@cards.details.activate-card.modal.title:Activate Your Card`;
  cardActivationDebitHeader = $localize`:@@cards.details.activate-card.debit.header: Activate Your Debit Card`;
  cardActivationATMHeader = $localize`:@@cards.details.activate-card.atm.header: Activate Your ATM Card`;
  cardActivationDebitSubtext = $localize`:@@cards.details.activate-card.debit.subtext: Great! You've received your new debit card. Activate it here quickly and securely.`;
  cardActivationATMSubtext = $localize`:@@cards.details.activate-card.atm.subtext: Great! You've received your new ATM card. Activate it here quickly and securely.`;

  cardActivationConfirmText = $localize`:@@cards.details.activate-card.confirm.btn:Activate Card`;
  cardActivationLoadingText = $localize`:@@cards.details.activate-card.loading:Activating your card…`;
  cardActivationActivateButton = $localize`:@@cards.details.card-activation.activate-btn: Activate Card`;
  cardActivationSuccessNotificationHeader = $localize`:@@cards.notification-popup.activate-card.success.header:Success! Your card is active`;
  cardActivationFailureNotificationHeader = $localize`:@@cards.notification-popup.activate-card.failure.header:We couldn't activate your card`;

  /** CVV */
  cvvHeader = $localize`:@@card.details.base-pin-modal.cvv.header:Enter the CVV code`;
  cvvSubText = $localize`:@@card.details.base-pin-modal.cvv.subtext:The CVV code can be found on the back of your card`;
  cvvLabelText = $localize`:@@card.details.base-pin-modal.cvv.label:CVV code`;
  cvvMismatchError = $localize`:@@card.details.base-pin-modal.cvv.mismatch-error:Let's try that again. That didn't match the CVV on your card.`;
  cvvLengthError = $localize`:@@card.details.base-pin-modal.cvv.length-error:Please enter a 3-digit CVV code`;

  /** PIN */
  pinLabelText = $localize`:@@card.details.base-pin-modal.pin.label:Enter a new 4-digit PIN`;
  pinMismatchError = $localize`:@@card.details.base-pin-modal.pin.mismatch-error:Let's try that again. The PIN numbers didn't match.`;
  pinLengthError = $localize`:@@card.details.base-pin-modal.pin.length-error:Please type in a 4-digit PIN number`;

  /** Confirm PIN */
  confirmPINLabelText = $localize`:@@card.details.base-pin-modal.confirm-pin.label:Confirm your new PIN`;

  /** */
  lockCardSuccessNotificationHeader = $localize`:@@cards.notification-popup.locking.success.header:Success! Your card is locked`;
  unlockCardSuccessNotificationHeader = $localize`:@@cards.notification-popup.unlocking.success.header:Success! Your card is unlocked`;
  unlockCardFailedNotificationHeader = $localize`:@@cards.notification-popup.unlocking.failure.header:Unable to unlock card`;
  lockCardFailedNotificationHeader = $localize`:@@cards.notification-popup.locking.failure.header:Unable to lock card`;

  lockCardFailedNotificationMessage = (cardItem?: CardItem) =>
    $localize`:@@cards.notification-popup.locking.failure.message:We couldn't lock the card ending in ${cardItem?.maskedNumber}:INTERPOLATION:. Refresh the screen and try again.`;
  unlockCardFailedNotificationMessage = (cardItem?: CardItem) =>
    $localize`:@@cards.notification-popup.unlocking.failure.message:We couldn't unlock the card ending in ${cardItem?.maskedNumber}:INTERPOLATION:. Refresh the screen and try again.`;
  lockCardSuccessNotificationMessage = (cardItem?: CardItem) =>
    $localize`:@@cards.notification-popup.locking.success.message:Your card ending in ${cardItem?.maskedNumber}:INTERPOLATION: is locked and you won't be able to use it..`;
  unlockCardSuccessNotificationMessage = (cardItem?: CardItem) =>
    $localize`:@@cards.notification-popup.unlocking.success.message:Your card ending in ${cardItem?.maskedNumber}:INTERPOLATION: is unlocked and ready to use.`;
  resetPinSuccessNotificationMessage = (lastFourOfCard: string) =>
    $localize`:@@cards.notification-popup.reset-pin.success.message:The PIN for your card ending in ${lastFourOfCard} has been updated and is ready to use`;
  resetPinFailureNotificationMessage = (lastFourOfCard: string) =>
    $localize`:@@cards.notification-popup.reset-pin.failure.message:Sorry, we weren't able to update the PIN for your card ending in ${lastFourOfCard}. Please try updating your PIN again.`;
  cardActivationSuccessNotificationMessage = (lastFourOfCard: string) =>
    $localize`:@@cards.notification-popup.activate-card.success.message:Your card ending in ${lastFourOfCard} is active and ready to use `;
  cardActivationFailureNotificationMessage = (lastFourOfCard: string) =>
    $localize`:@@cards.notification-popup.activate-card.failure.message:Sorry, we weren't able to activate your card ending in ${lastFourOfCard}. Please try activating your card again.`;
}
