<ent-confirmation-modal
  [isOpen]="isOpen | async"
  [title]="textService.confirmationModalTitle"
  [confirmMessage]="''"
  [bodyTemplate]="confirmationBodyTemplate"
  [noBtnText]="textService.confirmationModalNo"
  [yesBtnText]="textService.confirmationModalYes"
  (close)="continueSession()"
  (no)="logout()"
  (yes)="continueSession()"
></ent-confirmation-modal>

<ng-template #confirmationBodyTemplate>
  <span> {{ textService.confirmationModalBody }} </span>
  <span>
    {{ textService | translationFunctions: 'confirmationModalBodyTimeLeft':timeFormat:minutesRemaining:secondsRemaining
    }}
  </span>
</ng-template>
