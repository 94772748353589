import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { EntUIModule } from "@ent/ui";
import { HeaderModule } from "@backbase/ui-ang/header";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadButtonModule } from "@backbase/ui-ang/load-button";
import { InputValidationMessageModule } from "@backbase/ui-ang/input-validation-message";
import { InputCheckboxModule } from "@backbase/ui-ang/input-checkbox";
import { MoneyInsightComponent } from "./components/money-insight/money-insight.component";
import { MoneyDesktopWidgetComponent } from "./components/money-desktop-widget/money-desktop-widget.component";
import { MoneyInsightsEnrollmentComponent } from "./components/money-insights-enrollment/money-insights-enrollment.component";
import { MoneyInsightsTextService } from "./services/money-insights.text.service";

@NgModule({
  imports: [
    CommonModule,
    EntUIModule,
    HeaderModule,
    InputCheckboxModule,
    InputValidationMessageModule,
    FormsModule,
    ReactiveFormsModule,
    LoadButtonModule,
    NgbCarouselModule,
    RouterModule.forChild([{ path: "", component: MoneyInsightComponent, pathMatch: "full" }]),
  ],
  declarations: [MoneyInsightComponent, MoneyDesktopWidgetComponent, MoneyInsightsEnrollmentComponent],
  providers: [MoneyInsightsTextService, Title],
})
export class MoneyInsightModule {}
