<ng-container [ngSwitch]="errorKey">
  <div *ngSwitchCase="productErrorKey.VERIFICATION_DENIED">
    <ent-page-error
      [errorText]="textService.alloyErrorTextLabel"
      [errorHeaderText]="textService.alloyErrorHeaderLabel"
      [dismissButtonText]="textService.alloyButtonLabel"
      (clickDismiss)="stepsService.setCurrentStep(formStates.EXPLORE)"
    ></ent-page-error>
  </div>
  <div *ngSwitchDefault class="card">
    <div class="card-body">
      <ent-page-retry
        [errorText]="textService.errorTextLabel"
        [errorHeaderText]="textService.errorHeaderLabel"
        [buttonText]="textService.retryButtonLabel"
        [backButtonText]="textService.backButtonLabel"
        (clickBack)="stepsService.setCurrentStep(formStates.REVIEW)"
        (clickRetry)="retryClick.emit()"
      ></ent-page-retry>
    </div>
  </div>
</ng-container>
