import { Injectable } from "@angular/core";
import { CardItem, CardsHttpService, LockStatus } from "@backbase/cards-http-ang";
import { DateTime } from "luxon";
import { LOADING_KEYS } from "../../models/loading-keys";
import { CARD_STATE, PaymentCard } from "../../models/payment-card";
import { LoadingService } from "../loading.service";

@Injectable({ providedIn: "root" })
export class CardsHTTPService {
  public cardItem?: CardItem;
  public paymentCard?: PaymentCard;
  public isDebitCard = false;
  public isATMCard = false;
  public isLocked = false;
  public cardState = CARD_STATE.ERROR;
  constructor(private readonly cardsHTTPService: CardsHttpService, private readonly loadingService: LoadingService) {
    // add in the base path to fix upgrade 2023.06 issue where this base path was removed
    // cardsHTTPService.configuration.basePath = "/api/cards-presentation-service";
  }

  getCard = (id: string) => {
    this.loadingService.startLoading(LOADING_KEYS.INITIAL_LOADING);
    return new Promise((resolve, reject) => {
      this.cardsHTTPService
        .getCardById({
          id,
        })
        .subscribe({
          next: (response) => {
            this.setCardItem(response);
            this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
            resolve(response);
          },
          error: (err) => {
            this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
            reject(err);
          },
        });
    });
  };

  resetPin = (pin: string, cvv: string) => {
    this.loadingService.startLoading(LOADING_KEYS.RESET_PIN);
    return new Promise((resolve, reject) => {
      this.cardsHTTPService
        .resetPin({
          id: this.cardItem?.id ?? "",
          resetPinPost: {
            pin,
            token: "",
            additions: {
              cvv2: cvv,
              expirationMonth: this.cardItem?.expiryDate?.month ?? "",
              expirationYear: this.cardItem?.expiryDate?.year ?? "",
            },
          },
        })
        .subscribe({
          next: (response) => {
            this.setCardItem(response);
            this.loadingService.stopLoading(LOADING_KEYS.RESET_PIN);
            resolve(response);
          },
          error: (err) => {
            this.loadingService.stopLoading(LOADING_KEYS.RESET_PIN);
            reject(err);
          },
        });
    });
  };
  activateCard = (pin: string, cvv: string) => {
    this.loadingService.startLoading(LOADING_KEYS.CARD_ACTIVATE);
    return new Promise((resolve, reject) => {
      this.cardsHTTPService
        .activate({
          id: this.cardItem?.id ?? "",
          activatePost: {
            token: "activate",
            additions: {
              pin,
              cvv2: cvv,
              expirationMonth: this.cardItem?.expiryDate?.month ?? "",
              expirationYear: this.cardItem?.expiryDate?.year ?? "",
            },
          },
        })
        .subscribe({
          next: (response) => {
            this.setCardItem(response);
            this.loadingService.stopLoading(LOADING_KEYS.CARD_ACTIVATE);
            resolve(response);
          },
          error: (err) => {
            this.loadingService.stopLoading(LOADING_KEYS.CARD_ACTIVATE);
            reject(err);
          },
        });
    });
  };

  toggleLockStatus = () => {
    this.loadingService.startLoading(LOADING_KEYS.INITIAL_LOADING);
    return new Promise((resolve, reject) => {
      this.cardsHTTPService
        .updateLockStatus({
          id: this.cardItem?.id ?? "",
          lockStatusPost: {
            lockStatus: this.isLocked ? LockStatus.UNLOCKED : LockStatus.LOCKED,
          },
        })
        .subscribe({
          next: (response) => {
            this.setCardItem(response);
            this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
            resolve(response);
          },
          error: (err) => {
            this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
            reject(err);
          },
        });
    });
  };

  setIsDebitCard() {
    this.isDebitCard =
      !!this.cardItem &&
      this.cardItem.type === "Debit" &&
      (!this.cardItem.subType || (this.cardItem?.subType ?? "").toUpperCase() === "DEBIT");
  }

  setIsATMCard() {
    this.isATMCard =
      !!this.cardItem && this.cardItem.type === "Debit" && (this.cardItem?.subType ?? "").toUpperCase() === "ATM";
  }

  setIsLocked() {
    this.isLocked = this.cardItem?.lockStatus === LockStatus.LOCKED;
  }

  setCardState() {
    switch (this.cardItem?.status?.toLowerCase()) {
      case "active":
        this.cardState = CARD_STATE.ACTIVE;
        break;
      case "inactive":
        this.cardState = CARD_STATE.INACTIVE;
        break;
      case "cancelled":
      case "canceled":
      case "deactivated":
      case "expired":
        this.cardState = CARD_STATE.CANCELLED;
        break;
      default:
        this.cardState = CARD_STATE.ERROR;
        break;
    }
  }

  setCardItem = (cardItem?: CardItem) => {
    this.cardItem = cardItem ? { ...cardItem } : undefined;
    this.setCardState();
    this.setPaymentCard();
    this.setIsATMCard();
    this.setIsDebitCard();
    this.setIsLocked();
  };

  setPaymentCard = () => {
    this.paymentCard = this.cardItem
      ? {
          id: this.cardItem.id,
          type: this.cardItem.type,
          subType: this.cardItem.subType,
          vendor: this.cardItem.brand,
          number: this.cardItem.maskedNumber,
          expirationDate: DateTime.fromObject(
            {
              month: parseInt(this.cardItem.expiryDate?.month ?? "0", 10),
              year: parseInt(this.cardItem.expiryDate?.year ?? "0", 10),
            },
            { zone: "America/Denver" },
          ).toJSDate(),
          cardName: this.cardItem.name ?? "",
          name: this.cardItem.holder?.name ?? "",
          lockStatus: this.cardItem.lockStatus,
          status: this.cardItem.status,
          currency: "USD",
        }
      : undefined;
  };
}
