import { AmountModule } from "@backbase/ui-ang/amount";
import { HeaderModule } from "@backbase/ui-ang/header";
import { LoadingIndicatorModule } from "@backbase/ui-ang/loading-indicator";
import { ProductSelectorModule } from "@backbase/ui-ang/product-selector";
import { InputValidationMessageModule } from "@backbase/ui-ang/input-validation-message";
import { ProductItemBasicAccountModule } from "@backbase/ui-ang/product-item-basic-account";
import { EmptyStateModule } from "@backbase/ui-ang/empty-state";
import { AmountInputModule } from "@backbase/ui-ang/amount-input";
import { InputDatepickerModule } from "@backbase/ui-ang/input-datepicker";
import { InputTimepickerModule } from "@backbase/ui-ang/input-timepicker";
import { ModalModule } from "@backbase/ui-ang/modal";
import { ButtonModule } from "@backbase/ui-ang/button";
import { InputRadioGroupModule } from "@backbase/ui-ang/input-radio-group";
import { TextareaModule } from "@backbase/ui-ang/textarea";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { EntitlementsModule } from "@backbase/foundation-ang/entitlements";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { LayoutModule } from "@backbase/ui-ang/layout";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxMaskModule } from "ngx-mask";
import { SuccessSvgComponent } from "./components/success-svg/success-svg.component";
import { SuccessSvgNoMotionComponent } from "./components/success-svg-no-motion/success-svg-no-motion.component";
import { LogoComponent } from "./components/logo/logo.component";
import { PageLoaderComponent } from "./components/page-loader/page-loader.component";
import { StatefulComponentComponent } from "./components/stateful-component/stateful-component.component";
import { PageErrorComponent } from "./components/page-error/page-error.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import {
  AccountSelectorFormControlComponent,
  AmountFormControlComponent,
  DatepickerComponent,
  MemoFormControlComponent,
  DropdownSingleSelectComponent,
} from "./components/form";
import {
  FromRecordDisplayComponent,
  ToRecordDisplayComponent,
  CalendarDisplayComponent,
  MemoDisplayComponent,
  AmountDisplayComponent,
  FrequencyDisplayComponent,
} from "./components/display";
import { StringOrDefaultPipe } from "./pipes/string-or-default/string-or-default.pipe";
import { NumberOrDefaultPipe } from "./pipes/number-or-default/number-or-default.pipe";
import { TranslationFunctionsPipe } from "./pipes/translation-functions/translation-functions.pipe";
import { NavmenuItemComponent } from "./components/navmenu-item/navmenu-item.component";
import { NavmenuItemSubmenuComponent } from "./components/navmenu-item-submenu/navmenu-item-submenu.component";
import { PageRetryComponent } from "./components/page-retry/page-retry.component";
import { PageSuccessComponent } from "./components/page-success/page-success.component";
import { FrequencySelectorComponent } from "./components/frequency-selector/frequency-selector.component";

@NgModule({
  imports: [
    CommonModule,
    EntitlementsModule,
    AmountModule,
    TextareaModule,
    ReactiveFormsModule,
    LoadingIndicatorModule,
    HeaderModule,
    ProductSelectorModule,
    InputValidationMessageModule,
    ProductItemBasicAccountModule,
    EmptyStateModule,
    AmountInputModule,
    InputDatepickerModule,
    ModalModule,
    ButtonModule,
    InputRadioGroupModule,
    RouterModule,
    NgbDropdownModule,
    LayoutModule,
    InputTimepickerModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    LogoComponent,
    PageLoaderComponent,
    StatefulComponentComponent,
    PageErrorComponent,
    AccountSelectorFormControlComponent,
    DropdownSingleSelectComponent,
    DatepickerComponent,
    ConfirmationModalComponent,
    FromRecordDisplayComponent,
    ToRecordDisplayComponent,
    CalendarDisplayComponent,
    MemoDisplayComponent,
    AmountDisplayComponent,
    FrequencyDisplayComponent,
    SuccessSvgComponent,
    SuccessSvgNoMotionComponent,
    MemoFormControlComponent,
    AccountSelectorFormControlComponent,
    AmountFormControlComponent,
    StringOrDefaultPipe,
    NumberOrDefaultPipe,
    TranslationFunctionsPipe,
    NavmenuItemComponent,
    NavmenuItemSubmenuComponent,
    PageRetryComponent,
    PageSuccessComponent,
    FrequencySelectorComponent,
  ],
  exports: [
    LogoComponent,
    PageLoaderComponent,
    StatefulComponentComponent,
    PageErrorComponent,
    AccountSelectorFormControlComponent,
    DropdownSingleSelectComponent,
    DatepickerComponent,
    ConfirmationModalComponent,
    FromRecordDisplayComponent,
    ToRecordDisplayComponent,
    CalendarDisplayComponent,
    MemoDisplayComponent,
    AmountDisplayComponent,
    FrequencyDisplayComponent,
    SuccessSvgComponent,
    SuccessSvgNoMotionComponent,
    MemoFormControlComponent,
    AccountSelectorFormControlComponent,
    AmountFormControlComponent,
    StringOrDefaultPipe,
    NumberOrDefaultPipe,
    TranslationFunctionsPipe,
    NavmenuItemComponent,
    PageRetryComponent,
    PageSuccessComponent,
    FrequencySelectorComponent,
  ],
})
export class EntUIModule {}
