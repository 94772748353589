import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { NotificationService } from "@backbase/ui-ang/notification";
import { LoadingService, CardsHTTPService, LOADING_KEYS } from "@ent/data";
import { TextService } from "../../services/text.service";
import { BasePinModalComponent } from "../../components/base-pin-modal/base-pin-modal.component";

@Component({
  selector: "ent-reset-pin-modal",
  templateUrl: "../../components/base-pin-modal/base-pin-modal.component.html",
  styleUrls: ["../../components/base-pin-modal/base-pin-modal.component.scss"],
})
export class ResetPinModalComponent extends BasePinModalComponent implements OnInit, OnChanges {
  @Input()
  isOpen: boolean;

  @Output()
  closeModal = new EventEmitter();

  title = this.textService.resetPinModalHeader;
  loadingText = this.textService.resetPinLoadingText;
  confirmButtonText = this.textService.resetPinSetPinText;

  constructor(
    private readonly loadingService: LoadingService,
    public readonly textService: TextService,
    protected readonly cardsHTTPService: CardsHTTPService,
    private readonly notificationService: NotificationService,
  ) {
    super(textService, cardsHTTPService);
  }

  onCloseModal = () => {
    this.closeModal.emit();
  };

  ngOnInit(): void {
    this.loadingService.isLoading(LOADING_KEYS.RESET_PIN).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.setInitialModalState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen.currentValue) {
      this.setInitialModalState();
    }
  }

  resetSuccess = () => {
    this.notificationService.showNotification({
      header: this.textService.resetPinSuccessNotificationHeader,
      message: this.textService.resetPinSuccessNotificationMessage(this.cardsHTTPService.cardItem?.maskedNumber),
      modifier: "success",
    });
    this.onCloseModal();
  };

  resetFailure = (err: any) => {
    this.loadingService.stopLoading(LOADING_KEYS.CARD_ACTIVATE);

    if (this.isCVVMismatchError(err)) {
      this.resetFormInvalidCVV();
    } else {
      this.onCloseModal();
      this.notificationService.showNotification({
        header: this.textService.resetPinFailureNotificationHeader,
        message: this.textService.resetPinFailureNotificationMessage(this.cardsHTTPService.cardItem?.maskedNumber),
        modifier: "error",
      });
    }
  };

  onFormSubmit = async () => {
    this.form.markAllAsTouched();
    if (!this.form.valid || this.isLoading) return;
    if (!this.isPinConfirmed()) {
      this.onPinsMismatch();
      return;
    }
    try {
      await this.cardsHTTPService.resetPin(this.form.controls.pin.value, this.form.controls.cvv.value);
      this.resetSuccess();
    } catch (err) {
      this.resetFailure(err);
    }
  };
}
