import { Injectable } from "@angular/core";
import { SharedRoutableModalService } from "@backbase/shared/feature/routable-modal";
import { Router } from "@angular/router";

const makeAPaymentModal = "make-a-payment";
const accessMyCardModal = "access-my-card";

@Injectable({
  providedIn: "root",
})
export class AccountsInitiatePaymentCommunication {
  constructor(private readonly routableModal: SharedRoutableModalService, private readonly router: Router) {}

  reset() {}

  closeEvent(): void {
    this.routableModal.closeModal();
  }

  headerNavigationAction(value: any): void {
    this.routableModal.openModal(value);
  }

  repayEvent() {
    this.routableModal.openModal(makeAPaymentModal);
  }

  cashAdvanceEvent() {
    this.routableModal.openModal(accessMyCardModal);
  }
}
