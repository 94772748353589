<div>
  <ent-dropdown-single-select
    [controlFormGroup]="controlFormGroup"
    [selectLabel]="schedulingTextService.occurrenceSelectLabel"
    [isInvalid]="!!controlFormGroup.controls.occurrence.touched && !!errors?.occurrence"
    formFieldName="occurrence"
    testId="occurrence"
    [isRequired]="true"
    [options]="occurrences"
    [error]="errors?.occurrence"
  >
  </ent-dropdown-single-select>
  <div [ngSwitch]="controlFormGroup.controls.occurrence.value">
    <ng-container *ngSwitchCase="occurrencesEnum.LATER">
      <ng-container *ngTemplateOutlet="laterOptions"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="occurrencesEnum.RECURRING">
      <ng-container *ngTemplateOutlet="recurringOptions"></ng-container>
    </ng-container>
  </div>
</div>
<ng-template #laterOptions>
  <div [formGroup]="controlFormGroup" class="mb-3">
    <ent-datepicker
      [controlFormGroup]="controlFormGroup"
      [isInvalid]="!!controlFormGroup.controls.executionDate.touched && !!errors?.executionDate"
      [formFieldName]="'executionDate'"
      [label]="schedulingTextService.executionDateLabel"
      [error]="errors?.executionDate"
      [minDate]="minDate"
      [overrideDateFormat]="'MM/dd/yyyy'"
      [markDisabled]="isExternalTransfer? restrictedDatesFunction: undefined"
    >
    </ent-datepicker>
  </div>
</ng-template>

<ng-template #recurringOptions>
  <div [formGroup]="controlFormGroup" class="mb-3">
    <ent-dropdown-single-select
      [controlFormGroup]="controlFormGroup"
      [selectLabel]="schedulingTextService.frequencySelectLabel"
      [formFieldName]="'frequency'"
      [isInvalid]="!!controlFormGroup.controls.frequency.touched && !!errors?.frequency"
      [testId]="'frequency'"
      [error]="errors?.frequency"
      [options]="frequencies"
    >
    </ent-dropdown-single-select>
    <ent-datepicker
      [controlFormGroup]="controlFormGroup"
      [isInvalid]="!!controlFormGroup.controls.executionDate.touched && !!errors?.executionDate"
      [formFieldName]="'executionDate'"
      [label]="schedulingTextService.recurringExecutionDateLabel"
      [error]="errors?.executionDate"
      [minDate]="minDate"
      [overrideDateFormat]="'MM/dd/yyyy'"
      [markDisabled]="isExternalTransfer? restrictedDatesFunction: undefined"
    >
    </ent-datepicker>
    <div class="bb-label mt-3">{{ this.schedulingTextService.endTypeHeaderLabel }}</div>
    <bb-input-radio-group-ui
      [horizontal]="false"
      [required]="true"
      name="endType"
      formControlName="endType"
      [verticalAlign]="'top'"
      inputSelector=".bb-input-radio-group"
    >
      <bb-input-radio-ui value="NEVER" [labelTemplate]="this.neverEndsLabel"></bb-input-radio-ui>
      <bb-input-radio-ui value="ON" [labelTemplate]="this.lastTransferDateLabel"></bb-input-radio-ui>
      <bb-input-radio-ui value="AFTER" [labelTemplate]="this.numberOfTransfersLabel"></bb-input-radio-ui>
    </bb-input-radio-group-ui>
  </div>
</ng-template>

<ng-template #neverEndsLabel>
  <div class="radio-btn__label--container bb-input__datepicker__wrapper" [formGroup]="controlFormGroup">
    <div class="radio-btn__label--header">
      <span class="bb-input-radio-group__radio-label"> {{ schedulingTextService.endTypeNeverEndsLabel }} </span>
    </div>
  </div>
</ng-template>

<ng-template #lastTransferDateLabel>
  <div class="radio-btn__label--container bb-input__datepicker__wrapper" [formGroup]="controlFormGroup">
    <div class="radio-btn__label--header">
      <span class="bb-input-radio-group__radio-label"> {{ schedulingTextService.endTypeLastTransferDateLabel }} </span>
    </div>
    <div *ngIf="controlFormGroup?.controls.endType.value === 'ON'">
      <ent-datepicker
        [controlFormGroup]="controlFormGroup"
        [formFieldName]="'endDate'"
        label=""
        [error]="errors?.endDate"
        [minDate]="minEndDate"
        [isInvalid]="!!controlFormGroup.controls.endDate.touched && !!errors?.endDate"
        [overrideDateFormat]="'MM/dd/yyyy'"
        [markDisabled]="isExternalTransfer? restrictedDatesFunction: undefined"
      ></ent-datepicker>
    </div>
  </div>
</ng-template>

<ng-template #numberOfTransfersLabel>
  <div class="radio-btn__label--container bb-input__numberinput" [formGroup]="controlFormGroup">
    <div class="radio-btn__label--header">
      <span class="bb-input-radio-group__radio-label"> {{ schedulingTextService.endTypeNumberOfTransfersLabel }} </span>
    </div>
    <div
      *ngIf="controlFormGroup?.controls.endType.value === 'AFTER'"
      [ngClass]="{ 'ent-input--invalid': !!controlFormGroup.controls.repeat.touched && !!errors?.repeat }"
    >
      <bb-input-number-ui
        formControlName="repeat"
        [aria-describedby]="'input-number-showcase-error'"
        [min]="2"
        #numberInput
        [allowNegativeSign]="false"
      >
      </bb-input-number-ui>

      <bb-input-validation-message-ui [showErrors]="!!controlFormGroup.controls.repeat.touched && !!errors?.repeat">
        <span>{{ errors?.repeat }}</span>
      </bb-input-validation-message-ui>
    </div>
  </div>
</ng-template>
