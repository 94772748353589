import { CardItem } from "@backbase/cards-http-ang";
import { PaymentCardStatus } from "@backbase/cards-management-journey-ang";

export const getStatusFromCard = (cardItem: CardItem) => {
  switch (cardItem.status.toLowerCase()) {
    case "active":
      return PaymentCardStatus.Active;
    case "inactive":
      return PaymentCardStatus.Inactive;
    case "expired":
      return PaymentCardStatus.Expired;
    case "cancelled":
    case "canceled":
    case "deactivated":
      return PaymentCardStatus.Canceled;
    default:
      return "";
  }
};

export const normalizeCardItemFromAPICall = (cardItem: CardItem): CardItem => ({
  ...cardItem,
  type: cardItem.type.toLowerCase() === "debit" ? "Debit" : "Credit",
  status: getStatusFromCard(cardItem),
});
