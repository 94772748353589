export enum PRODUCT_FORM_ERROR_KEY {
  AMOUNT_REQUIRED = "amount.required",
  AMOUNT_MIN = "amount.min",
  AMOUNT_MAX = "amount.max",
  ACCOUNT_REQUIRED = "account.required",
}

export enum PRODUCT_FORM_ERRORS {
  FROM_ACCOUNT = "fromAccount",
  AMOUNT = "amount",
}

export interface IProductFormErrors {
  [PRODUCT_FORM_ERRORS.FROM_ACCOUNT]?: string;
  [PRODUCT_FORM_ERRORS.AMOUNT]?: string;
}
