import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-open-share-card",
  template: `
    <div class="row justify-content-center">
      <div class="col-md-8">
        <ng-content select="[navigation]"></ng-content>
        <div class="px-0 pb-5">
          <bb-header-ui [heading]="heading" headingType="h1"> </bb-header-ui>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center m-3">
              <i aria-hidden="true" class="bb-icon main-icon ent-fa-light" [ngClass]="iconClass"></i>
              <div class="my-2 d-inline-block text-center">
                <bb-header-ui headingType="h3" [heading]="title"></bb-header-ui>
                <p class="bb-subtitle text-center">
                  <span *ngIf="!supportModalText" class="bb-text-support">{{ subtitle }}</span>
                  <ent-support-text-modal
                    *ngIf="supportModalText"
                    class="bb-text-support"
                    [modalTitle]="subtitle"
                    [modalText]="supportModalText"
                    [closeButtonText]="supportModalCloseButtonText"
                  >
                    {{ subtitle }}
                  </ent-support-text-modal>
                </p>
              </div>
            </div>
            <ng-content select="[card-content]"></ng-content>
            <ng-content select="[footer]"> </ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class OpenShareCardComponent {
  @Input() heading;
  @Input() title;
  @Input() subtitle;
  @Input() iconClass;
  @Input() supportModalText?;
  @Input() supportModalCloseButtonText?;
}
