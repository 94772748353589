import { Injectable } from "@angular/core";

export enum PAYMENT_ORDER_ERROR_CODES {
  A2A_2110 = "a2a.2110",
  A2A_2124 = "a2a.2124",
  A2A_2123 = "a2a.2123",
  A2A_2115 = "a2a.2115",
  A2A_4109 = "a2a.4109",
  SYM_3010 = "sym.3010",
  SYM_3033 = "sym.3033",
  SYM_3035 = "sym.3035",
  SYM_3036 = "sym.3036",
}

export const SYMITAR_ERROR_CODES = ["3010", "3033", "3035", "3036"];
export const A2A_ERROR_CODES = ["2110", "2115", "2123", "2124", "4109"];

@Injectable({ providedIn: "any" })
export class PaymentErrorBannerTextService {
  a2a2110Error = $localize`:@@payment-order.a2a.error.2110:Your transaction limit has been reached, please correct and resubmit or contact us.`;
  a2a2124Error = $localize`:@@payment-order.a2a.error.2124:Your send date cannot be earlier than today, please correct and resubmit.`;
  a2a2115Error = $localize`:@@payment-order.a2a.error.2115:Hmm... external transfers can't be made on holidays. Edit your transfer to select a different date.`;
  a2a4109Error = $localize`:@@payment-order.a2a.error.4109:You already have a payment scheduled for this date with similar payment details.`;
  sym3010Error = $localize`:@@payment-order.error.3010:Invalid transaction amount.`;
  sym3033Error = $localize`:@@payment-order.error.3033:There are insufficient funds to complete this transaction.`;
  sym3035Error = $localize`:@@payment-order.error.3035:The requested loan payment is greater than the maximum loan payment.`;
  sym3036Error = $localize`:@@payment-order.error.3036:The amount of the transaction is less than the minimum allowed.`;

  getErrorTextByCode = (errorCode?: PAYMENT_ORDER_ERROR_CODES): string | undefined => {
    switch (errorCode) {
      case PAYMENT_ORDER_ERROR_CODES.A2A_2110:
        return this.a2a2110Error;
      case PAYMENT_ORDER_ERROR_CODES.A2A_2124:
        return this.a2a2124Error;
      case PAYMENT_ORDER_ERROR_CODES.A2A_2115:
      case PAYMENT_ORDER_ERROR_CODES.A2A_2123:
        return this.a2a2115Error;
      case PAYMENT_ORDER_ERROR_CODES.A2A_4109:
        return this.a2a4109Error;
      case PAYMENT_ORDER_ERROR_CODES.SYM_3010:
        return this.sym3010Error;
      case PAYMENT_ORDER_ERROR_CODES.SYM_3033:
        return this.sym3033Error;
      case PAYMENT_ORDER_ERROR_CODES.SYM_3035:
        return this.sym3035Error;
      case PAYMENT_ORDER_ERROR_CODES.SYM_3036:
        return this.sym3036Error;
      default:
        return undefined;
    }
  };
}
