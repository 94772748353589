import { Injectable } from "@angular/core";

@Injectable({ providedIn: "any" })
export class MemberTransferTextService {
  titleText = $localize`:@@member-transfer.page-title:Send Money to a Member`;
  addRecipient = $localize`:@@member-transfer.add-recipient.button:Add a Recipient`;
  loadingText = $localize`:@@member-transfer.loading.spinner-text:Loading…`;
  addRecipientAPISuccess = $localize`:@@contacts-notifications.notification.create.success.header:`;
  addRecipientAPIFailure = $localize`:@@contacts-notifications.server.contacts.validation.account.information.result:`;
  loadingErrorHeader = $localize`:@@member-transfer.loading-error-header:We have a little problem`;
  loadingErrorText = $localize`:@@member-transfer.loading-error-text:We couldn't load the page. Please refresh the screen and try again.`;
  addARecipientButton = $localize`:@@member-transfer.form.add-recipient:Add a Recipient`;
  addButton = $localize`:@@member-transfer.form.add-button:Add`;
  noContactsTitle = $localize`:@@member-transfer.form.no-contacts-title:No Saved Recipients`;
  noContactsDescription = $localize`:@@member-transfer.form.no-contacts-description:Do you want to add a member?`;
}
