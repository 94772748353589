<div>
  <div>
    <div class="bb-text-align-center bb-block--xl">
      <ent-success-svg></ent-success-svg>
      <bb-header-ui headingClasses="bb-text-semi-bold" headingType="h4" [heading]="successHeaderText"> </bb-header-ui>
      <span class="bb-text-support"> {{ successText }} </span>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center flex-row-reverse">
  <button bbButton (click)="clickCancel.emit()" [color]="'primary'" class="mt-3" data-role="cancel-button">
    {{cancelButtonText}}
  </button>
  <button
    *ngIf="clickRepeat && repeatButtonText"
    bbButton
    (click)="clickRepeat.emit()"
    [color]="'secundary'"
    class="mt-3 mx-3"
    data-role="repeat-button"
  >
    {{repeatButtonText}}
  </button>
</div>
