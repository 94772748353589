<div class="card">
  <div class="card-body">
    <div class="bb-text-align-center bb-block--xl">
      <em class="fa-regular fa-triangle-exclamation fa-4x mb-4"></em>
      <bb-header-ui headingClasses="bb-text-semi-bold" headingType="h4" [heading]="errorHeaderText"> </bb-header-ui>
      <span class="bb-text-support"> {{ errorText }} </span>
    </div>
    <div *ngIf="dismissButtonText" class="d-flex justify-content-center">
      <button bbButton (click)="clickDismiss.emit()" [color]="'primary'" class="col-12">{{dismissButtonText}}</button>
    </div>
  </div>
</div>
