import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { BehaviorSubject } from "rxjs";
import { OAuthService } from "angular-oauth2-oidc";
import {
  AccountMetadata,
  COMPONENT_STATE,
  ConnectWidgetUrl,
  ExternalAccount,
  MicrodepositAccountMetadata,
  MoneyInsightHttpService,
  SaveAccountResponse,
} from "@ent/data";
import { LinkAccountService } from "@backbase/connect-external-accounts-journey-ang";
import { CustomConnectExternalAccountsTextService } from "../services/custom-connect-external-accounts.text.service";

@Component({
  selector: "ent-custom-connect-external-accounts",
  templateUrl: "./custom-connect-external-accounts.component.html",
})
export class CustomConnectExternalAccountsComponent implements OnInit {
  showDialog = true;
  showConfirmationDialog = false;
  url: string;
  accountMetadata: AccountMetadata | MicrodepositAccountMetadata;
  existingAccountError = false;
  existingAccountErrorText: string;
  successText: string;
  successHeader: string;
  componentState = new BehaviorSubject(COMPONENT_STATE.LOADED);
  modalOptions;

  constructor(
    private readonly oAuthService: OAuthService,
    private readonly location: Location,
    private readonly linkAccountService: LinkAccountService,
    private readonly service: MoneyInsightHttpService,
    public readonly textService: CustomConnectExternalAccountsTextService,
  ) {}

  ngOnInit(): void {
    this.modalOptions = {
      beforeDismiss: this.canCloseDialog,
    };
    this.getMXConnectUrl();
  }

  closeDialog() {
    this.service.completeFlow();
    this.linkAccountService.refreshAccounts();
    this.location.back();
  }

  handleMessage(eventData) {
    switch (eventData?.type) {
      case "mx/connect/memberConnected":
        this.getAccountMetadata(eventData.metadata);
        this.saveAccount();
        break;
      case "mx/connect/microdeposits/detailsSubmitted":
        this.getMicrodepositAccountMetadata(eventData.metadata);
        this.saveAccount();
        break;
      case "mx/ping":
        this.oAuthService.refreshToken();
        break;
      default:
        break;
    }
  }

  getMXConnectUrl = async () => {
    this.accountMetadata = undefined;
    this.url = undefined;
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      const response = await this.service.getConnectUrl();
      if (this.isConnectUrl(response)) {
        this.url = response.url;
        this.componentState.next(COMPONENT_STATE.LOADED);
      }
    } catch {
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  };

  saveAccount = async () => {
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      if (!this.accountMetadata) throw new Error();
      const response: SaveAccountResponse = await this.saveAccountByAccountType(this.accountMetadata);
      if (!this.isSaveAccountResponse(response)) throw new Error();
      this.setSuccessTextByType(response.accounts[0]);
      this.componentState.next(COMPONENT_STATE.LOADED);
    } catch (error) {
      if (error.status === 409) {
        this.existingAccountError = true;
      }
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  };

  async saveAccountByAccountType(
    accountMetadata: AccountMetadata | MicrodepositAccountMetadata,
  ): Promise<SaveAccountResponse> {
    return this.isMicrodeposit(accountMetadata)
      ? this.service.saveMicrodepositAccount(accountMetadata)
      : this.service.saveAccount(accountMetadata);
  }

  getAccountMetadata = (metadata) => {
    if (metadata && metadata.user_guid && metadata.member_guid) {
      this.accountMetadata = {
        userGuid: metadata.user_guid,
        sessionGuid: metadata.session_guid,
        memberGuid: metadata.member_guid,
      };
    }
  };

  getMicrodepositAccountMetadata = (metadata) => {
    if (metadata && metadata.user_guid && metadata.microdeposit_guid) {
      this.accountMetadata = {
        userGuid: metadata.user_guid,
        sessionGuid: metadata.session_guid,
        microdepositGuid: metadata.microdeposit_guid,
      };
    }
  };

  toggleConfirmationDialog = () => {
    this.showConfirmationDialog = !this.showConfirmationDialog;
  };

  canCloseDialog = (): boolean => {
    if (this.accountMetadata || !this.url) {
      return true;
    }
    this.toggleConfirmationDialog();
    return false;
  };

  private isMicrodeposit(object: any): object is MicrodepositAccountMetadata {
    return "microdepositGuid" in object;
  }

  private isConnectUrl(getConectUrlResponse?: any): getConectUrlResponse is ConnectWidgetUrl {
    return (
      getConectUrlResponse &&
      typeof getConectUrlResponse.url === "string" &&
      typeof getConectUrlResponse.mobile === "boolean"
    );
  }

  private isSaveAccountResponse(response?: any): response is SaveAccountResponse {
    const account = response.accounts[0];
    return (
      response &&
      response.accounts.length > 0 &&
      typeof account.bankName === "string" &&
      typeof account.accountType === "string" &&
      typeof account.maskedAccountNumber === "string"
    );
  }

  private setSuccessTextByType(account: ExternalAccount) {
    if (this.isMicrodeposit(this.accountMetadata)) {
      this.successText = this.textService.getSuccessMicrodepositSubText(account);
      this.successHeader = this.textService.successMicrodepositsHeader;
    } else {
      this.successText = this.textService.getSuccessSubText(account);
      this.successHeader = this.textService.successHeader;
    }
  }
}
