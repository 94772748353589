import { Component, OnChanges } from "@angular/core";
import { CardsHTTPService, PaymentCard } from "@ent/data";
import { TextService } from "../../services/text.service";
import { BasePinFormComponent } from "../base-pin-form/base-pin-form.component";

@Component({
  selector: "ent-pin-form",
  templateUrl: "./pin-form.component.html",
})
export class PinFormComponent extends BasePinFormComponent implements OnChanges {
  protected inputControlName = "pin";
  paymentCard: PaymentCard;
  constructor(public readonly textService: TextService, public readonly cardsHTTPService: CardsHTTPService) {
    super();
  }
  ngOnChanges(): void {
    this.paymentCard = { ...this.cardsHTTPService.paymentCard, status: "ACTIVE", lockStatus: "UNLOCKED" };
  }
}
