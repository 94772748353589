import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NotificationService } from "@backbase/ui-ang/notification";
import { CardsHTTPService } from "@ent/data";
import { TextService } from "../../services/text.service";

@Component({
  selector: "ent-card-controls",
  templateUrl: "card-controls.component.html",
})
export class CardControlsComponent implements OnInit {
  constructor(
    public readonly cardsHTTPService: CardsHTTPService,
    public readonly textService: TextService,
    private readonly notificationService: NotificationService,
  ) {}

  form = new FormGroup({
    isLocked: new FormControl(false),
  });

  isResetPinModalOpen = false;

  ngOnInit(): void {
    this.setLockStatus(this.cardsHTTPService.isLocked);
  }

  openResetPinModal = () => {
    this.isResetPinModalOpen = true;
  };

  closeResetPinModal = () => {
    this.isResetPinModalOpen = false;
  };

  onToggleLockStatus = async () => {
    try {
      await this.cardsHTTPService.toggleLockStatus();
      this.successfulLockToggleNotification();
    } catch (err) {
      this.unsuccessfulLockToggleNotification();
    }
    this.setLockStatus(this.cardsHTTPService.isLocked);
  };

  setLockStatus = (isLocked: boolean) => {
    this.form.get("isLocked")?.setValue(isLocked);
  };

  successfulLockToggleNotification = () => {
    let header = this.textService.lockCardSuccessNotificationHeader;
    let message = this.textService.lockCardSuccessNotificationMessage(this.cardsHTTPService.cardItem);
    if (!this.cardsHTTPService.isLocked) {
      header = this.textService.unlockCardSuccessNotificationHeader;
      message = this.textService.unlockCardSuccessNotificationMessage(this.cardsHTTPService.cardItem);
    }
    this.notificationService.showNotification({
      header,
      message,
      modifier: "success",
    });
  };

  unsuccessfulLockToggleNotification = () => {
    let header = this.textService.unlockCardFailedNotificationHeader;
    let message = this.textService.unlockCardFailedNotificationMessage(this.cardsHTTPService.cardItem);
    if (!this.cardsHTTPService.isLocked) {
      header = this.textService.lockCardFailedNotificationHeader;
      message = this.textService.lockCardFailedNotificationMessage(this.cardsHTTPService.cardItem);
    }
    this.notificationService.showNotification({
      header,
      message,
      modifier: "error",
    });
  };
}
