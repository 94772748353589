import { HttpClient, HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import {
  ArrangementManagerConfiguration,
  GetProductSummaryRequestParams,
  ProductSummary,
  ProductSummaryHttpService,
} from "@backbase/arrangement-manager-http-ang";
import { Observable } from "rxjs";
import { RETAIL_NOTIFICATION_PREFERENCES_JOURNEY_ARRANGEMENT_MANAGER_BASE_PATH } from "@backbase/retail-notification-preferences-journey-ang";
import { PRODUCT_KINDS } from "../../models";

@Injectable({
  providedIn: "root",
})
export class ProductSummaryWrapperHttpService extends ProductSummaryHttpService {
  private getProductSummaryRequestParams: GetProductSummaryRequestParams = {
    ignoredProductKindNames: [PRODUCT_KINDS.DEBIT_CARD],
  };

  constructor(
    httpClient: HttpClient,
    @Inject(RETAIL_NOTIFICATION_PREFERENCES_JOURNEY_ARRANGEMENT_MANAGER_BASE_PATH) override basePath: string,
    configuration: ArrangementManagerConfiguration,
  ) {
    super(httpClient, basePath, configuration);
  }

  override getProductSummary(
    requestParameters: GetProductSummaryRequestParams,
    observe?: "body" | undefined,
    reportProgress?: boolean | undefined,
    options?: { httpHeaderAccept?: "application/json" | undefined } | undefined,
  ): Observable<ProductSummary>;
  override getProductSummary(
    requestParameters: GetProductSummaryRequestParams,
    observe?: "response" | undefined,
    reportProgress?: boolean | undefined,
    options?: { httpHeaderAccept?: "application/json" | undefined } | undefined,
  ): Observable<HttpResponse<ProductSummary>>;
  override getProductSummary(
    requestParameters: GetProductSummaryRequestParams,
    observe?: "events" | undefined,
    reportProgress?: boolean | undefined,
    options?: { httpHeaderAccept?: "application/json" | undefined } | undefined,
  ): Observable<HttpEvent<ProductSummary>>;
  override getProductSummary():
    | import("rxjs").Observable<import("@backbase/arrangement-manager-http-ang").ProductSummary>
    | import("rxjs").Observable<
        import("@angular/common/http").HttpResponse<import("@backbase/arrangement-manager-http-ang").ProductSummary>
      >
    | import("rxjs").Observable<
        import("@angular/common/http").HttpEvent<import("@backbase/arrangement-manager-http-ang").ProductSummary>
      > {
    return super.getProductSummary(this.getProductSummaryRequestParams);
  }
}
