<div class="bb-text-align-center bb-block--xl">
  <em class="fa-regular fa-triangle-exclamation fa-4x mb-4"></em>
  <bb-header-ui headingClasses="bb-text-semi-bold" headingType="h4" [heading]="errorHeaderText"> </bb-header-ui>
  <span class="bb-text-support"> {{ errorText }} </span>
</div>
<div class="d-flex justify-content-center flex-row-reverse">
  <button bbButton (click)="clickRetry.emit()" color="primary" class="mt-3" data-role="refresh-button">
    {{buttonText}}
  </button>
  <button
    *ngIf="backButtonText"
    bbButton
    (click)="clickBack.emit()"
    color="secundary"
    class="mt-3 mx-2"
    data-role="back-button"
  >
    {{backButtonText}}
  </button>
</div>
