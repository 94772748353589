import { Route } from "@angular/router";
import {
  AccountDetailsModalViewComponent,
  ConnectExternalAccountsJourneyComponent,
  ConnectExternalAccountsListViewComponent,
} from "@backbase/connect-external-accounts-journey-ang";
import { CustomConnectExternalAccountsComponent } from "./custom-connect-external-accounts/custom-connect-external-accounts.component";
import { ActivateAccountModalComponent } from "./activate-account-modal/activate-account-modal.component";

export const connectAccountsOverrideRoutes: Route = {
  path: "",
  component: ConnectExternalAccountsJourneyComponent,
  children: [
    {
      path: "",
      component: ConnectExternalAccountsListViewComponent,
      children: [
        { path: "account-details", component: AccountDetailsModalViewComponent },
        { path: "activate-account", component: ActivateAccountModalComponent },
        { path: "connect-account", component: CustomConnectExternalAccountsComponent },
      ],
    },
  ],
};
