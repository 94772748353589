import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CardItem } from "@backbase/cards-http-ang";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { normalizeCardItemFromAPICall } from "../helpers/card-status";

@Injectable()
export class GetCardHttpInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (/\/cards\/(\w|-)*$/i.test(httpRequest.url)) {
      return next.handle(httpRequest).pipe(
        filter((event: any) => event instanceof HttpResponse),
        map(this.normalizeAPIBody),
      );
    }
    return next.handle(httpRequest);
  }

  normalizeAPIBody = (event: HttpResponse<any>) => {
    let newBody = event.body;
    if (newBody && newBody.id) {
      newBody = normalizeCardItemFromAPICall(newBody);
    }
    return event.clone<CardItem>({
      body: newBody,
    });
  };
}
