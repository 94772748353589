import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { TextService } from "../../services/text.service";

@Component({
  selector: "ent-card-services",
  templateUrl: "card-services.component.html",
})
export class CardServicesComponent {
  @Input()
  isLoading: boolean;

  constructor(private readonly router: Router, public readonly textService: TextService) {}

  public navigateToTravelNotices = () => {
    return this.router.navigate(["self-service/manage-cards/travel-notice"]);
  };
}
