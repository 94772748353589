import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UserManagementService } from "@backbase/user-http-ang";

@Component({
  selector: "ent-google-tag",
  template: `<div style="display:none"></div>`,
})
export class GoogleTagComponent implements OnInit {
  legalEntityId: string;
  environments = [
    { envRegex: /dev\.ent/, env: "dev" },
    { envRegex: /tst\.ent/, env: "tst" },
    { envRegex: /dev2\.ent/, env: "dev2" },
    { envRegex: /stg\.ent/, env: "stg" },
    { envRegex: /prd\.ent/, env: "prod" },
  ];
  constructor(private readonly router: Router, private readonly userManagementService: UserManagementService) {}

  getLocationHost = () => window.location.host;

  getEnvironmentVariable = () => {
    const environment = this.environments.find((env) => env.envRegex.test(this.getLocationHost()));
    return environment ? environment.env : "local";
  };

  getLegalEntityID = () => {
    this.userManagementService.getOwnUser().subscribe(({ legalEntityId }) => {
      this.legalEntityId = legalEntityId;
    });
  };

  pushRoutingToDataLayer = (item) => {
    if (item instanceof NavigationEnd) {
      const gtmTag: any = {
        event: "page",
        pageName: item.url,
      };
      gtmTag.user_id = this.legalEntityId;
      gtmTag.environment = this.getEnvironmentVariable();
      if (!(<any>window).dataLayer) {
        (<any>window).dataLayer = [];
      }
      (<any>window).dataLayer.push(gtmTag);
    }
  };

  ngOnInit(): void {
    this.getLegalEntityID();
    this.router.events.forEach(this.pushRoutingToDataLayer);
  }
}
