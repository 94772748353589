import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "ent-page-success",
  templateUrl: "./page-success.component.html",
})
export class PageSuccessComponent {
  @Input()
  successHeaderText!: string;

  @Input()
  successText!: string;

  @Input()
  repeatButtonText: string;

  @Output()
  clickRepeat = new EventEmitter();

  @Input()
  cancelButtonText!: string;

  @Output()
  clickCancel = new EventEmitter();
}
