import { NotificationService } from "@backbase/ui-ang/notification";
import { BillpayAppEventType } from "../models/billpay-event";
import { IBillpayEventHandler } from "./billpay-event-handler";
import { BillpayTextService } from "../service/billpay.text.service";

interface PayeeType {
  payee: { name: string; nickname?: string };
}

export class SuccessAddPayeeEventHandler implements IBillpayEventHandler {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly textService: BillpayTextService,
  ) {}

  public handleEvent = (event: BillpayAppEventType<PayeeType>) => {
    this.notificationService.showNotification({
      header: this.textService.addPayeeSuccessHeader,
      message: this.textService.addPayeeSuccessText(event.payload.payee.nickname ?? event.payload.payee.name),
      modifier: "success",
    });
  };
}
