import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "ent-dropdown-single-select",
  templateUrl: "./dropdown-single-select.component.html",
  styleUrls: ["dropdown-single-select.component.scss"],
})
export class DropdownSingleSelectComponent {
  @Input()
  controlFormGroup!: FormGroup;

  @Input()
  formFieldName!: string;

  @Input()
  error?: string;

  @Input()
  isInvalid!: boolean;

  @Input()
  testId!: string;

  @Input()
  selectLabel!: string;

  @Input()
  isRequired!: boolean;

  @Input()
  options!: { value: string | number | boolean; label: string }[];
}
