<form [formGroup]="form">
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <div class="bb-stack bb-block bb-block--xl" id="test__reset-confirm-pin-modal--pin-body">
        <div class="bb-stack__item">
          <label for="confirmPinNumber" [attr.data-role]="'label'">{{ textService.confirmPINLabelText }}</label>
          <div
            class="input-group"
            [ngClass]="{ 'input__group--invalid': form.controls.confirmPin.errors && form.controls.confirmPin.touched }"
          >
            <input
              id="confirmPinNumber"
              [type]="isShown ? 'text' : 'password'"
              class="form-control"
              mask="0000"
              autocomplete="off"
              [hiddenInput]="true"
              formControlName="confirmPin"
              [attr.data-role]="'input'"
            />
            <div class="input-group-append ent__input-append">
              <button
                bbButton
                (click)="toggleIsShown()"
                color="link"
                class="bb-input-password__visibility-toggle-button"
                data-role="bb-input-password-visibility-toggle-button"
              >
                <em class="fa-light" [ngClass]="{ 'fa-eye': !isShown, 'fa-eye-slash': isShown }"></em>
              </button>
            </div>
          </div>
          <bb-input-validation-message-ui
            id="confirm-pin-code-errors"
            [showErrors]="form.controls.confirmPin.errors && form.controls.confirmPin.touched"
          >
            <span *ngIf="form.controls.confirmPin.errors?.required || form.controls.confirmPin.errors?.minlength">
              {{ textService.pinLengthError }}
            </span>
          </bb-input-validation-message-ui>
        </div>
        <div class="bb-stack__item">
          <bb-payment-card-ui [paymentCard]="$any(paymentCard)"></bb-payment-card-ui>
        </div>
      </div>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui>
    <ng-template bbCustomModalFooter>
      <div class="ent__button-bar-container" id="test__reset-confirm-pin-modal--pin-footer">
        <div>
          <button bbButton color="link" class="bb-button-bar__button" (click)="onBack()" data-role="back-action-btn">
            {{ textService.pinBackText }}
          </button>
        </div>
        <div class="ent__button-container">
          <button
            bbButton
            color="secondary"
            class="bb-button-bar__button"
            (click)="onCancel()"
            data-role="cancel-action-btn"
          >
            {{ textService.pinCancelText }}
          </button>
          <button
            bbButton
            class="bb-button-bar__button"
            color="primary"
            (click)="onConfirm()"
            data-role="confirm-action-btn"
            type="submit"
          >
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </ng-template>
  </bb-modal-footer-ui>
</form>
