<div
  class="bb-layout bb-layout--horizontal-nav"
  [ngClass]="{
    'bb-layout--nav-expanded': layoutService.navigationExpanded$ | async,
    'bb-layout--high-resolution-view': layoutService.isHighResolutionView$ | async
  }"
>
  <!-- Impersonation banner -->
  <bb-impersonation-banner (stopImpersonation)="logout()"></bb-impersonation-banner>

  <!-- Skip to content -->
  <a
    href=""
    (click)="focusHandler.skipToContent($event)"
    aria-label="skip to main content"
    class="bb-layout__skip-to-content"
    i18n-aria-label="
      Skip to content|Text to enforce the user to click to put focus on the main content
      section@@bb-layout.topbar.skip_to_content.button"
  >
    Skip to Content
  </a>

  <!-- Topbar Area -->
  <nav
    class="bb-layout__topbar bb-layout__topbar--sm"
    aria-label="Navigation bar"
    i18n-aria-label="Navigation bar|Top bar with navigation links@@bb-layout.topbar.nav.aria-label"
  >
    <!-- Hamburger -->
    <button
      class="bb-layout__nav-expand-marker"
      aria-label="Toggle sidebar"
      i18n-aria-label="Sidebar Toggler|Button for toggling sidebar state@@bb-layout.sidebar_toggler"
      [attr.aria-expanded]="layoutService.navigationExpanded$ | async"
      (click)="layoutService.toggleNav()"
    >
      <span class="bb-icon"></span>
    </button>

    <!-- Logo -->
    <div class="bb-layout__branding-area">
      <ent-logo></ent-logo>
    </div>

    <!-- Navigation Items-->
    <ent-navmenu></ent-navmenu>

    <!-- Google Tag Manager -->
    <ent-google-tag></ent-google-tag>

    <!-- DataDog -->
    <ent-data-dog></ent-data-dog>

    <!-- Topbar Content Area (a.k.a. Right Topbar Area) -->
    <div class="bb-layout__topbar-content-area no-print">
      <div>
        <bb-notifications-badge
          [allowedRoutes]="notificationsAllowedRoutes"
          [displaySettingsButton]="displayNotificationSettingsButton"
          [displayShowMoreButtonInBadge]="true"
        ></bb-notifications-badge>
      </div>
      <div>
        <bb-user-context-menu-widget
          [appVersion]="appVersion"
          [showSingleContext]="false"
          [dropdownMenuPosition]="userContextMenuPosition$ | async"
          [pageSize]="7"
          [closeModalOnSelectSuccess]="false"
          [hasCardWrap]="false"
          (selectContextSuccess)="userContextService.reload()"
          (logout)="userContextService.logout()"
        >
        </bb-user-context-menu-widget>
      </div>
    </div>
  </nav>

  <!-- Backdrop-->
  <div class="bb-layout__nav-backdrop" (click)="layoutService.toggleNav()"></div>

  <!--Main content area-->
  <div class="bb-layout__main" *ngIf="routeData$ | async as routeData">
    <div class="bb-layout__sidebar"></div>
    <div class="bb-layout__content">
      <div class="bb-layout__container d-block bb-block--xl mt-5" [ngClass]="routeData?.cssClasses">
        <!--Header Area-->
        <div class="bb-layout__main-content-header" aria-live="polite"></div>

        <!-- Main Content Area -->
        <main class="bb-layout__main-content-area">
          <ng-content></ng-content>
        </main>
      </div>
    </div>
  </div>
</div>
