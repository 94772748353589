import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { PhoneAddress } from "@backbase/user-http-ang";
import { NotificationService } from "@backbase/ui-ang/notification";
import {
  COMPONENT_STATE,
  NotificationPreferenceHTTPService,
  OPT_IN_STATE,
  TOGGLE_TYPE,
  entPhoneNumberFormatter,
} from "@ent/data";
import { NotificationPreferenceTextService } from "./services/notification-preference.text.service";

@Component({
  selector: "ent-notification-preference",
  templateUrl: "./notification-preference.component.html",
})
export class NotificationPreferenceComponent implements OnInit {
  componentState = new BehaviorSubject(COMPONENT_STATE.LOADED);
  mobilePhoneNumber: PhoneAddress;
  homePhoneNumber: PhoneAddress;
  hasMobileNumber = false;
  hasHomeNumber = false;
  mobileSMSOptedIn = false;
  mobileCallOptedIn = false;
  homeCallOptedIn = false;
  toggleTypes = TOGGLE_TYPE;
  allNotificationsDisabled = false;
  form = new FormGroup({
    allNotificationsOptOutControl: new FormControl(this.allNotificationsDisabled),
    mobileSMSOptInControl: new FormControl(this.mobileSMSOptedIn),
    mobileCallOptInControl: new FormControl(this.mobileCallOptedIn),
    homeCallOptInControl: new FormControl(this.homeCallOptedIn),
  });
  isFormOpen = new BehaviorSubject(false);
  isAccordianOpen = new BehaviorSubject(false);

  constructor(
    public textService: NotificationPreferenceTextService,
    public notificationPreferenceHTTPService: NotificationPreferenceHTTPService,
    public notificationService: NotificationService,
  ) {}

  async ngOnInit() {
    await this.loadProducts();
  }

  openForm = () => this.isFormOpen.next(true);
  closeForm = () => this.isFormOpen.next(false);

  onTogglePreference = async (phoneNumber: PhoneAddress, toggleType: TOGGLE_TYPE) => {
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      await this.notificationPreferenceHTTPService.toggleNotificationPreference(phoneNumber, toggleType);
      await this.loadProducts();
      this.onOptInSuccess();
    } catch (err) {
      this.onOptInFailure();
    }
    this.isAccordianOpen.next(true);
  };
  onToggleAllPreferences = async () => {
    const allNotificationsDisabled = this.form.controls.allNotificationsOptOutControl.value;
    if (allNotificationsDisabled) {
      this.componentState.next(COMPONENT_STATE.LOADING);
      try {
        await this.notificationPreferenceHTTPService.disableAllNotificationPreferences();
        await this.loadProducts();
        this.onOptInSuccess();
      } catch (err) {
        this.onOptInFailure();
      }
    }

    this.isAccordianOpen.next(true);
  };

  onOptInSuccess = () => {
    this.notificationService.showNotification({
      header: this.textService.successNotification,
      message: "",
      modifier: "success",
    });
  };
  onOptInFailure = () => {
    this.notificationService.showNotification({
      header: this.textService.failureNotification,
      message: "",
      modifier: "error",
    });
  };
  updateAllValues = () => {
    this.mobilePhoneNumber = this.notificationPreferenceHTTPService.mobileNumber;
    this.homePhoneNumber = this.notificationPreferenceHTTPService.homeNumber;
    this.hasMobileNumber = !!this.notificationPreferenceHTTPService.mobileNumber;
    this.hasHomeNumber = !!this.notificationPreferenceHTTPService.homeNumber;
    this.mobileCallOptedIn =
      this.notificationPreferenceHTTPService.mobileNumber?.additions?.callOptIn === OPT_IN_STATE.OPTED_IN;
    this.mobileSMSOptedIn =
      this.notificationPreferenceHTTPService.mobileNumber?.additions?.smsOptIn === OPT_IN_STATE.OPTED_IN;
    this.homeCallOptedIn =
      this.notificationPreferenceHTTPService.homeNumber?.additions?.callOptIn === OPT_IN_STATE.OPTED_IN;
  };
  getOptInValues = async () => {
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      await this.notificationPreferenceHTTPService.getPhoneNumbers();
      this.componentState.next(COMPONENT_STATE.LOADED);
    } catch (err) {
      this.componentState.next(COMPONENT_STATE.ERROR);
      this.onOptInFailure();
    }
    this.updateAllValues();
  };

  loadProducts = async () => {
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      await this.notificationPreferenceHTTPService.getPhoneNumbers();
      this.updateAllValues();
      this.setFormValues();
      this.componentState.next(COMPONENT_STATE.LOADED);
    } catch (err) {
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  };
  setFormValues = () => {
    if (this.hasMobileNumber && this.hasHomeNumber) {
      this.form.controls.mobileCallOptInControl.setValue(this.mobileCallOptedIn);
      this.form.controls.mobileSMSOptInControl.setValue(this.mobileSMSOptedIn);
      this.form.controls.homeCallOptInControl.setValue(this.homeCallOptedIn);
      this.form.controls.allNotificationsOptOutControl.setValue(
        !this.mobileCallOptedIn && !this.mobileSMSOptedIn && !this.homeCallOptedIn,
      );
    } else if (this.hasMobileNumber) {
      this.form.controls.mobileCallOptInControl.setValue(this.mobileCallOptedIn);
      this.form.controls.mobileSMSOptInControl.setValue(this.mobileSMSOptedIn);
      this.form.controls.allNotificationsOptOutControl.setValue(!this.mobileCallOptedIn && !this.mobileSMSOptedIn);
    } else if (this.hasHomeNumber) {
      this.form.controls.homeCallOptInControl.setValue(this.homeCallOptedIn);
      this.form.controls.allNotificationsOptOutControl.setValue(!this.homeCallOptedIn);
    }
  };
  get mobileNumberFormatted() {
    return entPhoneNumberFormatter(this.mobilePhoneNumber.number);
  }
  get homeNumberFormatted() {
    return entPhoneNumberFormatter(this.homePhoneNumber.number);
  }
  get optInInfoString() {
    const message = [];
    if (this.mobileSMSOptedIn) {
      message.push(this.textService.optInCellSMS);
    }
    if (this.mobileCallOptedIn) {
      message.push(this.textService.optInCellCall);
    }
    if (this.homeCallOptedIn) {
      message.push(this.textService.optInHomeCall);
    }
    return message.join(", ");
  }
}
