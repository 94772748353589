/*
 *
 * The content of this file can be edited freely, but to maintain upgradability
 * this file should not be renamed and should always export an Angular module named
 * `AppRoutingModule`.
 *
 *
 */
import { NgModule, inject } from "@angular/core";
import { CanLoadFn, RouterModule, Routes, UrlSerializer } from "@angular/router";
import { EntitlementsGuard } from "@backbase/foundation-ang/entitlements";
import { AuthGuard, PERMISSIONS as EntPermissions } from "@ent/data";
import { SharedUserContextGuard } from "@backbase/shared/feature/user-context";
import { PERMISSIONS } from "./auth/permissions";
import { IdentityJourneyWrapperComponent } from "./journeys/wrapper-component/journey-wrapper.component";
import { LayoutComponent } from "./layout/layout.component";
import { creditScoreJourneyConfiguration } from "./journeys/credit-score/credit-score-journey-bundle.module";
import { environment } from "../environments/environment";

/**
 * @deprecated The guard is deprecated and will be removed together with the Environment.dashboardEnabled flag
 * when the dashboard page becomes active by default.
 */
const canLoadDashboard: CanLoadFn = () => {
  return environment.dashboardEnabled ? true : inject(UrlSerializer).parse("/my-accounts");
};

// TODO: Find a more elegant solution to decide what landing page to choose
// in the event that the default one is not available due to entitlements
const routes: Routes = [
  {
    path: "select-context",
    loadChildren: () => import("./user-context/user-context.module").then((m) => m.UserContextModule),
    data: {
      title: $localize`:@@context-selection.nav.item.title:Select Context`,
    },
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: environment.dashboardEnabled ? "dashboard" : "my-accounts",
      },
      {
        path: "dashboard",
        loadChildren: () => import("./journeys/dashboard/wrapper").then((m) => m.DashboardJourneyWrapperModule),
        data: {
          title: $localize`:@@dashboard.nav.item.title:Dashboard`,
          entitlements: EntPermissions.canViewDashboard,
          cssClasses: ["container-fluid", "container"],
          redirectTo: "my-accounts",
        },
        canLoad: [canLoadDashboard],
      },
      {
        path: "my-accounts",
        data: {
          title: $localize`:@@accounts.nav.item.title:My Accounts - Ent Digital Banking`,
          entitlements: EntPermissions.canViewMyAccounts,
          cssClasses: ["container-fluid", "container"],
          redirectTo: "transfers/make-a-transfer",
        },
        children: [
          {
            path: "",
            loadChildren: () => {
              return environment.refactoredAccountsRouting
                ? import("./journeys/accounts").then((_) => _.AccountsTransactionsJourneyWrapperModule)
                : import(
                    "./journeys/accounts/accounts-transactions-wrapper/wrapper-accounts-transactions-journey.module"
                  ).then((m) => m.AccountsTransactionsJourneyBundleModule);
            },
          },
          {
            path: "loans/details",
            children: [
              {
                path: "",
                loadChildren: () => {
                  const loadModule = environment.refactoredAccountsRouting
                    ? import("./journeys/loans/loans-journey-bundle.module")
                    : import("./journeys/accounts/loans/loans-journey-bundle.module");
                  return loadModule.then((_) => _.LoansJourneyBundleModule);
                },
                data: {
                  title: $localize`:@@loans.nav.item.title:Loans - Accounts - Ent Digital Banking`,
                  entitlements: EntPermissions.canViewLoans,
                },
              },
              {
                path: ":selectedAccount",
                loadChildren: () => {
                  const loadModule = environment.refactoredAccountsRouting
                    ? import("./journeys/loans/loan-payments/loan-payment-wrapper-bundle.module")
                    : import("./journeys/accounts/loans/loan-payments/loan-payment-wrapper-bundle.module");
                  return loadModule.then((_) => _.LoanPaymentJourneyWrapperBundleModule);
                },
                data: {
                  title: $localize`:@@loans-item.nav.item.title:Loan - Accounts - Ent Digital Banking`,
                  entitlements: EntPermissions.canViewLoans,
                },
              },
            ],
          },
        ],
      },
      {
        path: "products",
        loadChildren: () => import("@ent/app").then((m) => m.ProductsModule),
      },
      {
        path: "pockets",
        loadChildren: () =>
          import("./journeys/pockets/pockets-wrapper/pocket-wrapper-bundle.module").then(
            (m) => m.PocketWrapperBundleModule,
          ),
        data: {
          title: $localize`:@@accounts.nav.item.title:Pockets`,
          entitlements: EntPermissions.canViewManagePockets,
          cssClasses: ["container--fixed-width mx-auto"],
        },
      },
      {
        path: "transfers",
        data: { cssClasses: ["container--fixed-width mx-auto"] },
        children: [
          {
            path: "activity",
            loadChildren: () =>
              import("./journeys/transfers/upcoming-and-history-payments.module").then(
                (m) => m.ManageUpcomingAndHistoricalPaymentsJourneyBundleModule,
              ),
            data: {
              title: $localize`:@@activity.nav.item.title:Activity - Transfer - Ent Digital Banking`,
              entitlements: EntPermissions.canViewScheduledTransfers,
            },
          },
          {
            path: "connected-accounts",
            loadChildren: () =>
              import("./journeys/transfers/connect-external-accounts-journey.module").then(
                (m) => m.ConnectExternalAccountsJourneyBundleModule,
              ),
            data: {
              title: $localize`:@@connected-accounts.nav.item.title:Connected Accounts - Transfer - Ent Digital Banking`,
              entitlements: EntPermissions.canViewConnectedAccounts,
            },
          },
          {
            path: "backbase",
            loadChildren: () =>
              import(
                "./journeys/transfers/wrapper-initiate-payment-journey/initiate-payment-wrapper-bundle.module"
              ).then((m) => m.InitiatePaymentWrapperBundleModule),
          },
          {
            path: "make-a-transfer",
            loadChildren: () => import("@ent/app").then((m) => m.TransferModule),
            data: {
              title: $localize`:@@transfers.nav.item.title:Make a Transfer - Ent Digital Banking`,
              entitlements: EntPermissions.canCreateA2A,
            },
          },
          {
            path: "pay-loan",
            loadChildren: () => import("@ent/app").then((m) => m.PaymentsModule),
            data: {
              title: $localize`:@@pay-loan.nav.item.title:Pay a Loan - Ent Digital Banking`,
              entitlements: EntPermissions.canCreateA2A,
            },
          },
          {
            path: "money-to-member",
            loadChildren: () => import("@ent/app").then((m) => m.MemberTransfersModule),
            data: {
              title: $localize`:@@member-transfer.nav.item.title:Send Money to a Member - Ent Digital Banking`,
              entitlements: EntPermissions.canCreateA2A,
            },
          },
        ],
      },
      {
        path: "call-center-password",
        loadChildren: () => import("@ent/app").then((m) => m.SecurityModule),
      },
      {
        path: "notification-preference",
        loadChildren: () => import("@ent/app").then((m) => m.NotificationPreferenceModule),
      },
      {
        path: "billpay",
        children: [
          {
            path: "",
            loadChildren: () => import("@ent/app").then((m) => m.BillpayModule),
            data: {
              title: $localize`:@@pay-bill.nav.item.title:Pay a Bill - Bill Pay - Ent Digital Banking`,
              entitlements: EntPermissions.canViewPayABill,
            },
          },
        ],
      },
      {
        path: "insights",
        data: { cssClasses: ["container--fixed-width mx-auto"] },
        children: [
          {
            path: "cashflow",
            loadChildren: () =>
              import("./journeys/insights/bundle-turnovers.module").then((m) => m.TurnoversJourneyBundleModule),
            data: {
              title: $localize`:@@cash-flow.nav.item.title:Cash Flow - Analytics - Ent Digital Banking`,
            },
          },
          {
            path: "income-analysis",
            loadChildren: () =>
              import("./journeys/insights/bundle-income-analysis.module").then((m) => m.IncomeAnalysisBundleModule),
            data: {
              title: $localize`:@@income-analysis.nav.item.title:Income Analysis - Analytics - Ent Digital Banking`,
            },
          },
          {
            path: "spending-analysis",
            loadChildren: () =>
              import("./journeys/insights/bundle-spending-analysis.module").then((m) => m.SpendingAnalysisBundleModule),
            data: {
              title: $localize`:@@spending-analysis.nav.item.title:Spending Analysis - Analytics - Ent Digital Banking`,
            },
          },
        ],
      },
      {
        path: "self-service",
        data: { cssClasses: ["container--fixed-width mx-auto"] },
        children: [
          {
            path: "profile",
            loadChildren: () =>
              import("./journeys/my-profile/my-profile/my-profile-bundle.module").then((m) => m.MyProfileBundleModule),
          },
          {
            path: "authorized-users",
            loadChildren: () =>
              import("./journeys/self-service/bundle-authorized-users.module").then(
                (m) => m.AuthorizedUsersJourneyBundleModule,
              ),
            data: {
              title: $localize`:@@authorized-users.nav.item.title:Authorized Users - Self services - Ent Digital Banking`,
              entitlements: EntPermissions.canViewAuthorizedUsers,
            },
          },
          {
            path: "manage-cards",
            loadChildren: () => import("@ent/app").then((m) => m.CardsModule),
            data: {
              title: $localize`:@@manage-cards.nav.item.title:Manage Cards - Self services - Ent Digital Banking`,
              entitlements: EntPermissions.canViewManageCards,
            },
          },
          {
            path: "product-list",
            loadChildren: () =>
              import("./journeys/self-service/bundle-retail-notification-preferences-journey.module").then(
                (m) => m.RetailNotificationPreferencesJourneyBundleModule,
              ),
            data: {
              title: $localize`:@@manage-notifications.nav.item.title:Manage Notifications - Self services - Ent Digital Banking`,
              entitlements: EntPermissions.canViewManageNotifications,
            },
          },
          {
            path: "manage-contacts",
            loadChildren: () =>
              import("./journeys/self-service/bundle-contact-journey.module").then(
                (m) => m.ContactManagerJourneyBundleModule,
              ),
            data: {
              title: $localize`:@@manage-contacts.nav.item.title:Manage Contacts - Self services - Ent Digital Banking`,
              entitlements: EntPermissions.canViewManageContacts,
            },
          },
          {
            path: "stop-checks",
            loadChildren: () =>
              import("./journeys/self-service/wrapper-stop-checks-journey/bundle-stop-checks-wrapper.module").then(
                (m) => m.StopChecksJourneyWrapperBundleModule,
              ),
            data: {
              title: $localize`:@@stop-checks.nav.item.title:Stop Checks - Self services - Ent Digital Banking`,
              entitlements: EntPermissions.canViewStopChecks,
            },
          },
          {
            path: "download-statements",
            loadChildren: () =>
              import("./journeys/self-service/bundle-accounts-statement-retail-journey.module").then(
                (m) => m.AccountStatementRetailJourneyBundleModule,
              ),
            data: {
              title: $localize`:@@download-statements.nav.item.title:Account Statements - Self services - Ent Digital Banking`,
              entitlements: EntPermissions.canViewAccountStatements,
            },
          },
          {
            path: "manage-statements",
            loadChildren: () =>
              import("./journeys/self-service/bundle-manage-statement-retail-journey.module").then(
                (m) => m.ManageStatementsJourneyBundleModule,
              ),
            data: {
              title: $localize`:@@manage-statements.nav.item.title:Manage Statements - Self services - Ent Digital Banking`,
              entitlements: EntPermissions.canViewManageStatements,
            },
          },
        ],
      },
      {
        path: "money-insights",
        loadChildren: () => import("@ent/app").then((m) => m.MoneyInsightModule),
      },
      {
        path: "more",
        data: { cssClasses: ["container--fixed-width mx-auto"] },
        children: [
          {
            path: "budgets",
            loadChildren: () => import("./journeys/more/bundle-budget.module").then((m) => m.BudgetJourneyBundleModule),
            data: {
              title: $localize`:@@budgets.nav.item.title:Budgets - Ent Digital Banking`,
              entitlements: EntPermissions.canViewBudgets,
            },
          },
          {
            path: "messages",
            loadChildren: () =>
              import("./journeys/more/bundle-messages-client-inbox-journey-bundle.module").then(
                (m) => m.MessagesClientInboxJourneyBundleModule,
              ),
            data: {
              title: $localize`:@@messages.nav.item.title:Messages - Ent Digital Banking`,
              entitlements: EntPermissions.canViewMessages,
            },
          },
          {
            path: "find-us",
            loadChildren: () => import("./journeys/more/bundle-places.module").then((m) => m.PlacesJourneyBundleModule),
            data: {
              title: $localize`:@@places.nav.item.title:Find Branches and ATMs - Ent Digital Banking`,
              entitlements: EntPermissions.canViewPlaces,
            },
          },
        ],
      },
      {
        path: creditScoreJourneyConfiguration.capabilityRouteName,
        loadChildren: () =>
          import("./journeys/credit-score/credit-score-journey-bundle.module").then(
            (m) => m.CreditScoreJourneyBundleModule,
          ),
        data: {
          title: $localize`:@@credit-score.nav.item.title:Credit-score - Ent Digital Banking`,
          entitlements: EntPermissions.canViewCreditScore,
        },
      },
    ],
    canActivate: [AuthGuard, SharedUserContextGuard],
    canActivateChild: [EntitlementsGuard],
  },
  {
    path: "consent",
    loadChildren: () =>
      import("./journeys/consent/bundle-consent-journey.module").then((m) => m.ConsentJourneyBundleModule),
  },
  {
    path: "enrollment",
    component: IdentityJourneyWrapperComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./journeys/self-enrollment/self-enrollment-bundle.module").then(
            (m) => m.SelfEnrollmentJourneyBundleModule,
          ),
      },
    ],
  },
  {
    path: "password-reset",
    component: IdentityJourneyWrapperComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./journeys/password-reset/password-reset-bundle.module").then(
            (m) => m.PasswordResetJourneyBundleModule,
          ),
      },
    ],
  },
  {
    path: "logout",
    component: IdentityJourneyWrapperComponent,
    children: [
      {
        path: "username-success",
        loadChildren: () =>
          import("./journeys/self-service/bundle-change-username.module").then(
            (m) => m.ChangeUsernameSuccessBundleModule,
          ),
      },
    ],
  },
  { path: "**", pathMatch: "full", redirectTo: "" },
];

@NgModule({
  declarations: [IdentityJourneyWrapperComponent],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
