/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { MxWidgetService } from "@ent/data";
import { Subscription, fromEvent } from "rxjs";

const WIDGET_NAME = "mx-connect-widget";

@Component({
  selector: "ent-mx-connect-widget",
  templateUrl: "./mx-connect-widget.component.html",
  styleUrls: ["./mx-connect-widget.component.scss"],
})
export class MxConnectWidgetComponent implements OnDestroy, OnInit {
  @Input() title: string;
  @Input() url: string;
  @Output() mxMessage = new EventEmitter();
  widgetSubscription: Subscription;
  unsubscribeFromMessages: Subscription;

  ngOnInit(): void {
    this.widgetSubscription = MxWidgetService.forConnect(this.url, WIDGET_NAME).subscribe();
    this.unsubscribeFromMessages = fromEvent(window, "message").subscribe(this.handleMessage);
  }

  ngOnDestroy(): void {
    this.unsubscribeFromMessages.unsubscribe();
    this.widgetSubscription.unsubscribe();
  }

  handleMessage = (event): void => {
    if (event.data && event.data.mx === true) {
      this.mxMessage.emit(event.data);
    }
  };
}
