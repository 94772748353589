import { Injectable } from "@angular/core";
import { IReviewTextService } from "../../services/review.text.service";

@Injectable({ providedIn: "any" })
export class PaymentsReviewTextService implements IReviewTextService {
  title = $localize`:@@pay-loan.review.title:Review Your Payment`;
  fromLabel = $localize`:@@pay-loan.review.from-label:From`;
  toLabel = $localize`:@@pay-loan.review.to-label:To`;
  memo = $localize`:@@pay-loan.review.memo-label:Personal Note`;
  paymentDueLabel = $localize`:@@pay-loan.review.payment-due-label:Payment Due`;
  pastDueLabel = $localize`:@@pay-loan.review.past-due-label:Past Due`;
  principalOnlyLabel = $localize`:@@pay-loan.review.principal-only-label:Principal Only`;
  otherAmountLabel = $localize`:@@pay-loan.review.other-amount-label:Other Amount`;
  fullBalanceLabel = $localize`:@@pay-loan.review.full-balance-label:Full Balance`;
  submitError = $localize`:@@pay-loan.review.submit-error:We couldn't complete your payment. Please click Confirm Payment or Cancel to try again.`;
  confirm = $localize`:@@pay-loan.review.submit-btn:Confirm Payment`;
  edit = $localize`:@@pay-loan.review.edit-btn:Edit`;
  cancel = $localize`:@@pay-loan.review.cancel-btn:Cancel`;
  amount = $localize`:@@pay-loan.review.amount-label: Amount`;
  discardTitle = $localize`:@@pay-loan.cancel-modal-title:Cancel Payment?`;
  discardConfirm = $localize`:@@pay-loan.cancel-modal-description:Are you sure you want to cancel this payment? The information you've entered will be lost.`;
  discardYes = $localize`:@@pay-loan.discard-modal.yes:Cancel Payment`;
  discardNo = $localize`:@@pay-loan.discard-modal.no:Keep Reviewing`;

  unknownErrorHeader = $localize`:@@initiate-payment-unknown-error-header:Server error`;
  unknownError = $localize`:@@initiate-payment-unknown-error:Unknown error occurred.`;
  validateFailed = $localize`:@@initiate-payment-validate-failed:Payment data error`;

  /** Frequency  */
  frequency = $localize`:@@payments.review.frequency:Frequency`;
  frequencyWeeklyLabel = $localize`:@@payments.review.frequency.weekly: Every Week`;
  frequencyBiWeeklyLabel = $localize`:@@payments.review.frequency.bi-weekly: Every 2 weeks`;
  frequencyMonthlyLabel = $localize`:@@payments.review.frequency.monthly: Every Month`;
  frequencyBiMonthlyLabel = $localize`:@@payments.review.frequency.bi-monthly: Every 2 months`;
  frequencyQuarterlyLabel = $localize`:@@payments.review.frequency.quarterly: Every 3 months`;
  frequencySemiAnnuallyLabel = $localize`:@@payments.review.frequency.semi-annually: Every 6 months`;
  frequencyAnnuallyLabel = $localize`:@@payments.review.frequency.annually: Every Year`;

  /** Calendar  */
  calendarDate = $localize`:@@payments.review.calendar.transfer-date: Date`;
  calendarTodayText = $localize`:@@payments.review.calendar-text.today: Today`;
  calendarStartDate = $localize`:@@payments.review.calendar.start-date: Start Date`;
  calendarEndOn = $localize`:@@payments.review.calendar.end-date: End Date`;
  calendarEndNever = $localize`:@@payments.review.calendar.duration: Duration`;
  calendarEndAfter = $localize`:@@payments.review.calendar.end-after: End After`;
  calendarNeverEndsText = $localize`:@@payments.review.calendar.never-ends: Never Ends`;
  calendarOccurrencesText = (occurrence: number) =>
    $localize`:@@payments.review.calendar.number-occurrences: ${occurrence} occurrences`;
}
