<div class="card">
  <div class="card-body">
    <bb-header-ui
      headingClasses="bb-text-semi-bold"
      headingType="h2"
      [heading]="isATMCard ? textService.cardActivationATMHeader : textService.cardActivationDebitHeader"
    ></bb-header-ui>
    <span class="bb-text-support">
      {{ isATMCard ? textService.cardActivationATMSubtext : textService.cardActivationDebitSubtext }}
    </span>
    <div class="mt-4">
      <button bbButton color="primary" data-role="reset-pin-button" (click)="openCardActivationModal()">
        {{ textService.cardActivationActivateButton }}
      </button>
    </div>
  </div>
</div>
<ent-card-activation-modal
  (closeModal)="closeCardActivationModal()"
  [isOpen]="isCardActivationModalOpen"
></ent-card-activation-modal>
