import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "ent-memo-form-control",
  templateUrl: "./memo.component.html",
})
export class MemoFormControlComponent {
  @Input()
  controlFormGroup!: FormGroup;

  @Input()
  memoLabel!: string;

  @Input()
  memoHelperText!: string;

  @Input()
  memoPlaceholder!: string;
}
