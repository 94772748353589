<div class="card">
  <div class="card-header">
    <h2 class="m-0">{{ textService.cardServicesHeader }}</h2>
  </div>
  <div class="card-body">
    <div class="bb-list">
      <div class="pb-3 bb-stack bb-payment-card-self-service-row">
        <div class="bb-stack bb-stack--align-top bb-stack__item bb-payment-card-self-service-row__description">
          <div class="bb-stack__item">
            <em class="fa-solid fa-plane-up fa-lg"></em>
          </div>
          <div class="bb-stack__item">
            <h5 class="bb-block--no-margin">{{ textService.cardServicesTravelNoticeHeader }}</h5>
            <span class="bb-text-support text-small"> {{ textService.cardServicesTravelNoticeSubtext }} </span>
          </div>
        </div>
        <div class="bb-stack__item bb-payment-card-self-service-row__button">
          <button
            bbButton
            color="secondary"
            data-role="reset-pin-button"
            [disabled]="isLoading"
            (click)="navigateToTravelNotices()"
          >
            {{ textService.cardServicesTravelNoticeButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
