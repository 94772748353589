import { Component, OnChanges } from "@angular/core";
import { BaseReviewComponent } from "../../components/base-review/base-review.component";
import { MemberTransferReviewTextService } from "../services/member-transfer-review.text.service";

@Component({
  selector: "ent-member-transfer-review",
  templateUrl: "./member-transfer-review.component.html",
})
export class MemberTransferReviewComponent extends BaseReviewComponent implements OnChanges {
  constructor(public readonly memberTransferReviewTextService: MemberTransferReviewTextService) {
    super();
  }
  getReviewTextService = () => {
    return this.memberTransferReviewTextService;
  };
}
