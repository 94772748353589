import { FlatProductItem } from "../models";
import {
  PRODUCT_SUBSECTIONS,
  PRODUCT_SECTIONS,
  TERM_LINKS_KEYS,
  FORM_CONTROL_NAMES,
  ProductCard,
  ProductReviewAssets,
  TermLink,
  ProductReviewLinks,
  SUBCODES,
  ReviewItem,
} from "../models/products";

export const PRODUCT_CARD_ITEMS: ReadonlyArray<ProductCard> = [
  {
    types: new Set(["10"]),
    iconClass: "fa-wallet",
    subtitle: $localize`:@@products.explore-products.no-strings.subtitle.label: Hassle-free, stress-free checking`,
    subsection: PRODUCT_SUBSECTIONS.CHECKING,
    section: PRODUCT_SECTIONS.BANK,
    informationLink: "https://www.ent.com/personal/accounts/checking/",
    benefits: [
      {
        iconClass: "fa-ban",
        title: $localize`:@@products.product-information.no-strings.benefit-1.title.label: No surprise fees`,
        subtitle: $localize`:@@products.product-information.no-strings.benefit-1.subtitle.label: No monthly maintenance fees or minimum balance requirements`,
      },
      {
        iconClass: "fa-badge-percent",
        title: $localize`:@@products.product-information.no-strings.benefit-2.title.label: Earn dividends`,
        subtitle: $localize`:@@products.product-information.no-strings.benefit-2.subtitle.label: Earn more with checking dividends`,
      },
      {
        iconClass: "fa-money-simple-from-bracket",
        title: $localize`:@@products.product-information.no-strings.benefit-3.title.label: No Ent ATM fees`,
        subtitle: $localize`:@@products.product-information.no-strings.benefit-3.subtitle.label: Ent will never charge you an ATM fee`,
      },
    ],
  },
  {
    types: new Set(["10"]),
    subCode: SUBCODES.BRONCOS,
    iconClass: "fa-football",
    subtitle: $localize`:@@products.explore-products.broncos-checking.subtitle.label: Your go-to for everyday spending`,
    subsection: PRODUCT_SUBSECTIONS.CHECKING,
    section: PRODUCT_SECTIONS.BANK,
    informationLink: "https://www.ent.com/broncos/",
    successText: $localize`:@@products.explore-products.broncos-checking.success.text.label:When you receive your new card, set up automatic payments, and then direct deposit to your new account.`,
    benefits: [
      {
        iconClass: "fa-credit-card",
        title: $localize`:@@products.product-information.broncos-checking.benefit-1.title.label: Broncos debit card`,
        subtitle: $localize`:@@products.product-information.broncos-checking.benefit-1.subtitle.label: Show your superfan status with the official Broncos debit card`,
      },
      {
        iconClass: "fa-tags",
        title: $localize`:@@products.product-information.broncos-checking.benefit-2.title.label: Broncos discounts`,
        subtitle: $localize`:@@products.product-information.broncos-checking.benefit-2.subtitle.label: Get discounts on licensed merch and game day concessions`,
      },
      {
        iconClass: "fa-piggy-bank",
        title: $localize`:@@products.product-information.broncos-checking.benefit-3.title.label: Hassle-free checking`,
        subtitle: $localize`:@@products.product-information.broncos-checking.benefit-3.subtitle.label: Enjoy no maintenance or Ent ATM fees and earn dividends`,
      },
    ],
    informationImageUrl: "/assets/BroncosDebitCard-Web.png",
    considerations: [
      {
        iconClass: "fa-triangle-exclamation",
        title: $localize`:@@products.product-consideration.broncos-checking.benefit-1.title.label: New card number`,
        subtitle: $localize`:@@products.product-consideration.broncos-checking.benefit-1.subtitle.label: Make sure you and any joint owners update recurring debit card payments within 4-7 business days`,
      },
      {
        iconClass: "fa-thumbs-up",
        title: $localize`:@@products.product-consideration.broncos-checking.benefit-2.title.label: Same account number`,
        subtitle: $localize`:@@products.product-consideration.broncos-checking.benefit-2.subtitle.label: Payments and transfers with your account and routing numbers won't be affected, and you won't need new checks`,
      },
    ],
  },
  {
    types: new Set(["4"]),
    iconClass: "fa-badge-dollar",
    subtitle: $localize`:@@products.explore-products.ent-high-yield-savings.subtitle.label: Earn bigger dividends based on how much you save`,
    subsection: PRODUCT_SUBSECTIONS.SAVINGS_CERTIFICATES,
    section: PRODUCT_SECTIONS.BANK,
  },
  {
    types: new Set(["1"]),
    iconClass: "fa-piggy-bank",
    subtitle: $localize`:@@products.explore-products.savings.subtitle.label: Earn dividends with no fees`,
    subsection: PRODUCT_SUBSECTIONS.SAVINGS_CERTIFICATES,
    section: PRODUCT_SECTIONS.BANK,
    informationLink: "https://www.ent.com/personal/accounts/savings/",
    benefits: [
      {
        iconClass: "fa-badge-percent",
        title: $localize`:@@products.product-information.savings.benefit-1.title.label: Earn dividends`,
        subtitle: $localize`:@@products.product-information.savings.benefit-1.subtitle.label: Earn more on the money you save`,
      },
      {
        iconClass: "fa-coins",
        title: $localize`:@@products.product-information.savings.benefit-2.title.label: Save more with no fees`,
        subtitle: $localize`:@@products.product-information.savings.benefit-2.subtitle.label: Pay no monthly fees for your Ent savings account`,
      },
      {
        iconClass: "fa-chart-mixed-up-circle-dollar",
        title: $localize`:@@products.product-information.savings.benefit-3.title.label: {$RATE}`,
        subtitle: $localize`:@@products.product-information.savings.benefit-3.subtitle.label: Annual Percentage Yield (APY)`,
      },
    ],
  },
  {
    types: new Set(["16"]),
    iconClass: "fa-money-bills",
    subtitle: $localize`:@@products.explore-products.money-market.subtitle.label: Save money and earn interest`,
    subsection: PRODUCT_SUBSECTIONS.SAVINGS_CERTIFICATES,
    section: PRODUCT_SECTIONS.BANK,
    informationLink: "https://www.ent.com/personal/accounts/money-market/",
    successText: $localize`:@@products.product-success.money-market.success.label: You're set to save and earn with confidence`,
    successHeaderText: $localize`:@@products.product-success.money-market.success.header.label: Congrats on your new account!`,
    benefits: [
      {
        iconClass: "fa-percent",
        title: $localize`:@@products.product-information.money-market.benefit-1.title.label: Better rates`,
        subtitle: $localize`:@@products.product-information.money-market.benefit-1.subtitle.label: Earn more than you would with regular savings accounts`,
      },
      {
        iconClass: "fa-ban",
        title: $localize`:@@products.product-information.money-market.benefit-2.title.label: Fewer fees`,
        subtitle: $localize`:@@products.product-information.money-market.benefit-2.subtitle.label: Pay no monthly fees or minimum balance fee`,
      },
      {
        iconClass: "fa-money-bill-trend-up",
        title: $localize`:@@products.product-information.money-market.benefit-3.title.label: Rising returns`,
        subtitle: $localize`:@@products.product-information.money-market.benefit-3.subtitle.label: Save more and you’ll earn more as your money grows over time`,
      },
    ],
    goodToKnow: [
      {
        iconClass: "fa-coins",
        title: $localize`:@@products.product-information.money-market.good-to-know-1.title.label: Minimum deposit`,
        subtitle: $localize`:@@products.product-information.money-market.good-to-know-1.subtitle.label: You'll need to deposit at least {$MINIMUM}`,
      },
    ],
  },
  {
    types: new Set(["64", "72", "90"]),
    iconClass: "fa-money-bill-wave",
    subtitle: $localize`:@@products.explore-products.flex-cd.subtitle.label: The stability of a CD with the flexibility to save on your terms`,
    subsection: PRODUCT_SUBSECTIONS.SAVINGS_CERTIFICATES,
    section: PRODUCT_SECTIONS.BANK,
  },
  {
    types: new Set(["50", "51"]),
    iconClass: "fa-money-bill-trend-up",
    subtitle: $localize`:@@products.explore-products.short-term-cd.subtitle.label: Reach your short-term savings goals sooner`,
    subsection: PRODUCT_SUBSECTIONS.SAVINGS_CERTIFICATES,
    section: PRODUCT_SECTIONS.BANK,
  },
  {
    types: new Set(["55", "57", "62", "69", "71", "78", "82", "86", "87", "91"]),
    iconClass: "fa-chart-mixed-up-circle-dollar",
    subtitle: $localize`:@@products.explore-products.long-term-cd.subtitle.label: Get peace of mind with a locked-in, long-term rate`,
    subsection: PRODUCT_SUBSECTIONS.SAVINGS_CERTIFICATES,
    section: PRODUCT_SECTIONS.BANK,
  },
  {
    types: new Set(["0"]),
    iconClass: "fa-car-mirrors",
    subtitle: $localize`:@@products.explore-products.auto-loan.subtitle.label: Buy a car or change your current loan terms`,
    subsection: PRODUCT_SUBSECTIONS.AUTO,
    section: PRODUCT_SECTIONS.BORROW,
  },
  {
    types: new Set(["1"]),
    iconClass: "fa-credit-card",
    subtitle: $localize`:@@products.explore-products.card.subtitle.label: Apply for an Elan credit card`,
    subsection: PRODUCT_SUBSECTIONS.CARDS,
    section: PRODUCT_SECTIONS.BORROW,
  },
  {
    types: new Set(["2"]),
    iconClass: "fa-money-check-dollar",
    subtitle: $localize`:@@products.explore-products.heloc.subtitle.label: Tap into the equity of your home`,
    subsection: PRODUCT_SUBSECTIONS.HOME,
    section: PRODUCT_SECTIONS.BORROW,
  },
  {
    types: new Set(["3"]),
    iconClass: "fa-house",
    subtitle: $localize`:@@products.explore-products.mortgage.subtitle.label: Buy a new home or refinance your current home loan`,
    subsection: PRODUCT_SUBSECTIONS.HOME,
    section: PRODUCT_SECTIONS.BORROW,
  },
  {
    types: new Set(["4"]),
    iconClass: "fa-sack-dollar",
    subtitle: $localize`:@@products.explore-products.peloc.subtitle.label: Borrow only what's needed when it's needed`,
    subsection: PRODUCT_SUBSECTIONS.PERSONAL,
    section: PRODUCT_SECTIONS.BORROW,
  },
  {
    types: new Set(["5"]),
    iconClass: "fa-user-vneck",
    subtitle: $localize`:@@products.explore-products.personal-loan.subtitle.label: Borrow a lump sum in the form of a loan`,
    subsection: PRODUCT_SUBSECTIONS.PERSONAL,
    section: PRODUCT_SECTIONS.BORROW,
  },
  {
    types: new Set(["6"]),
    iconClass: "fa-motorcycle",
    subtitle: $localize`:@@products.explore-products.specialty-vehicle.subtitle.label: Buy or refinance a motorcycle, boat or other specialty vehicle`,
    subsection: PRODUCT_SUBSECTIONS.AUTO,
    section: PRODUCT_SECTIONS.BORROW,
  },
];

export const PRODUCT_REVIEW_ASSETS: ReadonlyArray<ProductReviewAssets> = [
  {
    types: new Set(["1", "10", "16"]),
    section: PRODUCT_SECTIONS.BANK,
    reviewItems: [
      {
        title: $localize`:@@products.product-review.open.reviewItem-1.title.label: New account`,
        formName: FORM_CONTROL_NAMES.SHARE_TYPE,
      },
      {
        iconClass: "fa-money-bill-transfer",
        title: $localize`:@@products.product-review.open.reviewItem-2.title.label: Funding from`,
        formName: FORM_CONTROL_NAMES.DEPOSIT_ACCOUNT,
      },
      {
        iconClass: "fa-coin",
        title: $localize`:@@products.product-review.open.reviewItem-3.title.label: Amount`,
        formName: FORM_CONTROL_NAMES.DEPOSIT_AMOUNT,
      },
      {
        iconClass: "fa-user-plus",
        title: $localize`:@@products.product-review.open.reviewItem-4.title.label: Beneficiaries`,
      },
    ],
    terms: {
      prefixLabel: $localize`:@@products.product-review.legend.label: By selecting "Open Account" 
      I agree to the terms and conditions, including`,
      suffixLabel: $localize`:@@products.product-review.legend.suffix.label: I also accept any future amendments. Please review and retain the disclosures.`,
    },
  },
];

export const CONVERTING_REVIEW_ITEMS: ReviewItem[] = [
  {
    iconClass: "fa-swap",
    title: $localize`:@@products.product-review.open.reviewItem-5.title.label: Converting`,
    formName: FORM_CONTROL_NAMES.ARRANGEMENT_ID,
  },
  {
    title: $localize`:@@products.product-review.open.reviewItem-6.title.label: To`,
    formName: FORM_CONTROL_NAMES.NEW_BRONCOS_NAME,
  },
];

export const PRODUCT_REVIEW_LINKS: ReadonlyArray<ProductReviewLinks> = [
  {
    types: new Set(["1"]),
    section: PRODUCT_SECTIONS.BANK,
    links: [
      TERM_LINKS_KEYS.MEMBERSHIP_SERVICE_AGREEMENT,
      TERM_LINKS_KEYS.PRIVACY_POLICY,
      TERM_LINKS_KEYS.FEE_SCHEDULE,
      TERM_LINKS_KEYS.RATE_SHEET,
    ],
  },
  {
    types: new Set(["10"]),
    section: PRODUCT_SECTIONS.BANK,
    links: [
      TERM_LINKS_KEYS.MEMBERSHIP_SERVICE_AGREEMENT,
      TERM_LINKS_KEYS.DEPOSIT_AVAILABILITY,
      TERM_LINKS_KEYS.PRIVACY_POLICY,
      TERM_LINKS_KEYS.FEE_SCHEDULE,
      TERM_LINKS_KEYS.RATE_SHEET,
    ],
  },
  {
    types: new Set(["16"]),
    section: PRODUCT_SECTIONS.BANK,
    links: [
      TERM_LINKS_KEYS.MEMBERSHIP_SERVICE_AGREEMENT,
      TERM_LINKS_KEYS.DEPOSIT_AVAILABILITY,
      TERM_LINKS_KEYS.PRIVACY_POLICY,
      TERM_LINKS_KEYS.FEE_SCHEDULE,
      TERM_LINKS_KEYS.RATE_SHEET,
    ],
  },
];

export const TERM_LINKS: { [key: string]: TermLink } = {
  [TERM_LINKS_KEYS.MEMBERSHIP_SERVICE_AGREEMENT]: {
    text: $localize`:@@products.product-review.legend.membership-service-agreement.label:Membership Service Agreement`,
    url: "https://www.ent.com/globalassets/pdf-files/legal/ent-membership-and-account-agreement.pdf",
  },
  [TERM_LINKS_KEYS.PRIVACY_POLICY]: {
    text: $localize`:@@products.product-review.legend.privacy-policy.label:Privacy Policy`,
    url: "https://www.ent.com/globalassets/pdf-files/legal/privacypolicy_11102022.pdf",
  },
  [TERM_LINKS_KEYS.FEE_SCHEDULE]: {
    text: $localize`:@@products.product-review.legend.fee-schedule.label:Fee Schedule`,
    url: "https://www.ent.com/globalassets/pdf-files/legal/personal-banking-schedule-of-fees-charges.pdf",
  },
  [TERM_LINKS_KEYS.RATE_SHEET]: {
    text: $localize`:@@products.product-review.legend.rate-sheet.label:Rate Sheet`,
    url: "https://ent.com/rates",
  },
  [TERM_LINKS_KEYS.DEPOSIT_AVAILABILITY]: {
    text: $localize`:@@products.product-review.legend.deposit-availability.label:Deposit Availability Policy`,
    url: "https://www.ent.com/globalassets/pdf-files/legal/deposit-availability-disclosure.pdf",
  },
  [TERM_LINKS_KEYS.CERTIFICATE_DISCLOSURE]: {
    text: $localize`:@@products.product-review.legend.certificate-disclosure.label:Certificate of Deposit Disclosure`,
    url: "https://www.ent.com/globalassets/pdf-files/legal/certificate-agreement-terms-and-conditions-disclsoure.pdf",
  },
};

export const getAccountLabel = (account: FlatProductItem) => `${account.displayName} ${account.BBAN?.slice(-9)}`;
