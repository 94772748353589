import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PRODUCTS_FORM_STATE, ProductCard } from "@ent/data";
import { ProductsBaseTextService } from "../../services/products-base.text.service";
import { ProductsBaseStepsService } from "../../services/products-base.steps.service";
import { IProductFormErrors } from "../../models/product-errors";

@Component({
  selector: "ent-open-share-base",
  template: "<div style='display:none;'>DO NOT RENDER</div>",
})
export class OpenShareBaseComponent {
  @Input() card: ProductCard;
  @Input() form: FormGroup;
  @Input() errors: IProductFormErrors;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() iconClass: string;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor(public textService: ProductsBaseTextService, public stepsService: ProductsBaseStepsService) {}

  onNext() {
    if (this.next.observed) {
      this.next.emit();
    } else {
      this.stepsService.goNext();
    }
  }

  onBack() {
    if (this.back.observed) {
      this.back.emit();
    } else {
      this.stepsService.goBack();
    }
  }

  onCancel() {
    if (this.cancel.observed) {
      this.cancel.emit();
    } else {
      this.stepsService.setCurrentStep(PRODUCTS_FORM_STATE.EXPLORE);
    }
  }
}
