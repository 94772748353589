import { Injectable } from "@angular/core";

@Injectable({ providedIn: "any" })
export class BillpayTextService {
  titleText = $localize`:@@billpay.header.pay-bills:Pay Bills`;
  addPayeeFailureHeader = $localize`:@@billpay.event.add-payee.failure.header:Sorry, we weren't able to add that payee.`;
  addPayeeFailureText = $localize`:@@billpay.event.add-payee.failure.message:Please check the payee information and try again.`;
  editPayeeSuccesseHeader = $localize`:@@billpay.event.edit-payee.success.header:Successfully saved payee`;
  editPayeeFailureHeader = $localize`:@@billpay.event.edit-payee.failure.header:Error saving payee`;
  deletePayeeFailureText = $localize`:@@billpay.event.delete-payee.failure.message:Sorry, we weren't able to delete that payee. Please try again.`;
  deletePayeeSuccessText = $localize`:@@billpay.event.delete-payee.success.message:Payee was deleted successfully`;
  addPayeeSuccessHeader = $localize`:@@billpay.event.add-payee.success.header:Success! A payee was added`;
  addSingleBillPaymentFailureHeader = $localize`:@@billpay.event.add-single-bill-payment.failure.header:Sorry, we weren't able to schedule the bill payment.`;
  addSingleBillPaymentFailureText = $localize`:@@billpay.event.add-single-bill-payment.failure.message:Please try again later.`;
  loadingText = $localize`:@@billpay.loading:Loading...`;
  errorHeaderText = $localize`:@@billpay.error.header:Sorry, we're having trouble loading the page.`;
  errorText = $localize`:@@billpay.error.message:Please try again later.`;
  validateBillerFailureHeader = $localize`:@@billpay.event.validate-biller.failure.header:Sorry, we weren't able to validate that payee.`;
  validateBillerFailureText = $localize`:@@billpay.event.validate-biller.failure.message:Please check the payee information and try again.`;
  deletePaymentSuccessText = $localize`:@@billpay.event.delete-payment.success.message:Payment was deleted successfully`;
  deletePaymentFailureText = $localize`:@@billpay.event.delete-payment.failure.message:Sorry, we weren't able to delete that payment. Please try again.`;
  editPaymentFailureHeader = $localize`:@@billpay.event.edit-payment.failure.header:Error saving payment`;
  getPaymentActivityFailureHeader = $localize`:@@billpay.event.get-payment-activity.failure.header:Error grabbing Payment Activity`;
  ebillAutopaymentErrorText = $localize`:@@billpay.event.ebill-autopayment.error.message:Sorry, we weren't able to ebill auto payment preferences`;
  ebillCancelAutopaymentSuccessText = $localize`:@@billpay.event.ebill-cancel-autopayment.success.message:You have successfully unenrolled in eBill AutoPayment.`;
  ebillCancelAutopaymentErrorHeader = $localize`:@@billpay.event.ebill-cancel-autopayment.error.header:Error unenrolling in eBill AutoPayment`;
  ebillCancelAutopaymentErrorText = $localize`:@@billpay.event.ebill-cancel-autopayment.error.message:Sorry, we weren't able to unenroll you in eBill AutoPayment.`;
  ebillEnrollErrorText = $localize`:@@billpay.event.ebill-enroll.error.message:Error enrolling in eBills`;
  ebillDisconnectErrorText = $localize`:@@billpay.event.ebill-disconnect.error.message:Error disconnecting eBill`;
  ebillDisconnectSuccessText = $localize`:@@billpay.event.ebill-disconnect.success.message:eBill was disconnected successfully`;
  addPayeeSuccessText = (payeeName: string) =>
    $localize`:@@billpay.event.add-payee.success.message:Added ${payeeName}:INTERPOLATION: to your payees.`;
}
