import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PRODUCTS_FORM_STATE, PRODUCT_ERROR_KEY } from "@ent/data";
import { ProductsBaseTextService } from "../../services/products-base.text.service";
import { ProductsBaseStepsService } from "../../services/products-base.steps.service";

@Component({
  selector: "ent-product-error",
  templateUrl: "./product-error.component.html",
})
export class ProductErrorComponent {
  constructor(public textService: ProductsBaseTextService, public stepsService: ProductsBaseStepsService) {}

  @Input() errorKey = "";
  @Output() retryClick = new EventEmitter();
  formStates = PRODUCTS_FORM_STATE;
  productErrorKey = PRODUCT_ERROR_KEY;
}
