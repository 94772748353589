import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ManageNotificationsTextService {
  labels = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "new-transaction-occurred": {
      heading: $localize`:@@manage-notifications.notification-card.new-transaction-ocurred.heading:Debits and Credits`,
      description: $localize`:@@manage-notifications.notification-card.new-transaction-ocurred.description: Receive notification when an amount is debited or credited from your account.`,
      currencyInputLabel: $localize`:@@manage-notifications.notification-card.account-balance-low.currency-input.label: Lower than`,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "new-deposit-occurred": {
      heading: $localize`:@@manage-notifications.notification-card.new-deposit-ocurred.heading:Deposit Transaction Alert`,
      description: $localize`:@@manage-notifications.notification-card.new-deposit-ocurred.description: Be notified whenever funds are added to your account through a transaction larger than the amount you specify.`,
      balanceDescription: $localize`:@@manage-notifications.notification-card.new-deposit-ocurred.balance.description: Enter $0 if you would like to be notified about all deposits.`,
      currencyInputLabel: $localize`:@@manage-notifications.notification-card.new-deposit-ocurred.currency-input.label: Amount`,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "account-balance-low": {
      heading: $localize`:@@manage-notifications.notification-card.account-balance-low.heading:Low Balance Alert`,
      description: $localize`:@@manage-notifications.notification-card.account-balance-low.description: Be notified whenever your available balance drops below the amount you specify.`,
      currencyInputLabel: $localize`:@@manage-notifications.notification-card.account-balance-low.currency-input.label: Lower than`,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "new-withdrawal-occurred": {
      heading: $localize`:@@manage-notifications.notification-card.new-withdrawal-occurred.heading:Withdrawal Transaction Alert`,
      description: $localize`:@@manage-notifications.notification-card.new-withdrawal-occurred.description: Be notified whenever funds are removed from your account through a transaction larger than the amount you specify.`,
      balanceDescription: $localize`:@@manage-notifications.notification-card.new-withdrawal-occurred.balance.description: Enter $0 if you would like to be notified about all withdrawals.`,
      currencyInputLabel: $localize`:@@manage-notifications.notification-card.new-withdrawal-occurred.currency-input.label: Amount`,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "balance-on-schedule": {
      heading: $localize`:@@manage-notifications.notification-card.balance-on-schedule.heading:Balance Summary`,
      description: $localize`:@@manage-notifications.notification-card.balance-on-schedule.description:Choose when you would like to get a summary of your available and actual balances.`,
    },
  };

  channelLabels = {
    sms: $localize`:@@manage-notifications.notification-card.channels.sms.label: Text Message`,
    email: $localize`:@@manage-notifications.notification-card.channels.email.label: Email`,
    push: $localize`:@@manage-notifications.notification-card.channels.push.label: Push`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "in-app-notification": $localize`:@@manage-notifications.notification-card.channels.in-app-notification.label: In-App Notification`,
  };

  headingLabel = $localize`:@@manage-notifications.product-settings.heading.label: Notification details`;
  backButtonLabel = $localize`:@@manage-notifications.product-settings.back-button.label: Back to Manage notifications`;
  currencyInputAriaLabel = $localize`:@@manage-notifications.notification-card.currency-input.aria-label: Edit amount without fractions`;
  channelsLabel = $localize`:@@manage-notifications.notification-card.channels.label: Alert channels`;
  channelsAriaLabel = $localize`:@@manage-notifications.notification-card.channels.aria-label: Other channels that you would like to receive notifications through, additionally to the ones in-app`;

  loadingText = $localize`:@@manage-notifications.product-settings.loading.label: Loading...`;
  loadingErrorText = $localize`:@@manage-notifications.product-settings.loading-error.label: Error loading notification details`;
  loadingErrorHeader = $localize`:@@manage-notifications.product-settings.loading-error-header.label: Error`;

  notificationSuccess = $localize`:@@manage-notifications.product-settings.notification.success.label: Your changes are saved`;
  notificationError = $localize`:@@manage-notifications.product-settings.notification.error.label: Please try again later`;
}
