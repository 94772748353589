import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { UserProfile } from "@backbase/user-http-ang";
import { normalizePhoneAddressFromAPICall } from "../helpers/phone-address";

@Injectable()
export class GetPhoneAddressHttpInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (/\/profile\/?(phone-addresses)?$/i.test(httpRequest.url)) {
      return next.handle(httpRequest).pipe(
        filter((event: any) => event instanceof HttpResponse),
        map(this.normalizeAPIBody),
      );
    }
    return next.handle(httpRequest);
  }
  normalizeAPIBody = (event: HttpResponse<any>) => {
    let newBody = event.body;
    if (newBody && newBody["phone-addresses"]?.length > 0) {
      newBody = normalizePhoneAddressFromAPICall(newBody);
    }
    return event.clone<UserProfile>({
      body: newBody,
    });
  };
}
