import { Injectable, NgZone } from "@angular/core";
import { ActivityEvent, ActivityMonitorService } from "@backbase/identity-auth";
import { OAuthService } from "angular-oauth2-oidc";
import { ICountdown } from "../models/session-timeout.interface";
import { BILLPAY_URL_SESSION_STORAGE_KEY } from "../../billpay/models/constants";

/**
 * CustomSessionTimeoutService used to call logout and session services for session management.
 */
@Injectable({
  providedIn: "root",
})
export class SessionTimeoutService {
  /**
   * CustomSessionTimeoutService constructor
   * @param activityMonitorService Auth service used to monitor user activity.
   * @param ngZone Service for executing work inside or outside of the Angular zone.
   * @param oAuthService angular-oauth2-oidc service.
   */
  constructor(
    private activityMonitorService: ActivityMonitorService,
    private readonly ngZone: NgZone,
    private oAuthService: OAuthService,
  ) {}

  /**
   * Calls the oAuth logout service to log the user out and revoke a current token.
   * @returns a promise from the logout service.
   */
  logout = (): Promise<void> => {
    window.sessionStorage.removeItem(BILLPAY_URL_SESSION_STORAGE_KEY);
    return this.oAuthService.revokeTokenAndLogout();
  };

  /**
   * Calls the activity monitor service to register a countdown object for managing session.
   * @param countdown a `Countdown` object that allows a controller to register functions to auth session actions.
   */
  registerCountdown = (countdown: ICountdown) => {
    this.ngZone.runOutsideAngular(() => {
      this.activityMonitorService.events.subscribe((event: ActivityEvent) => {
        switch (event.type) {
          case "start":
            countdown.start();
            break;
          case "tick":
            countdown.tick(event.remaining);
            break;
          case "end":
            countdown.end();
            break;
          case "reset":
            countdown.reset();
            break;
          default:
            break;
        }
      });

      this.activityMonitorService.start({
        maxInactivityDuration: countdown.maxInactivityDuration,
        countdownDuration: countdown.countdownDuration,
      });
    });
  };
}
