import { Component, Input, OnInit } from "@angular/core";
import {
  FORM_CONTROL_NAMES,
  NO_STRINGS_CHECKING_KEY,
  PRODUCT_REVIEW_ASSETS,
  PRODUCT_REVIEW_LINKS,
  ProductReviewAssets,
  ReviewItem,
  SelectionItem,
  TERM_LINKS,
  TermLink,
} from "@ent/data";
import { OpenShareBaseComponent } from "../open-share-base/open-share-base.component";

@Component({
  selector: "ent-product-review",
  templateUrl: "./product-review.component.html",
})
export class ProductReviewComponent extends OpenShareBaseComponent implements OnInit {
  @Input() reviewItems: ReviewItem[] = [];
  @Input() accountsList: SelectionItem[] = [];
  @Input() isSaving = false;
  @Input() isCheckingConversion = false;
  formControlNames = FORM_CONTROL_NAMES;
  productReviewAssets = PRODUCT_REVIEW_ASSETS;
  productReviewLinks = PRODUCT_REVIEW_LINKS;
  termLinks = TERM_LINKS;
  reviewAssets: ProductReviewAssets;
  links: Array<TermLink>;

  ngOnInit(): void {
    this.getProductCardAssets();
  }

  setReviewItemsValues() {
    let convertingAccountLabel: string;
    if (!this.reviewItems.length) this.reviewItems = this.reviewAssets.reviewItems;
    this.reviewItems?.forEach((item) => {
      const defaultValue = this.form.get(item.formName)?.value;
      if (item.formName) {
        switch (item.formName) {
          case this.formControlNames.SHARE_TYPE:
            item.value = this.card.description;
            break;
          case this.formControlNames.ARRANGEMENT_ID:
            convertingAccountLabel = this.getAccountLabel(defaultValue);
            item.value = convertingAccountLabel;
            break;
          case this.formControlNames.NEW_BRONCOS_NAME:
            item.value = convertingAccountLabel.includes(NO_STRINGS_CHECKING_KEY)
              ? this.card.description.toUpperCase() + convertingAccountLabel.slice(-9)
              : convertingAccountLabel;
            break;
          case this.formControlNames.DEPOSIT_ACCOUNT:
            item.value = this.card.fromAccountLabel;
            break;
          case this.formControlNames.DEPOSIT_AMOUNT:
            item.value = defaultValue ? `$${defaultValue}` : undefined;
            break;
          default:
            item.value = defaultValue;
            break;
        }
      }
    });
  }

  getTermLinks() {
    const productReviewLink = this.productReviewLinks.find(
      (reviewLink) => reviewLink.types.has(this.card.type) && reviewLink.section === this.card.section,
    );
    if (productReviewLink) {
      this.links = productReviewLink.links.flatMap((link) => this.termLinks[link] || []);
    }
  }

  getProductCardAssets() {
    this.reviewAssets = this.productReviewAssets.find(
      (cardAsset) => cardAsset.types.has(this.card.type) && cardAsset.section === this.card.section,
    );
    if (this.reviewAssets) {
      this.setReviewItemsValues();
      this.getTermLinks();
    }
  }

  getAccountLabel(id: string) {
    return this.accountsList.find((account) => account.value === id)?.label;
  }
}
