import { Schedule, PaymentMode, PaymentOrdersPost } from "@backbase/payment-order-http-ang";
import { formatDateTimeString, FREQUENCY_SELECTOR } from "@ent/data";
import { IFormModel } from "./form-model";

export const getFrequencyValue = (frequency: string): Schedule.TransferFrequencyEnum => {
  switch (frequency) {
    case FREQUENCY_SELECTOR.BIMONTHLY:
      return Schedule.TransferFrequencyEnum.MONTHLY;
    case FREQUENCY_SELECTOR.SEMIANNUALLY:
      return Schedule.TransferFrequencyEnum.QUARTERLY;
    default:
      return frequency as Schedule.TransferFrequencyEnum;
  }
};

export const getEveryValue = (frequency: string) => {
  switch (frequency) {
    case FREQUENCY_SELECTOR.BIMONTHLY:
    case FREQUENCY_SELECTOR.SEMIANNUALLY:
      return Schedule.EveryEnum._2;
    default:
      return Schedule.EveryEnum._1;
  }
};

export const getSchedule = (formModel: IFormModel) => {
  if (formModel.paymentMode !== PaymentMode.RECURRING) return undefined;
  return {
    transferFrequency: getFrequencyValue(formModel.frequency),
    every: getEveryValue(formModel.frequency),
    on: 1,
    startDate: formatDateTimeString(formModel.executionDate, "yyyy-LL-dd"),
    endDate: formatDateTimeString(formModel.endDate, "yyyy-LL-dd"),
    repeat: formModel.repeat,
  };
};

/** 
@description External transfers always have the originator account as the member's account. The direction is determined
by the counterparty role. DEBTOR means the external account is the source, CREDITOR means the external account
is the destination.
*/
export const decorateExternalAccountPaymentOrder = (formModel: IFormModel, paymentOrderRequest: PaymentOrdersPost) => {
  const clonedPaymentOrderRequest = { ...paymentOrderRequest };
  clonedPaymentOrderRequest.paymentType = "EXTERNAL_A2A";
  clonedPaymentOrderRequest.originatorAccount.identification.identification = formModel.toAccount.id;
  clonedPaymentOrderRequest.originatorAccount.identification.schemeName = "ID";
  clonedPaymentOrderRequest.originatorAccount.name = formModel.toAccount.name;

  clonedPaymentOrderRequest.transferTransactionInformation.counterparty.name = formModel.fromAccount.name;
  clonedPaymentOrderRequest.transferTransactionInformation.counterparty.role = "DEBTOR";
  clonedPaymentOrderRequest.transferTransactionInformation.counterpartyAccount.identification.identification =
    formModel.fromAccount.id;
  clonedPaymentOrderRequest.transferTransactionInformation.counterpartyAccount.identification.schemeName =
    "EXTERNAL_ID";
  return clonedPaymentOrderRequest;
};
