import { Component, Input, OnChanges } from "@angular/core";
import { CardsHTTPService, PaymentCard } from "@ent/data";
import { TextService } from "../../services/text.service";
import { BasePinFormComponent } from "../base-pin-form/base-pin-form.component";

@Component({
  selector: "ent-pin-confirm-form",
  templateUrl: "./pin-confirm-form.component.html",
})
export class PinConfirmFormComponent extends BasePinFormComponent implements OnChanges {
  @Input() confirmButtonText: string;
  paymentCard: PaymentCard;

  protected inputControlName = "confirmPin";
  constructor(public readonly textService: TextService, public readonly cardsHTTPService: CardsHTTPService) {
    super();
  }

  ngOnChanges(): void {
    this.paymentCard = { ...this.cardsHTTPService.paymentCard, status: "ACTIVE", lockStatus: "UNLOCKED" };
  }
}
