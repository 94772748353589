<div id="test__memo-text-field">
  <div class="bb-block bb-block--md bb-inline-stack bb-stack--align-top">
    <div class="bb-stack__item bb-stack__item--spacing-sm">
      <em class="fa-regular fa-align-left fa-fw"></em>
    </div>
    <div class="bb-stack__item">
      <div class="bb-text-bold" data-role="payment-description-label">{{ memoLabel }}</div>
      <div class="break-word" data-role="payment-description">{{ memo }}</div>
    </div>
  </div>
</div>
