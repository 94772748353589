import { Component, OnChanges } from "@angular/core";
import { isProductALOC } from "@ent/data";
import { BaseReviewComponent } from "../../components/base-review/base-review.component";
import { IReviewTextService } from "../../services/review.text.service";
import { PaymentsReviewTextService } from "../services/payments-review.text.service";

@Component({
  selector: "ent-payment-review",
  templateUrl: "./payment-review.component.html",
})
export class PaymentReviewComponent extends BaseReviewComponent implements OnChanges {
  isToAccountAnLOC = false;
  constructor(public readonly paymentsReviewTextService: PaymentsReviewTextService) {
    super();
  }
  ngOnChanges(): void {
    super.ngOnChanges();
    this.isToAccountAnLOC = isProductALOC(this.formModel.toAccount);
  }
  getReviewTextService = (): IReviewTextService => {
    return this.paymentsReviewTextService;
  };
}
