<div class="bb-item-log__record item-log__record--has-bullet" id="test__to-selector">
  <div class="bb-stack bb-stack--align-top">
    <div class="bb-item-log-record__bullet-icon bb-stack__item bb-stack__item--spacing-md"></div>
    <div class="bb-item-log-record__details">
      <span class="bb-item-log__to-label">{{ toLabel }}</span>
      <div class="bb-payment-account-details-card h-100">
        <div class="bb-payment-account-details-card__body justify-content-start">
          <div class="bb-text-bold break-word" data-role="creditor-name">{{ name }}</div>
          <div class="bb-text-support bb-subtitle" data-role="creditor-number">{{ accountNumber }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
