import { ChangeDetectorRef, Component, NgZone, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  ICountdown,
  COUNTDOWN_DURATION_SECS,
  MAX_INACTIVITY_DURATION_SECS,
  SESSION_TIMEOUT_TIME_FORMAT,
} from "../models/session-timeout.interface";
import { SessionTimeoutService } from "../services/session-timeout.service";
import { SessionTimeoutTextService } from "../services/session-timeout.text.service";

/**
 * CustomSessionTimeoutComponent performs basic session logout and management.
 */
@Component({
  selector: "ent-session-timeout-modal",
  templateUrl: "./session-timeout.component.html",
  providers: [SessionTimeoutService],
})
export class SessionTimeoutModalComponent implements OnInit {
  maxInactivityDuration = MAX_INACTIVITY_DURATION_SECS;

  countdownDuration = COUNTDOWN_DURATION_SECS;

  /**
   * Determines whether the modal is open.
   */
  isOpen = new BehaviorSubject<boolean>(false);
  /**
   * The `SessionTimeoutTimeFormat` time format for the current remaining session time.
   */
  timeFormat: SESSION_TIMEOUT_TIME_FORMAT | undefined;
  /**
   * The `SessionTimeoutTimeFormat` options list.
   */
  formats = SESSION_TIMEOUT_TIME_FORMAT;
  /**
   * The amount of minutes remaining in the session.
   */
  minutesRemaining: number | undefined;
  /**
   * The amount of seconds remaining in the session.
   */
  secondsRemaining: number | undefined;

  /**
   * CustomSessionTimeoutComponent constructor
   * @param sessionTimeoutService Factory that allows calls to web sdk endpoints for controlling session
   * @param cd Change Detector Ref factory
   * @param ngZone Service for executing work inside or outside of the Angular zone
   */
  constructor(
    private readonly sessionTimeoutService: SessionTimeoutService,
    private readonly cd: ChangeDetectorRef,
    private readonly ngZone: NgZone,
    public readonly textService: SessionTimeoutTextService,
  ) {}

  /**
   * @internal (undocumented)
   */
  ngOnInit(): void {
    this.isOpen.next(false);
    const countdown: ICountdown = {
      maxInactivityDuration: this.maxInactivityDuration,
      countdownDuration: this.countdownDuration,
      start: this.onStart,
      end: this.onEnd,
      reset: this.onReset,
      tick: this.onTick,
    };
    this.sessionTimeoutService.registerCountdown(countdown);
  }

  /**
   * Calls the Session Timeout Service to log the user out.
   * Once Logged out sends the user to the login page.
   * @returns a promise from the session timeout service
   */
  logout = () => {
    return this.sessionTimeoutService.logout();
  };

  continueSession = () => {
    this.closeModal();
  };

  onStart = () => {
    this.ngZone.run(() => {
      this.isOpen.next(true);
      this.cd.markForCheck();
    });
  };

  onEnd = () => {
    this.closeModal();
    return this.logout();
  };

  onReset = () => {
    this.closeModal();
  };

  onTick = (ttl: number) => {
    this.ngZone.run(() => {
      if (ttl < 60) {
        this.timeFormat = SESSION_TIMEOUT_TIME_FORMAT.SECONDS;
        this.minutesRemaining = 0;
        this.secondsRemaining = ttl;
      } else if (ttl % 60 === 0) {
        this.timeFormat = SESSION_TIMEOUT_TIME_FORMAT.MINUTES;
        this.minutesRemaining = ttl / 60;
        this.secondsRemaining = 0;
      } else {
        this.timeFormat = SESSION_TIMEOUT_TIME_FORMAT.FULL;
        this.minutesRemaining = Math.floor(ttl / 60);
        this.secondsRemaining = ttl - this.minutesRemaining * 60;
      }
      this.cd.markForCheck();
    });
  };

  closeModal = () => {
    this.ngZone.run(() => {
      this.timeFormat = undefined;
      this.minutesRemaining = undefined;
      this.secondsRemaining = undefined;
      this.isOpen.next(false);
      this.cd.markForCheck();
    });
  };
}
