import { ActivityMonitorConfig } from "@backbase/identity-auth";

export interface ICountdown extends ActivityMonitorConfig {
  start: () => void;
  reset: () => void;
  end: () => void;
  tick: (remaining: number) => void;
}

export enum SESSION_TIMEOUT_TIME_FORMAT {
  FULL,
  MINUTES,
  SECONDS,
}

export const MAX_INACTIVITY_DURATION_SECS = 900; // This is 15 minutes
export const COUNTDOWN_DURATION_SECS = 120; // this is 2 minutes
