import { Injectable } from "@angular/core";
import { IReviewTextService } from "../../services/review.text.service";

@Injectable({ providedIn: "any" })
export class TransferReviewTextService implements IReviewTextService {
  title = $localize`:@@internal-transfer.review.title:Review Your Transfer`;
  fromLabel = $localize`:@@internal-transfer.review.from:From`;
  toLabel = $localize`:@@internal-transfer.review.to:To`;
  memo = $localize`:@@internal-transfer.review.memo:Personal Note`;
  submitError = $localize`:@@internal-transfer.review.submitError:We couldn't complete your transfer. Please click Confirm Transfer or Cancel to try again.`;
  confirm = $localize`:@@internal-transfer.review.confirm:Confirm Transfer`;
  edit = $localize`:@@internal-transfer.review.edit:Edit`;
  cancel = $localize`:@@internal-transfer.review.cancel:Cancel`;
  amount = $localize`:@@internal-transfer.review.amount:Amount`;

  /** Frequency  */
  frequency = $localize`:@@internal-transfer.review.frequency:Frequency`;
  frequencyWeeklyLabel = $localize`:@@internal-transfer.review.frequency.weekly: Every Week`;
  frequencyBiWeeklyLabel = $localize`:@@internal-transfer.review.frequency.bi-weekly: Every 2 weeks`;
  frequencyMonthlyLabel = $localize`:@@internal-transfer.review.frequency.monthly: Every Month`;
  frequencyBiMonthlyLabel = $localize`:@@internal-transfer.review.frequency.bi-monthly: Every 2 months`;
  frequencyQuarterlyLabel = $localize`:@@internal-transfer.review.frequency.quarterly: Every 3 months`;
  frequencySemiAnnuallyLabel = $localize`:@@internal-transfer.review.frequency.semi-annually: Every 6 months`;
  frequencyAnnuallyLabel = $localize`:@@internal-transfer.review.frequency.annually: Every Year`;

  /** Discard Modal */
  discardTitle = $localize`:@@internal-transfer.review.discard.title: Cancel making a transfer?`;
  discardConfirm = $localize`:@@internal-transfer.review.discard.confirm: The information you've entered won't be saved. Are you sure you want to cancel?`;
  discardYes = $localize`:@@internal-transfer.review.discard.yes:Don't Save`;
  discardNo = $localize`:@@internal-transfer.review.discard.no:Keep Reviewing`;

  /** Calendar  */
  calendarDate = $localize`:@@internal-transfer.review.calendar.transfer-date: Date`;
  calendarTodayText = $localize`:@@internal-transfer.review.calendar-text.today: Today`;
  calendarStartDate = $localize`:@@internal-transfer.review.calendar.start-date: Start Date`;
  calendarEndOn = $localize`:@@internal-transfer.review.calendar.end-date: End Date`;
  calendarEndNever = $localize`:@@internal-transfer.review.calendar.duration: Duration`;
  calendarEndAfter = $localize`:@@internal-transfer.review.calendar.end-after: End After`;
  calendarNeverEndsText = $localize`:@@internal-transfer.review.calendar.never-ends: Never Ends`;
  calendarOccurrencesText = (occurrence: number) =>
    $localize`:@@internal-transfer.review.calendar.number-occurrences: ${occurrence} occurrences`;
}
