export const entPhoneNumberFormatter = (phoneNumber: string) => {
  const rawPhoneNumber = phoneNumber.replace(/\D/g, "");
  if (rawPhoneNumber.length === 10) {
    const areaCode = rawPhoneNumber.slice(0, 3);
    const midSection = rawPhoneNumber.slice(3, 6);
    const endSection = rawPhoneNumber.slice(6, 10);
    return `(${areaCode}) ${midSection}-${endSection}`;
  }
  return phoneNumber;
};
