<div class="card">
  <div class="card-header">
    <h2 class="m-0">{{ textService.cardControlsHeader }}</h2>
  </div>
  <div class="card-body">
    <div class="bb-list">
      <div class="bb-stack bb-payment-card-self-service-row mb-4 pb-3">
        <div class="bb-stack bb-stack--align-top bb-stack__item bb-payment-card-self-service-row__description">
          <div class="bb-stack__item">
            <em class="fa-regular fa-fingerprint fa-lg"></em>
          </div>
          <div class="bb-stack__item">
            <h5 class="bb-block--no-margin">{{ textService.cardControlsPinUpdateHeader }}</h5>
            <span class="bb-text-support text-small">
              {{ cardsHTTPService.isATMCard ? textService.updatePINATMSubtext : textService.updatePINDebitSubText }}
            </span>
          </div>
        </div>
        <div class="bb-stack__item bb-payment-card-self-service-row__button">
          <button bbButton color="secondary" data-role="reset-pin-button" (click)="openResetPinModal()">
            {{ textService.cardControlsPinUpdateButton }}
          </button>
        </div>
      </div>
      <div class="mb-4 pb-3">
        <hr class="m-0" />
      </div>
      <div class="pb-3 bb-stack">
        <div class="bb-stack bb-stack--align-top">
          <div class="bb-stack__item">
            <em class="fa-regular fa-lock-keyhole fa-lg"></em>
          </div>
          <div class="bb-stack__item">
            <h5 class="bb-block--no-margin">{{ textService.cardControlsLockHeader }}</h5>
            <span class="bb-text-support text-small"> {{ textService.cardControlsLockSubHeader }} </span>
          </div>
        </div>
        <form [formGroup]="form">
          <bb-switch-ui
            [aria-label]="textService.cardControlsLockSwitchLabel"
            formControlName="isLocked"
            (click)="onToggleLockStatus()"
            (toggleSwitch)="onToggleLockStatus()"
          >
          </bb-switch-ui>
        </form>
      </div>
    </div>
  </div>
</div>
<ent-reset-pin-modal (closeModal)="closeResetPinModal()" [isOpen]="isResetPinModalOpen"></ent-reset-pin-modal>
