import { END_TYPE, FREQUENCY_SELECTOR, getMinimumEndDateFromStartDate, OCCURRENCE } from "@ent/data";
import { DateTime } from "luxon";
import * as yup from "yup";
import { TRANSFER_FORM_ERROR_KEY } from "./transfer-errors";

export const transferFormSchema = yup.object().shape({
  fromAccountId: yup.string().required(TRANSFER_FORM_ERROR_KEY.FROM_REQUIRED),
  fromAccountBalance: yup.number().nullable(),
  toAccountId: yup.string().required(TRANSFER_FORM_ERROR_KEY.TO_REQUIRED),
  toAccountBalance: yup.number().nullable(),
  minLoanAdvance: yup.number().nullable(),
  minExecutionDate: yup.date().nullable(),
  amount: yup
    .number()
    .required(TRANSFER_FORM_ERROR_KEY.AMOUNT_REQUIRED)
    .when("minLoanAdvance", (minLoanAdvance: number | undefined, schema) => {
      if (minLoanAdvance) {
        return schema.min(minLoanAdvance, TRANSFER_FORM_ERROR_KEY.AMOUNT_MIN_LOAN_ADVANCE);
      }
      return schema.min(0.01, TRANSFER_FORM_ERROR_KEY.AMOUNT_REQUIRED);
    })
    .when("fromAccountBalance", (fromAccountBalance: number | undefined, schema) => {
      return schema.max(fromAccountBalance ?? Number.MAX_SAFE_INTEGER, TRANSFER_FORM_ERROR_KEY.AMOUNT_MAX);
    })
    .when("occurrence", {
      is: (occurrence: OCCURRENCE) => occurrence !== OCCURRENCE.NOW,
      then: yup.number().max(Number.MAX_SAFE_INTEGER, TRANSFER_FORM_ERROR_KEY.AMOUNT_MAX),
    }),
  occurrence: yup
    .string()
    .required(TRANSFER_FORM_ERROR_KEY.OCCURRENCE_REQUIRED)
    .oneOf([OCCURRENCE.NOW, OCCURRENCE.LATER, OCCURRENCE.RECURRING], TRANSFER_FORM_ERROR_KEY.OCCURRENCE_REQUIRED),
  executionDate: yup
    .date()
    .required(TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_REQUIRED)
    .when(["occurrence", "minExecutionDate"], (...rest) => {
      const [occurrence, minExecutionDate, schema] = rest;
      if (occurrence === OCCURRENCE.NOW) {
        return schema.min(DateTime.now().startOf("day").toJSDate(), TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_MIN);
      }
      return schema.min(minExecutionDate, TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_MIN);
    }),
  frequency: yup.string().when("occurrence", {
    is: OCCURRENCE.RECURRING,
    then: yup
      .string()
      .required(TRANSFER_FORM_ERROR_KEY.FREQUENCY_REQUIRED)
      .oneOf(
        [
          FREQUENCY_SELECTOR.WEEKLY,
          FREQUENCY_SELECTOR.BIWEEKLY,
          FREQUENCY_SELECTOR.MONTHLY,
          FREQUENCY_SELECTOR.BIMONTHLY,
          FREQUENCY_SELECTOR.QUARTERLY,
          FREQUENCY_SELECTOR.SEMIANNUALLY,
          FREQUENCY_SELECTOR.ANNUALLY,
        ],
        TRANSFER_FORM_ERROR_KEY.FREQUENCY_REQUIRED,
      ),
    otherwise: yup
      .string()
      .required(TRANSFER_FORM_ERROR_KEY.FREQUENCY_REQUIRED)
      .oneOf([FREQUENCY_SELECTOR.ONCE], TRANSFER_FORM_ERROR_KEY.FREQUENCY_REQUIRED),
  }),
  endType: yup.string().when("occurrence", {
    is: OCCURRENCE.RECURRING,
    then: yup
      .string()
      .oneOf([END_TYPE.AFTER, END_TYPE.NEVER, END_TYPE.ON], TRANSFER_FORM_ERROR_KEY.END_TYPE_REQUIRED)
      .required(TRANSFER_FORM_ERROR_KEY.END_TYPE_REQUIRED),
    otherwise: yup.string().optional().nullable(),
  }),
  endDate: yup.date().when(["occurrence", "endType", "frequency", "executionDate"], (...rest) => {
    const [occurrence, endType, frequency, executionDate, schema] = rest;
    if (occurrence === OCCURRENCE.RECURRING && endType === END_TYPE.ON) {
      const luxonExecutionDate = DateTime.fromJSDate(executionDate);
      const minDate = getMinimumEndDateFromStartDate(
        frequency,
        luxonExecutionDate.isValid ? luxonExecutionDate.toISO() : undefined,
      );
      if (minDate) {
        return schema
          .required(TRANSFER_FORM_ERROR_KEY.END_DATE_REQUIRED)
          .min(minDate, TRANSFER_FORM_ERROR_KEY.END_DATE_MIN);
      }
      return schema.required(TRANSFER_FORM_ERROR_KEY.END_DATE_REQUIRED);
    }
    return schema.optional().nullable();
  }),
  repeat: yup.number().when(["occurrence", "endType"], {
    is: (occurrence: OCCURRENCE, endType: END_TYPE) =>
      occurrence === OCCURRENCE.RECURRING && endType === END_TYPE.AFTER,
    then: yup
      .number()
      .required(TRANSFER_FORM_ERROR_KEY.REPEAT_REQUIRED)
      .min(2, TRANSFER_FORM_ERROR_KEY.REPEAT_REQUIRED),
    otherwise: yup.number().optional().nullable(),
  }),
  memo: yup.string().nullable(),
});
