<div [formGroup]="controlFormGroup" [ngClass]="{ 'ent-input--invalid': isInvalid }">
  <bb-input-datepicker-ui
    [label]="label"
    [formControlName]="formFieldName"
    [minDate]="minDate"
    [firstDayOfWeek]="7"
    [overrideDateFormat]="overrideDateFormat"
    [placeholder]="'MM/DD/YYYY'"
    [clickOpen]="true"
    [markDisabled]="markDisabled"
  ></bb-input-datepicker-ui>
  <bb-input-validation-message-ui [showErrors]="isInvalid" [id]="'test__occurrence__error'">
    <span>{{ error }}</span>
  </bb-input-validation-message-ui>
</div>
