<bb-header-ui
  headingType="h1"
  [heading]="textService.titleText"
  headingClasses="bb-heading-widget__heading my-4"
></bb-header-ui>
<ent-stateful-component
  [componentState]="componentState"
  [loadingText]=""
  [errorHeaderText]=""
  [errorText]=""
  [contentTemplate]="loadedForm"
>
</ent-stateful-component>

<ng-template #loadedForm>
  <ng-container [ngSwitch]="formState">
    <ng-container *ngSwitchCase="formStates.EDITING">
      <ent-payment-form
        [formModel]="formModel"
        [isLoading]="isLoading"
        (validateForm)="validateForm($event)"
      ></ent-payment-form>
    </ng-container>
    <ng-container *ngSwitchCase="formStates.REVIEW">
      <ent-payment-review
        [paymentOrderPostResponse]="paymentOrderPostResponse"
        [formModel]="formModel"
        [isLoading]="isLoading"
        [isSubmitError]="isSubmitError | async"
        (edit)="setFormState(formStates.EDITING)"
        (cancel)="restartForm()"
        (submit)="postPayment()"
      ></ent-payment-review>
    </ng-container>
    <ng-container *ngSwitchCase="formStates.SUCCESS">
      <ent-payment-success
        [paymentOrderPostResponse]="paymentOrderPostResponse"
        [formModel]="formModel"
        (navigateToAccounts)="goToAccounts()"
        (restart)="restartForm()"
      ></ent-payment-success>
    </ng-container>
  </ng-container>
</ng-template>
