import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-frequency-display",
  templateUrl: "./frequency.component.html",
})
export class FrequencyDisplayComponent {
  @Input()
  frequencyLabel!: string;

  @Input()
  frequency!: string;
}
