import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ExploreProductsTextService } from "../../services/explore-products.text.service";

@Component({
  selector: "ent-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"],
})
export class ProductCardComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() iconClass: string;
  @Input() rateLabel: string;
  @Input() buttonLabel: string;
  @Input() disabled: boolean;
  @Output() addClick = new EventEmitter();

  constructor(public textService: ExploreProductsTextService) {}

  onAddClick() {
    this.addClick.emit();
  }
}
