import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FREQUENCY_SELECTOR, getOccurrenceFromPaymentMode, OCCURRENCE } from "@ent/data";
import { PaymentOrdersPostResponse } from "@backbase/payment-order-http-ang";
import { IFormModel } from "../../models/form-model";
import { ISuccessTextService } from "../../services/success.text.service";

@Component({
  selector: "ent-base-success",
  template: "<div style='display:none;'>DO NOT RENDER</div>",
})
export class BaseSuccessComponent implements OnChanges {
  @Input() formModel!: IFormModel;
  @Input() paymentOrderPostResponse: PaymentOrdersPostResponse;

  @Output()
  restart = new EventEmitter();

  @Output()
  navigateToAccounts = new EventEmitter();

  occurrences = OCCURRENCE;
  occurrence = OCCURRENCE.NOW;
  accountName = "";
  frequency = "monthly";

  onRestart = () => this.restart.emit();

  onNavigateToAccounts = () => this.navigateToAccounts.emit();

  ngOnChanges(): void {
    this.setOccurrence();
    this.setAccountName();
    this.setFrequency();
  }

  setOccurrence = () => {
    this.occurrence = getOccurrenceFromPaymentMode(this.formModel.paymentMode, this.formModel.executionDate);
  };

  setAccountName = () => {
    this.accountName = this.formModel.toAccount?.displayName ?? this.formModel.toAccount?.name ?? "";
  };

  setFrequency = () => {
    switch (this.formModel.frequency) {
      case FREQUENCY_SELECTOR.WEEKLY:
        this.frequency = "weekly";
        break;
      case FREQUENCY_SELECTOR.BIWEEKLY:
        this.frequency = "biweekly";
        break;
      case FREQUENCY_SELECTOR.BIMONTHLY:
        this.frequency = "bimonthly";
        break;
      case FREQUENCY_SELECTOR.QUARTERLY:
        this.frequency = "quarterly";
        break;
      case FREQUENCY_SELECTOR.SEMIANNUALLY:
        this.frequency = "semiannually";
        break;
      case FREQUENCY_SELECTOR.ANNUALLY:
        this.frequency = "yearly";
        break;
      case FREQUENCY_SELECTOR.MONTHLY:
      default:
        this.frequency = "monthly";
        break;
    }
  };

  getSuccessTextService = (): ISuccessTextService => {
    throw new Error("Not implemented");
  };
}
