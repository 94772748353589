import { Component, OnInit } from "@angular/core";
import { RemoteConfigService } from "@backbase/remote-config-ang";
import { NotificationService } from "@backbase/ui-ang/notification";
import { CallCenterPasswordHttpService, COMPONENT_STATE, RetailAppRemoteConfig } from "@ent/data";
import { BehaviorSubject } from "rxjs";
import { CallCenterPasswordTextService } from "./services/call-center-password.text.service";

@Component({
  selector: "ent-security",
  templateUrl: "security.component.html",
})
export class SecurityComponent implements OnInit {
  componentState = new BehaviorSubject<COMPONENT_STATE>(COMPONENT_STATE.LOADING);
  currentPassword = new BehaviorSubject<string>(undefined);
  currentReminder = new BehaviorSubject<string>(undefined);
  remoteConfigParameters = {
    callCenterPassword: this.remoteConfigService.getValue("call_center_password"),
  };
  constructor(
    private readonly callCenterPasswordHTTPService: CallCenterPasswordHttpService,
    private readonly notificationService: NotificationService,
    public textService: CallCenterPasswordTextService,
    private remoteConfigService: RemoteConfigService<RetailAppRemoteConfig>,
  ) {}
  ngOnInit() {
    this.getCallCenterPassword();
  }

  updateCallCenterPassword = async (password, reminder) => {
    try {
      await this.callCenterPasswordHTTPService.setCallCenterPassword({ password, reminder });
      this.getCallCenterPassword();
      this.notificationService.showNotification({
        header: "Call Center Password changed successfully",
        message: "",
        modifier: "success",
      });
    } catch {
      this.notificationService.showNotification({
        message: "An unexpected error occurred",
        modifier: "error",
      });
    }
  };

  getCallCenterPassword = async () => {
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      const value = await this.callCenterPasswordHTTPService.getCallCenterPassword();
      this.currentPassword.next(value?.password);
      this.currentReminder.next(value?.reminder);
      if (value) {
        this.componentState.next(COMPONENT_STATE.LOADED);
      }
    } catch {
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  };
}
