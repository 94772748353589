import { Route } from "@angular/router";
import { RetailNotificationPreferencesViewComponent } from "@backbase/retail-notification-preferences-journey-ang";
import { CustomProductSettingsComponent } from "./custom-product-settings/custom-product-settings.component";

export const manageNotificationsOverrideRoutes: Route = {
  path: "",
  children: [
    {
      path: "",
      redirectTo: "manage-notifications",
      pathMatch: "full",
    },
    { path: "manage-notifications", component: RetailNotificationPreferencesViewComponent },
    {
      path: "notification-details",
      component: CustomProductSettingsComponent,
    },
  ],
};
