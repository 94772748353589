import { Component, ViewChild, AfterViewInit, Input } from "@angular/core";
import { LayoutNavDropdownDirective } from "@backbase/ui-ang/layout";
import { IRoute } from "@ent/data";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "ent-navmenu-item",
  templateUrl: "./navmenu-item.component.html",
  styleUrls: ["./navmenu-item.component.scss"],
})
export class NavmenuItemComponent implements AfterViewInit {
  @Input()
  route!: IRoute;

  @ViewChild("bbLayoutNavDropdown") bbLayoutNavDropdown!: LayoutNavDropdownDirective | undefined;
  @ViewChild("ngbDropdown") subMenuDropdown!: NgbDropdown | undefined;

  isActive = new BehaviorSubject(false);
  isDropDownOpen = new BehaviorSubject(false);

  ngAfterViewInit() {
    if (this.bbLayoutNavDropdown) {
      this.isActive.next(this.bbLayoutNavDropdown.isActive);
    }
  }

  isOpenChange = (isOpen: boolean) => {
    this.isDropDownOpen.next(isOpen);
  };

  closeDropDown = () => {
    if (this.subMenuDropdown) {
      this.subMenuDropdown.close();
    }
  };
}
