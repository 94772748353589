<ent-open-share-card [heading]="card?.description" [title]="title" [subtitle]="subtitle" [iconClass]="iconClass">
  <div card-content [formGroup]="form">
    <div class="input__left-aligned mb-4" [attr.data-testid]="'deposit-amount'">
      <div>
        <label class="bb-dynamic-input__label form-label"> {{ textService.depositAmountLabel }} </label>
        <bb-amount-input-ui
          testId="deposit-amount"
          currency="USD"
          [formControlName]="amountFieldName"
          placeholder="0.00"
          [autoDecimal]="true"
          [inputClassName]="'bb-text-align-start'"
        ></bb-amount-input-ui>
        <div *ngIf="minimumDeposit" class="bb-subtitle bb-text-support mt-3">
          {{textService.depositAmountInfo+minimumDeposit}}
        </div>
        <bb-input-validation-message-ui
          [showErrors]="!!form.controls[amountFieldName]?.touched && !!errors?.[formErrors.AMOUNT]"
        >
          <span>{{ errors?.[formErrors.AMOUNT] }}</span>
        </bb-input-validation-message-ui>
      </div>
    </div>
    <ent-account-selector-form-control
      [products]="fromProducts | async"
      [selectedProduct]="selectedProduct"
      testId="deposit-from-selector"
      [label]="textService.depositFromLabel"
      [amountKey]="'availableBalance'"
      [noItemSelectedText]="textService.depositSelectAccountLabel"
      [noItemsFoundText]="textService.depositNoAccountsLabel"
      [isInvalid]="!!form.controls[accountFieldName]?.touched && !!errors?.[formErrors.FROM_ACCOUNT]"
      [error]="errors?.[formErrors.FROM_ACCOUNT]"
      (selectProduct)="onSelectProduct($event)"
    ></ent-account-selector-form-control>
  </div>
  <div footer class="footer">
    <div class="buttons-row pt-5">
      <button bbButton [color]="'link'" (click)="onBack()">
        <span>{{textService.backButtonLabel}}</span>
      </button>
      <div class="d-flex">
        <div class="pr-2">
          <button bbButton [color]="'primary'" (click)="onValidateNext()">{{textService.nextButtonLabel}}</button>
        </div>
      </div>
    </div>
  </div>
</ent-open-share-card>
