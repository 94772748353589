import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-calendar-display",
  templateUrl: "./calendar.component.html",
})
export class CalendarDisplayComponent {
  @Input()
  inputType!: string;

  @Input()
  inputString!: string;

  @Input()
  calendarLabel!: string;
}
