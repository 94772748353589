import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-page-loader",
  templateUrl: "./page-loader.component.html",
})
export class PageLoaderComponent {
  @Input()
  loadingText?: string;
}
