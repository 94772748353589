<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="px-0 flex-column align-items-start">
        <ng-container [ngSwitch]="currentStep | async">
          <ng-container *ngSwitchCase="formStates.EXPLORE">
            <button class="btn btn-link ps-0 ms-0 mb-3" (click)="navigateTo(myAccountsPath)">
              <i aria-hidden="true" class="bb-icon bb-icon-arrow-back"></i>
              <span>{{textService.backLinkLabel}}</span>
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <ent-stateful-component
      #statefulComponent
      [componentState]="componentState | async"
      [loadingText]="textService.loadingText"
      [contentTemplate]="loaded"
      [errorTemplate]="error"
    >
    </ent-stateful-component>
    <ng-template #error>
      <div class="col-md-12">
        <div class="bb-text-align-center">
          <em class="fa-regular fa-triangle-exclamation fa-4x mb-4"></em>
          <bb-header-ui headingClasses="bb-text-semi-bold" headingType="h4" [heading]="textService.errorHeaderText">
          </bb-header-ui>
          <span class="bb-text-support"> {{ textService.errorText }} </span>
        </div>
      </div>
    </ng-template>
    <ng-template #loaded>
      <ng-container [ngSwitch]="currentStep | async">
        <ng-container *ngSwitchCase="formStates.EXPLORE">
          <ent-explore-products
            [shareTypes]="shareTypes"
            [loanTypes]="loanTypes"
            [isLoading]="isLoading"
            (stateChange)="changeComponentState($event)"
            (addProduct)="addProduct($event)"
            (tabChange)="onTabChange($event)"
          ></ent-explore-products>
        </ng-container>
        <ng-container *ngSwitchCase="formStates.INFORMATION">
          <ent-product-information
            [card]="editingCard"
            [canConvertChecking]="!!checkingAccounts.length"
            (convert)="onInformationNext(productFlowCodes.BRONCOS_CONVERTION)"
            (next)="onInformationNext()"
          ></ent-product-information>
        </ng-container>
        <ng-container *ngSwitchCase="formStates.DEPOSIT">
          <ent-product-deposit
            [card]="editingCard"
            [form]="form"
            [errors]="errors"
            [title]="textService.depositTitleLabel"
            [subtitle]="textService.depositSubtitleLabel"
            [iconClass]="'fa-coin'"
            [amountFieldName]="formControlNames.DEPOSIT_AMOUNT"
            [accountFieldName]="formControlNames.DEPOSIT_ACCOUNT"
            [fromProducts]="depositFromAccounts"
            (validateNext)="onValidateNext()"
          ></ent-product-deposit>
        </ng-container>
        <ng-container *ngSwitchCase="formStates.CHECKING_ACCOUNT_SELECTION">
          <ent-membership-selector
            [card]="editingCard"
            [form]="form"
            [title]="textService.convertCheckingTitleLabel"
            [subtitle]="textService.convertCheckingSubtitleLabel"
            [iconClass]="'fa-swap-arrows'"
            [formFieldName]="formControlNames.ARRANGEMENT_ID"
            [accounts]="checkingAccounts"
          ></ent-membership-selector>
        </ng-container>
        <ng-container *ngSwitchCase="formStates.CONSIDERATIONS">
          <ng-container [ngSwitch]="currentProductFLow">
            <ent-product-considerations
              *ngSwitchCase="productFlowCodes.BRONCOS_CONVERTION"
              [considerations]="editingCard.considerations"
              [iconClass]="'fa-credit-card-front'"
              [title]="textService.considerationsTitleLabel"
              [subtitle]="textService.considerationsSubtitleLabel"
              [informationImageUrl]="editingCard.informationImageUrl"
            ></ent-product-considerations>
            <ent-product-considerations
              *ngSwitchDefault
              [iconClass]="'fa-credit-card-front'"
              [title]="textService.considerationsTitleLabel"
              [subtitle]="textService.considerationsNewBroncosSubtitleLabel"
              [informationImageUrl]="editingCard.informationImageUrl"
              [showCancelButton]="false"
            ></ent-product-considerations>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="formStates.MEMBERSHIP_SELECTION">
          <ent-membership-selector
            [card]="editingCard"
            [form]="form"
            [title]="textService.membershipTitleLabel"
            [subtitle]="textService.membershipSubtitleLabel"
            [iconClass]="'fa-coin'"
            formFieldName="accountNumber"
            [accounts]="accountsList"
            [hideCancelButton]="true"
            [supportText]="textService.membershipModalBodyLabel"
          ></ent-membership-selector>
        </ng-container>
        <ng-container *ngSwitchCase="formStates.REVIEW">
          <ent-product-review
            [card]="editingCard"
            [form]="form"
            [accountsList]="checkingAccounts"
            [reviewItems]="reviewItems"
            [isSaving]="isSaving"
            [isCheckingConversion]="currentProductFLow === productFlowCodes.BRONCOS_CONVERTION"
            (next)="saveProduct()"
          ></ent-product-review>
        </ng-container>
        <ng-container *ngSwitchCase="formStates.SUCCESS">
          <div class="row justify-content-center">
            <ent-product-success
              class="col-md-8"
              [card]="editingCard"
              [productFlow]="currentProductFLow"
            ></ent-product-success></div
        ></ng-container>
        <ng-container *ngSwitchCase="formStates.ERROR">
          <div class="row justify-content-center">
            <ent-product-error class="col-md-8" [errorKey]="errorKey" (retryClick)="saveProduct()"></ent-product-error>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>
</div>
