import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FrecuencySelectorTextService {
  frequencySelectorLabel = $localize`:@@frequency-selector.frequencySelectorLabel:Frequency`;
  frequencySelectorDaily = $localize`:@@frequency-selector.frequencySelectorLabel.Daily:Daily`;
  frequencySelectorWeekly = $localize`:@@frequency-selector.frequencySelectorLabel.Weekly:Weekly`;
  frequencySelectorMonthly = $localize`:@@frequency-selector.frequencySelectorLabel.Monthly:Monthly`;
  weeklySelectorLabel = $localize`:@@frequency-selector.weeklySelectorLabel:Day of the Week`;
  monthlySelectorLabel = $localize`:@@frequency-selector.monthlySelectorLabel:Day of the Month`;
  timeSelectorLabel = $localize`:@@frequency-selector.timeSelectorLabel:Time`;
  dayOfMonthErrorText = $localize`:@@frequency-selector.dayOfMonthErrorText:Please enter a value between 1 and 31`;
  timeErrorText = $localize`:@@frequency-selector.timeErrorText:Please enter whole hours i.e. 7:00`;
  timeSupportText = $localize`:@@frequency-selector.timeSupportText:Enter whole hours i.e. 7:00`;
}
