import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CallCenterPassword } from "../../models";

@Injectable({ providedIn: "root" })
export class CallCenterPasswordHttpService {
  private url = "/api/call-center-password";

  httpOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getCallCenterPassword(): Promise<CallCenterPassword> {
    return new Promise((resolve, reject) => {
      this.http.get<CallCenterPassword>(this.url).subscribe({
        next: resolve,
        error: reject,
      });
    });
  }

  setCallCenterPassword(callCenterPassword: CallCenterPassword) {
    return new Promise((resolve, reject) => {
      this.http.post<CallCenterPassword>(this.url, callCenterPassword, this.httpOptions).subscribe({
        next: resolve,
        error: reject,
      });
    });
  }
}
