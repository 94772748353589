<div class="bb-heading-widget px-0 pt-4 pb-5 flex-column-reverse align-items-start">
  <button class="btn btn-link ps-0 ms-0 mb-3" (click)="navigateBack()">
    <i aria-hidden="true" class="bb-icon bb-icon-arrow-back"></i>
    <span>{{textService.backButtonLabel}}</span>
  </button>
  <bb-header-ui [heading]="textService.headingLabel" headingType="h1"> </bb-header-ui>
</div>
<ent-stateful-component
  #statefulComponent
  [componentState]="componentState | async"
  [loadingText]="textService.loadingText"
  [errorHeaderText]="textService.loadingErrorHeader"
  [errorText]="textService.loadingErrorText"
  [contentTemplate]="loaded"
>
</ent-stateful-component>
<ng-template #loaded>
  <ng-container *ngFor="let preference of alertSettings?.notificationPreferences">
    <ent-notification-card
      [alertSetting]="preference"
      [entityId]="entityId"
      [isSaving]="isSaving"
      (cardSubmit)="submit($event)"
    ></ent-notification-card>
  </ng-container>
</ng-template>
