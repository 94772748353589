import { Component, EventEmitter, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MoneyInsightHttpService } from "@ent/data";
import { MoneyInsightsTextService } from "../../services/money-insights.text.service";

@Component({
  selector: "ent-money-insights-enrollment",
  templateUrl: "./money-insights-enrollment.component.html",
})
export class MoneyInsightsEnrollmentComponent {
  isLoading = false;
  @Output() userEnrollEvent = new EventEmitter();
  vForm: FormGroup | undefined = this.formBuilder.group({
    termsConditions: [false, Validators.compose([Validators.requiredTrue])],
  });
  constructor(
    public readonly textService: MoneyInsightsTextService,
    private readonly formBuilder: FormBuilder,
    private service: MoneyInsightHttpService,
  ) {}

  async enrollUser() {
    this.vForm.markAllAsTouched();
    if (this.vForm.valid) {
      this.isLoading = true;
      try {
        const response = await this.service.enrollMoneyInsightUser();
        if (response) {
          this.userEnrollEvent.emit(response);
        }
      } catch (error) {
        this.userEnrollEvent.emit(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
