<ent-open-share-card [heading]="card?.description" [iconClass]="iconClass" [title]="title" [subtitle]="subtitle">
  <div card-content>
    <div *ngIf="informationImageUrl" class="d-flex justify-content-center">
      <img class="img-fluid col-lg-6 col-sm-12" [src]="informationImageUrl" alt="information image" />
    </div>
    <div *ngIf="considerations.length" class="card warning-card">
      <div class="card-body">
        <div *ngFor="let item of considerations" class="d-flex align-items-center my-3">
          <i aria-hidden="true" class="bb-icon sub-icon ent-fa-solid align-self-start" [ngClass]="item.iconClass"></i>
          <div class="item-information">
            <div class="item-header">{{item.title}}</div>
            <div class="bb-subtitle bb-text-support">{{item.subtitle}}</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!considerations.length">
      <p class="bb-subtitle text-center">{{textService.considerationsCourtesyOverdraftText}}</p>
      <p class="bb-subtitle text-center">
        <ent-support-text-modal
          class="bb-text-support"
          [modalTitle]="textService.considerationsOverdraftText"
          [modalText]="textService.considerationsOverdraftModalText"
          [closeButtonText]="textService.modalCloseButtonLabel"
        >
          {{textService.considerationsOverdraftText}}
        </ent-support-text-modal>
      </p>
      <p class="bb-subtitle text-center">
        <ent-support-text-modal
          class="bb-text-support"
          [modalTitle]="textService.considerationsCourtesyText"
          [modalText]="textService.considerationsCourtesyModalText"
          [closeButtonText]="textService.modalCloseButtonLabel"
        >
          {{textService.considerationsCourtesyText}}
        </ent-support-text-modal>
      </p>
    </div>
  </div>
  <div footer class="footer">
    <div class="buttons-row pt-5">
      <button bbButton [color]="'link'" (click)="onBack()">
        <span>{{textService.backButtonLabel}}</span>
      </button>
      <div class="d-flex">
        <div *ngIf="showCancelButton" class="pr-2">
          <button bbButton [color]="'secondary'" (click)="onCancel()">{{textService.cancelButtonLabel }}</button>
        </div>
        <div class="pr-2">
          <button bbButton [color]="'primary'" (click)="onNext()">{{textService.nextButtonLabel}}</button>
        </div>
      </div>
    </div>
  </div>
</ent-open-share-card>
