import * as yup from "yup";
import {
  ARRANGEMENT_TYPE,
  END_TYPE,
  FREQUENCY_SELECTOR,
  getMinimumEndDateFromStartDate,
  OCCURRENCE,
  PAYMENT_OPTION,
} from "@ent/data";
import { DateTime } from "luxon";
import { PAYMENTS_ERROR_KEY } from "./payment-errors";
import { IFormModel } from "./form-model";

export interface PaymentFormModel extends IFormModel {
  paymentOption: PAYMENT_OPTION;
}

const amountMinimumValidation = (
  arrangementType: ARRANGEMENT_TYPE,
  paymentOption: PAYMENT_OPTION,
  monthlyDueAmount: number,
  schema: yup.NumberSchema,
) => {
  if (arrangementType === ARRANGEMENT_TYPE.MORTGAGE && paymentOption === PAYMENT_OPTION.OTHER_AMOUNT) {
    return schema.min(monthlyDueAmount, PAYMENTS_ERROR_KEY.AMOUNT_PAYMENT_MIN);
  }
  return schema.min(0.01, PAYMENTS_ERROR_KEY.AMOUNT_REQUIRED);
};

const paymentOptionValidation = (
  toAccountArrangementType: ARRANGEMENT_TYPE,
  isPastDue: boolean,
  isInGracePeriod: boolean,
  schema: yup.StringSchema,
) => {
  let paymentOptions = [PAYMENT_OPTION.OTHER_AMOUNT];
  if (isPastDue && toAccountArrangementType !== ARRANGEMENT_TYPE.MORTGAGE) {
    paymentOptions = [...paymentOptions, PAYMENT_OPTION.PAST_DUE, PAYMENT_OPTION.MONTHLY_DUE];
  }
  if (isPastDue && toAccountArrangementType === ARRANGEMENT_TYPE.MORTGAGE && !isInGracePeriod) {
    paymentOptions = [...paymentOptions, PAYMENT_OPTION.PAST_DUE];
  }
  if (isPastDue && toAccountArrangementType === ARRANGEMENT_TYPE.MORTGAGE && isInGracePeriod) {
    paymentOptions = [...paymentOptions, PAYMENT_OPTION.PRINCIPAL, PAYMENT_OPTION.MONTHLY_DUE];
  }
  if (!isPastDue) {
    paymentOptions = [...paymentOptions, PAYMENT_OPTION.PRINCIPAL, PAYMENT_OPTION.MONTHLY_DUE];
  }
  if (toAccountArrangementType !== ARRANGEMENT_TYPE.MORTGAGE) {
    paymentOptions = [...paymentOptions, PAYMENT_OPTION.PAY_OFF];
  }
  return schema.oneOf(paymentOptions, PAYMENTS_ERROR_KEY.PAYMENT_OPTION_REQUIRED);
};

export const paymentsFormSchema = yup.object().shape({
  fromAccountId: yup.string().required(PAYMENTS_ERROR_KEY.FROM_REQUIRED),
  fromAccountBalance: yup
    .number()
    .nullable()
    .when(["toAccountId", "paymentOptionDueAmount", "paymentOption", "occurrence"], (...rest) => {
      const [toAccountId, paymentOptionDueAmount, paymentOption, occurrence, schema] = rest;
      if (occurrence !== OCCURRENCE.NOW) {
        return schema.nullable();
      }
      if (
        !!toAccountId &&
        [PAYMENT_OPTION.MONTHLY_DUE, PAYMENT_OPTION.PAST_DUE, PAYMENT_OPTION.PAY_OFF].includes(paymentOption)
      ) {
        return schema.min(paymentOptionDueAmount, PAYMENTS_ERROR_KEY.FROM_INSUFFICIENT_FUNDS);
      }
      return schema.min(1, PAYMENTS_ERROR_KEY.FROM_INSUFFICIENT_FUNDS);
    }),
  toAccountId: yup.string().required(PAYMENTS_ERROR_KEY.TO_REQUIRED),
  toAccountBalance: yup.number().nullable(),
  toAccountArrangementType: yup.string().nullable(),
  isPastDue: yup.boolean().nullable(),
  isInGracePeriod: yup.boolean().nullable(),
  paymentOptionDueAmount: yup.number().nullable(),
  monthlyDueAmount: yup.number().nullable(),
  paymentOption: yup
    .string()
    .required(PAYMENTS_ERROR_KEY.PAYMENT_OPTION_REQUIRED)
    .when(["toAccountArrangementType", "isPastDue", "isInGracePeriod"], (...rest) => {
      const [toAccountArrangementType, isPastDue, isInGracePeriod, schema] = rest;
      return paymentOptionValidation(toAccountArrangementType, isPastDue, isInGracePeriod, schema);
    }),
  amount: yup
    .number()
    .when(["toAccountArrangementType", "paymentOption", "monthlyDueAmount"], (...rest) =>
      amountMinimumValidation(rest[0], rest[1], rest[2], rest[3]),
    )
    .when(["fromAccountBalance", "toAccountBalance", "occurrence"], (...rest) => {
      const [fromAccountBalance, toAccountBalance, occurrence, schema] = rest;
      if (occurrence !== OCCURRENCE.NOW) {
        return schema.max(Number.MAX_SAFE_INTEGER, PAYMENTS_ERROR_KEY.AMOUNT_MAX);
      }
      if (fromAccountBalance <= toAccountBalance) {
        return schema.max(fromAccountBalance, PAYMENTS_ERROR_KEY.AMOUNT_FROM_MAX);
      }
      return schema.max(toAccountBalance, PAYMENTS_ERROR_KEY.AMOUNT_TO_MAX);
    })
    .when("paymentOption", {
      is: (paymentOption: PAYMENT_OPTION) =>
        [PAYMENT_OPTION.OTHER_AMOUNT, PAYMENT_OPTION.PRINCIPAL].includes(paymentOption),
      then: yup.number().required(PAYMENTS_ERROR_KEY.AMOUNT_REQUIRED),
    }),
  occurrence: yup
    .string()
    .required(PAYMENTS_ERROR_KEY.OCCURRENCE_REQUIRED)
    .oneOf([OCCURRENCE.NOW, OCCURRENCE.LATER, OCCURRENCE.RECURRING], PAYMENTS_ERROR_KEY.OCCURRENCE_REQUIRED),
  executionDate: yup
    .date()
    .required(PAYMENTS_ERROR_KEY.EXECUTION_DATE_REQUIRED)
    .when(["occurrence", "minExecutionDate"], (...rest) => {
      const [occurrence, minExecutionDate, schema] = rest;
      if (occurrence === OCCURRENCE.NOW) {
        return schema.min(DateTime.now().startOf("day").toJSDate(), PAYMENTS_ERROR_KEY.EXECUTION_DATE_MIN);
      }
      return schema.min(minExecutionDate, PAYMENTS_ERROR_KEY.EXECUTION_DATE_MIN);
    }),
  frequency: yup.string().when("occurrence", {
    is: OCCURRENCE.RECURRING,
    then: yup
      .string()
      .required(PAYMENTS_ERROR_KEY.FREQUENCY_REQUIRED)
      .oneOf(
        [
          FREQUENCY_SELECTOR.WEEKLY,
          FREQUENCY_SELECTOR.BIWEEKLY,
          FREQUENCY_SELECTOR.MONTHLY,
          FREQUENCY_SELECTOR.BIMONTHLY,
          FREQUENCY_SELECTOR.QUARTERLY,
          FREQUENCY_SELECTOR.SEMIANNUALLY,
          FREQUENCY_SELECTOR.ANNUALLY,
        ],
        PAYMENTS_ERROR_KEY.FREQUENCY_REQUIRED,
      ),
    otherwise: yup
      .string()
      .required(PAYMENTS_ERROR_KEY.FREQUENCY_REQUIRED)
      .oneOf([FREQUENCY_SELECTOR.ONCE], PAYMENTS_ERROR_KEY.FREQUENCY_REQUIRED),
  }),
  endType: yup.string().when("occurrence", {
    is: OCCURRENCE.RECURRING,
    then: yup
      .string()
      .oneOf([END_TYPE.AFTER, END_TYPE.NEVER, END_TYPE.ON], PAYMENTS_ERROR_KEY.END_TYPE_REQUIRED)
      .required(PAYMENTS_ERROR_KEY.END_TYPE_REQUIRED),
    otherwise: yup.string().optional().nullable(),
  }),
  endDate: yup.date().when(["occurrence", "endType", "frequency", "executionDate"], (...rest) => {
    const [occurrence, endType, frequency, executionDate, schema] = rest;
    if (occurrence === OCCURRENCE.RECURRING && endType === END_TYPE.ON) {
      const luxonExecutionDate = DateTime.fromJSDate(executionDate);
      const minDate = getMinimumEndDateFromStartDate(
        frequency,
        luxonExecutionDate.isValid ? luxonExecutionDate.toISO() : undefined,
      );
      if (minDate) {
        return schema.required(PAYMENTS_ERROR_KEY.END_DATE_REQUIRED).min(minDate, PAYMENTS_ERROR_KEY.END_DATE_MIN);
      }
      return schema.required(PAYMENTS_ERROR_KEY.END_DATE_REQUIRED);
    }
    return schema.optional().nullable();
  }),
  repeat: yup.number().when(["occurrence", "endType"], {
    is: (occurrence: OCCURRENCE, endType: END_TYPE) =>
      occurrence === OCCURRENCE.RECURRING && endType === END_TYPE.AFTER,
    then: yup.number().required(PAYMENTS_ERROR_KEY.REPEAT_REQUIRED).min(2, PAYMENTS_ERROR_KEY.REPEAT_REQUIRED),
    otherwise: yup.number().optional().nullable(),
  }),
});
