import { Component, Input } from "@angular/core";

@Component({
  selector: "ent-support-text-modal",
  templateUrl: "./support-text-modal.component.html",
  styleUrls: ["./support-text-modal.component.scss"],
})
export class SupportTextModalComponent {
  @Input() modalText: string;
  @Input() modalTitle: string;
  @Input() closeButtonText: string;
  @Input() iconClass? = "fa-circle-question";
  isModalOpen = false;

  toggleModal() {
    this.isModalOpen = !this.isModalOpen;
  }
}
