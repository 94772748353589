<bb-header-ui
  headingType="h1"
  [heading]="textService.enrollmentTitleText"
  headingClasses="bb-heading-widget__heading my-4"
></bb-header-ui>
<div class="card card-body">
  <p>{{textService.enrollmentSubtitleText}}</p>
  <p>{{textService.enrollmentDescriptionText}}</p>
  <p>
    <a
      class="font-weight-bold"
      href="https://www.ent.com/personal/online-and-mobile/ents-money-insight"
      target="_blank"
      rel="noopener"
      >{{textService.enrollmentLearnText}}</a
    >
  </p>
  <ngb-carousel>
    <ng-template ngbSlide>
      <img class="img-fluid" src="/assets/ent-mx-user-enrollment/mx-carousel-1.png" alt="budgets slide" />
    </ng-template>
    <ng-template ngbSlide>
      <img class="img-fluid" src="/assets/ent-mx-user-enrollment/mx-carousel-2.png" alt="accounts slide" />
    </ng-template>
    <ng-template ngbSlide>
      <img class="img-fluid" src="/assets/ent-mx-user-enrollment/mx-carousel-3.png" alt="add accounts slide" />
    </ng-template>
    <ng-template ngbSlide>
      <img class="img-fluid" src="/assets/ent-mx-user-enrollment/mx-carousel-4.png" alt="details slide" />
    </ng-template>
  </ngb-carousel>
  <form [formGroup]="vForm">
    <p>
      <bb-input-checkbox-ui
        formControlName="termsConditions"
        aria-labelledby="termsLabel"
        [aria-describedby]="'name-errors'"
      >
        <span id="termsLabel">
          {{textService.enrollmentTermsText}}
          <a
            class="font-weight-bold"
            href="https://www.ent.com/MoneyInsightTermsAndConditions"
            target="_blank"
            rel="noopener"
            >{{textService.enrollmentTermsLinkText}}
          </a>
        </span>
      </bb-input-checkbox-ui>
      <bb-input-validation-message-ui
        id="name-errors"
        [showErrors]="vForm.controls.termsConditions.errors && vForm.controls.termsConditions.touched"
      >
        <span> {{textService.enrollmentValidationErrorText}} </span>
      </bb-input-validation-message-ui>
    </p>
    <div class="btn-container bb-dynamic-input bb-button-bar mt-4">
      <bb-load-button-ui
        id="continueButton"
        type="submit"
        class="bb-button-bar__button"
        [disabled]="isLoading"
        [isLoading]="isLoading"
        (click)="enrollUser()"
      >
        {{textService.enrollmentContinueText}}
      </bb-load-button-ui>
    </div>
  </form>
</div>
