import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "translationFunctions",
})
export class TranslationFunctionsPipe implements PipeTransform {
  transform<T>(value: T, property: keyof T, ...args: any[]): string {
    return (value[property] as any)(...args);
  }
}
