<ng-container [ngSwitch]="componentState">
  <ng-container *ngSwitchCase="componentStates.LOADING">
    <ng-container *ngTemplateOutlet="loadingTemplate ? loadingTemplate : defaultLoading"> </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="componentStates.LOADED">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="componentStates.ERROR">
    <ng-container *ngTemplateOutlet="errorTemplate ? errorTemplate : defaultError"> </ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultLoading><ent-page-loader [loadingText]="loadingText"></ent-page-loader></ng-template>
<ng-template #defaultError>
  <ent-page-error [errorText]="errorText" [errorHeaderText]="errorHeaderText"></ent-page-error
></ng-template>
