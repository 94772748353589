<bb-modal-ui [isOpen]="isOpen" (cancel)="close.emit()" id="test__confirmation-modal">
  <bb-modal-header-ui [title]="title"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <ng-container *ngTemplateOutlet="bodyTemplate ? bodyTemplate : defaultBody"> </ng-container>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui>
    <ng-template bbCustomModalFooter>
      <ng-container *ngTemplateOutlet="footerTemplate ? footerTemplate : defaultFooter"> </ng-container>
    </ng-template>
  </bb-modal-footer-ui>
</bb-modal-ui>

<ng-template #defaultBody> {{ confirmMessage }} </ng-template>
<ng-template #defaultFooter>
  <div class="bb-button-bar">
    <button
      id="test__confirmation-modal-no-btn"
      bbButton
      color="danger"
      class="bb-button-bar__button"
      (click)="no.emit()"
    >
      {{ noBtnText }}
    </button>
    <button
      id="test__confirmation-modal-yes-btn"
      bbButton
      color="secondary"
      class="bb-button-bar__button"
      (click)="yes.emit()"
    >
      {{ yesBtnText }}
    </button>
  </div>
</ng-template>
