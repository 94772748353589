<div class="bb-block bb-block--md bb-stack bb-stack--align-top" id="test__scheduling-container">
  <div class="bb-stack__item bb-stack__item--spacing-sm">
    <em class="fa-regular fa-calendar-day fa-fw"></em>
  </div>
  <div class="bb-stack__item">
    <div class="bb-text-bold bb-block bb-block--xs">{{ calendarLabel }}</div>
    <div>
      <span class="bb-text-support bb-subtitle" *ngIf="inputType === 'string'"> {{ inputString }} </span>
      <span class="bb-text-support bb-subtitle" *ngIf="inputType === 'date'">
        {{ inputString | date: 'MMMM dd, YYYY' }}
      </span>
    </div>
  </div>
</div>
