<bb-modal-ui [isOpen]="true" [modalOptions]="modalOptions" (cancel)="closeDialog()" #modalRef>
  <bb-modal-header-ui [title]="textService.headerActivateTitle" (close)="modalRef.dismissModal()"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <ent-stateful-component
        #statefulComponent
        [componentState]="componentState | async"
        [loadingText]="textService.loadingText"
        [errorTemplate]="errorTemplate"
        [contentTemplate]="loadedTemplate"
      >
      </ent-stateful-component>
      <ng-template #loadedTemplate>
        <ng-container *ngIf="accountMetadata; then successScreen; else mxVerifyWidget"></ng-container>
        <ng-template #mxVerifyWidget>
          <ent-mx-connect-widget
            [url]="url"
            [title]="textService.headerTitle"
            (mxMessage)="handleMessage($event)"
          ></ent-mx-connect-widget>
        </ng-template>
        <ng-template #successScreen>
          <ent-page-success
            *ngIf="successText"
            [successText]="successText"
            [successHeaderText]="textService.successHeader"
            [cancelButtonText]="textService.cancelButton"
            (clickCancel)="closeDialog()"
          ></ent-page-success>
        </ng-template>
      </ng-template>
      <ng-template #errorTemplate>
        <ng-container *ngIf="accountMetadata; then accountConnectedError; else getUrlError"></ng-container>
        <ng-template #getUrlError>
          <ent-page-retry
            [errorText]="textService.errorSubText"
            [errorHeaderText]="textService.errorHeader"
            [buttonText]="textService.errorButton"
            (clickRetry)="getMXConnectUrl()"
          ></ent-page-retry>
        </ng-template>
        <ng-template #accountConnectedError>
          <ent-page-retry
            [errorText]="textService.verifyErrorSubText"
            [errorHeaderText]="textService.connectErrorHeader"
            [buttonText]="textService.connectErrorButton"
            (clickRetry)="verifyAccount()"
          ></ent-page-retry>
        </ng-template>
      </ng-template>
    </ng-template>
  </bb-modal-body-ui>
  <ent-confirmation-modal
    [isOpen]="showConfirmationDialog"
    [title]="textService.confirmationTitle"
    [confirmMessage]="textService.confirmationVerifyMessage"
    [yesBtnText]="textService.confirmationYesButton"
    [noBtnText]="textService.confirmationNoButton"
    (no)="toggleConfirmationDialog()"
    (yes)="closeDialog()"
  ></ent-confirmation-modal>
</bb-modal-ui>
