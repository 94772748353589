import { DetailedExternalAccount } from "@backbase/payment-order-a2a-http-ang";
import { FlatProductItem } from "./flat-product-item";

export const convertExternalProductToFlatProductItem = (
  externalProductItem: DetailedExternalAccount,
): FlatProductItem => {
  // eslint-disable-next-line no-extra-boolean-cast
  const displayName = !!externalProductItem.accountNickName
    ? externalProductItem.accountNickName
    : externalProductItem.bankName;
  return {
    id: externalProductItem.externalAccountId,
    name: externalProductItem.accountNickName,
    displayName,
    currency: "USD",
    minimumPayment: 0,
    minimumPaymentDueDate: "",
    productNumber: externalProductItem.accountNumber,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BBAN: externalProductItem.maskedAccountNumber,
    isExternal: true,
  };
};
