<div class="card card-body">
  <fieldset class="bb-fieldset omni-payment-adapted-review">
    <legend class="bb-label bb-block bb-block--lg">{{ transferReviewTextService.title }}</legend>
    <ent-payment-error-banner
      *ngIf="isSubmitError"
      [errorCode]="errorCode | async"
      [defaultErrorMessage]="transferReviewTextService.submitError"
    ></ent-payment-error-banner>
    <div class="bb-item-log bb-block bb-block--lg">
      <ent-from-record-display
        [fromLabel]="transferReviewTextService.fromLabel"
        [name]="formModel.fromAccount.displayName"
        [accountNumber]="(formModel.fromAccount | stringOrDefault: 'BBAN':'')!"
      ></ent-from-record-display>
      <ent-to-record-display
        [toLabel]="transferReviewTextService.toLabel"
        [name]="formModel.toAccount.displayName"
        [accountNumber]="(formModel.toAccount | stringOrDefault: 'BBAN':'')!"
      ></ent-to-record-display>
    </div>
    <ent-amount-display
      [amount]="'' + formModel.amount"
      [amountLabel]="transferReviewTextService.amount"
    ></ent-amount-display>
    <div [ngSwitch]="occurrence">
      <ng-container *ngSwitchCase="occurrencesEnum.NOW">
        <ng-container *ngTemplateOutlet="nowOptions"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="occurrencesEnum.LATER">
        <ng-container *ngTemplateOutlet="laterOptions"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="occurrencesEnum.RECURRING">
        <ng-container *ngTemplateOutlet="recurringOptions"></ng-container>
      </ng-container>
    </div>

    <ent-memo-display
      *ngIf="formModel.memo"
      [memoLabel]="transferReviewTextService.memo"
      [memo]="formModel.memo"
    ></ent-memo-display>
  </fieldset>
  <div class="bb-button-bar bb-button-bar--reverse">
    <bb-load-button-ui
      type="submit"
      class="bb-button-bar__button"
      data-role="submit-button"
      (click)="onSubmit()"
      [disabled]="isLoading"
      [isLoading]="isLoading"
    >
      {{ transferReviewTextService.confirm }}
    </bb-load-button-ui>
    <button
      class="bb-button-bar__button"
      bbButton
      color="secondary"
      data-role="edit-button"
      (click)="onEdit()"
      [disabled]="isLoading"
    >
      {{ transferReviewTextService.edit }}
    </button>
    <button
      bbButton
      class="bb-button-bar__button bb-button-bar__button--across"
      color="link"
      data-role="cancel-button"
      (click)="openDiscardModal()"
      [disabled]="isLoading"
    >
      {{ transferReviewTextService.cancel }}
    </button>
  </div>
</div>

<ent-confirmation-modal
  [isOpen]="(isDiscardModalOpen | async)!"
  [title]="transferReviewTextService.discardTitle"
  [confirmMessage]="transferReviewTextService.discardConfirm"
  [noBtnText]="transferReviewTextService.discardYes"
  [yesBtnText]="transferReviewTextService.discardNo"
  (close)="closeDiscardModal()"
  (no)="onCancel()"
  (yes)="closeDiscardModal()"
></ent-confirmation-modal>
<ng-template #nowOptions>
  <ent-calendar-display
    [calendarLabel]="transferReviewTextService.calendarDate"
    [inputType]="'string'"
    [inputString]="transferReviewTextService.calendarTodayText"
  ></ent-calendar-display>
</ng-template>
<ng-template #laterOptions>
  <ent-calendar-display
    [calendarLabel]="transferReviewTextService.calendarDate"
    [inputType]="'date'"
    [inputString]="formModel.executionDate"
  ></ent-calendar-display>
</ng-template>
<ng-template #recurringOptions>
  <ent-frequency-display
    [frequencyLabel]="transferReviewTextService.frequency"
    [frequency]="frequencyTypeText"
  ></ent-frequency-display>
  <ent-calendar-display
    [calendarLabel]="transferReviewTextService.calendarStartDate"
    [inputType]="'date'"
    [inputString]="formModel.executionDate"
  ></ent-calendar-display>
  <ng-container [ngSwitch]="endType">
    <ng-container *ngSwitchCase="'ON'">
      <ent-calendar-display
        [calendarLabel]="transferReviewTextService.calendarEndOn"
        [inputType]="'date'"
        [inputString]="formModel.endDate ?? ''"
      ></ent-calendar-display>
    </ng-container>
    <ng-container *ngSwitchCase="'AFTER'">
      <ent-calendar-display
        [calendarLabel]="transferReviewTextService.calendarEndAfter"
        [inputType]="'string'"
        [inputString]="transferReviewTextService | translationFunctions: 'calendarOccurrencesText':formModel.repeat"
      ></ent-calendar-display>
    </ng-container>
    <ng-container *ngSwitchCase="'NEVER'">
      <ent-calendar-display
        [calendarLabel]="transferReviewTextService.calendarEndNever"
        [inputType]="'string'"
        [inputString]="transferReviewTextService.calendarNeverEndsText"
      ></ent-calendar-display>
    </ng-container>
  </ng-container>
</ng-template>
