import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "insertValue",
  pure: true,
})
export class InsertValuePipe implements PipeTransform {
  transform(value: string, rate: string): string {
    return value.replace(/{\$.+}/g, rate);
  }
}
