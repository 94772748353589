/* eslint-disable @typescript-eslint/naming-convention */
import { Routes } from "@angular/router";
import { TransactionDetailsComponent, TransactionsListComponent } from "@backbase/accounts-transactions-journey-ang";
import type { LoanInfoDataConfig } from "@backbase/internal-loans-shared-util";
import {
  LoanDetailsWrapperComponent,
  loansConfigurationDefault,
  LoanDefaultViewType,
  Loan,
  LoanItemCustomBase,
} from "@backbase/loans-retail-journey";
import { LoanInfoConfigWrapperComponent } from "@backbase/internal-loans-retail-feature-details";
import { loanInfoMortgageDataConfig } from "./services/mortgage-info.config";
import { loanInfoTermLoanLineOfCreditDataConfig } from "./services/term-loan-info.config";

export const tabTitles = {
  transactions: () =>
    $localize`:Loan details transactions tab title@@loans.details.tab.transactions.title:Transactions`,
  statements: () =>
    $localize`:Loan details account statements tab title@@loans.details.tab.statements.title:Statements`,
  loanInfo: () => $localize`:Loan details info tab title@@loans.details.tab.loan-info.title:Loan info`,
  paymentSchedule: () =>
    $localize`:Loan details payment schedule tab title@@loans.details.tab.payment-schedule.title:Payment schedule`,
};

export const loansOverrideRoutes: Routes = [
  {
    path: ":selectedAccount",
    component: LoanDetailsWrapperComponent,
    children: [
      {
        path: "",
        redirectTo: "transactions",
        pathMatch: "full",
      },
      {
        path: "transactions",
        component: TransactionsListComponent,
        data: { titleFn: tabTitles.transactions },
        children: [
          {
            path: "detail",
            component: TransactionDetailsComponent,
          },
        ],
      },
      {
        path: "list",
        data: { titleFn: tabTitles.transactions },
        redirectTo: "transactions",
        pathMatch: "full",
      },
      {
        path: "info",
        component: LoanInfoConfigWrapperComponent,
        data: { titleFn: tabTitles.loanInfo },
      },
    ],
  },
];

const loanInfoDataConfig = ({ viewType }): LoanInfoDataConfig<Loan, LoanItemCustomBase<Loan>> => {
  switch (viewType) {
    case LoanDefaultViewType.Mortgage:
      return loanInfoMortgageDataConfig;
    case LoanDefaultViewType.TermLoan:
    case LoanDefaultViewType.LineOfCredit:
      return loanInfoTermLoanLineOfCreditDataConfig;
    default:
      throw new Error(`[bb-loans]: "${viewType}" view type is not configured`);
  }
};

export const loansConfigurationOverride: typeof loansConfigurationDefault = {
  ...loansConfigurationDefault,
  transaction: {
    ...loansConfigurationDefault.transaction,
    enableDisputeAndInquiry: false,
  },
  loanTypeMapping: {
    LineOfCredit: {
      viewType: LoanDefaultViewType.LineOfCredit,
      typeAlias: $localize`:Loan line of credit loan type alias@@loans.type.line-of-credit.alias:Line Of Credit`,
    },
    Mortgage: {
      viewType: LoanDefaultViewType.Mortgage,
      typeAlias: $localize`:Loan mortgage loan type alias@@loans.type.mortgage.alias:Mortgage Loan`,
    },
    TermLoan: {
      viewType: LoanDefaultViewType.TermLoan,
      typeAlias: $localize`:Loan term loan type alias@@loans.type.term.alias:Term Loan`,
    },
  },
  loanInfoView: {
    Mortgage: loanInfoDataConfig({ viewType: LoanDefaultViewType.Mortgage }),
    TermLoan: loanInfoDataConfig({ viewType: LoanDefaultViewType.TermLoan }),
    LineOfCredit: loanInfoDataConfig({ viewType: LoanDefaultViewType.LineOfCredit }),
  },
};
