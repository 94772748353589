<div
  ngbDropdownMenu
  class="bb-layout__horizontal-nav-submenu"
  role="region"
  tabindex="-1"
  aria-labelledby="bb-menu-header-button-1"
>
  <button class="bb-layout__horizontal-nav-submenu-header" (click)="closeSubMenu.emit()">
    <em class="bb-layout__horizontal-nav-submenu--close fa-regular fa-chevron-left"></em>
    <span class="bb-layout__horizontal-nav-submenu--text">{{parentRoute.subMenuLabel}}</span>
  </button>
  <ul class="bb-layout__horizontal-nav-column">
    <ng-container *ngFor="let route of parentRoute.subRoutes">
      <li *bbIfEntitlements="route.permissionRequired">
        <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
        <ng-container *ngIf="isLinkAbsolute(route.link)">
          <a [href]="route.link" class="bb-layout__horizontal-nav-submenu-link" target="_blank">
            <div class="bb-layout__bb-horizontal-nav-submenu-item-label">
              <div class="bb-layout__bb-horizontal-nav-submenu-title">{{route.label}}</div>
            </div>
          </a>
        </ng-container>
        <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
        <ng-container *ngIf="!isLinkAbsolute(route.link)">
          <a
            class="bb-layout__horizontal-nav-submenu-link"
            ngbDropdownItem
            routerLinkActive="bb-layout__horizontal-nav-submenu-link--active"
            #routeRouterLink="routerLinkActive"
            [routerLink]="route.link"
            [attr.aria-current]="routeRouterLink.isActive ? 'page' : undefined"
          >
            <div class="bb-layout__bb-horizontal-nav-submenu-item-label">
              <div class="bb-layout__bb-horizontal-nav-submenu-title">{{route.label}}</div>
            </div>
          </a>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</div>
