import { Router } from "@angular/router";
import { BehaviorSubject, combineLatest } from "rxjs";
import { DateTime } from "luxon";
import {
  InstructionPriority,
  InvolvedPartyRole,
  PaymentOrdersPost,
  PaymentOrdersPostResponse,
  PostPaymentOrdersRequestParams,
  PostValidateRequestParams,
} from "@backbase/payment-order-http-ang";
import {
  ArrangementsHTTPService,
  FORM_STATE,
  LoadingService,
  LOADING_KEYS,
  PaymentOrderHTTPService,
  PRODUCT_KINDS,
  COMPONENT_STATE,
} from "@ent/data";
import { NotificationService } from "@backbase/ui-ang/notification";
import { IFormModel } from "../../models/form-model";
import { getSchedule } from "../../models/helpers";
import { APIErrorTextService } from "../../services/api-error.text.service";

export abstract class BaseWrapperComponent {
  formState = FORM_STATE.EDITING;
  formStates = FORM_STATE;
  componentState = COMPONENT_STATE.LOADING;
  isSubmitError = new BehaviorSubject<boolean>(false);
  isLoading = false;
  formModel: IFormModel;
  paymentOrderPostResponse: PaymentOrdersPostResponse;

  today = DateTime.now().startOf("day");

  constructor(
    protected readonly router: Router,
    protected readonly loadingService: LoadingService,
    protected readonly paymentOrderHTTPService: PaymentOrderHTTPService,
    protected readonly arrangementHTTPService: ArrangementsHTTPService,
    protected readonly notificationService: NotificationService,
    protected readonly apiErrorTextService: APIErrorTextService,
  ) {}

  abstract getInitialFormModel: () => IFormModel;

  initialize = (): void => {
    this.formModel = this.getInitialFormModel();
    this.formState = FORM_STATE.EDITING;
    combineLatest([
      this.loadingService.isLoading(LOADING_KEYS.INITIAL_LOADING),
      this.loadingService.isLoading(LOADING_KEYS.VALIDATE_LOADING),
      this.loadingService.isLoading(LOADING_KEYS.SUBMIT_LOADING),
    ]).subscribe(this.updateLoading);
  };

  updateLoading = ([initialLoading, validateLoading, submitLoading]: boolean[]) => {
    this.isLoading = initialLoading || validateLoading || submitLoading;
  };

  loadProducts = async (fromProductKinds: PRODUCT_KINDS[], toProductKinds: PRODUCT_KINDS[]) => {
    this.componentState = COMPONENT_STATE.LOADING;
    try {
      await Promise.all([
        this.arrangementHTTPService.getFromProducts(fromProductKinds),
        this.arrangementHTTPService.getToProducts(toProductKinds),
      ]);
      this.componentState = COMPONENT_STATE.LOADED;
    } catch (err) {
      this.componentState = COMPONENT_STATE.ERROR;
    }
  };

  goToAccounts = () => {
    return this.router.navigate(["my-accounts/list"]);
  };

  setFormState = (formState: FORM_STATE) => {
    this.formState = formState;
  };

  postPayment = () => {
    this.isSubmitError.next(false);
    const payload: PostPaymentOrdersRequestParams = {
      paymentOrdersPost: this.createPaymentOrderFormRequest(this.formModel),
    };
    this.paymentOrderHTTPService.postPaymentOrder(payload).subscribe({
      next: (resp) => {
        this.paymentOrderPostResponse = resp;
        if (this.paymentOrderHTTPService.isPaymentSuccessful(resp)) {
          this.setFormState(FORM_STATE.SUCCESS);
        } else {
          this.handleErrors(resp ?? {});
        }
      },
      error: (err) => {
        this.paymentOrderPostResponse = undefined;
        this.handleErrors(err ?? {});
      },
    });
  };

  handleErrors = (response?: { error?: object } | PaymentOrdersPostResponse) => {
    this.isSubmitError.next(!!response);
  };

  validateForm = (formModel: IFormModel) => {
    this.formModel = formModel;
    const payload: PostValidateRequestParams = {
      paymentOrdersValidatePost: this.createPaymentOrderFormRequest(this.formModel),
    };
    return this.paymentOrderHTTPService
      .validatePaymentOrder(payload)
      .then(() => {
        this.isSubmitError.next(false);
        this.setFormState(FORM_STATE.REVIEW);
      })
      .catch(this.handleValidateFailure);
  };

  handleValidateFailure = () => {
    this.notificationService.showNotification({
      header: this.apiErrorTextService.validationErrorHeader,
      message: this.apiErrorTextService.validationErrorMessage,
      modifier: "error",
    });
  };

  padNumber = (numberToPad: number): string => {
    if (numberToPad < 10 && numberToPad >= 0) {
      return `0${numberToPad}`;
    }
    return `${numberToPad}`;
  };

  restartForm() {
    this.formModel = this.getInitialFormModel();
    this.setFormState(FORM_STATE.EDITING);
  }

  createPaymentOrderFormRequest(formModel: IFormModel): PaymentOrdersPost {
    return {
      paymentType: "INTERNAL_TRANSFER",
      originatorAccount: {
        identification: {
          identification: formModel.fromAccount?.id ?? "",
          schemeName: "ID",
        },
        name: formModel.fromAccount?.name ?? "",
      },
      requestedExecutionDate: formModel.executionDate,
      paymentMode: formModel.paymentMode,
      instructionPriority: InstructionPriority.NORM,
      schedule: getSchedule(formModel),
      transferTransactionInformation: {
        remittanceInformation: formModel.memo,
        instructedAmount: {
          amount: `${formModel.amount}`,
          currencyCode: "USD",
        },
        counterparty: { name: formModel.toAccount?.name ?? "", role: InvolvedPartyRole.CREDITOR },
        counterpartyAccount: {
          identification: {
            identification: formModel.toAccount?.id ?? "",
            schemeName: "ID",
          },
        },
      },
    };
  }
}
