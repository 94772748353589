import { ProductSummaryItem, ProductItem } from "@backbase/arrangement-manager-http-ang";
import { FlatProductItem } from "./flat-product-item";

export interface ArrangementItemWithAdditions extends ProductItem, ProductSummaryItem {
  displayName?: string;
  minimumPayment: number;
  minimumPaymentDueDate: string;
  productNumber: string;
  bookedBalance?: number;
  availableBalance?: number;
  amount?: string;
  additions?: {
    paymentDueDate: string;
    regularPayment?: string;
    arrangementType: string;
    payoffAmount?: string;
    minimumPayment?: string;
    micrAcctNumber: string;
    minLoanAdvance?: string;
    shareType?: string;
    productSubCode?: string;
  };
}

export const convertToFlatProductItem = (
  arrangementItemWithAdditions: ArrangementItemWithAdditions,
): FlatProductItem => {
  // eslint-disable-next-line no-extra-boolean-cast
  const displayName = !!arrangementItemWithAdditions.displayName
    ? arrangementItemWithAdditions.displayName
    : arrangementItemWithAdditions.name;
  return {
    id: arrangementItemWithAdditions.id,
    name: arrangementItemWithAdditions.name,
    displayName,
    currency: arrangementItemWithAdditions.currency,
    amount: arrangementItemWithAdditions.amount,
    minimumPayment: arrangementItemWithAdditions.minimumPayment,
    minimumPaymentDueDate: arrangementItemWithAdditions.minimumPaymentDueDate,
    productNumber: arrangementItemWithAdditions.productNumber,
    bookedBalance: arrangementItemWithAdditions.bookedBalance,
    availableBalance: arrangementItemWithAdditions.availableBalance,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    IBAN: arrangementItemWithAdditions.IBAN,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BBAN: arrangementItemWithAdditions.BBAN,
    number: arrangementItemWithAdditions.number,
    productKindName: arrangementItemWithAdditions.productKindName,
    paymentDueDate: arrangementItemWithAdditions.additions?.paymentDueDate,
    regularPayment: arrangementItemWithAdditions.additions?.regularPayment,
    arrangementType: arrangementItemWithAdditions.additions?.arrangementType,
    payoffAmount: arrangementItemWithAdditions.additions?.payoffAmount,
    micrAcctNumber: arrangementItemWithAdditions.additions?.micrAcctNumber,
    minLoanAdvance: arrangementItemWithAdditions.additions?.minLoanAdvance,
    isExternal: false,
  };
};
