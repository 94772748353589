import { Component, Input } from "@angular/core";
import { SelectionItem } from "@ent/data";
import { OpenShareBaseComponent } from "../open-share-base/open-share-base.component";

@Component({
  selector: "ent-membership-selector",
  templateUrl: "./membership-selector.component.html",
})
export class MembershipSelectorComponent extends OpenShareBaseComponent {
  @Input() formFieldName!: string;
  @Input() accounts!: SelectionItem[];
  @Input() hideCancelButton = false;
  @Input() supportText?: string;
}
