import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CardsHTTPService } from "@ent/data";
import { TextService } from "../../services/text.service";

export enum PIN_MODAL_STATE {
  CVV,
  PIN,
  CONFIRM,
}

export abstract class BasePinModalComponent {
  title: string;
  loadingText: string;

  form = new FormGroup({
    cvv: new FormControl(""),
    pin: new FormControl("", [Validators.required, Validators.minLength(4)]),
    confirmPin: new FormControl("", [Validators.required, Validators.minLength(4)]),
  });

  debitCVVValidators = [Validators.required, Validators.minLength(3)];

  modalStates = PIN_MODAL_STATE;
  modalState = PIN_MODAL_STATE.CVV;

  isLoading = true;

  constructor(public readonly textService: TextService, protected readonly cardsHTTPService: CardsHTTPService) {}

  setInitialModalState = () => {
    this.form.reset();
    this.form.controls.cvv.setValidators(this.cardsHTTPService.isATMCard ? [] : this.debitCVVValidators);
    this.isLoading = false;
    this.modalState = this.cardsHTTPService.isATMCard ? PIN_MODAL_STATE.PIN : PIN_MODAL_STATE.CVV;
  };

  onPinsMismatch = () => {
    this.form.controls.pin.setValue("");
    this.form.controls.pin.setErrors({ mismatch: true });
    this.form.controls.confirmPin.reset("");
    this.setModalState(PIN_MODAL_STATE.PIN);
  };

  resetFormInvalidCVV = () => {
    this.form.controls.pin.setValue("");
    this.form.controls.confirmPin.setValue("");
    this.form.controls.cvv.setValue("");
    this.form.controls.cvv.setErrors({ mismatch: true });
    this.setModalState(PIN_MODAL_STATE.CVV);
  };

  isCVVMismatchError = (err) => err?.error?.key === "card.error.invalidCvv" && !this.cardsHTTPService.isATMCard;

  setModalState = (newModalState: PIN_MODAL_STATE) => {
    this.modalState = newModalState;
  };

  confirmCVV = () => {
    const cvvControl = this.form.get("cvv");
    cvvControl.markAllAsTouched();
    if (cvvControl.valid && cvvControl.value?.length === 3) {
      this.setModalState(PIN_MODAL_STATE.PIN);
    }
  };

  confirmPIN = () => {
    const pinControl = this.form.get("pin");
    pinControl.markAllAsTouched();
    if (pinControl.valid && pinControl.value?.length === 4) {
      this.setModalState(PIN_MODAL_STATE.CONFIRM);
    }
  };

  isPinConfirmed = () => {
    const pin = this.form.controls.pin.value;
    const confirmPin = this.form.controls.confirmPin.value;
    return pin === confirmPin;
  };

  protected onFormSubmit = async () => {};
}
