import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DefaultPaymentCardNumberFormat } from "@backbase/ui-ang/payment-card-number-pipe";

@Component({
  selector: "ent-base-pin-form",
  template: "<div>not used</div>",
})
export class BasePinFormComponent {
  @Input()
  form: FormGroup;

  @Output()
  cancel = new EventEmitter();

  @Output()
  confirm = new EventEmitter();

  @Output()
  back = new EventEmitter();

  numberFormat = DefaultPaymentCardNumberFormat;

  isShown = false;

  protected inputControlName = "pin";

  toggleIsShown = () => {
    this.isShown = !this.isShown;
  };

  onCancel = () => {
    this.cancel.emit();
  };

  onBack = () => {
    this.back.emit();
  };

  onConfirm = () => {
    this.confirm.emit();
  };
}
