import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ProductSummaryHttpService } from "@backbase/arrangement-manager-http-ang";
import {
  ConvertProductPayload,
  CreateProductPayload,
  LoanEligibilityPayload,
  ShareEligibilityPayload,
} from "../../models/products";
import { ArrangementItemWithAdditions, convertToFlatProductItem, FlatProductItem, PRODUCT_KINDS } from "../../models";

@Injectable({
  providedIn: "root",
})
export class ProductsHttpService {
  private getEligibilityUrl = "/api/ent-online-account-opening/client-api/v1/share/eligibility";
  private createProductUrl = "/api/ent-online-account-opening/client-api/v1/share/create";
  private convertProductUrl = "/api/ent-online-account-opening/client-api/v1/share/convert";
  private getLoanInfoUrl = "/api/ent-online-account-opening/client-api/v1/loan/info";

  constructor(private http: HttpClient, private readonly productSummaryHttpService: ProductSummaryHttpService) {}

  getEligibleProducts = (): Observable<ShareEligibilityPayload> => {
    return this.http.get<ShareEligibilityPayload>(this.getEligibilityUrl);
  };

  getLoanInfoProducts = (): Observable<LoanEligibilityPayload> => {
    return this.http.get<LoanEligibilityPayload>(this.getLoanInfoUrl);
  };

  createProduct = (product: CreateProductPayload): Observable<object> => {
    return this.http.post<object>(this.createProductUrl, product);
  };

  convertProduct = (product: ConvertProductPayload): Observable<object> => {
    return this.http.post<object>(this.convertProductUrl, product);
  };

  getArrangementItems = (
    ignoredProductKindNames: Array<PRODUCT_KINDS> = [],
  ): Observable<ArrangementItemWithAdditions[]> => {
    const businessFunction = "Product Summary";
    const privilege = "view";
    const resourceName = "Product Summary";

    return this.productSummaryHttpService
      .getArrangementsByBusinessFunction({
        businessFunction,
        resourceName,
        privilege,
        ignoredProductKindNames,
        size: 100,
      })
      .pipe(
        map((products) =>
          products.flatMap((product) =>
            !product.additions?.["closedDate"] ? (product as ArrangementItemWithAdditions) : [],
          ),
        ),
      );
  };

  getCheckingAccounts = (): Observable<ArrangementItemWithAdditions[]> => {
    const ignoredProductKindNames = [PRODUCT_KINDS.LOAN, PRODUCT_KINDS.SAVINGS_ACCOUNT, PRODUCT_KINDS.DEBIT_CARD];

    return this.getArrangementItems(ignoredProductKindNames).pipe(
      map((products) =>
        products.filter(
          (product) => product.additions?.["shareType"] === "10" && !product.additions?.["productSubCode"],
        ),
      ),
    );
  };

  getDepositFromAccounts = (): Observable<FlatProductItem[]> => {
    const ignoredProductKindNames = [
      PRODUCT_KINDS.LOAN,
      PRODUCT_KINDS.CREDIT_CARD,
      PRODUCT_KINDS.DEBIT_CARD,
      PRODUCT_KINDS.CONNECTED_ACCOUNT,
      PRODUCT_KINDS.INVESTMENT_ACCOUNT,
      PRODUCT_KINDS.TERM_DEPOSIT,
    ];

    return this.getArrangementItems(ignoredProductKindNames).pipe(
      map((products) => products.map((product) => convertToFlatProductItem(product))),
    );
  };
}
