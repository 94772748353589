<div class="bb-item-log__record item-log__record--has-bullet" id="test__from-selector">
  <div class="bb-stack bb-stack--align-top">
    <div
      class="bb-item-log-record__bullet-icon bb-item-log-record__bullet-icon--filled bb-stack__item bb-stack__item--spacing-md"
    ></div>
    <div class="bb-item-log-record__details">
      <span class="bb-item-log__from-label">{{ fromLabel }}</span>
      <div class="bb-payment-account-details-card h-100">
        <div class="bb-payment-account-details-card__body justify-content-start">
          <div class="bb-text-bold break-word" data-role="debit-account-name">{{ name }}</div>
          <div class="bb-text-support bb-subtitle contextual-alternates-off" data-role="debit-account-number">
            {{ accountNumber }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- We need a blank div after the main one so that the style apply correctly -->
<div></div>
