import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { VISA_COUNTRIES, DestinationViewModel } from "../models/visa";

@Injectable({ providedIn: "root" })
export class TravelNoticesService {
  getDestinations = (): Observable<DestinationViewModel[]> => {
    return of(VISA_COUNTRIES);
  };
}
