import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

@Component({
  selector: "ent-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
})
export class ConfirmationModalComponent {
  @Input()
  isOpen = false;

  @Input()
  title!: string;

  @Input()
  confirmMessage!: string;

  @Input()
  yesBtnText!: string;

  @Input()
  noBtnText!: string;

  @Input()
  bodyTemplate?: TemplateRef<Component>;

  @Input()
  footerTemplate?: TemplateRef<Component>;

  @Output()
  close = new EventEmitter();

  @Output()
  yes = new EventEmitter();

  @Output()
  no = new EventEmitter();
}
