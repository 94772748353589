import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PRODUCT_KINDS } from "../models/product-kinds";

@Injectable()
export class GetCustomizeAccountsHttpInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest = httpRequest.clone();
    let newParams = new HttpParams();
    const newParam = `${PRODUCT_KINDS.CURRENT_ACCOUNT},${PRODUCT_KINDS.SAVINGS_ACCOUNT},${PRODUCT_KINDS.TERM_DEPOSIT},${PRODUCT_KINDS.LOAN},${PRODUCT_KINDS.CREDIT_CARD}`;
    if (
      /\/productsummary\/context\/arrangements/i.test(httpRequest.url) &&
      httpRequest.params.has("businessFunction")
    ) {
      newParams = httpRequest.params.append("productKindNames", newParam);
      const params = newParams;
      newRequest = httpRequest.clone({ params });
    }
    return next.handle(newRequest);
  }
}
