import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { EntUIModule } from "@ent/ui";
import { AmountModule } from "@backbase/ui-ang/amount";
import { ButtonModule } from "@backbase/ui-ang/button";
import { LoadButtonModule } from "@backbase/ui-ang/load-button";
import { AlertModule } from "@backbase/ui-ang/alert";
import { HeaderModule } from "@backbase/ui-ang/header";
import { InputRadioGroupModule } from "@backbase/ui-ang/input-radio-group";
import { InputValidationMessageModule } from "@backbase/ui-ang/input-validation-message";
import { InputNumberModule } from "@backbase/ui-ang/input-number";
import { TransferComponent } from "./transfer.component";
import { TransferFormComponent } from "./transfer-form/transfer-form.component";
import { TransferSuccessComponent } from "./transfer-success/transfer-success.component";
import { TransferReviewComponent } from "./transfer-review/transfer-review.component";
import { MoneyMovementModule } from "../money-movement.module";

@NgModule({
  declarations: [TransferComponent, TransferFormComponent, TransferSuccessComponent, TransferReviewComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: "", component: TransferComponent, pathMatch: "full" }]),
    EntUIModule,
    AlertModule,
    HeaderModule,
    AmountModule,
    LoadButtonModule,
    ButtonModule,
    InputRadioGroupModule,
    InputNumberModule,
    ReactiveFormsModule,
    InputValidationMessageModule,
    MoneyMovementModule,
  ],
  providers: [],
})
export class TransferModule {}
