import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IRoute } from "@ent/data";

@Component({
  selector: "ent-navmenu-item-submenu",
  templateUrl: "./navmenu-item-submenu.component.html",
  styleUrls: ["./navmenu-item-submenu.component.scss"],
})
export class NavmenuItemSubmenuComponent {
  @Input()
  parentRoute!: IRoute;

  @Output()
  closeSubMenu = new EventEmitter();

  isLinkAbsolute(link: string): boolean {
    return link.startsWith("http://") || link.startsWith("https://");
  }
}
