<div *ngIf="!this.errorGettingSSOUrl">
  <div>
    <div style="white-space: pre-line">
      {{textService.leavingEntModalText}}
      <a style="font-weight: bold; text-decoration: underline" href="tel:8005583424">(800) 558-3424</a>.
    </div>
  </div>
  <div class="bb-button-bar" id="test__reset-pin-modal--cvv-footer">
    <form id="samlform" name="samlForm" [action]="formAction | async" method="post" target="_blank">
      <div>
        <input type="hidden" name="SAMLResponse" [value]="samlResponse | async" />
      </div>
      <button
        bbButton
        color="primary"
        (click)="this.submitAndClose()"
        type="submit"
        [disabled]="isLoading | async"
        data-role="confirm-action-btn"
      >
        {{textService.leavingEntModalButtonText}}
      </button>
    </form>
  </div>
</div>

<div *ngIf="this.errorGettingSSOUrl">
  <div>
    <div style="white-space: pre-line">{{textService.leavingEntModalError}}</div>
  </div>
</div>
