import "@angular/localize/init";

export interface DestinationViewModel {
  name: string;
  code: string;
  regions?: Array<{
    name: string;
    code: string;
  }>;
}

const usStates = [
  { code: "US-AK", name: $localize`:@@cards.travel-notices.country.USA.region.AK:Alaska` },
  { code: "US-AL", name: $localize`:@@cards.travel-notices.country.USA.region.AL:Alabama` },
  { code: "US-AR", name: $localize`:@@cards.travel-notices.country.USA.region.AR:Arkansas` },
  { code: "US-AZ", name: $localize`:@@cards.travel-notices.country.USA.region.AZ:Arizona` },
  { code: "US-CA", name: $localize`:@@cards.travel-notices.country.USA.region.CA:California` },
  { code: "US-CO", name: $localize`:@@cards.travel-notices.country.USA.region.CO:Colorado` },
  { code: "US-CT", name: $localize`:@@cards.travel-notices.country.USA.region.CT:Connecticut` },
  { code: "US-DC", name: $localize`:@@cards.travel-notices.country.USA.region.DC:District of Columbia` },
  { code: "US-DE", name: $localize`:@@cards.travel-notices.country.USA.region.DE:Delaware` },
  { code: "US-FL", name: $localize`:@@cards.travel-notices.country.USA.region.FL:Florida` },
  { code: "US-GA", name: $localize`:@@cards.travel-notices.country.USA.region.GA:Georgia` },
  { code: "US-HI", name: $localize`:@@cards.travel-notices.country.USA.region.HI:Hawaii` },
  { code: "US-IA", name: $localize`:@@cards.travel-notices.country.USA.region.IA:Iowa` },
  { code: "US-ID", name: $localize`:@@cards.travel-notices.country.USA.region.ID:Idaho` },
  { code: "US-IL", name: $localize`:@@cards.travel-notices.country.USA.region.IL:Illinois` },
  { code: "US-IN", name: $localize`:@@cards.travel-notices.country.USA.region.IN:Indiana` },
  { code: "US-KS", name: $localize`:@@cards.travel-notices.country.USA.region.KS:Kansas` },
  { code: "US-KY", name: $localize`:@@cards.travel-notices.country.USA.region.KY:Kentucky` },
  { code: "US-LA", name: $localize`:@@cards.travel-notices.country.USA.region.LA:Louisiana` },
  { code: "US-MA", name: $localize`:@@cards.travel-notices.country.USA.region.MA:Massachusetts` },
  { code: "US-MD", name: $localize`:@@cards.travel-notices.country.USA.region.MD:Maryland` },
  { code: "US-ME", name: $localize`:@@cards.travel-notices.country.USA.region.ME:Maine` },
  { code: "US-MI", name: $localize`:@@cards.travel-notices.country.USA.region.MI:Michigan` },
  { code: "US-MN", name: $localize`:@@cards.travel-notices.country.USA.region.MN:Minnesota` },
  { code: "US-MO", name: $localize`:@@cards.travel-notices.country.USA.region.MO:Missouri` },
  { code: "US-MS", name: $localize`:@@cards.travel-notices.country.USA.region.MS:Mississippi` },
  { code: "US-MT", name: $localize`:@@cards.travel-notices.country.USA.region.MT:Montana` },
  { code: "US-NC", name: $localize`:@@cards.travel-notices.country.USA.region.NC:North Carolina` },
  { code: "US-ND", name: $localize`:@@cards.travel-notices.country.USA.region.ND:North Dakota` },
  { code: "US-NE", name: $localize`:@@cards.travel-notices.country.USA.region.NE:Nebraska` },
  { code: "US-NH", name: $localize`:@@cards.travel-notices.country.USA.region.NH:New Hampshire` },
  { code: "US-NJ", name: $localize`:@@cards.travel-notices.country.USA.region.NJ:New Jersey` },
  { code: "US-NM", name: $localize`:@@cards.travel-notices.country.USA.region.NM:New Mexico` },
  { code: "US-NV", name: $localize`:@@cards.travel-notices.country.USA.region.NV:Nevada` },
  { code: "US-NY", name: $localize`:@@cards.travel-notices.country.USA.region.NY:New York` },
  { code: "US-OH", name: $localize`:@@cards.travel-notices.country.USA.region.OH:Ohio` },
  { code: "US-OK", name: $localize`:@@cards.travel-notices.country.USA.region.OK:Oklahoma` },
  { code: "US-OR", name: $localize`:@@cards.travel-notices.country.USA.region.OR:Oregon` },
  { code: "US-PA", name: $localize`:@@cards.travel-notices.country.USA.region.PA:Pennsylvania` },
  { code: "US-RI", name: $localize`:@@cards.travel-notices.country.USA.region.RI:Rhode Island` },
  { code: "US-SC", name: $localize`:@@cards.travel-notices.country.USA.region.SC:South Carolina` },
  { code: "US-SD", name: $localize`:@@cards.travel-notices.country.USA.region.SD:South Dakota` },
  { code: "US-TN", name: $localize`:@@cards.travel-notices.country.USA.region.TN:Tennessee` },
  { code: "US-TX", name: $localize`:@@cards.travel-notices.country.USA.region.TX:Texas` },
  { code: "US-UT", name: $localize`:@@cards.travel-notices.country.USA.region.UT:Utah` },
  { code: "US-VA", name: $localize`:@@cards.travel-notices.country.USA.region.VA:Virginia` },
  { code: "US-VT", name: $localize`:@@cards.travel-notices.country.USA.region.VT:Vermont` },
  { code: "US-WA", name: $localize`:@@cards.travel-notices.country.USA.region.WA:Washington` },
  { code: "US-WI", name: $localize`:@@cards.travel-notices.country.USA.region.WI:Wisconsin` },
  { code: "US-WV", name: $localize`:@@cards.travel-notices.country.USA.region.WV:West Virginia` },
  { code: "US-WY", name: $localize`:@@cards.travel-notices.country.USA.region.WY:Wyoming` },
];

const canadianStates = [
  { code: "CA-AB", name: $localize`:@@cards.travel-notices.country.CAN.region.AB:Alberta` },
  { code: "CA-BC", name: $localize`:@@cards.travel-notices.country.CAN.region.BC:British Columbia` },
  { code: "CA-MB", name: $localize`:@@cards.travel-notices.country.CAN.region.MB:Manitoba` },
  { code: "CA-NB", name: $localize`:@@cards.travel-notices.country.CAN.region.NB:New Brunswick` },
  { code: "CA-NL", name: $localize`:@@cards.travel-notices.country.CAN.region.NL:Newfoundland` },
  { code: "CA-NS", name: $localize`:@@cards.travel-notices.country.CAN.region.NS:Nova Scotia` },
  { code: "CA-ON", name: $localize`:@@cards.travel-notices.country.CAN.region.ON:Ontario` },
  { code: "CA-PE", name: $localize`:@@cards.travel-notices.country.CAN.region.PE:Prince Edward Island` },
  { code: "CA-QC", name: $localize`:@@cards.travel-notices.country.CAN.region.QC:Quebec` },
  { code: "CA-SK", name: $localize`:@@cards.travel-notices.country.CAN.region.SK:Saskatchewan` },
  { code: "CA-NT", name: $localize`:@@cards.travel-notices.country.CAN.region.NT:Northwest Territories` },
  { code: "CA-NU", name: $localize`:@@cards.travel-notices.country.CAN.region.NU:Nunavut` },
  { code: "CA-YT", name: $localize`:@@cards.travel-notices.country.CAN.region.YT:Yukon` },
];

const countriesWithTravelBan = ["CUB", "IRN", "PRK", "RUS", "SYR"];

export const VISA_COUNTRIES: DestinationViewModel[] = [
  { name: $localize`:@@cards.travel-notices.country.AFG:Afghanistan`, code: "AFG" },
  { name: $localize`:@@cards.travel-notices.country.ALB:Albania`, code: "ALB" },
  { name: $localize`:@@cards.travel-notices.country.DZA:Algeria`, code: "DZA" },
  { name: $localize`:@@cards.travel-notices.country.ASM:American Samoa`, code: "ASM" },
  { name: $localize`:@@cards.travel-notices.country.AND:Andorra`, code: "AND" },
  { name: $localize`:@@cards.travel-notices.country.AGO:Angola`, code: "AGO" },
  { name: $localize`:@@cards.travel-notices.country.AIA:Anguilla`, code: "AIA" },
  { name: $localize`:@@cards.travel-notices.country.ATA:Antarctica`, code: "ATA" },
  { name: $localize`:@@cards.travel-notices.country.ATG:Antigua and Barbuda`, code: "ATG" },
  { name: $localize`:@@cards.travel-notices.country.ARG:Argentina`, code: "ARG" },
  { name: $localize`:@@cards.travel-notices.country.ARM:Armenia`, code: "ARM" },
  { name: $localize`:@@cards.travel-notices.country.ABW:Aruba`, code: "ABW" },
  { name: $localize`:@@cards.travel-notices.country.AUS:Australia`, code: "AUS" },
  { name: $localize`:@@cards.travel-notices.country.AUT:Austria`, code: "AUT" },
  { name: $localize`:@@cards.travel-notices.country.AZE:Azerbaijan`, code: "AZE" },
  { name: $localize`:@@cards.travel-notices.country.BHS:Bahamas`, code: "BHS" },
  { name: $localize`:@@cards.travel-notices.country.BHR:Bahrain`, code: "BHR" },
  { name: $localize`:@@cards.travel-notices.country.BGD:Bangladesh`, code: "BGD" },
  { name: $localize`:@@cards.travel-notices.country.BRB:Barbados`, code: "BRB" },
  { name: $localize`:@@cards.travel-notices.country.BLR:Belarus`, code: "BLR" },
  { name: $localize`:@@cards.travel-notices.country.BEL:Belgium`, code: "BEL" },
  { name: $localize`:@@cards.travel-notices.country.BLZ:Belize`, code: "BLZ" },
  { name: $localize`:@@cards.travel-notices.country.BEN:Benin`, code: "BEN" },
  { name: $localize`:@@cards.travel-notices.country.BMU:Bermuda`, code: "BMU" },
  { name: $localize`:@@cards.travel-notices.country.BTN:Bhutan`, code: "BTN" },
  { name: $localize`:@@cards.travel-notices.country.BOL:Bolivia`, code: "BOL" },
  { name: $localize`:@@cards.travel-notices.country.BES:Bonaire, Sint Eustatius, and Saba`, code: "BES" },
  { name: $localize`:@@cards.travel-notices.country.BIH:Bosnia and Herzegovina`, code: "BIH" },
  { name: $localize`:@@cards.travel-notices.country.BWA:Botswana`, code: "BWA" },
  { name: $localize`:@@cards.travel-notices.country.BVT:Bouvet Island`, code: "BVT" },
  { name: $localize`:@@cards.travel-notices.country.BRA:Brazil`, code: "BRA" },
  { name: $localize`:@@cards.travel-notices.country.IOT:British Indian Ocean Territory`, code: "IOT" },
  { name: $localize`:@@cards.travel-notices.country.VGB:British Virgin Islands`, code: "VGB" },
  { name: $localize`:@@cards.travel-notices.country.BRN:Brunei Darussalam`, code: "BRN" },
  { name: $localize`:@@cards.travel-notices.country.BGR:Bulgaria`, code: "BGR" },
  { name: $localize`:@@cards.travel-notices.country.BFA:Burkina Faso`, code: "BFA" },
  { name: $localize`:@@cards.travel-notices.country.BDI:Burundi`, code: "BDI" },
  { name: $localize`:@@cards.travel-notices.country.KHM:Cambodia`, code: "KHM" },
  { name: $localize`:@@cards.travel-notices.country.CMR:Cameroon, United Republic of`, code: "CMR" },
  {
    name: $localize`:@@cards.travel-notices.country.CAN:Canada`,
    code: "CAN",
    regions: canadianStates,
  },
  { name: $localize`:@@cards.travel-notices.country.CPV:Cape Verde Island`, code: "CPV" },
  { name: $localize`:@@cards.travel-notices.country.CYM:Cayman Islands`, code: "CYM" },
  { name: $localize`:@@cards.travel-notices.country.CAF:Central African Republic`, code: "CAF" },
  { name: $localize`:@@cards.travel-notices.country.TCD:Chad`, code: "TCD" },
  { name: $localize`:@@cards.travel-notices.country.CHL:Chile`, code: "CHL" },
  { name: $localize`:@@cards.travel-notices.country.CHN:China`, code: "CHN" },
  { name: $localize`:@@cards.travel-notices.country.CXR:Christmas Island`, code: "CXR" },
  { name: $localize`:@@cards.travel-notices.country.CCK:Cocos (Keeling) Islands`, code: "CCK" },
  { name: $localize`:@@cards.travel-notices.country.COL:Colombia`, code: "COL" },
  { name: $localize`:@@cards.travel-notices.country.COM:Comoros`, code: "COM" },
  { name: $localize`:@@cards.travel-notices.country.COG:Congo`, code: "COG" },
  { name: $localize`:@@cards.travel-notices.country.COK:Cook Islands`, code: "COK" },
  { name: $localize`:@@cards.travel-notices.country.CRI:Costa Rica`, code: "CRI" },
  { name: $localize`:@@cards.travel-notices.country.CIV:Ivory Coast`, code: "CIV" },
  { name: $localize`:@@cards.travel-notices.country.HRV:Croatia`, code: "HRV" },
  { name: $localize`:@@cards.travel-notices.country.CUB:Cuba`, code: "CUB" },
  { name: $localize`:@@cards.travel-notices.country.CUW:Curaçao`, code: "CUW" },
  { name: $localize`:@@cards.travel-notices.country.CYP:Cyprus`, code: "CYP" },
  { name: $localize`:@@cards.travel-notices.country.CZE:Czech Republic`, code: "CZE" },
  { name: $localize`:@@cards.travel-notices.country.COD:Democratic Republic of the Congo`, code: "COD" },
  { name: $localize`:@@cards.travel-notices.country.DNK:Denmark`, code: "DNK" },
  { name: $localize`:@@cards.travel-notices.country.DJI:Djibouti`, code: "DJI" },
  { name: $localize`:@@cards.travel-notices.country.DMA:Dominica`, code: "DMA" },
  { name: $localize`:@@cards.travel-notices.country.DOM:Dominican Republic`, code: "DOM" },
  { name: $localize`:@@cards.travel-notices.country.ECU:Ecuador`, code: "ECU" },
  { name: $localize`:@@cards.travel-notices.country.EGY:Egypt`, code: "EGY" },
  { name: $localize`:@@cards.travel-notices.country.SLV:El Salvador`, code: "SLV" },
  { name: $localize`:@@cards.travel-notices.country.GNQ:Equatorial Guinea`, code: "GNQ" },
  { name: $localize`:@@cards.travel-notices.country.ERI:Eritrea`, code: "ERI" },
  { name: $localize`:@@cards.travel-notices.country.EST:Estonia`, code: "EST" },
  { name: $localize`:@@cards.travel-notices.country.SWZ:Swaziland`, code: "SWZ" },
  { name: $localize`:@@cards.travel-notices.country.ETH:Ethiopia`, code: "ETH" },
  { name: $localize`:@@cards.travel-notices.country.FLK:Falkland Islands (Malvinas)`, code: "FLK" },
  { name: $localize`:@@cards.travel-notices.country.FRO:Faroe Islands`, code: "FRO" },
  { name: $localize`:@@cards.travel-notices.country.FJI:Fiji`, code: "FJI" },
  { name: $localize`:@@cards.travel-notices.country.FIN:Finland`, code: "FIN" },
  { name: $localize`:@@cards.travel-notices.country.FRA:France`, code: "FRA" },
  { name: $localize`:@@cards.travel-notices.country.FXX:Metropolitan France`, code: "FXX" },
  { name: $localize`:@@cards.travel-notices.country.GUF:French Guiana`, code: "GUF" },
  { name: $localize`:@@cards.travel-notices.country.PYF:French Polynesia`, code: "PYF" },
  { name: $localize`:@@cards.travel-notices.country.ATF:French Southern Territories`, code: "ATF" },
  { name: $localize`:@@cards.travel-notices.country.GAB:Gabon`, code: "GAB" },
  { name: $localize`:@@cards.travel-notices.country.GMB:Gambia`, code: "GMB" },
  { name: $localize`:@@cards.travel-notices.country.GEO:Georgia`, code: "GEO" },
  { name: $localize`:@@cards.travel-notices.country.DEU:Germany`, code: "DEU" },
  { name: $localize`:@@cards.travel-notices.country.GHA:Ghana`, code: "GHA" },
  { name: $localize`:@@cards.travel-notices.country.GIB:Gibralter`, code: "GIB" },
  { name: $localize`:@@cards.travel-notices.country.GRC:Greece`, code: "GRC" },
  { name: $localize`:@@cards.travel-notices.country.GRL:Greenland`, code: "GRL" },
  { name: $localize`:@@cards.travel-notices.country.GRD:Grenada`, code: "GRD" },
  { name: $localize`:@@cards.travel-notices.country.GLP:Guadeloupe`, code: "GLP" },
  { name: $localize`:@@cards.travel-notices.country.GUM:Guam`, code: "GUM" },
  { name: $localize`:@@cards.travel-notices.country.GTM:Guatemala`, code: "GTM" },
  { name: $localize`:@@cards.travel-notices.country.GIN:Guinea`, code: "GIN" },
  { name: $localize`:@@cards.travel-notices.country.GNB:Guinea-Bissau`, code: "GNB" },
  { name: $localize`:@@cards.travel-notices.country.GUY:Guyana`, code: "GUY" },
  { name: $localize`:@@cards.travel-notices.country.HTI:Haiti`, code: "HTI" },
  { name: $localize`:@@cards.travel-notices.country.HMD:Heard and McDonald Islands`, code: "HMD" },
  { name: $localize`:@@cards.travel-notices.country.VAT:Holy See (Vatican City State)`, code: "VAT" },
  { name: $localize`:@@cards.travel-notices.country.HND:Honduras`, code: "HND" },
  { name: $localize`:@@cards.travel-notices.country.HKG:Hong Kong`, code: "HKG" },
  { name: $localize`:@@cards.travel-notices.country.HUN:Hungary`, code: "HUN" },
  { name: $localize`:@@cards.travel-notices.country.ISL:Iceland`, code: "ISL" },
  { name: $localize`:@@cards.travel-notices.country.IND:India`, code: "IND" },
  { name: $localize`:@@cards.travel-notices.country.IDN:Indonesia`, code: "IDN" },
  { name: $localize`:@@cards.travel-notices.country.IRN:Iran, Islamic Republic of`, code: "IRN" },
  { name: $localize`:@@cards.travel-notices.country.IRQ:Iraq`, code: "IRQ" },
  { name: $localize`:@@cards.travel-notices.country.IRL:Ireland`, code: "IRL" },
  { name: $localize`:@@cards.travel-notices.country.IMN:Isle of Man`, code: "IMN" },
  { name: $localize`:@@cards.travel-notices.country.ISR:Israel`, code: "ISR" },
  { name: $localize`:@@cards.travel-notices.country.ITA:Italy`, code: "ITA" },
  { name: $localize`:@@cards.travel-notices.country.JAM:Jamaica`, code: "JAM" },
  { name: $localize`:@@cards.travel-notices.country.JPN:Japan`, code: "JPN" },
  { name: $localize`:@@cards.travel-notices.country.JOR:Jordan`, code: "JOR" },
  { name: $localize`:@@cards.travel-notices.country.KAZ:Kazakhstan`, code: "KAZ" },
  { name: $localize`:@@cards.travel-notices.country.KEN:Kenya`, code: "KEN" },
  { name: $localize`:@@cards.travel-notices.country.KIR:Kiribati`, code: "KIR" },
  { name: $localize`:@@cards.travel-notices.country.KOR:South Korea`, code: "KOR" },
  { name: $localize`:@@cards.travel-notices.country.PRK:North Korea`, code: "PRK" },
  { name: $localize`:@@cards.travel-notices.country.KWT:Kuwait`, code: "KWT" },
  { name: $localize`:@@cards.travel-notices.country.KGZ:Kyrgyzstan`, code: "KGZ" },
  { name: $localize`:@@cards.travel-notices.country.LAO:Laos`, code: "LAO" },
  { name: $localize`:@@cards.travel-notices.country.LVA:Latvia`, code: "LVA" },
  { name: $localize`:@@cards.travel-notices.country.LBN:Lebanon`, code: "LBN" },
  { name: $localize`:@@cards.travel-notices.country.LSO:Lesotho`, code: "LSO" },
  { name: $localize`:@@cards.travel-notices.country.LBR:Liberia`, code: "LBR" },
  { name: $localize`:@@cards.travel-notices.country.LBY:Libyan Arab Jamahiriya`, code: "LBY" },
  { name: $localize`:@@cards.travel-notices.country.LIE:Liechtenstein`, code: "LIE" },
  { name: $localize`:@@cards.travel-notices.country.LTU:Lithuania`, code: "LTU" },
  { name: $localize`:@@cards.travel-notices.country.LUX:Luxembourg`, code: "LUX" },
  { name: $localize`:@@cards.travel-notices.country.MAC:Macau`, code: "MAC" },
  { name: $localize`:@@cards.travel-notices.country.MKD:Macedonia`, code: "MKD" },
  { name: $localize`:@@cards.travel-notices.country.MDG:Madagascar`, code: "MDG" },
  { name: $localize`:@@cards.travel-notices.country.MWI:Malawi`, code: "MWI" },
  { name: $localize`:@@cards.travel-notices.country.MYS:Malaysia`, code: "MYS" },
  { name: $localize`:@@cards.travel-notices.country.MDV:Maldives`, code: "MDV" },
  { name: $localize`:@@cards.travel-notices.country.MLI:Mali`, code: "MLI" },
  { name: $localize`:@@cards.travel-notices.country.MLT:Malta`, code: "MLT" },
  { name: $localize`:@@cards.travel-notices.country.MHL:Marshall Islands`, code: "MHL" },
  { name: $localize`:@@cards.travel-notices.country.MTQ:Martinique`, code: "MTQ" },
  { name: $localize`:@@cards.travel-notices.country.MRT:Mauritania`, code: "MRT" },
  { name: $localize`:@@cards.travel-notices.country.MUS:Mauritius`, code: "MUS" },
  { name: $localize`:@@cards.travel-notices.country.MYT:Mayotte`, code: "MYT" },
  { name: $localize`:@@cards.travel-notices.country.MEX:Mexico`, code: "MEX" },
  { name: $localize`:@@cards.travel-notices.country.FSM:Micronesia`, code: "FSM" },
  { name: $localize`:@@cards.travel-notices.country.MDA:Moldova`, code: "MDA" },
  { name: $localize`:@@cards.travel-notices.country.MCO:Monaco`, code: "MCO" },
  { name: $localize`:@@cards.travel-notices.country.MNG:Mongolia`, code: "MNG" },
  { name: $localize`:@@cards.travel-notices.country.MNE:Montenegro`, code: "MNE" },
  { name: $localize`:@@cards.travel-notices.country.MSR:Montserrat`, code: "MSR" },
  { name: $localize`:@@cards.travel-notices.country.MAR:Morocco`, code: "MAR" },
  { name: $localize`:@@cards.travel-notices.country.MOZ:Mozambique`, code: "MOZ" },
  { name: $localize`:@@cards.travel-notices.country.MMR:Myanmar`, code: "MMR" },
  { name: $localize`:@@cards.travel-notices.country.NAM:Namibia`, code: "NAM" },
  { name: $localize`:@@cards.travel-notices.country.NRU:Nauru`, code: "NRU" },
  { name: $localize`:@@cards.travel-notices.country.NPL:Nepal`, code: "NPL" },
  { name: $localize`:@@cards.travel-notices.country.NLD:Netherlands`, code: "NLD" },
  { name: $localize`:@@cards.travel-notices.country.NCL:New Caledonia`, code: "NCL" },
  { name: $localize`:@@cards.travel-notices.country.NZL:New Zealand`, code: "NZL" },
  { name: $localize`:@@cards.travel-notices.country.NIC:Nicaragua`, code: "NIC" },
  { name: $localize`:@@cards.travel-notices.country.NER:Niger`, code: "NER" },
  { name: $localize`:@@cards.travel-notices.country.NGA:Nigeria`, code: "NGA" },
  { name: $localize`:@@cards.travel-notices.country.NIU:Niue`, code: "NIU" },
  { name: $localize`:@@cards.travel-notices.country.NFK:Norfolk Island`, code: "NFK" },
  { name: $localize`:@@cards.travel-notices.country.MNP:Northern Mariana Islands`, code: "MNP" },
  { name: $localize`:@@cards.travel-notices.country.NOR:Norway`, code: "NOR" },
  { name: $localize`:@@cards.travel-notices.country.OMN:Oman`, code: "OMN" },
  { name: $localize`:@@cards.travel-notices.country.PAK:Pakistan`, code: "PAK" },
  { name: $localize`:@@cards.travel-notices.country.PLW:Palau`, code: "PLW" },
  { name: $localize`:@@cards.travel-notices.country.PSE:Palestine`, code: "PSE" },
  { name: $localize`:@@cards.travel-notices.country.PAN:Panama`, code: "PAN" },
  { name: $localize`:@@cards.travel-notices.country.PNG:Papua New Guinea`, code: "PNG" },
  { name: $localize`:@@cards.travel-notices.country.PRY:Paraguay`, code: "PRY" },
  { name: $localize`:@@cards.travel-notices.country.PER:Peru`, code: "PER" },
  { name: $localize`:@@cards.travel-notices.country.PHL:Philippines`, code: "PHL" },
  { name: $localize`:@@cards.travel-notices.country.PCN:Pitcairn`, code: "PCN" },
  { name: $localize`:@@cards.travel-notices.country.POL:Poland`, code: "POL" },
  { name: $localize`:@@cards.travel-notices.country.PRT:Portugal`, code: "PRT" },
  { name: $localize`:@@cards.travel-notices.country.PRI:Puerto Rico`, code: "PRI" },
  { name: $localize`:@@cards.travel-notices.country.QAT:Qatar`, code: "QAT" },
  { name: $localize`:@@cards.travel-notices.country.REU:Réunion`, code: "REU" },
  { name: $localize`:@@cards.travel-notices.country.ROU:Romania`, code: "ROU" },
  { name: $localize`:@@cards.travel-notices.country.RUS:Russian Federation`, code: "RUS" },
  { name: $localize`:@@cards.travel-notices.country.RWA:Rwanda`, code: "RWA" },
  { name: $localize`:@@cards.travel-notices.country.WSM:Samoa`, code: "WSM" },
  { name: $localize`:@@cards.travel-notices.country.SMR:San Marino`, code: "SMR" },
  { name: $localize`:@@cards.travel-notices.country.STP:Sao Tome and Principe`, code: "STP" },
  { name: $localize`:@@cards.travel-notices.country.SAU:Saudi Arabia`, code: "SAU" },
  { name: $localize`:@@cards.travel-notices.country.SEN:Senegal`, code: "SEN" },
  { name: $localize`:@@cards.travel-notices.country.SRB:Serbia`, code: "SRB" },
  { name: $localize`:@@cards.travel-notices.country.SYC:Seychelles`, code: "SYC" },
  { name: $localize`:@@cards.travel-notices.country.SLE:Sierra Leone`, code: "SLE" },
  { name: $localize`:@@cards.travel-notices.country.SGP:Singapore`, code: "SGP" },
  { name: $localize`:@@cards.travel-notices.country.SXM:Sint Maarten`, code: "SXM" },
  { name: $localize`:@@cards.travel-notices.country.SVK:Slovakia`, code: "SVK" },
  { name: $localize`:@@cards.travel-notices.country.SVN:Slovenia`, code: "SVN" },
  { name: $localize`:@@cards.travel-notices.country.SLB:Solomon Islands`, code: "SLB" },
  { name: $localize`:@@cards.travel-notices.country.SOM:Somalia`, code: "SOM" },
  { name: $localize`:@@cards.travel-notices.country.ZAF:South Africa`, code: "ZAF" },
  { name: $localize`:@@cards.travel-notices.country.SGS:South Georgia and the South Sandwich Islands`, code: "SGS" },
  { name: $localize`:@@cards.travel-notices.country.SSD:South Sudan`, code: "SSD" },
  { name: $localize`:@@cards.travel-notices.country.ESP:Spain`, code: "ESP" },
  { name: $localize`:@@cards.travel-notices.country.LKA:Sri Lanka`, code: "LKA" },
  { name: $localize`:@@cards.travel-notices.country.SHN:St. Helena, Ascension, and Tristan da Cunha`, code: "SHN" },
  { name: $localize`:@@cards.travel-notices.country.KNA:St. Kitts and Nevis`, code: "KNA" },
  { name: $localize`:@@cards.travel-notices.country.LCA:St. Lucia`, code: "LCA" },
  { name: $localize`:@@cards.travel-notices.country.MAF:St. Martin`, code: "MAF" },
  { name: $localize`:@@cards.travel-notices.country.SPM:St. Pierre and Miquelon`, code: "SPM" },
  { name: $localize`:@@cards.travel-notices.country.VCT:St. Vincent and the Grenadines`, code: "VCT" },
  { name: $localize`:@@cards.travel-notices.country.SDN:Sudan`, code: "SDN" },
  { name: $localize`:@@cards.travel-notices.country.SUR:Suriname`, code: "SUR" },
  { name: $localize`:@@cards.travel-notices.country.SJM:Svalbard and Jan Mayen Islands`, code: "SJM" },
  { name: $localize`:@@cards.travel-notices.country.SWE:Sweden`, code: "SWE" },
  { name: $localize`:@@cards.travel-notices.country.CHE:Switzerland`, code: "CHE" },
  { name: $localize`:@@cards.travel-notices.country.SYR:Syrian Arab Republic`, code: "SYR" },
  { name: $localize`:@@cards.travel-notices.country.TWN:Taiwan`, code: "TWN" },
  { name: $localize`:@@cards.travel-notices.country.TJK:Tajikistan`, code: "TJK" },
  { name: $localize`:@@cards.travel-notices.country.TZA:Tanzania`, code: "TZA" },
  { name: $localize`:@@cards.travel-notices.country.THA:Thailand`, code: "THA" },
  { name: $localize`:@@cards.travel-notices.country.TLS:Timor-Leste`, code: "TLS" },
  { name: $localize`:@@cards.travel-notices.country.TGO:Togo`, code: "TGO" },
  { name: $localize`:@@cards.travel-notices.country.TKL:Tokelau`, code: "TKL" },
  { name: $localize`:@@cards.travel-notices.country.TON:Tonga`, code: "TON" },
  { name: $localize`:@@cards.travel-notices.country.TTO:Trinidad and Tobago`, code: "TTO" },
  { name: $localize`:@@cards.travel-notices.country.TUN:Tunisia`, code: "TUN" },
  { name: $localize`:@@cards.travel-notices.country.TUR:Turkey`, code: "TUR" },
  { name: $localize`:@@cards.travel-notices.country.TKM:Turkmenistan`, code: "TKM" },
  { name: $localize`:@@cards.travel-notices.country.TCA:Turks and Caicos Islands`, code: "TCA" },
  { name: $localize`:@@cards.travel-notices.country.TUV:Tuvalu`, code: "TUV" },
  { name: $localize`:@@cards.travel-notices.country.UGA:Uganda`, code: "UGA" },
  { name: $localize`:@@cards.travel-notices.country.UKR:Ukraine`, code: "UKR" },
  { name: $localize`:@@cards.travel-notices.country.ARE:United Arab Emirates`, code: "ARE" },
  { name: $localize`:@@cards.travel-notices.country.GBR:United Kingdom`, code: "GBR" },
  {
    name: $localize`:@@cards.travel-notices.country.QZZ:United Nations Interim Administration Mission in Kosovo`,
    code: "QZZ",
  },
  {
    name: $localize`:@@cards.travel-notices.country.USA:United States`,
    code: "USA",
    regions: usStates,
  },
  { name: $localize`:@@cards.travel-notices.country.UMI:United States Minor Outlying Islands`, code: "UMI" },
  { name: $localize`:@@cards.travel-notices.country.VIR:United States Virgin Islands`, code: "VIR" },
  { name: $localize`:@@cards.travel-notices.country.URY:Uruguay`, code: "URY" },
  { name: $localize`:@@cards.travel-notices.country.UZB:Uzbekistan`, code: "UZB" },
  { name: $localize`:@@cards.travel-notices.country.VUT:Vanuatu`, code: "VUT" },
  { name: $localize`:@@cards.travel-notices.country.VEN:Venezuela`, code: "VEN" },
  { name: $localize`:@@cards.travel-notices.country.VNM:Vietnam`, code: "VNM" },
  { name: $localize`:@@cards.travel-notices.country.WLF:Wallis and Futuna Islands`, code: "WLF" },
  { name: $localize`:@@cards.travel-notices.country.ESH:Western Sahara`, code: "ESH" },
  { name: $localize`:@@cards.travel-notices.country.YEM:Yemen`, code: "YEM" },
  { name: $localize`:@@cards.travel-notices.country.ZMB:Zambia`, code: "ZMB" },
  { name: $localize`:@@cards.travel-notices.country.ZWE:Zimbabwe`, code: "ZWE" },
].filter((country) => !countriesWithTravelBan.includes(country.code));
