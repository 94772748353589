import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { RestrictedDatesPayload } from "../../models/restricted-dates";

@Injectable({
  providedIn: "root",
})
export class RestrictedDatesService {
  private getHolidaysUrl = "/api/payment-order-a2a/client-api/v1/calendars/restricted-dates";

  constructor(private http: HttpClient) {}

  getHolidays = (startWithDate: string, endWithDate: string): Observable<RestrictedDatesPayload> => {
    const options = {
      params: {
        startWithDate,
        endWithDate,
      },
    };
    return this.http.get<RestrictedDatesPayload>(this.getHolidaysUrl, options);
  };
}
