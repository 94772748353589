<div class="card bb-block">
  <div class="card-body">
    <div class="icon-container">
      <i aria-hidden="true" class="bb-icon icon ent-fa-regular" [ngClass]="iconClass"></i>
    </div>
    <div class="my-4">
      <bb-header-ui headingType="h5" [heading]="title"></bb-header-ui>
      <p class="bb-subtitle bb-text-support">{{subtitle}}</p>
    </div>
    <div class="product-footer">
      <span><bb-badge-ui *ngIf="rateLabel" [text]="rateLabel" color="info"></bb-badge-ui></span>
      <button class="btn btn-link" (click)="onAddClick()" [disabled]="this.disabled">
        <span class="buttonLabel">{{buttonLabel}}</span>
        <i aria-hidden="true" class="bb-icon bb-icon-arrow-forward"></i>
      </button>
    </div>
  </div>
</div>
