<div class="card">
  <div class="card-body">
    <ent-page-success
      [successText]="successText"
      [successHeaderText]="successHeaderText"
      [cancelButtonText]="textService.successButtonLabel"
      (clickCancel)="stepsService.setCurrentStep(formStates.EXPLORE)"
    ></ent-page-success>
  </div>
</div>
