import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { END_TYPE, FREQUENCY_SELECTOR, OCCURRENCE } from "@ent/data";
import { DateTime } from "luxon";
import { IFormErrors } from "../../models/form-errors";
import { ISchedulingFormTextService } from "../../services/scheduling-form.text.service";

@Component({
  selector: "ent-move-money-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
})
export class ScheduleComponent implements OnInit, OnChanges {
  @Input() controlFormGroup!: FormGroup;
  @Input() schedulingTextService!: ISchedulingFormTextService;
  @Input() errors?: IFormErrors;
  @Input() minEndDate?: string;
  @Input() isMortgage?: boolean;
  @Input() isPastDue?: boolean;
  @Input() hasPartialPayment?: boolean;
  @Input() isExternalTransfer?: boolean;
  @Input() isOtherAmountSelected?: boolean;
  @Input() minExecutionDate?: Date;
  @Input() restrictedDates?: Array<string> = [];

  occurrencesEnum = OCCURRENCE;
  endType = END_TYPE;
  restrictedDatesFunction;

  minDate: string;

  occurrences: { label: string; value: OCCURRENCE }[] = [];

  frequencies: { label: string; value: FREQUENCY_SELECTOR }[] = [];

  ngOnInit(): void {
    this.setOccurrences();
    this.setFrequencies();
    this.setMinDate();
    this.setRestrictedDatesFunction();
  }

  ngOnChanges(): void {
    this.setOccurrences();
    this.setFrequencies();
    this.setMinDate();
  }

  setMinDate() {
    this.minDate = this.minExecutionDate?.toString();
  }

  setOccurrences() {
    const allOccurrences = [
      { label: this.schedulingTextService.nowLabel, value: OCCURRENCE.NOW },
      { label: this.schedulingTextService.laterLabel, value: OCCURRENCE.LATER },
      { label: this.schedulingTextService.recurringLabel, value: OCCURRENCE.RECURRING },
    ];
    const externalOccurrences = [
      { label: this.schedulingTextService.laterLabel, value: OCCURRENCE.LATER },
      { label: this.schedulingTextService.recurringLabel, value: OCCURRENCE.RECURRING },
    ];
    const externalPastDueOccurrences = [{ label: this.schedulingTextService.laterLabel, value: OCCURRENCE.LATER }];
    let occurrences = allOccurrences;
    if (this.isExternalTransfer) {
      occurrences = externalOccurrences;
    }
    if (this.isExternalTransfer && this.isPastDue) {
      occurrences = externalPastDueOccurrences;
    }
    if (!this.isMortgage && this.hasPartialPayment && !this.isOtherAmountSelected) {
      occurrences = occurrences.filter((occurrence) => occurrence.value !== OCCURRENCE.RECURRING);
    }
    this.occurrences = occurrences;
  }

  setFrequencies() {
    const mortgageFrequencies = [
      { label: this.schedulingTextService.frequencyMonthlyLabel, value: FREQUENCY_SELECTOR.MONTHLY },
    ];
    const allFrequencies = [
      { label: this.schedulingTextService.frequencyWeeklyLabel, value: FREQUENCY_SELECTOR.WEEKLY },
      { label: this.schedulingTextService.frequencyBiWeeklyLabel, value: FREQUENCY_SELECTOR.BIWEEKLY },
      ...mortgageFrequencies,
      { label: this.schedulingTextService.frequencyBiMonthlyLabel, value: FREQUENCY_SELECTOR.BIMONTHLY },
      { label: this.schedulingTextService.frequencyQuarterlyLabel, value: FREQUENCY_SELECTOR.QUARTERLY },
      { label: this.schedulingTextService.frequencySemiAnnuallyLabel, value: FREQUENCY_SELECTOR.SEMIANNUALLY },
      { label: this.schedulingTextService.frequencyAnnuallyLabel, value: FREQUENCY_SELECTOR.ANNUALLY },
    ];
    this.frequencies = this.isMortgage ? mortgageFrequencies : allFrequencies;
  }

  setRestrictedDatesFunction() {
    this.restrictedDatesFunction = (ngbDate: { year: number; month: number; day: number }) => {
      const dateToCheck = DateTime.local(ngbDate.year, ngbDate.month, ngbDate.day);
      return (
        this.restrictedDates.includes(dateToCheck.toISODate()) || dateToCheck.weekday === 6 || dateToCheck.weekday === 7
      );
    };
  }
}
