import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, of, map, combineLatest } from "rxjs";
import { DateTime } from "luxon";
import {
  getMinimumEndDateFromStartDate,
  getEndTypeFromDuration,
  OCCURRENCE,
  getPaymentModeFromOccurrence,
  getOccurrenceFromPaymentMode,
  FREQUENCY_SELECTOR,
  PAYMENT_OPTION,
  getRepeat,
  isPastDue,
  ArrangementsHTTPService,
  getLoanPaymentAmount,
  isProductAMortgage,
  formatDateTimeString,
  getLocalDateTimeFromUTC,
  getPaymentDue,
  isInGracePeriod,
  FlatProductItem,
  convertToFlatProductItem,
  isPaymentScheduleable,
  ExternalTransfersHTTPService,
  convertExternalProductToFlatProductItem,
  hasPartialPayment,
  RetailAppRemoteConfig,
  RestrictedDatesService,
} from "@ent/data";
import { RemoteConfigService } from "@backbase/remote-config-ang";
import { BaseFormComponent } from "../../components/base-form/base-form.component";
import { PaymentsFormErrors, PAYMENTS_ERROR_KEY } from "../../models/payment-errors";
import { paymentsFormSchema, PaymentFormModel } from "../../models/payment-model";
import { PaymentsFormTextService } from "../services/payments-form.text.service";
import { IFormTextService } from "../../services/form.text.service";

@Component({
  selector: "ent-payment-form",
  templateUrl: "./payment-form.component.html",
})
export class PaymentFormComponent extends BaseFormComponent implements OnInit {
  @Input()
  formModel: PaymentFormModel;

  @Input()
  isLoading: boolean;

  @Output()
  validateForm = new EventEmitter<PaymentFormModel>();

  fromProducts: Observable<FlatProductItem[]> = of([]);
  toProducts: Observable<FlatProductItem[]> = of([]);

  constructor(
    public readonly paymentsFormTextService: PaymentsFormTextService,
    public readonly arrangementsHTTPService: ArrangementsHTTPService,
    public readonly externalTransferHTTPService: ExternalTransfersHTTPService,
    restrictedDatesService: RestrictedDatesService,
    remoteConfigService: RemoteConfigService<RetailAppRemoteConfig>,
  ) {
    super(arrangementsHTTPService, remoteConfigService, restrictedDatesService);
  }

  isSchedulingShown: boolean;
  isMortgage = false;
  isPastDue = false;
  isExternalTransfer = false;
  isOtherAmountSelected = false;
  hasPartialPayment = false;

  ngOnInit(): void {
    this.form = new FormGroup({
      fromAccount: new FormControl(""),
      toAccount: new FormControl(""),
      paymentOption: new FormControl(PAYMENT_OPTION.MONTHLY_DUE),
      amount: new FormControl(undefined),
      occurrence: new FormControl(OCCURRENCE.NOW),
      executionDate: new FormControl(this.todayISO),
      frequency: new FormControl(FREQUENCY_SELECTOR.ONCE),
      endType: new FormControl(undefined),
      endDate: new FormControl(undefined),
      repeat: new FormControl(undefined),
      memo: new FormControl(""),
    });
    this.resetForm(this.formModel);
    this.setupFormEvents();
    this.isSchedulingShown = this.toAccount
      ? isPaymentScheduleable(this.toAccount, this.form.controls.paymentOption.value)
      : false;
    this.isMortgage = this.toAccount ? isProductAMortgage(this.toAccount) : false;
    this.isPastDue = this.toAccount ? isPastDue(this.toAccount) : false;
    this.hasPartialPayment = this.toAccount ? hasPartialPayment(this.toAccount) : false;
    this.getCutoffTime();
    this.getRestrictedDates();
    this.runYupValidation();
    this.runYupValidation();
    this.fromProducts = combineLatest([
      this.arrangementsHTTPService.fromProductsObservable.pipe(
        map((productItems) => productItems.map(convertToFlatProductItem)),
      ),

      this.externalTransferHTTPService.externalTransferAccountsObservable.pipe(
        map((productItems) => productItems.map(convertExternalProductToFlatProductItem)),
      ),
    ]).pipe(map((productItems) => [...productItems[0], ...productItems[1]]));
    this.toProducts = this.arrangementsHTTPService.toProductsObservable.pipe(
      map((productItems) => productItems.map(convertToFlatProductItem)),
    );
    this.isExternalTransfer = this.isOneExternal(this.fromAccount, this.toAccount);
  }

  resetForm = (defaultModel?: PaymentFormModel) => {
    this.fromAccount = defaultModel?.fromAccount;
    this.toAccount = defaultModel?.toAccount;
    this.form.reset({
      fromAccount: defaultModel?.fromAccount?.id,
      toAccount: defaultModel?.toAccount?.id,
      paymentOption: defaultModel?.paymentOption ?? PAYMENT_OPTION.MONTHLY_DUE,
      amount: !defaultModel?.amount ? undefined : defaultModel.amount,
      occurrence: getOccurrenceFromPaymentMode(defaultModel?.paymentMode, defaultModel?.executionDate),
      frequency: defaultModel?.frequency,
      executionDate: getLocalDateTimeFromUTC(defaultModel?.executionDate)?.toISO() ?? this.todayISO,
      endType: defaultModel?.endType,
      endDate: getLocalDateTimeFromUTC(defaultModel?.endDate)?.toISO(),
      repeat: defaultModel?.repeat,
      memo: defaultModel?.memo,
    });
  };

  onAccountSelection = () => {
    const { occurrence } = this.form.controls;
    this.isExternalTransfer = this.isOneExternal(this.fromAccount, this.toAccount);
    if (this.isExternalTransfer) occurrence.reset(OCCURRENCE.LATER);
  };

  setupFormEvents = () => {
    const { toAccount, amount, paymentOption, frequency, executionDate, fromAccount, occurrence, endType } =
      this.form.controls;
    toAccount.valueChanges.subscribe(() => {
      this.clearPaymentDetails();
      this.isMortgage = this.toAccount ? isProductAMortgage(this.toAccount) : false;
      this.hasPartialPayment = this.toAccount ? hasPartialPayment(this.toAccount) : false;
      this.onAccountSelection();
    });
    fromAccount.valueChanges.subscribe(() => {
      amount.updateValueAndValidity();
      paymentOption.updateValueAndValidity();
      this.onAccountSelection();
    });
    paymentOption.valueChanges.subscribe(this.onPaymentOptionChange);
    occurrence.valueChanges.subscribe(this.onOccurrenceChange);
    executionDate.valueChanges.subscribe(this.onExecutionDateChange);
    frequency.valueChanges.subscribe(this.onFrequencyChange);
    endType.valueChanges.subscribe(this.onEndTypeChange);
    this.form.valueChanges.subscribe(() => {
      this.runYupValidation();
      this.isSchedulingShown = isPaymentScheduleable(this.toAccount, this.form.controls.paymentOption.value);
    });
  };

  onPaymentOptionChange = () => {
    const { amount, paymentOption, occurrence } = this.form.controls;
    amount.reset();
    this.isOtherAmountSelected = paymentOption.value === PAYMENT_OPTION.OTHER_AMOUNT;
    if (paymentOption.value === PAYMENT_OPTION.PAY_OFF) {
      occurrence.reset(OCCURRENCE.NOW);
    }
  };

  clearPaymentDetails = () => {
    const { paymentOption, amount, occurrence, memo, frequency, endType, endDate, repeat } = this.form.controls;
    if (this.toAccount && isPastDue(this.toAccount)) {
      paymentOption.reset(PAYMENT_OPTION.PAST_DUE);
    } else {
      paymentOption.reset(PAYMENT_OPTION.MONTHLY_DUE);
    }

    amount.reset(undefined);
    occurrence.reset(OCCURRENCE.NOW);
    frequency.reset(FREQUENCY_SELECTOR.ONCE);
    endType.reset(undefined);
    endDate.reset(undefined);
    repeat.reset(undefined);
    memo.reset("");
  };

  runYupValidation = () => {
    this.errors = undefined;
    const {
      executionDate,
      endDate,
      amount,
      fromAccount,
      toAccount,
      occurrence,
      frequency,
      repeat,
      memo,
      endType,
      paymentOption,
    } = this.form.controls;
    try {
      const executionDateValue = DateTime.fromISO(executionDate.value);
      const endDateValue = DateTime.fromISO(endDate.value);
      paymentsFormSchema.validateSync(
        {
          fromAccountId: fromAccount.value ?? undefined,
          fromAccountBalance: this.fromAccount?.availableBalance,
          toAccountId: toAccount.value ?? undefined,
          toAccountBalance: this.toAccount?.bookedBalance,
          toAccountArrangementType: this.toAccount?.arrangementType ?? undefined,
          isPastDue: this.toAccount ? isPastDue(this.toAccount) : undefined,
          isInGracePeriod: this.toAccount ? isInGracePeriod(this.toAccount) : undefined,
          paymentOptionDueAmount: this.toAccount
            ? getLoanPaymentAmount(amount.value, paymentOption.value, this.toAccount)
            : undefined,
          monthlyDueAmount: this.toAccount ? getPaymentDue(this.toAccount) : undefined,
          paymentOption: paymentOption.value,
          amount: Number.isFinite(Number.parseFloat(amount.value)) ? amount.value : undefined,
          occurrence: occurrence.value ?? undefined,
          frequency: frequency.value ?? undefined,
          executionDate: executionDateValue.isValid ? executionDateValue.toISO() : undefined,
          endType: endType.value ?? undefined,
          endDate: endDateValue.isValid ? endDateValue.toISO() : undefined,
          repeat: Number.isInteger(Number.parseInt(repeat.value, 10)) ? repeat.value : undefined,
          memo: memo.value ?? undefined,
          minExecutionDate: this.minExecutionDate ?? undefined,
        },
        { abortEarly: false },
      );
    } catch (err) {
      this.setErrors(err.errors);
    }
  };

  getAmountErrors = (errors: PAYMENTS_ERROR_KEY[]) => {
    const { paymentOption } = this.form.controls;
    let amount: string | undefined;
    if (errors.includes(PAYMENTS_ERROR_KEY.AMOUNT_REQUIRED) && paymentOption.value === PAYMENT_OPTION.OTHER_AMOUNT) {
      amount = this.paymentsFormTextService.otherAmountRequiredError;
    }
    if (errors.includes(PAYMENTS_ERROR_KEY.AMOUNT_REQUIRED) && paymentOption.value === PAYMENT_OPTION.PRINCIPAL) {
      amount = this.paymentsFormTextService.principalAmountRequiredError;
    }
    if (errors.includes(PAYMENTS_ERROR_KEY.AMOUNT_TO_MAX)) {
      amount = this.paymentsFormTextService.amountToMaxError;
    }
    if (errors.includes(PAYMENTS_ERROR_KEY.AMOUNT_FROM_MAX)) {
      amount = this.paymentsFormTextService.amountFromMaxError(this.fromAccount);
    }
    if (errors.includes(PAYMENTS_ERROR_KEY.AMOUNT_PAYMENT_MIN) && paymentOption.value === PAYMENT_OPTION.OTHER_AMOUNT) {
      amount = this.paymentsFormTextService.otherAmountMinimumError(this.toAccount);
    }
    if (errors.includes(PAYMENTS_ERROR_KEY.AMOUNT_PAYMENT_MIN) && paymentOption.value === PAYMENT_OPTION.PRINCIPAL) {
      amount = this.paymentsFormTextService.principalAmountMinimumError;
    }

    return amount;
  };

  getExecutionDateErrors = (errors: PAYMENTS_ERROR_KEY[]) => {
    let executionDate: string | undefined;
    if (errors.includes(PAYMENTS_ERROR_KEY.EXECUTION_DATE_REQUIRED)) {
      executionDate = this.paymentsFormTextService.executionDateRequiredError;
    }
    if (errors.includes(PAYMENTS_ERROR_KEY.EXECUTION_DATE_MIN)) {
      executionDate = this.paymentsFormTextService.executionDatePastError;
    }
    return executionDate;
  };

  getEndDateErrors = (errors: PAYMENTS_ERROR_KEY[]) => {
    let endDate: string | undefined;

    if (errors.includes(PAYMENTS_ERROR_KEY.END_DATE_REQUIRED)) {
      endDate = this.paymentsFormTextService.endDateRequiredError;
    }
    if (errors.includes(PAYMENTS_ERROR_KEY.END_DATE_MIN)) {
      const minDate = DateTime.fromISO(
        getMinimumEndDateFromStartDate(
          this.form.controls.frequency.value,
          getLocalDateTimeFromUTC(this.form.controls.executionDate.value)?.toISO(),
        ),
      );
      endDate = this.paymentsFormTextService.endDateLessThanMinDateError(minDate.toFormat("LLLL dd, yyyy"));
    }
    return endDate;
  };

  setErrors = (errors: PAYMENTS_ERROR_KEY[] = []) => {
    this.errors = {
      fromAccount: errors.includes(PAYMENTS_ERROR_KEY.FROM_REQUIRED)
        ? this.paymentsFormTextService.fromAccountRequiredError
        : undefined,
      toAccount: errors.includes(PAYMENTS_ERROR_KEY.TO_REQUIRED)
        ? this.paymentsFormTextService.toAccountRequiredError
        : undefined,
      paymentOption: errors.includes(PAYMENTS_ERROR_KEY.FROM_INSUFFICIENT_FUNDS)
        ? this.paymentsFormTextService.insufficientFundsError
        : undefined,
      amount: this.getAmountErrors(errors),
      executionDate: this.getExecutionDateErrors(errors),
      endDate: this.getEndDateErrors(errors),
      repeat: errors.includes(PAYMENTS_ERROR_KEY.REPEAT_REQUIRED)
        ? this.paymentsFormTextService.repeatRequiredError
        : undefined,
    } as PaymentsFormErrors;
  };

  onValidateForm = () => {
    const { amount, executionDate, occurrence, memo, frequency, endType, repeat, endDate, paymentOption } =
      this.form.controls;
    this.form.markAllAsTouched();
    this.getCutoffTime();
    this.runYupValidation();
    if (this.errors) {
      return;
    }
    this.validateForm.emit({
      amount: getLoanPaymentAmount(amount.value, paymentOption.value, this.toAccount),
      executionDate: formatDateTimeString(getLocalDateTimeFromUTC(executionDate.value)?.toISO(), "yyyy-LL-dd"),
      paymentMode: getPaymentModeFromOccurrence(occurrence.value),
      memo: memo.value,
      toAccount: this.toAccount,
      fromAccount: this.fromAccount,
      frequency: frequency.value,
      paymentOption: paymentOption.value,
      repeat: getRepeat(endType.value, repeat.value),
      endDate: this.getEndDate(endType.value, getLocalDateTimeFromUTC(endDate.value)?.toISO()),
      endType: getEndTypeFromDuration(repeat.value, getLocalDateTimeFromUTC(endDate.value)?.toISO()),
    });
  };

  getFormTextService = (): IFormTextService => {
    return this.paymentsFormTextService;
  };
}
