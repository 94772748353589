<div class="container--fixed-width mx-auto">
  <ent-stateful-component
    #statefulComponent
    [componentState]="componentState | async"
    [loadingText]="textService.loadingText"
    [errorHeaderText]="textService.moneyInsightErrorHeader"
    [errorText]="errorMessage"
    [contentTemplate]="loaded"
  >
  </ent-stateful-component>
  <ng-template #loaded>
    <div *ngIf="isRegistered; then enrolled else notenrolled"></div>
    <ng-template #enrolled>
      <ent-money-desktop-widget [url]="url" [title]="frameTitle"></ent-money-desktop-widget>
    </ng-template>
    <ng-template #notenrolled>
      <ent-money-insights-enrollment (userEnrollEvent)="onUserEnroll($event)"></ent-money-insights-enrollment>
    </ng-template>
  </ng-template>
</div>
