import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "@backbase/ui-ang/button";
import { TextService } from "./services/text.service";
import { LeavingEntModalComponent } from "./components/leaving-ent-modal/leaving-ent-modal.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: "", component: LeavingEntModalComponent, pathMatch: "full" }]),
    ButtonModule,
  ],
  declarations: [LeavingEntModalComponent],
  providers: [TextService],
})
export class CreditCardModule {}
