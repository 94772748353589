import { IFormErrors } from "./form-errors";

export enum PAYMENTS_ERROR_KEY {
  EXECUTION_DATE_REQUIRED = "executionDate.required",
  EXECUTION_DATE_MIN = "executionDate.min",
  FREQUENCY_REQUIRED = "frequency.required",
  END_TYPE_REQUIRED = "endType.required",
  END_DATE_REQUIRED = "endDate.required",
  END_DATE_MIN = "endDate.min",
  REPEAT_REQUIRED = "repeat.required",
  FROM_REQUIRED = "from.required",
  FROM_INSUFFICIENT_FUNDS = "from.insufficientFunds",
  TO_REQUIRED = "to.required",
  AMOUNT_MAX = "amount.max",
  AMOUNT_TO_MAX = "amount.to.max",
  AMOUNT_FROM_MAX = "amount.from.max",
  AMOUNT_REQUIRED = "amount.required",
  AMOUNT_PAYMENT_MIN = "amount.payment.min",
  PAYMENT_OPTION_REQUIRED = "paymentOption.required",
  OCCURRENCE_REQUIRED = "occurrence.required",
}

export interface PaymentsFormErrors extends IFormErrors {
  paymentOption?: string;
}
