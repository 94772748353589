import { Injectable } from "@angular/core";
import {
  ApprovalStatus,
  PaymentOrdersHttpService,
  PaymentOrdersPostResponse,
  PostPaymentOrdersRequestParams,
  PostValidateRequestParams,
} from "@backbase/payment-order-http-ang";
import { tap } from "rxjs";
import { LoadingService } from "../loading.service";
import { LOADING_KEYS } from "../../models/loading-keys";
import { APIError } from "../../models";

@Injectable({ providedIn: "root" })
export class PaymentOrderHTTPService {
  constructor(
    protected readonly paymentOrderHttpService: PaymentOrdersHttpService,
    protected readonly loadingService: LoadingService,
  ) {}

  validatePaymentOrder = (payload: PostValidateRequestParams) => {
    return new Promise((resolve, reject) => {
      this.loadingService.startLoading(LOADING_KEYS.VALIDATE_LOADING);
      this.paymentOrderHttpService.postValidate(payload).subscribe({
        next: (response) => {
          this.loadingService.stopLoading(LOADING_KEYS.VALIDATE_LOADING);
          resolve(response);
        },
        error: (err: APIError) => {
          this.loadingService.stopLoading(LOADING_KEYS.VALIDATE_LOADING);
          reject(err);
        },
      });
    });
  };

  postPaymentOrder = (payload: PostPaymentOrdersRequestParams) => {
    this.loadingService.startLoading(LOADING_KEYS.SUBMIT_LOADING);
    return this.paymentOrderHttpService.postPaymentOrders(payload).pipe(
      tap({
        next: (resp: PaymentOrdersPostResponse) => {
          this.loadingService.stopLoading(LOADING_KEYS.SUBMIT_LOADING);
          return resp;
        },
        error: (err) => {
          this.loadingService.stopLoading(LOADING_KEYS.SUBMIT_LOADING);
          return err;
        },
      }),
    );
  };

  isPaymentSuccessful = (response: PaymentOrdersPostResponse) => {
    const { status: _paymentStatus } = response;
    const isApprovalRejected = _paymentStatus === ApprovalStatus.REJECTED;
    return !!response.id && !isApprovalRejected;
  };
}
