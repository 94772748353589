/* eslint-disable @typescript-eslint/naming-convention */
import { Provider } from "@angular/core";
import { Route } from "@angular/router";
import {
  IdentitySelfServiceJourneyConfiguration,
  IdentitySelfServiceJourneyConfigurationToken,
  IdentitySelfServiceJourneyComponent,
  userProfileDefaultRoute,
  deviceManagementDefaultRoute,
  IdentityLoginSecurityJourneyConfigurationToken,
  IdentityLoginSecurityJourneyConfiguration,
} from "@backbase/identity-self-service-journey-ang";
import { RemoteConfigService } from "@backbase/remote-config-ang";
import { RetailAppRemoteConfig } from "@ent/data";

export const EntProfileConfigProvider: Provider = {
  provide: IdentitySelfServiceJourneyConfigurationToken,
  useValue: {
    userManageProfile: {
      notificationDismissTime: 5,
      maxEmailAddresses: 1,
      maxPhoneNumbers: 3,
      isAddressSearchAutocompleteActive: true,
      phoneNumberCountryCode: "US",
      maxPostalAddresses: 1,
      phoneOperationsEnabled: ["EDIT", "REMOVE"],
      phoneNumberTypes: ["Home", "Work", "Mobile"],
      emailAddressTypes: [],
      postalAddressTypes: [],
      emailAddressIsPrimaryEditable: false,
      emailAddressIsTypeEditable: false,
      postalAddressIsPrimaryEditable: false,
      postalAddressIsTypeEditable: false,
      phoneNumberIsPrimaryEditable: false,
    },
  } as Partial<IdentitySelfServiceJourneyConfiguration>,
};
export const getEntLoginSecurityConfig = (remoteConfig: RemoteConfigService<RetailAppRemoteConfig>) => {
  return {
    changeUsernameEnabled: remoteConfig.getValue("change_username_enabled"),
    hideCurrentUsername: false,
    changeUsernameSuccessPath: "/retail-app/logout/username-success",
    usernameValidationRules: [
      {
        pattern: "^(.{4,32})$",
        label: $localize`:Label for username length validation@@profile-security-change-username.validation-length:Between 4 and 32 characters`,
        validatorName: "usernameLength",
      },
      {
        pattern: "^[a-zA-Z0-9]+$",
        label: $localize`:Label for username alphanumeric validation@@profile-security-change-username.validation-allowed-characters:Only letters and numbers`,
        validatorName: "usernameAlphanumeric",
      },
      {
        pattern: "[a-zA-Z]",
        label: $localize`:Label for username letter validation@@profile-security-change-username.validation-alpha-required:At least one letter`,
        validatorName: "usernameLetter",
      },
      {
        pattern: "^[a-z0-9]+$",
        label: $localize`:Label for username lowercase validation@@profile-security-change-username.validation-lowercase:Only lowercase letters`,
        validatorName: "usernameLowerCase",
      },
    ],
  } as Partial<IdentityLoginSecurityJourneyConfiguration>;
};

export const EntLoginSecurityConfigProvider: Provider = {
  provide: IdentityLoginSecurityJourneyConfigurationToken,
  useFactory: getEntLoginSecurityConfig,
  deps: [RemoteConfigService],
};

export const defaultRoute: Route = {
  path: "",
  component: IdentitySelfServiceJourneyComponent,
  children: [
    { path: "", redirectTo: "profile", pathMatch: "full" },
    {
      path: "profile",
      data: {
        title: $localize`:Tab label for managing user profile@@bb-identity-self-service-journey.tab-user-manage-profile:Profile`,
      },
      children: [userProfileDefaultRoute],
    },
    {
      path: "login-security",

      data: {
        title: $localize`:Tab label for login and security settings@@bb-identity-self-service-journey.tab-login-security:Security`,
      },
      loadChildren: () => import("../security/security.module").then((m) => m.SecurityModule),
    },
    {
      path: "devices",

      data: { title: $localize`:Tab label for managing devices@@bb-identity-self-service-journey.tab-devices:Devices` },

      children: [deviceManagementDefaultRoute],
    },
    {
      path: "notification-preference",
      data: {
        title: $localize`:Tab label for notification preference@@bb-identity-self-service-journey.tab-notification-preference:Notification Preferences`,
      },
      loadChildren: () =>
        import("../notification-preference/notification-preference.module").then((m) => m.NotificationPreferenceModule),
    },
  ],
};
