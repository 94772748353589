<div class="col-md-12">
  <div class="px-0 pb-5 flex-column align-items-start">
    <bb-header-ui [heading]="textService.headingLabel" headingType="h1"> </bb-header-ui>
  </div>
  <bb-alert-ui
    *ngIf="(this.componentState | async) === 'MEMO_POST'"
    [title]="this.textService.memoPostNotificationHeader"
    modifier="warning"
  >
    <span data-role="failure-message"> {{this.textService.memoPostNotificationMessage}} </span>
  </bb-alert-ui>
  <bb-tab-group-ui *ngIf="this.cardsLayout?.bank" [initialSelection]="0" (select)="onTabSelect($event)">
    <bb-tab-ui>{{textService.bankLabel}}</bb-tab-ui>
    <bb-tab-ui>{{textService.borrowLabel}}</bb-tab-ui>
  </bb-tab-group-ui>
</div>
<ng-container [ngSwitch]="selectedTab" *ngFor="let section of cardsLayout | keyvalue">
  <ent-page-loader *ngIf="isLoading"></ent-page-loader>
  <div class="row" *ngSwitchCase="section.key">
    <ng-container *ngFor="let subsection of section.value | keyvalue : sortSubsections">
      <bb-header-ui class="m-3" headingType="h3" [heading]="textService[subsection.key + 'Label']"></bb-header-ui>
      <div *ngFor="let card of subsection.value" class="col-md-6 mb-3">
        <ent-product-card
          [title]="card.description"
          [subtitle]="card.subtitle"
          [iconClass]="card.iconClass"
          [rateLabel]="card.rateLabel"
          [buttonLabel]="card.section === sections.BANK ? textService.bankButtonLabel: card.subsection === subsections.CARDS?  textService.cardButtonLabel : textService.borrowButtonLabel"
          [attr.data-testid]="'product-'+card.section+'-'+card.type+card.subCode"
          (addClick)="onAddProduct(card)"
          [disabled]="(this.componentState | async) === 'MEMO_POST'"
        ></ent-product-card>
      </div>
    </ng-container>
  </div>
</ng-container>
