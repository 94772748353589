import { Router } from "@angular/router";
import { IBillpayEventHandler } from "./billpay-event-handler";

export class NavigateToAccountsEventHandler implements IBillpayEventHandler {
  constructor(private readonly router: Router) {}

  public handleEvent = () => {
    this.router.navigate(["/my-accounts"]);
  };
}
