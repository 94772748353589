import { PaymentMode } from "@backbase/payment-order-http-ang";
import { DateTime, DurationLike } from "luxon";
import { OCCURRENCE, PAYMENT_OPTION, FREQUENCY_SELECTOR, END_TYPE } from "../models/transfer-payments-constants";
import { SUNDAY, SATURDAY } from "../models/date";
import { FlatProductItem } from "../models/flat-product-item";
import { ARRANGEMENT_TYPE, HELOC_PRODUCT_NUMBERS, PLOC_PRODUCT_NUMBERS } from "../models/arrangement-types";
import { getLocalDateTimeFromUTC } from "./date.helper";

export const isProductALoan = (product: FlatProductItem) => `${product.arrangementType}` === ARRANGEMENT_TYPE.LOAN;

export const isProductALOC = (product: FlatProductItem) => `${product.arrangementType}` === ARRANGEMENT_TYPE.LOC;

export const isProductAPLOC = (product: FlatProductItem) =>
  isProductALOC(product) && PLOC_PRODUCT_NUMBERS.includes(`${product.productNumber}`);

export const isProductAHELOC = (product: FlatProductItem) =>
  isProductALOC(product) && HELOC_PRODUCT_NUMBERS.includes(`${product.productNumber}`);

export const isProductAMortgage = (product: FlatProductItem) =>
  `${product.arrangementType}` === ARRANGEMENT_TYPE.MORTGAGE;

export const getFullBalance = (product: FlatProductItem): number =>
  Number.parseFloat(product.payoffAmount && product.payoffAmount.length > 0 ? product.payoffAmount : "0.00");

export const getRegularPayment = (product: FlatProductItem): number =>
  Number.parseFloat(product.regularPayment && product.regularPayment.length > 0 ? product.regularPayment : "0.00");

export const getPastDuePayment = (product: FlatProductItem): number => {
  const { minimumPayment } = product;
  if (isProductAMortgage(product)) {
    return getRegularPayment(product);
  }
  if (isProductALOC(product)) {
    return minimumPayment;
  }
  return minimumPayment - getRegularPayment(product);
};

export const getPaymentDueDate = (product: FlatProductItem): DateTime =>
  (getLocalDateTimeFromUTC(product.minimumPaymentDueDate) ?? DateTime.now()).startOf("day");

export const getFirstBusinessDayAfterThe15th = (date: DateTime) => {
  const dateAfter15 = date.set({ day: 1, month: date.month, year: date.year }).plus({ day: 15 });
  if (dateAfter15.weekday === SATURDAY) {
    return dateAfter15.plus({ day: 2 });
  }

  if (dateAfter15.weekday === SUNDAY) {
    return dateAfter15.plus({ day: 1 });
  }

  return dateAfter15;
};

export const isInGracePeriod = (product: FlatProductItem): boolean => {
  const today = DateTime.now();
  const paymentDueDate = getPaymentDueDate(product);
  const firstBusinessDayAfterThe15th = getFirstBusinessDayAfterThe15th(paymentDueDate);
  return isProductAMortgage(product) && today > paymentDueDate && today < firstBusinessDayAfterThe15th;
};

export const isPastDue = (product: FlatProductItem): boolean => {
  const paymentDueDate = getPaymentDueDate(product);
  const today = DateTime.now().startOf("day");
  return paymentDueDate < today && !isInGracePeriod(product) && !!product.bookedBalance && product.bookedBalance > 0;
};

export const getPaymentDue = (product: FlatProductItem): number => {
  const { minimumPayment } = product;
  return minimumPayment === 0 ? getRegularPayment(product) : minimumPayment;
};

export const isFutureDatedMortgagePayment = (toAccount: FlatProductItem, occurrence: OCCURRENCE) => {
  return isProductAMortgage(toAccount) && occurrence !== OCCURRENCE.NOW;
};

export const isPaymentScheduleable = (toAccount?: FlatProductItem, paymentOption?: PAYMENT_OPTION) => {
  if (!toAccount) return false;
  if (paymentOption === PAYMENT_OPTION.PAY_OFF) return false;
  if (paymentOption === PAYMENT_OPTION.PRINCIPAL) return false;

  return true;
};

export const getPaymentModeFromOccurrence = (occurrence: OCCURRENCE): PaymentMode => {
  switch (occurrence) {
    case OCCURRENCE.LATER:
    case OCCURRENCE.NOW:
      return PaymentMode.SINGLE;
    case OCCURRENCE.RECURRING:
      return PaymentMode.RECURRING;
    default:
      return PaymentMode.SINGLE;
  }
};

export const getOccurrenceFromPaymentMode = (paymentMode: PaymentMode, executionDate?: string): OCCURRENCE => {
  const format = "mm-dd-yyyy";
  const today = DateTime.now().startOf("day");
  const executionDateTime = executionDate ? DateTime.fromISO(executionDate).startOf("day") : today;
  if (paymentMode === PaymentMode.RECURRING) return OCCURRENCE.RECURRING;

  if (paymentMode === PaymentMode.SINGLE && today.toFormat(format) !== executionDateTime.toFormat(format)) {
    return OCCURRENCE.LATER;
  }

  return OCCURRENCE.NOW;
};

export const getEndTypeFromDuration = (repeat?: number, endDate?: string): END_TYPE => {
  if (repeat) return END_TYPE.AFTER;
  if (endDate) return END_TYPE.ON;
  return END_TYPE.NEVER;
};

export const endDateFrequencyMap = new Map<FREQUENCY_SELECTOR, DurationLike>();

endDateFrequencyMap.set(FREQUENCY_SELECTOR.WEEKLY, {
  weeks: 1,
});
endDateFrequencyMap.set(FREQUENCY_SELECTOR.BIWEEKLY, {
  weeks: 2,
});
endDateFrequencyMap.set(FREQUENCY_SELECTOR.MONTHLY, {
  months: 1,
});
endDateFrequencyMap.set(FREQUENCY_SELECTOR.BIMONTHLY, {
  months: 2,
});
endDateFrequencyMap.set(FREQUENCY_SELECTOR.QUARTERLY, {
  months: 3,
});
endDateFrequencyMap.set(FREQUENCY_SELECTOR.SEMIANNUALLY, {
  months: 6,
});
endDateFrequencyMap.set(FREQUENCY_SELECTOR.ANNUALLY, {
  years: 1,
});

export const getRepeat = (endType: string, repeat: number) => {
  return endType === END_TYPE.AFTER ? repeat : undefined;
};

export const getMinimumEndDateFromStartDate = (frequency: FREQUENCY_SELECTOR, date?: string) => {
  if (!date) return "";

  let startDate = DateTime.fromISO(date);
  const durationToAdd = endDateFrequencyMap.get(frequency);

  if (durationToAdd) {
    startDate = startDate.plus(durationToAdd);
  }

  return startDate.toISO();
};

export const getLoanPaymentAmount = (amount: number, paymentOption: PAYMENT_OPTION, toAccount: FlatProductItem) => {
  switch (paymentOption) {
    case PAYMENT_OPTION.PAST_DUE:
      return getRegularPayment(toAccount);
    case PAYMENT_OPTION.MONTHLY_DUE:
      return getPaymentDue(toAccount);
    case PAYMENT_OPTION.PAY_OFF:
      return getFullBalance(toAccount);
    default:
      return amount;
  }
};

export const hasPartialPayment = (product: FlatProductItem): boolean => {
  return product.minimumPayment % getRegularPayment(product) !== 0 && product.minimumPayment > 0;
};

export const getPartialPaymentAmount = (product: FlatProductItem): number => {
  const regularPayment = getRegularPayment(product);
  const { minimumPayment } = product;
  return regularPayment * Math.ceil(minimumPayment / regularPayment) - minimumPayment;
};
