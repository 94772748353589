import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SymitarStatusResponse } from "../../models";

@Injectable({
  providedIn: "root",
})
export class SymitarHttpService {
  private url = "/api/ent-online-account-opening/client-api/v1/status";

  constructor(private http: HttpClient) {}

  getSymitarStatus = (): Observable<SymitarStatusResponse> => {
    return this.http.get<SymitarStatusResponse>(this.url);
  };
}
