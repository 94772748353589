<div class="card card-body">
  <div class="bb-text-align-center bb-block--xl">
    <ent-success-svg></ent-success-svg>
    <div></div>
    <bb-header-ui
      headingClasses="bb-text-semi-bold"
      headingType="h2"
      [heading]="transferSuccessTextService.successHeader"
    ></bb-header-ui>
    <span class="bb-text-support">
      <ng-container
        *ngIf="paymentOrderPostResponse?.bankStatus === 'MEMO_POST'; then memopostText; else regularText"
      ></ng-container>
    </span>
  </div>
  <div class="bb-stack bb-stack--center btn-container">
    <div class="bb-stack__item">
      <button bbButton color="primary" (click)="onRestart()">{{ transferSuccessTextService.restartButton }}</button>
    </div>
    <div class="bb-stack__item">
      <button bbButton color="link" (click)="onNavigateToAccounts()">
        {{ transferSuccessTextService.accountsButton }}
      </button>
    </div>
  </div>
</div>

<ng-template #memopostText> {{transferSuccessTextService.memopostMessage}} </ng-template>

<ng-template #regularText>
  <div [ngSwitch]="occurrence">
    <ng-container *ngSwitchCase="occurrences.NOW">
      {{ transferSuccessTextService | translationFunctions: 'immediateSuccessMessage':formModel.amount:accountName }}
    </ng-container>
    <ng-container *ngSwitchCase="occurrences.LATER">
      {{ transferSuccessTextService | translationFunctions : 'scheduledLaterSuccessMessage' : formModel.amount :
      accountName : formModel.executionDate }}
    </ng-container>
    <ng-container *ngSwitchCase="occurrences.RECURRING">
      {{ transferSuccessTextService | translationFunctions:
      'scheduledRecurringSuccessMessage':frequency:formModel.amount:accountName }}
    </ng-container>
  </div>
</ng-template>
