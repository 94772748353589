import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "@backbase/ui-ang/notification";
import { IBillpayEventHandler } from "./billpay-event-handler";
import { BillpayAppEventType, EVENT_TYPE } from "../models/billpay-event";
import { MissingEventHandler } from "./missing-event.handler";
import { UrlEventHandler } from "./url-event.handler";
import { BillpayTextService } from "../service/billpay.text.service";
import { SuccessAddPayeeEventHandler } from "./success-add-payee-event.handler";
import { FailureAddPayeeEventHandler } from "./failure-add-payee-event.handler";
import { SuccessDeletePayeeEventHandler } from "./success-delete-payee-event.handler";
import { FailureDeletePayeeEventHandler } from "./failure-delete-payee-event.handler";
import { OpenCheckingEventHandler } from "./open-checking-event.handler";
import { FailureEditPayeeEventHandler } from "./failure-edit-payee-event.handler";
import { SuccessEditPayeeEventHandler } from "./success-edit-payee-event.handler";
import { UpdateProfileEventHandler } from "./update-profile-event.handler";
import { NavigateToAccountsEventHandler } from "./navigate-to-accounts-event.handler";
import { FailureValidateBillerEventHandler } from "./failure-validate-biller-event.handler";
import { FailureDeletePaymentEventHandler } from "./failure-delete-payment-event.handler";
import { SuccessDeletePaymentEventHandler } from "./success-delete-payment-event.handler";
import { FailureGetPaymentActivityEventHandler } from "./failure-get-payment-activity-event.handler";
import { FailureCancelEbillAutopaymentEventHandler } from "./failure-cancel-ebill-autopayment-event.handler";
import { SuccessCancelEbillAutoPaymentEventHandler } from "./success-cancel-ebill-autopayment-event.handler";
import { FailureEbillAutopaymentEventHandler } from "./failure-ebill-autopayment-event.handler";
import { FailureAddSingleBillPaymentEventHandler } from "./failure-add-single-bill-payment-event.handler";
import { FailureEditPaymentEventHandler } from "./failure-edit-payment-event.handler";
import { FailureEbillEnrollEventHandler } from "./failure-ebill-enroll-event.handler";
import { FailureEbillDisconnectEventHandler } from "./failure-ebill-disconnect-event.handler";
import { SuccessDisconnectEBillEventHandler } from "./success-disconnect-ebill-event.handler";

@Injectable({ providedIn: "root" })
export class BillpayEventFactory {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly textService: BillpayTextService,
    private readonly router: Router,
  ) {}

  public getEventHandler = (event: BillpayAppEventType<any>): IBillpayEventHandler => {
    switch (event.type) {
      case EVENT_TYPE.URL_CHANGED:
        return new UrlEventHandler();
      case EVENT_TYPE.ADD_BILLER_SUCCESS:
      case EVENT_TYPE.ADD_MANUAL_BILLER_SUCCESS:
        return new SuccessAddPayeeEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.ADD_BILLER_ERROR:
      case EVENT_TYPE.ADD_MANUAL_BILLER_ERROR:
        return new FailureAddPayeeEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.DELETE_PAYEE_SUCCESS:
        return new SuccessDeletePayeeEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.EDIT_BILLER_SUCCESS:
        return new SuccessEditPayeeEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.EDIT_BILLER_ERROR:
        return new FailureEditPayeeEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.DELETE_PAYEE_ERROR:
        return new FailureDeletePayeeEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.VALIDATE_BILLER_ERROR:
        return new FailureValidateBillerEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.NAVIGATE_OPEN_NEW_ACCOUNT:
        return new OpenCheckingEventHandler(this.router);
      case EVENT_TYPE.NAVIGATE_UPDATE_PROFILE:
        return new UpdateProfileEventHandler(this.router);
      case EVENT_TYPE.PAYMENT_ERROR:
        return new FailureAddSingleBillPaymentEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.DELETE_PAYMENT_ERROR:
        return new FailureDeletePaymentEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.DELETE_PAYMENT_SUCCESS:
        return new SuccessDeletePaymentEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.EDIT_PAYMENT_ERROR:
        return new FailureEditPaymentEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.GET_PAYMENT_SUMMARY_ERROR:
        return new FailureGetPaymentActivityEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.EBILL_CANCEL_AUTOPAYMENT_SUCCESS:
        return new SuccessCancelEbillAutoPaymentEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.EBILL_CANCEL_AUTOPAYMENT_ERROR:
        return new FailureCancelEbillAutopaymentEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.EBILL_AUTOPAYMENT_ERROR:
        return new FailureEbillAutopaymentEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.NAVIGATE_TO_ACCOUNTS:
        return new NavigateToAccountsEventHandler(this.router);
      case EVENT_TYPE.EBILL_ENROLL_ERROR:
        return new FailureEbillEnrollEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.EBILL_DISCONNECT_ERROR:
        return new FailureEbillDisconnectEventHandler(this.notificationService, this.textService);
      case EVENT_TYPE.EBILL_DISCONNECT_SUCCESS:
        return new SuccessDisconnectEBillEventHandler(this.notificationService, this.textService);
      default:
        return new MissingEventHandler();
    }
  };
}
