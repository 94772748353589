import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { AlertModule } from "@backbase/ui-ang/alert";
import { AmountModule } from "@backbase/ui-ang/amount";
import { ButtonModule } from "@backbase/ui-ang/button";
import { HeaderModule } from "@backbase/ui-ang/header";
import { InputNumberModule } from "@backbase/ui-ang/input-number";
import { InputRadioGroupModule } from "@backbase/ui-ang/input-radio-group";
import { InputValidationMessageModule } from "@backbase/ui-ang/input-validation-message";
import { LoadButtonModule } from "@backbase/ui-ang/load-button";
import { EntUIModule } from "@ent/ui";
import { PaymentsComponent } from "./payments.component";
import { PaymentFormComponent } from "./payment-form/payment-form.component";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { PaymentReviewComponent } from "./payment-review/payment-review.component";
import { PaymentTypeRadioGroupComponent } from "./payment-form/payment-type-radio-group/payment-type-radio-group.component";
import { MoneyMovementModule } from "../money-movement.module";

@NgModule({
  declarations: [
    PaymentsComponent,
    PaymentFormComponent,
    PaymentSuccessComponent,
    PaymentReviewComponent,
    PaymentTypeRadioGroupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: "", component: PaymentsComponent, pathMatch: "full" }]),
    EntUIModule,
    AlertModule,
    HeaderModule,
    AmountModule,
    LoadButtonModule,
    ButtonModule,
    InputRadioGroupModule,
    InputNumberModule,
    ReactiveFormsModule,
    InputValidationMessageModule,
    MoneyMovementModule,
  ],
  providers: [],
})
export class PaymentsModule {}
