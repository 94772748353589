<button bbbutton (click)="navigateToCardList()" type="button" color="link" class="btn-link btn btn-md">
  <em class="fa-solid fa-arrow-left me-2 mb-1"></em>
  {{ textService.cardDetailsBackButton }}
</button>
<bb-header-ui
  headingType="h1"
  [heading]="textService.cardDetailsHeader"
  headingClasses="bb-heading-widget__heading my-4"
></bb-header-ui>

<ent-stateful-component
  [componentState]="componentState"
  [loadingText]="loadingText"
  [errorHeaderText]="textService.cardDetailsErrorHeader"
  [errorText]="textService.cardDetailsErrorSubText"
  [contentTemplate]="loaded"
>
</ent-stateful-component>

<ng-template #loaded>
  <ng-container [ngSwitch]="cardsHTTPService.cardState">
    <ng-container *ngSwitchCase="cardStates.ACTIVE">
      <ng-container [ngTemplateOutlet]="activeCard"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="cardStates.INACTIVE">
      <ng-container [ngTemplateOutlet]="inactiveCard"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="cardStates.CANCELLED">
      <ng-container [ngTemplateOutlet]="cancelledCard"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="cardStates.ERROR">
      <ent-page-error
        [errorHeaderText]="textService.cardDetailsErrorHeader"
        [errorText]="textService.cardDetailsErrorSubText"
      ></ent-page-error>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #activeCard>
  <div class="mb-4">
    <bb-payment-card [paymentCard]="$any(cardsHTTPService.paymentCard)"></bb-payment-card>
  </div>
  <div class="mb-4">
    <ent-card-controls></ent-card-controls>
  </div>
  <div *ngIf="cardsHTTPService.isDebitCard">
    <ent-card-services [isLoading]="isLoadingCard"></ent-card-services>
  </div>
</ng-template>

<ng-template #inactiveCard>
  <div class="mb-4">
    <bb-payment-card [paymentCard]="$any(cardsHTTPService.paymentCard)"></bb-payment-card>
  </div>
  <div class="mb-4">
    <ent-card-activation [isATMCard]="cardsHTTPService.isATMCard"></ent-card-activation>
  </div>
</ng-template>

<ng-template #cancelledCard>
  <div class="mb-4">
    <bb-payment-card [paymentCard]="$any(cardsHTTPService.paymentCard)"></bb-payment-card>
  </div>
</ng-template>
