import { Injectable } from "@angular/core";

@Injectable({ providedIn: "any" })
export class NavmenuTextService {
  myAccountsLabel = $localize`:@@myAccounts.nav.item.description:My Accounts`;
  transfersLabel = $localize`:@@transfers.nav.item.description:Move Money`;
  subMenuLabel = $localize`:@@main.menu.nav.submenu.text:Main Menu`;
  makeTransferLabel = $localize`:@@make.transfer.nav.submenu.title:Make a Transfer`;
  payLoanLabel = $localize`:@@pay-loans.nav.submenu.title:Pay a Loan`;
  memberTransferLabel = $localize`:@@member-transfer.nav.submenu.title:Send Money to a Member`;
  moveMoneyActivityLabel = $localize`:@@upcoming-and-history-payments.journey.title:Activity`;
  connectedAccountsLabel = $localize`:@@connected.accounts.nav.submenu.title:Connected Accounts`;
  selfServiceLabel = $localize`:@@self.service.nav.item.description:Self Service`;
  myProfileLabel = $localize`:@@profile.nav.submenu.title:My Profile`;
  authorizedUsersLabel = $localize`:@@authorized.users.nav.submenu.title:Authorized Users`;
  manageCardsLabel = $localize`:@@manage.cards.nav.submenu.title:Manage Cards`;
  manageNotificationsLabel = $localize`:@@manage.notifications.nav.submenu.title:Manage Notifications`;
  manageContactsLabel = $localize`:@@manage.contacts.nav.submenu.title:Manage Contacts`;
  stopChecksLabel = $localize`:@@stop.checks.nav.submenu.title:Stop Checks`;
  downloadStatementsLabel = $localize`:@@download.statements.nav.submenu.title:Download Statements`;
  messagesLabel = $localize`:@@messages.nav.submenu.title:Messages`;
  moneyInsightsLabel = $localize`:@@money-insights.nav.submenu.title:Money Insight`;
  billpayLabel = $localize`:@@billpay.nav.submenu.title:Pay Bills`;
  helpCenterLabel = $localize`:@@help-center.nav.submenu.title:Help Center`;
}
