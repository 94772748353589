<ent-stateful-component
  [loadingTemplate]="loading"
  [errorHeaderText]="textService.errorHeaderText"
  [errorText]="textService.errorText"
  [componentState]="componentState | async"
  [contentTemplate]="loaded"
></ent-stateful-component>

<ng-template #loaded>
  <div class="bb-layout__container d-block bb-block--xl mt-5 container--fixed-width mx-auto">
    <bb-header-ui
      headingType="h1"
      [heading]="textService.titleText"
      headingClasses="bb-heading-widget__heading my-5"
    ></bb-header-ui>
  </div>
  <ent-billpay-iframe [legalEntityId]="legalEntityId"></ent-billpay-iframe>
</ng-template>

<ng-template #loading>
  <div class="bb-layout__container d-block bb-block--xl mt-5 container--fixed-width mx-auto">
    <ent-page-loader [loadingText]="textService.loadingText"></ent-page-loader>
  </div>
</ng-template>
