import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SUBCODES } from "@ent/data";
import { OpenShareBaseComponent } from "../open-share-base/open-share-base.component";

@Component({
  selector: "ent-product-information",
  templateUrl: "./product-information.component.html",
})
export class ProductInformationComponent extends OpenShareBaseComponent {
  subCodes = SUBCODES;
  @Input() canConvertChecking = false;
  @Output() convert = new EventEmitter();
  onConvert() {
    this.convert.emit(true);
  }
}
