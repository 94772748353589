import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, of } from "rxjs";
import { FlatProductItem, getAccountLabel } from "@ent/data";
import { OpenShareBaseComponent } from "../open-share-base/open-share-base.component";
import { PRODUCT_FORM_ERRORS } from "../../models/product-errors";

@Component({
  selector: "ent-product-deposit",
  templateUrl: "./product-deposit.component.html",
})
export class ProductDepositComponent extends OpenShareBaseComponent implements OnInit {
  @Input() amountFieldName!: string;
  @Input() accountFieldName!: string;
  @Input() fromProducts: Observable<FlatProductItem[]> = of([]);
  @Output() validateNext = new EventEmitter();
  minimumDeposit = 0;
  formErrors = PRODUCT_FORM_ERRORS;
  selectedProduct: FlatProductItem;

  ngOnInit(): void {
    this.minimumDeposit = Number(this.card?.minimumDeposit) || 0;
    this.setSelectedProduct();
  }

  setSelectedProduct() {
    const selectedAccountId = this.form.controls[this.accountFieldName]?.value;
    if (selectedAccountId) {
      this.fromProducts.subscribe({
        next: (flatProductItems) => {
          this.selectedProduct = flatProductItems.find((item) => item.id === selectedAccountId);
        },
      });
    }
  }

  onSelectProduct(selected: FlatProductItem) {
    this.selectedProduct = selected;
    this.card.fromAccountLabel = getAccountLabel(selected);
    this.card.fromAccountBalance = selected.availableBalance;
    this.form.controls[this.accountFieldName].setValue(selected.id);
  }

  onValidateNext() {
    this.form.controls[this.accountFieldName].markAsTouched();
    this.form.controls[this.amountFieldName].markAsTouched();
    this.validateNext.emit();
  }
}
