import { LoanInfoItemType } from "@backbase/internal-loans-shared-util";
import { texts } from "./loans-info.text.service";

const cssClasses = "col-sm-6 col-12";
const mortgageMonthlyPaymentDetailsSection = {
  title: texts.mortgage.monthlyMortgagePaymentSection.title,
  items: [
    {
      cssClasses,
      title: texts.mortgage.monthlyMortgagePaymentSection.principal,
      fieldKey: "currentPrincipalPayment",
      fieldType: LoanInfoItemType.String,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.monthlyMortgagePaymentSection.interest,
      fieldKey: "currentInterestPayment",
      fieldType: LoanInfoItemType.String,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.monthlyMortgagePaymentSection.totalRegularPayment,
      fieldKey: "currentTotalPayment",
      fieldType: LoanInfoItemType.String,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.monthlyMortgagePaymentSection.escrowPayment,
      fieldKey: "currentEscrowPayment",
      fieldType: LoanInfoItemType.String,
      isAdditions: true,
    },
    {
      cssClasses: `${cssClasses} blue-bold-child-text`,
      title: texts.mortgage.monthlyMortgagePaymentSection.paymentDue,
      fieldKey: "minimumPayment",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses: `${cssClasses} blue-bold-child-text`,
      title: texts.mortgage.monthlyMortgagePaymentSection.paymentDueDate,
      fieldKey: "paymentDueDate",
      fieldType: LoanInfoItemType.Date,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.monthlyMortgagePaymentSection.lastPayment,
      fieldKey: "lastPaymentAmount",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.monthlyMortgagePaymentSection.lastPaymentDate,
      fieldKey: "lastPaymentDate",
      fieldType: LoanInfoItemType.Date,
      isAdditions: true,
    },
  ],
};
const mortgageAdditionalInfoSection = {
  title: texts.mortgage.additionalInformationSection.title,
  items: [
    {
      cssClasses,
      title: texts.mortgage.additionalInformationSection.address,
      fieldKey: "alias",
      fieldType: LoanInfoItemType.String,
    },
    {
      cssClasses,
      title: texts.mortgage.additionalInformationSection.interestRate,
      fieldKey: "interestRate",
      fieldType: LoanInfoItemType.Percent,
    },
    {
      cssClasses,
      title: texts.mortgage.additionalInformationSection.balance,
      fieldKey: "outstandingAmount",
      fieldType: LoanInfoItemType.Amount,
    },
    {
      cssClasses,
      title: texts.mortgage.additionalInformationSection.escrowBalance,
      fieldKey: "currentEscrowBalance",
      fieldType: LoanInfoItemType.String,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.additionalInformationSection.ytdInterest,
      fieldKey: "ytdInterest",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.additionalInformationSection.previousYearInterest,
      fieldKey: "priorYearInterest",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.additionalInformationSection.ytdTax,
      fieldKey: "ytdTax",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses,
      title: texts.mortgage.additionalInformationSection.previousYearTax,
      fieldKey: "priorYtdTax",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
  ],
};

export const loanInfoMortgageDataConfig = [mortgageMonthlyPaymentDetailsSection, mortgageAdditionalInfoSection];
