import { LoanInfoItemType } from "@backbase/internal-loans-shared-util";
import { texts } from "./loans-info.text.service";

const halfWidthCssClasses = "col-sm-6 col-12";
const fullWidthCssClasses = "col-12";
const termLoanLineOfCreditPaymentSection = {
  title: texts.termLoanAndLineOfCredit.paymentSection.title,
  items: [
    {
      cssClasses: fullWidthCssClasses,
      title: texts.termLoanAndLineOfCredit.paymentSection.regularPayment,
      fieldKey: "regularPayment",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses: `${halfWidthCssClasses} blue-bold-child-text`,
      title: texts.termLoanAndLineOfCredit.paymentSection.paymentDue,
      fieldKey: "minimumPayment",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses: `${halfWidthCssClasses} blue-bold-child-text`,
      title: texts.termLoanAndLineOfCredit.paymentSection.paymentDueDate,
      fieldKey: "paymentDueDate",
      fieldType: LoanInfoItemType.Date,
      isAdditions: true,
    },
    {
      cssClasses: halfWidthCssClasses,
      title: texts.termLoanAndLineOfCredit.paymentSection.lastPaymentDate,
      fieldKey: "lastPaymentDate",
      fieldType: LoanInfoItemType.Date,
      isAdditions: true,
    },
  ],
};

const termLoanLineOfCreditAdditionalInformationSection = {
  title: texts.termLoanAndLineOfCredit.additionalInformationSection.title,
  items: [
    {
      cssClasses: halfWidthCssClasses,
      title: texts.termLoanAndLineOfCredit.additionalInformationSection.ytdInterest,
      fieldKey: "ytdInterest",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses: halfWidthCssClasses,
      title: texts.termLoanAndLineOfCredit.additionalInformationSection.previousYearInterest,
      fieldKey: "priorYearInterest",
      fieldType: LoanInfoItemType.Amount,
      isAdditions: true,
    },
    {
      cssClasses: halfWidthCssClasses,
      title: texts.termLoanAndLineOfCredit.additionalInformationSection.accountNumber,
      fieldKey: "micrAcctNumber",
      fieldType: LoanInfoItemType.String,
      isAdditions: true,
    },
    {
      cssClasses: halfWidthCssClasses,
      title: texts.termLoanAndLineOfCredit.additionalInformationSection.routingNumber,
      fieldKey: "routingNumber",
      fieldType: LoanInfoItemType.String,
      isAdditions: true,
    },
  ],
};

const termLoanLineOfCreditOtherSection = {
  title: texts.termLoanAndLineOfCredit.otherSection.title,
  items: [
    {
      cssClasses: halfWidthCssClasses,
      title: texts.termLoanAndLineOfCredit.otherSection.loanOpeningDate,
      fieldKey: "startDate",
      fieldType: LoanInfoItemType.Date,
    },
    {
      cssClasses: halfWidthCssClasses,
      title: texts.termLoanAndLineOfCredit.otherSection.jointOwners,
      fieldKey: "joints",
      fieldType: LoanInfoItemType.String,
      isAdditions: true,
    },
  ],
};

export const loanInfoTermLoanLineOfCreditDataConfig = [
  termLoanLineOfCreditPaymentSection,
  termLoanLineOfCreditAdditionalInformationSection,
  termLoanLineOfCreditOtherSection,
];
