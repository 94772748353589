import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CreditCardsHttpService {
  private baseURL = "/api/ent-credit-card-integration/client-api/v1/credit-card/sso";

  httpOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({ "Content-Type": "text/plain" }),
    responseType: "text" as const,
  };

  constructor(private http: HttpClient) {}

  getPaymentsSSO = (arrangementId: string) =>
    this.http.get(`${this.baseURL}/${arrangementId}/payments`, this.httpOptions);

  getAccountInfoSSO = (arrangementId: string) =>
    this.http.get(`${this.baseURL}/${arrangementId}/myaccount`, this.httpOptions);
}
