<div class="card card-body" [formGroup]="form">
  <ent-account-selector-form-control
    [products]="fromProducts | async"
    [selectedProduct]="fromAccount"
    [amountKey]="'availableBalance'"
    testId="from-selector"
    [label]="paymentsFormTextService.fromLabel"
    [noItemSelectedText]="paymentsFormTextService.selectAccount"
    [noItemsFoundText]="paymentsFormTextService.noAccounts"
    [productSelectorName]="'fromAccount'"
    [isInvalid]="!!form.controls.fromAccount.touched && !!errors?.fromAccount"
    [error]="errors?.fromAccount"
    (productSelectorIsOpenChange)="accountSelectorIsOpenChange('fromAccount')"
    (selectProduct)="onSelectFromAccount($any($event))"
  ></ent-account-selector-form-control>
  <ent-account-selector-form-control
    [products]="toProducts | async"
    [selectedProduct]="toAccount"
    [amountKey]="'bookedBalance'"
    testId="to-selector"
    [label]="paymentsFormTextService.toLabel"
    [noItemSelectedText]="paymentsFormTextService.selectAccount"
    [noItemsFoundText]="paymentsFormTextService.noAccounts"
    [productSelectorName]="'toAccount'"
    [isInvalid]="!!form.controls.toAccount.touched && !!errors?.toAccount"
    [error]="errors?.toAccount"
    (productSelectorIsOpenChange)="accountSelectorIsOpenChange('toAccount')"
    (selectProduct)="onSelectToAccount($any($event))"
  ></ent-account-selector-form-control>
  <ng-container *ngIf="!!toAccount; then paymentFormAfterAccountSelection; else null"> </ng-container>
</div>

<ng-template #paymentFormAfterAccountSelection>
  <ent-payment-type-radio-group
    [controlFormGroup]="form"
    [fromAccount]="fromAccount"
    [toAccount]="toAccount"
    [errors]="errors"
  ></ent-payment-type-radio-group>
  <ng-container *ngIf="isSchedulingShown">
    <ent-move-money-schedule
      [controlFormGroup]="form"
      [schedulingTextService]="paymentsFormTextService"
      [minEndDate]="minEndDate"
      [errors]="errors"
      [isMortgage]="isMortgage"
      [isPastDue]="isPastDue"
      [isExternalTransfer]="isExternalTransfer"
      [isOtherAmountSelected]="isOtherAmountSelected"
      [hasPartialPayment]="hasPartialPayment"
      [minExecutionDate]="minExecutionDate"
      [restrictedDates]="restrictedDates"
    ></ent-move-money-schedule>
  </ng-container>
  <ent-memo-form-control
    [controlFormGroup]="form"
    [memoLabel]="paymentsFormTextService.memoLabel"
    [memoHelperText]="paymentsFormTextService.memoHelperText"
    [memoPlaceholder]="paymentsFormTextService.memoPlaceholder"
  ></ent-memo-form-control>
  <div class="btn-container bb-dynamic-input bb-button-bar bb-button-bar--reverse">
    <bb-load-button-ui
      type="submit"
      class="bb-button-bar__button"
      [disabled]="isLoading"
      [isLoading]="isLoading"
      (click)="onValidateForm()"
    >
      {{ paymentsFormTextService.continueBtn }}
    </bb-load-button-ui>
    <button
      bbbutton
      (click)="resetForm()"
      type="button"
      color="link"
      class="bb-button-bar__button bb-button-bar__button--across btn-link btn btn-md"
    >
      {{ paymentsFormTextService.clearBtn }}
    </button>
  </div>
</ng-template>
