import { Component, OnChanges } from "@angular/core";
import { BaseSuccessComponent } from "../../components/base-success/base-success.component";
import { MemberTransferSuccessTextService } from "../services/member-transfer-success.text.service";

@Component({
  selector: "ent-member-transfer-success",
  templateUrl: "./member-transfer-success.component.html",
  styleUrls: ["./member-transfer-success.component.scss"],
})
export class MemberTransferSuccessComponent extends BaseSuccessComponent implements OnChanges {
  constructor(public readonly memberTransferSuccessTextService: MemberTransferSuccessTextService) {
    super();
  }

  getSuccessTextService = () => {
    return this.memberTransferSuccessTextService;
  };
}
