import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ContactFormViewModel } from "@backbase/internal-contact-manager-journey-ui";
import { PaymentMode, PaymentOrdersPost } from "@backbase/payment-order-http-ang";
import { NotificationService } from "@backbase/ui-ang/notification";
import {
  ArrangementsHTTPService,
  COMPONENT_STATE,
  ContactsHTTPService,
  FORM_STATE,
  LoadingService,
  LOADING_KEYS,
  PaymentOrderHTTPService,
  PRODUCT_KINDS,
} from "@ent/data";
import { BehaviorSubject } from "rxjs";
import { BaseWrapperComponent } from "../components/base-wrapper/base-wrapper.component";
import { IFormModel } from "../models/form-model";
import { MemberTransferTextService } from "./services/member-transfer.text.service";
import { APIErrorTextService } from "../services/api-error.text.service";

const IGNORE_PRODUCT_KINDS = [
  PRODUCT_KINDS.CREDIT_CARD,
  PRODUCT_KINDS.DEBIT_CARD,
  PRODUCT_KINDS.CONNECTED_ACCOUNT,
  PRODUCT_KINDS.INVESTMENT_ACCOUNT,
  PRODUCT_KINDS.TERM_DEPOSIT,
];

@Component({
  selector: "ent-member-transfers",
  templateUrl: "./member-transfers.component.html",
})
export class MemberTransfersComponent extends BaseWrapperComponent implements OnInit {
  formModel: IFormModel;
  componentState = COMPONENT_STATE.LOADING;
  isLoading = false;
  isLoadingAddContact = false;
  isOpen = new BehaviorSubject<boolean>(false);
  isSubmitError = new BehaviorSubject<boolean>(false);
  formStates = FORM_STATE;

  constructor(
    router: Router,
    loadingService: LoadingService,
    paymentOrderHTTPService: PaymentOrderHTTPService,
    arrangementHTTPService: ArrangementsHTTPService,
    notificationService: NotificationService,
    apiErrorTextService: APIErrorTextService,
    private readonly contactsHTTPService: ContactsHTTPService,
    public readonly textService: MemberTransferTextService,
  ) {
    super(
      router,
      loadingService,
      paymentOrderHTTPService,
      arrangementHTTPService,
      notificationService,
      apiErrorTextService,
    );
  }

  getInitialFormModel = () => ({
    fromAccount: undefined,
    toAccount: undefined,
    amount: 0,
    paymentMode: PaymentMode.SINGLE,
    executionDate: this.today.toISO(),
    memo: undefined,
    frequency: "ONCE",
  });

  loadProducts = async (fromIgnoredProductKindNames: PRODUCT_KINDS[]) => {
    this.componentState = COMPONENT_STATE.LOADING;
    try {
      await Promise.all([
        this.arrangementHTTPService.getFromProducts(fromIgnoredProductKindNames),
        this.contactsHTTPService.getContacts({}),
      ]);
      if (this.contactsHTTPService.contacts.length === 0) {
        this.setFormState(FORM_STATE.ACTION_NEEDED);
      } else {
        this.setFormState(FORM_STATE.EDITING);
      }
      this.componentState = COMPONENT_STATE.LOADED;
    } catch (err) {
      this.componentState = COMPONENT_STATE.ERROR;
    }
  };

  ngOnInit(): void {
    this.initialize();
    this.loadingService.isLoading(LOADING_KEYS.ADD_CONTACT).subscribe((isLoading) => {
      this.isLoadingAddContact = isLoading;
    });
    this.loadProducts(IGNORE_PRODUCT_KINDS);
  }

  restartForm() {
    super.restartForm();
    this.loadProducts(IGNORE_PRODUCT_KINDS);
  }

  createPaymentOrderFormRequest(formModel: IFormModel): PaymentOrdersPost {
    const paymentOrderRequest = super.createPaymentOrderFormRequest(formModel);
    paymentOrderRequest.transferTransactionInformation.counterpartyAccount.identification.schemeName = "BBAN";
    paymentOrderRequest.transferTransactionInformation.counterpartyAccount.identification.identification = "";
    paymentOrderRequest.transferTransactionInformation.counterpartyAccount.selectedContact = {
      contactId: formModel.toAccount?.id ?? "",
    };
    paymentOrderRequest.paymentType = "INTRABANK_TRANSFER";
    return paymentOrderRequest;
  }

  createContact = async (contactFormViewModel: ContactFormViewModel) => {
    if (this.isLoading || this.isLoadingAddContact) {
      return;
    }
    try {
      await this.contactsHTTPService.addContact({
        contactsPostRequestBody: {
          accounts: [
            {
              accountNumber: contactFormViewModel.accountIdentifier,
            },
          ],
          name: contactFormViewModel.name,
        },
      });
      this.notificationService.showNotification({
        header: this.textService.addRecipientAPISuccess,
        message: "",
        modifier: "success",
      });
      this.isOpen.next(false);
      this.loadProducts(IGNORE_PRODUCT_KINDS);
    } catch (err) {
      this.notificationService.showNotification({
        header: this.textService.addRecipientAPIFailure,
        message: "",
        modifier: "error",
      });
    }
  };
}
