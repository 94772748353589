import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HeaderModule } from "@backbase/ui-ang/header";
import { InputTextModule } from "@backbase/ui-ang/input-text";
import { ButtonModule } from "@backbase/ui-ang/button";
import { TooltipModule } from "@backbase/ui-ang/tooltip-directive";
import { IconModule } from "@backbase/ui-ang/icon";
import { NotificationModule } from "@backbase/ui-ang/notification";
import { LoginSecurityFeaturesModule } from "@backbase/identity-login-security-journey-ang";
import { CallCenterPasswordHttpService } from "@ent/data";
import { EntUIModule } from "@ent/ui";
import { CallCenterPasswordComponent } from "./call-center-password/call-center-password.component";
import { SecurityComponent } from "./security.component";
import { CallCenterPasswordTextService } from "./services/call-center-password.text.service";

@NgModule({
  declarations: [CallCenterPasswordComponent, SecurityComponent],
  imports: [
    EntUIModule,
    RouterModule.forChild([{ path: "", component: SecurityComponent, pathMatch: "full" }]),
    ReactiveFormsModule,
    CommonModule,
    HeaderModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    IconModule,
    NotificationModule,
    LoginSecurityFeaturesModule,
  ],
  exports: [],
  providers: [CallCenterPasswordHttpService, CallCenterPasswordTextService],
})
export class SecurityModule {}
