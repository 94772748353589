<div class="card card-body" [formGroup]="form">
  <ent-account-selector-form-control
    [products]="fromProducts | async"
    [selectedProduct]="fromAccount"
    [amountKey]="'availableBalance'"
    testId="from-selector"
    [label]="memberTransferFormTextService.fromLabel"
    [noItemSelectedText]="memberTransferFormTextService.selectAccount"
    [noItemsFoundText]="memberTransferFormTextService.noAccounts"
    [productSelectorName]="'fromAccount'"
    [isInvalid]="!!form.controls.fromAccount.touched && !!errors?.fromAccount"
    [error]="errors?.fromAccount"
    (productSelectorIsOpenChange)="accountSelectorIsOpenChange('fromAccount')"
    (selectProduct)="onSelectFromAccount($any($event))"
  ></ent-account-selector-form-control>
  <ent-account-selector-form-control
    [products]="contactProducts | async"
    [selectedProduct]="toAccount"
    [amountKey]="'availableBalance'"
    testId="to-selector"
    [label]="memberTransferFormTextService.toLabel"
    [noItemSelectedText]="memberTransferFormTextService.selectRecipient"
    [noItemsFoundText]="memberTransferFormTextService.noAccounts"
    [productSelectorName]="'toAccount'"
    [isInvalid]="!!form.controls.toAccount.touched && !!errors?.toAccount"
    [error]="errors?.toAccount"
    (productSelectorIsOpenChange)="accountSelectorIsOpenChange('toAccount')"
    (selectProduct)="onSelectToAccount($any($event))"
  ></ent-account-selector-form-control>
  <ent-amount-form-control
    [controlFormGroup]="form"
    [amountLabel]="memberTransferFormTextService.amount"
    [isInvalid]="!!form.controls.amount.touched && !!errors?.amount"
    [error]="errors?.amount"
    [testId]="'amount'"
  ></ent-amount-form-control>
  <ng-container *ngIf="!fromAccount || fromAccount.arrangementType !== arrangementTypesEnum.LOC">
    <ent-move-money-schedule
      [controlFormGroup]="form"
      [schedulingTextService]="memberTransferFormTextService"
      [minEndDate]="minEndDate"
      [errors]="errors"
    ></ent-move-money-schedule>
  </ng-container>
  <ent-memo-form-control
    [controlFormGroup]="form"
    [memoLabel]="memberTransferFormTextService.memoLabel"
    [memoHelperText]="memberTransferFormTextService.memoHelperText"
    [memoPlaceholder]="memberTransferFormTextService.memoPlaceholder"
  ></ent-memo-form-control>
  <div class="btn-container bb-dynamic-input bb-button-bar bb-button-bar--reverse">
    <bb-load-button-ui
      type="submit"
      class="bb-button-bar__button"
      [disabled]="isLoading"
      [isLoading]="isLoading"
      (click)="onValidateForm()"
    >
      {{ memberTransferFormTextService.continueBtn }}
    </bb-load-button-ui>
    <button
      bbbutton
      (click)="resetForm()"
      type="button"
      color="link"
      class="bb-button-bar__button bb-button-bar__button--across btn-link btn btn-md"
    >
      {{ memberTransferFormTextService.clearBtn }}
    </button>
  </div>
</div>
