<ent-open-share-card
  [heading]="card?.description"
  [iconClass]="'fa-magnifying-glass-dollar'"
  [title]="textService.reviewTitleLabel"
  [subtitle]="isCheckingConversion? textService.reviewConvertingSubtitle : textService | translationFunctions: 'getReviewSubtitleLabel': card.description + textService.reviewSubtitlesuffixLabel"
>
  <div card-content>
    <ng-container *ngFor="let reviewItem of reviewItems">
      <div *ngIf="reviewItem.value" class="d-flex align-items-center my-3">
        <i
          aria-hidden="true"
          class="bb-icon sub-icon ent-fa-regular"
          [ngClass]="reviewItem.iconClass || card.iconClass"
        ></i>
        <div class="item-information">
          <div class="bb-subtitle bb-text-support">{{reviewItem.title}}</div>
          <div class="item-header">{{reviewItem.value}}</div>
        </div>
      </div>
    </ng-container>
  </div>
  <div footer class="footer pt-3">
    <div class="bb-subtitle bb-text-support mb-5">
      <span>{{reviewAssets.terms.prefixLabel}}</span>
      <span *ngFor="let link of links; last as isLast">
        <a class="hyperlink" [href]="link.url" target="”_blank”">{{link.text}}</a>
        <ng-container *ngIf="!isLast">,</ng-container>
        <ng-container *ngIf="isLast">.</ng-container>
      </span>
      <span>{{reviewAssets.terms?.suffixLabel}}</span>
    </div>
    <div class="buttons-row">
      <button bbButton [color]="'link'" (click)="onBack()">
        <span>{{textService.backButtonLabel}}</span>
      </button>
      <div class="d-flex">
        <div class="pr-2">
          <button bbButton [color]="'secondary'" (click)="onCancel()">{{textService.cancelButtonLabel}}</button>
        </div>
        <bb-load-button-ui
          type="submit"
          class="bb-button-bar__button"
          (click)="onNext()"
          (keydown)="onNext()"
          [disabled]="isSaving"
          [isLoading]="isSaving"
        >
          {{textService.reviewNextButtonLabel}}
        </bb-load-button-ui>
      </div>
    </div>
  </div>
</ent-open-share-card>
