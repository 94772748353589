import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "stringOrDefault",
})
export class StringOrDefaultPipe implements PipeTransform {
  transform<T>(value: T | undefined, property: keyof T, defaultValue?: string): string | undefined {
    if (!value || !value[property]) {
      return defaultValue;
    }

    return value[property] as any;
  }
}
