import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { NotificationService } from "@backbase/ui-ang/notification";
import { LoadingService, CardsHTTPService, LOADING_KEYS } from "@ent/data";
import { TextService } from "../../services/text.service";
import { BasePinModalComponent } from "../../components/base-pin-modal/base-pin-modal.component";

@Component({
  selector: "ent-card-activation-modal",
  templateUrl: "../../components/base-pin-modal/base-pin-modal.component.html",
  styleUrls: ["../../components/base-pin-modal/base-pin-modal.component.scss"],
})
export class CardActivationModalComponent extends BasePinModalComponent implements OnInit, OnChanges {
  @Input()
  isOpen: boolean;

  @Output()
  closeModal = new EventEmitter();

  title = this.textService.cardActivationModalHeader;
  loadingText = this.textService.cardActivationLoadingText;
  confirmButtonText = this.textService.cardActivationConfirmText;

  constructor(
    private readonly loadingService: LoadingService,
    public readonly textService: TextService,
    protected readonly cardsHTTPService: CardsHTTPService,
    private readonly notificationService: NotificationService,
  ) {
    super(textService, cardsHTTPService);
  }

  onCloseModal = () => {
    this.closeModal.emit();
  };

  ngOnInit(): void {
    this.loadingService.isLoading(LOADING_KEYS.CARD_ACTIVATE).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.setInitialModalState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen.currentValue) {
      this.setInitialModalState();
    }
  }

  activateSuccess = () => {
    this.loadingService.stopLoading(LOADING_KEYS.CARD_ACTIVATE);
    this.notificationService.showNotification({
      header: this.textService.cardActivationSuccessNotificationHeader,
      message: this.textService.cardActivationSuccessNotificationMessage(this.cardsHTTPService.cardItem?.maskedNumber),
      modifier: "success",
    });
    this.onCloseModal();
  };

  activateFailure = (err: any) => {
    this.loadingService.stopLoading(LOADING_KEYS.CARD_ACTIVATE);

    if (this.isCVVMismatchError(err)) {
      this.resetFormInvalidCVV();
    } else {
      this.onCloseModal();
      this.notificationService.showNotification({
        header: this.textService.cardActivationFailureNotificationHeader,
        message: this.textService.cardActivationFailureNotificationMessage(
          this.cardsHTTPService.cardItem?.maskedNumber,
        ),
        modifier: "error",
      });
    }
  };

  onFormSubmit = async () => {
    this.form.markAllAsTouched();
    if (!this.form.valid || this.isLoading) return;
    if (!this.isPinConfirmed()) {
      this.onPinsMismatch();
      return;
    }
    this.loadingService.startLoading(LOADING_KEYS.CARD_ACTIVATE);

    this.cardsHTTPService
      .activateCard(this.form.controls.pin.value, this.form.controls.cvv.value)
      .then(this.activateSuccess)
      .catch(this.activateFailure);
  };
}
