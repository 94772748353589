import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService, CardsHTTPService, CARD_STATE, LOADING_KEYS, COMPONENT_STATE } from "@ent/data";
import { TextService } from "../services/text.service";

@Component({
  selector: "ent-card-detail-view",
  templateUrl: "card-detail-view.component.html",
  styleUrls: ["card-detail-view.component.scss"],
})
export class CardDetailViewComponent implements OnInit {
  numberFormat = {
    length: 16,
    maskRange: [0, 12],
    segments: 4,
  };
  componentState = COMPONENT_STATE.LOADED;
  componentStates = COMPONENT_STATE;
  cardStates = CARD_STATE;
  loadingText = $localize`:@@cards.details.loading:Loading card details..`;
  isLoadingCard = false;

  constructor(
    private readonly router: Router,
    private readonly activedRoute: ActivatedRoute,
    public readonly cardsHTTPService: CardsHTTPService,
    private readonly loadingService: LoadingService,
    public readonly textService: TextService,
  ) {}

  ngOnInit(): void {
    this.activedRoute.params.subscribe((params) => this.getCard(params?.selectedCard));
    this.loadingService.isLoading(LOADING_KEYS.INITIAL_LOADING).subscribe(this.setComponentState);
  }

  navigateToCardList = () => {
    return this.router.navigate(["self-service/manage-cards/list"]);
  };

  getCard = async (id?: string) => {
    if (this.componentState === COMPONENT_STATE.LOADING || !id) return;
    await this.cardsHTTPService.getCard(id);
  };

  setComponentState = (isLoading: boolean) => {
    if (isLoading) {
      this.componentState = COMPONENT_STATE.LOADING;
    } else if (!isLoading && !this.cardsHTTPService.cardItem) {
      this.componentState = COMPONENT_STATE.ERROR;
    } else if (!isLoading && this.cardsHTTPService.cardItem) {
      this.componentState = COMPONENT_STATE.LOADED;
    }
    this.isLoadingCard = isLoading;
  };
}
