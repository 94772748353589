import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { CallCenterPasswordTextService } from "../services/call-center-password.text.service";

@Component({
  selector: "ent-call-center-password",
  templateUrl: "call-center-password.component.html",
  styleUrls: ["call-center-password.component.scss"],
})
export class CallCenterPasswordComponent implements OnChanges {
  @Input()
  currentPassword?: string;
  @Input()
  currentReminder?: string;
  @Output()
  formSubmit = new EventEmitter<{
    password?: string;
    reminder?: string;
  }>();
  isFormOpen = new BehaviorSubject(false);
  form: FormGroup = new FormGroup(
    {
      password: new FormControl(undefined, [Validators.required]),
      confirm: new FormControl(undefined, [Validators.required]),
      reminder: new FormControl(undefined, [Validators.required]),
    },
    { validators: [this.passwordValidator] },
  );

  constructor(public textService: CallCenterPasswordTextService) {}

  passwordValidator(control: AbstractControl) {
    const password = control.root.get("password");
    const confirm = control.root.get("confirm");
    if (password?.value?.toLowerCase() !== confirm?.value?.toLowerCase()) {
      password?.setErrors({ passwordValidator: true });
      confirm?.setErrors({ passwordValidator: true });
      return { passwordValidator: true };
    }
    password?.setErrors(undefined);
    confirm?.setErrors(undefined);
    return undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.controls.password.setValue(changes.currentPassword.currentValue);
    this.form.controls.reminder.setValue(changes.currentReminder.currentValue);
  }

  openForm = () => this.isFormOpen.next(true);

  closeForm = () => {
    this.form.reset({ password: this.currentPassword, confirm: undefined, reminder: this.currentReminder });
    this.isFormOpen.next(false);
  };

  submitClick = () => {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.formSubmit.emit({
        password: this.form.controls.password.value,
        reminder: this.form.controls.reminder.value,
      });
      this.form.reset();
      this.isFormOpen.next(false);
    }
  };
}
