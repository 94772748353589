import { Component, Input, OnInit } from "@angular/core";
import { PRODUCT_FLOW_CODES, ProductCard, PRODUCTS_FORM_STATE } from "@ent/data";
import { ProductsBaseTextService } from "../../services/products-base.text.service";
import { ProductsBaseStepsService } from "../../services/products-base.steps.service";

@Component({
  selector: "ent-product-success",
  templateUrl: "./product-success.component.html",
})
export class ProductSuccessComponent implements OnInit {
  constructor(public textService: ProductsBaseTextService, public stepsService: ProductsBaseStepsService) {}

  @Input() productFlow = PRODUCT_FLOW_CODES.DEFAULT;
  @Input() card: ProductCard;
  successText: string;
  successHeaderText: string;
  formStates = PRODUCTS_FORM_STATE;
  productFlowCodes = PRODUCT_FLOW_CODES;

  ngOnInit(): void {
    this.successText = this.getSuccessText();
    this.successHeaderText = this.card?.successHeaderText ?? this.textService.successHeader;
  }

  getSuccessText(): string {
    if (this.productFlow === this.productFlowCodes.BRONCOS_CONVERTION)
      return this.textService.checkingConversionSuccessText;
    return this.card?.successText ?? this.textService.successText;
  }
}
