import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { DateTime } from "luxon";
import {
  OCCURRENCE,
  ArrangementsHTTPService,
  getOccurrenceFromPaymentMode,
  getPaymentModeFromOccurrence,
  getMinimumEndDateFromStartDate,
  getEndTypeFromDuration,
  formatDateTimeString,
  getLocalDateTimeFromUTC,
  ExternalTransfersHTTPService,
  convertToFlatProductItem,
  convertExternalProductToFlatProductItem,
  FlatProductItem,
  RetailAppRemoteConfig,
  RestrictedDatesService,
} from "@ent/data";
import { combineLatest, Observable, map, of } from "rxjs";
import { RemoteConfigService } from "@backbase/remote-config-ang";
import { TransferFormTextService } from "../services/transfer-form.text.service";
import { transferFormSchema } from "../../models/transfer-model";
import { BaseFormComponent } from "../../components/base-form/base-form.component";
import { TRANSFER_FORM_ERROR_KEY } from "../../models/transfer-errors";
import { IFormModel } from "../../models/form-model";
import { IFormTextService } from "../../services/form.text.service";

@Component({
  selector: "ent-transfer-form",
  templateUrl: "./transfer-form.component.html",
})
export class TransferFormComponent extends BaseFormComponent implements OnInit {
  constructor(
    public readonly transferFormTextService: TransferFormTextService,
    public readonly arrangementsHTTPService: ArrangementsHTTPService,
    public readonly externalTransfersHTTPService: ExternalTransfersHTTPService,
    restrictedDatesService: RestrictedDatesService,
    remoteConfigService: RemoteConfigService<RetailAppRemoteConfig>,
  ) {
    super(arrangementsHTTPService, remoteConfigService, restrictedDatesService);
  }

  fromProducts: Observable<FlatProductItem[]> = of([]);
  toProducts: Observable<FlatProductItem[]> = of([]);
  isExternalTransfer = false;

  ngOnInit(): void {
    this.form = new FormGroup({
      fromAccount: new FormControl(undefined),
      toAccount: new FormControl(undefined),
      amount: new FormControl(undefined),
      occurrence: new FormControl(OCCURRENCE.NOW),
      frequency: new FormControl(undefined),
      executionDate: new FormControl(this.todayISO),
      endType: new FormControl(undefined),
      endDate: new FormControl(undefined),
      repeat: new FormControl(undefined),
      memo: new FormControl(undefined),
    });
    this.resetForm(this.formModel);
    this.setupFormEvents();
    this.getCutoffTime();
    this.getRestrictedDates();
    this.runYupValidation();
    this.fromProducts = combineLatest([
      this.arrangementsHTTPService.fromProductsObservable.pipe(
        map((productItems) => productItems.map(convertToFlatProductItem)),
      ),

      this.externalTransfersHTTPService.externalTransferAccountsObservable.pipe(
        map((productItems) => productItems.map(convertExternalProductToFlatProductItem)),
      ),
    ]).pipe(map((productItems) => [...productItems[0], ...productItems[1]]));
    this.toProducts = combineLatest([
      this.arrangementsHTTPService.toProductsObservable.pipe(
        map((productItems) => productItems.map(convertToFlatProductItem)),
      ),

      this.externalTransfersHTTPService.externalTransferAccountsObservable.pipe(
        map((productItems) => productItems.map(convertExternalProductToFlatProductItem)),
      ),
    ]).pipe(map((productItems) => [...productItems[0], ...productItems[1]]));
    this.isExternalTransfer = this.isOneExternal(this.fromAccount, this.toAccount);
  }

  onAccountSelection = () => {
    const { occurrence } = this.form.controls;
    this.isExternalTransfer = this.isOneExternal(this.fromAccount, this.toAccount);
    if (this.isExternalTransfer) occurrence.reset(OCCURRENCE.LATER);
  };

  setupFormEvents = () => {
    const { frequency, executionDate, fromAccount, toAccount, occurrence, endType } = this.form.controls;
    fromAccount.valueChanges.subscribe(() => {
      this.onFromAccountChange();
      this.onAccountSelection();
    });
    toAccount.valueChanges.subscribe(this.onAccountSelection);
    occurrence.valueChanges.subscribe(this.onOccurrenceChange);
    endType.valueChanges.subscribe(this.onEndTypeChange);
    frequency.valueChanges.subscribe(this.onFrequencyChange);
    executionDate.valueChanges.subscribe(this.onExecutionDateChange);
    this.form.valueChanges.subscribe(() => {
      this.runYupValidation();
    });
  };

  resetForm = (defaultModel?: IFormModel) => {
    this.fromAccount = defaultModel?.fromAccount;
    this.toAccount = defaultModel?.toAccount;
    this.form.reset({
      fromAccount: defaultModel?.fromAccount?.id,
      toAccount: defaultModel?.toAccount?.id,
      amount: !defaultModel?.amount ? undefined : defaultModel.amount,
      occurrence: getOccurrenceFromPaymentMode(defaultModel?.paymentMode, defaultModel?.executionDate),
      frequency: defaultModel?.frequency,
      executionDate: DateTime.fromISO(defaultModel?.executionDate ?? this.todayISO).toISO(),
      endType: defaultModel?.endType,
      endDate: defaultModel ? DateTime.fromISO(defaultModel.endDate).toISO() : undefined,
      repeat: defaultModel?.repeat,
      memo: defaultModel?.memo,
    });
  };

  runYupValidation = () => {
    this.errors = undefined;
    const { executionDate, endDate, amount, fromAccount, toAccount, occurrence, frequency, repeat, memo, endType } =
      this.form.controls;
    try {
      const executionDateValue = DateTime.fromISO(executionDate.value);
      const endDateValue = DateTime.fromISO(endDate.value);
      transferFormSchema.validateSync(
        {
          fromAccountId: fromAccount.value ?? undefined,
          fromAccountBalance: this.fromAccount?.availableBalance,
          toAccountId: toAccount.value ?? undefined,
          toAccountBalance: this.toAccount?.availableBalance,
          minLoanAdvance: this.fromAccount?.minLoanAdvance ?? undefined,
          amount: Number.isFinite(Number.parseFloat(amount.value)) ? amount.value : undefined,
          occurrence: occurrence.value ?? undefined,
          frequency: frequency.value ?? undefined,
          executionDate: executionDateValue.isValid ? executionDateValue.toISO() : undefined,
          endType: endType.value ?? undefined,
          endDate: endDateValue.isValid ? endDateValue.toISO() : undefined,
          repeat: Number.isInteger(Number.parseInt(repeat.value, 10)) ? repeat.value : undefined,
          memo: memo.value ?? undefined,
          minExecutionDate: this.minExecutionDate ?? undefined,
        },
        { abortEarly: false },
      );
    } catch (err) {
      this.setErrors(err.errors);
    }
  };

  getAmountError = (errors: TRANSFER_FORM_ERROR_KEY[]) => {
    let amount: string | undefined;
    if (errors.includes(TRANSFER_FORM_ERROR_KEY.AMOUNT_MIN_LOAN_ADVANCE)) {
      amount = this.transferFormTextService.amountMinLoanAdvanceError(this.fromAccount);
    } else if (errors.includes(TRANSFER_FORM_ERROR_KEY.AMOUNT_MAX)) {
      amount = this.transferFormTextService.amountFromMaxError(this.fromAccount);
    } else if (errors.includes(TRANSFER_FORM_ERROR_KEY.AMOUNT_REQUIRED)) {
      amount = this.transferFormTextService.amountRequiredError;
    }
    return amount;
  };

  getExecutionDateError = (errors: TRANSFER_FORM_ERROR_KEY[]) => {
    let executionDate: string | undefined;
    if (errors.includes(TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_MIN)) {
      executionDate = this.transferFormTextService.executionDatePastError;
    } else if (errors.includes(TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_REQUIRED)) {
      executionDate = this.transferFormTextService.executionDateRequiredError;
    }
    return executionDate;
  };

  getEndDateError = (errors: TRANSFER_FORM_ERROR_KEY[]) => {
    let endDate: string | undefined;
    if (errors.includes(TRANSFER_FORM_ERROR_KEY.END_DATE_MIN)) {
      const minDate = DateTime.fromISO(
        getMinimumEndDateFromStartDate(
          this.form.controls.frequency.value,
          getLocalDateTimeFromUTC(this.form.controls.executionDate.value)?.toISO(),
        ),
      );
      endDate = this.transferFormTextService.endDateLessThanMinDateError(minDate.toFormat("LLLL dd, yyyy"));
    } else if (errors.includes(TRANSFER_FORM_ERROR_KEY.END_DATE_REQUIRED)) {
      endDate = this.transferFormTextService.endDateRequiredError;
    }
    return endDate;
  };

  setErrors = (errors: TRANSFER_FORM_ERROR_KEY[] = []) => {
    this.errors = {
      fromAccount: errors.includes(TRANSFER_FORM_ERROR_KEY.FROM_REQUIRED)
        ? this.transferFormTextService.fromAccountRequiredError
        : undefined,
      toAccount: errors.includes(TRANSFER_FORM_ERROR_KEY.TO_REQUIRED)
        ? this.transferFormTextService.toAccountRequiredError
        : undefined,
      amount: this.getAmountError(errors),
      executionDate: this.getExecutionDateError(errors),
      endDate: this.getEndDateError(errors),
      repeat: errors.includes(TRANSFER_FORM_ERROR_KEY.REPEAT_REQUIRED)
        ? this.transferFormTextService.repeatRequiredError
        : undefined,
    };
  };

  onValidateForm = () => {
    const { amount, executionDate, occurrence, memo, frequency, endType, repeat, endDate } = this.form.controls;
    this.form.markAllAsTouched();
    this.getCutoffTime();
    this.runYupValidation();
    if (this.errors) {
      return;
    }
    this.validateForm.emit({
      amount: amount.value,
      executionDate: formatDateTimeString(getLocalDateTimeFromUTC(executionDate.value)?.toISO(), "yyyy-LL-dd"),
      paymentMode: getPaymentModeFromOccurrence(occurrence.value),
      memo: memo.value,
      toAccount: this.toAccount,
      fromAccount: this.fromAccount,
      frequency: frequency.value,
      repeat: this.getRepeat(endType.value, repeat.value),
      endDate: this.getEndDate(endType.value, getLocalDateTimeFromUTC(endDate.value)?.toISO()),
      endType: getEndTypeFromDuration(repeat.value, getLocalDateTimeFromUTC(endDate.value)?.toISO()),
    });
  };

  getFormTextService = (): IFormTextService => {
    return this.transferFormTextService;
  };
}
