import { NgModule } from "@angular/core";
import { ModalModule } from "@backbase/ui-ang/modal";
import { ButtonModule } from "@backbase/ui-ang/button";
import { LoadingIndicatorModule } from "@backbase/ui-ang/loading-indicator";
import { EntUIModule } from "@ent/ui";
import { CommonModule } from "@angular/common";
import { MxConnectWidgetComponent } from "./mx-connect-widget/mx-connect-widget.component";
import { CustomConnectExternalAccountsComponent } from "./custom-connect-external-accounts/custom-connect-external-accounts.component";
import { CustomConnectExternalAccountsTextService } from "./services/custom-connect-external-accounts.text.service";
import { ActivateAccountModalComponent } from "./activate-account-modal/activate-account-modal.component";

@NgModule({
  declarations: [CustomConnectExternalAccountsComponent, MxConnectWidgetComponent, ActivateAccountModalComponent],
  imports: [CommonModule, EntUIModule, ModalModule, ButtonModule, LoadingIndicatorModule],
  providers: [CustomConnectExternalAccountsTextService],
})
export class CustomConnectExternalAccountsModule {}
