import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, LOCALE_ID, ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { EntitlementsModule } from "@backbase/foundation-ang/entitlements";
import { IdentityAuthModule } from "@backbase/identity-auth";
import { ImpersonationModule, ImpersonationService } from "@backbase/identity-auth/impersonation";
import { AlertModule } from "@backbase/ui-ang/alert";
import { ButtonModule } from "@backbase/ui-ang/button";
import { HeaderModule } from "@backbase/ui-ang/header";
import { ModalModule } from "@backbase/ui-ang/modal";
import { AuthEventsHandlerService, AuthInterceptor } from "@ent/data";
import { EntUIModule } from "@ent/ui";
import {
  AuthConfig,
  OAuthEvent,
  OAuthModule,
  OAuthModuleConfig,
  OAuthService,
  OAuthStorage,
} from "angular-oauth2-oidc";
import { CookieService } from "ngx-cookie-service";
import { SessionTimeoutModalComponent } from "./session-timeout-modal/session-timeout.component";

/**
 * This is a temporary workaround for capabilities that has download functionality working only via cookies
 * remove it as soon as capabilities work with auth header
 */
const cookiePaths = [
  "/api/account-statement/client-api/v2/account/statements/download",
  "/api/transaction-manager/client-api/v2/transactions/export",
  "/api/loan/client-api/v1/loans",
];

@NgModule({
  declarations: [SessionTimeoutModalComponent],
  imports: [
    CommonModule,
    EntUIModule,
    FormsModule,
    EntitlementsModule,
    AlertModule,
    ButtonModule,
    HeaderModule,
    ModalModule,
    OAuthModule.forRoot(),
    IdentityAuthModule,
    ImpersonationModule,
  ],
  exports: [SessionTimeoutModalComponent],
})
export class SessionManagerModule {
  constructor(@Optional() @SkipSelf() targetModule: SessionManagerModule) {
    if (targetModule) {
      throw new Error(
        `${targetModule.constructor.name} has already been loaded. Import this module in the AppModule only.`,
      );
    }
  }

  static forRoot(
    apiRoot: string,
    authConfig: (baseUrl: string) => AuthConfig,
  ): ModuleWithProviders<SessionManagerModule> {
    return {
      ngModule: SessionManagerModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: AuthConfig,
          useFactory: authConfig,
          deps: [[new Optional(), LOCALE_ID]],
        },
        { provide: OAuthStorage, useFactory: () => localStorage },
        {
          provide: APP_INITIALIZER,
          multi: true,
          deps: [OAuthService, CookieService, ImpersonationService, AuthEventsHandlerService],
          useFactory:
            (oAuthService: OAuthService, cookieService: CookieService, impersonationService: ImpersonationService) =>
            async () => {
              // delete when files download works without cookies
              oAuthService.events.subscribe((authEvent: OAuthEvent) => {
                if (authEvent.type === "token_received" || authEvent.type === "token_refreshed") {
                  cookiePaths.forEach((path) =>
                    cookieService.set("Authorization", oAuthService.getAccessToken(), { path }),
                  );
                }
              });

              await impersonationService.checkImpersonationStatus();
              await oAuthService.loadDiscoveryDocumentAndTryLogin();
            },
        },
        {
          provide: OAuthModuleConfig,
          useValue: {
            resourceServer: {
              allowedUrls: [apiRoot],
              sendAccessToken: true,
            },
          },
        },
      ],
    };
  }
}
