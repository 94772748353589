import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  ConnectExternalAccountsViewService,
  LinkAccountService,
} from "@backbase/connect-external-accounts-journey-ang";
import {
  MoneyInsightHttpService,
  COMPONENT_STATE,
  ConnectWidgetUrl,
  MicrodepositAccountMetadata,
  SaveAccountResponse,
} from "@ent/data";
import { OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { DetailedExternalAccount } from "@backbase/payment-order-a2a-http-ang";
import { CustomConnectExternalAccountsTextService } from "../services/custom-connect-external-accounts.text.service";

@Component({
  selector: "ent-activate-account-modal",
  templateUrl: "./activate-account-modal.component.html",
})
export class ActivateAccountModalComponent implements OnInit {
  componentState = new BehaviorSubject(COMPONENT_STATE.LOADED);
  url: string;
  accountMetadata: MicrodepositAccountMetadata;
  accountRecord: DetailedExternalAccount;
  successText: string;
  showConfirmationDialog = false;
  modalOptions;

  constructor(
    private readonly oAuthService: OAuthService,
    private readonly location: Location,
    private readonly linkAccountService: LinkAccountService,
    private readonly viewService: ConnectExternalAccountsViewService,
    private readonly service: MoneyInsightHttpService,
    public readonly textService: CustomConnectExternalAccountsTextService,
  ) {}

  ngOnInit(): void {
    this.modalOptions = {
      beforeDismiss: this.canCloseDialog,
    };
    this.getMXConnectUrl();
  }

  handleMessage(eventData) {
    switch (eventData.type) {
      case "mx/connect/microdeposits/verified":
        this.getAccountMetadata(eventData.metadata);
        this.verifyAccount();
        break;
      case "mx/connect/microdeposits/error/primaryAction":
        this.getPreventedMetadata(eventData.metadata);
        this.registerFailedVerification();
        this.closeDialog();
        break;
      case "mx/connect/microdeposits/comeBack/primaryAction":
        this.closeDialog();
        break;
      case "mx/ping":
        this.oAuthService.refreshToken();
        break;
      default:
        break;
    }
  }

  registerFailedVerification = async () => {
    try {
      await this.service.registerFailedVerification(this.accountMetadata, this.accountRecord.externalAccountId);
    } catch {
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  };

  getMXConnectUrl = async () => {
    this.accountMetadata = undefined;
    this.url = undefined;
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      this.accountRecord = await firstValueFrom(this.viewService.a2aRecord$);
      const response = await this.service.getConnectUrl(this.accountRecord.externalAccountId);
      if (this.isConnectUrl(response)) {
        this.url = response.url;
        this.componentState.next(COMPONENT_STATE.LOADED);
      }
    } catch {
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  };

  verifyAccount = async () => {
    this.componentState.next(COMPONENT_STATE.LOADING);
    try {
      if (!this.accountMetadata) throw new Error();
      const response = await this.service.verifyMicrodeposits(
        this.accountMetadata,
        this.accountRecord.externalAccountId,
      );
      if (!this.isVerifyAccountResponse(response)) throw new Error();
      this.successText = this.textService.getSuccessVerificationSubText(response.accounts[0]);
      this.componentState.next(COMPONENT_STATE.LOADED);
    } catch {
      this.componentState.next(COMPONENT_STATE.ERROR);
    }
  };

  closeDialog() {
    this.linkAccountService.refreshAccounts();
    this.location.back();
  }

  private isConnectUrl(getConectUrlResponse?: any): getConectUrlResponse is ConnectWidgetUrl {
    return (
      getConectUrlResponse &&
      typeof getConectUrlResponse.url === "string" &&
      typeof getConectUrlResponse.mobile === "boolean"
    );
  }

  getAccountMetadata = (metadata) => {
    if (metadata && metadata.user_guid && metadata.microdeposit_guid) {
      this.accountMetadata = {
        userGuid: metadata.user_guid,
        sessionGuid: metadata.session_guid,
        microdepositGuid: metadata.microdeposit_guid,
      };
    }
  };

  getPreventedMetadata = (metadata) => {
    if (metadata.status && metadata.guid) {
      this.accountMetadata = {
        userGuid: metadata.user_guid,
        sessionGuid: metadata.session_guid,
        microdepositGuid: metadata.guid,
        microdepositStatus: metadata.status,
      };
    }
  };

  toggleConfirmationDialog = () => {
    this.showConfirmationDialog = !this.showConfirmationDialog;
  };

  canCloseDialog = (): boolean => {
    if (this.accountMetadata || !this.url) {
      return true;
    }
    this.toggleConfirmationDialog();
    return false;
  };

  private isVerifyAccountResponse(response?: any): response is SaveAccountResponse {
    const account = response.accounts[0];
    return (
      response &&
      response.accounts.length > 0 &&
      typeof account.bankName === "string" &&
      typeof account.accountType === "string" &&
      typeof account.maskedAccountNumber === "string"
    );
  }
}
