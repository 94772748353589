export enum PAYMENT_OPTION {
  MONTHLY_DUE = "MONTHLY_DUE",
  PAST_DUE = "PAST_DUE",
  PAY_OFF = "PAY_OFF",
  PRINCIPAL = "PRINCIPAL",
  OTHER_AMOUNT = "OTHER_AMOUNT",
}

export enum OCCURRENCE {
  NOW = "NOW",
  LATER = "LATER",
  RECURRING = "RECURRING",
}

export enum FORM_STATE {
  ACTION_NEEDED = "ACTION_NEEDED",
  EDITING = "EDITING",
  REVIEW = "REVIEW",
  SUCCESS = "SUCCESS",
}

export enum FREQUENCY_SELECTOR {
  ONCE = "ONCE",
  WEEKLY = "WEEKLY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY",
  BIMONTHLY = "BIMONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMIANNUALLY = "SEMIANNUALLY",
  ANNUALLY = "YEARLY",
}

export enum END_TYPE {
  ON = "ON",
  AFTER = "AFTER",
  NEVER = "NEVER",
}

export enum SCHEDULE_EVERY {
  ONE = "1",
  TWO = "2",
}
