<bb-modal-ui [isOpen]="showDialog" [modalOptions]="modalOptions" (cancel)="closeDialog()" #modalRef>
  <bb-modal-header-ui [title]="textService.headerTitle" (close)="modalRef.dismissModal()"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <ent-stateful-component
        #statefulComponent
        [componentState]="componentState | async"
        [loadingText]="textService.loadingText"
        [loadingTemplate]="loading"
        [errorTemplate]="error"
        [contentTemplate]="loaded"
      >
      </ent-stateful-component>
      <ng-template #loading>
        <bb-loading-indicator-ui loaderSize="lg" [text]="textService.loadingText"></bb-loading-indicator-ui>
      </ng-template>
      <ng-template #loaded>
        <ng-container *ngIf="accountMetadata; then success; else mxWidget"></ng-container>
        <ng-template #mxWidget>
          <ent-mx-connect-widget
            [url]="url"
            [title]="textService.headerTitle"
            (mxMessage)="handleMessage($event)"
          ></ent-mx-connect-widget>
        </ng-template>
        <ng-template #success>
          <ent-page-success
            [successText]="successText"
            [successHeaderText]="successHeader"
            [repeatButtonText]="textService.repeatButton"
            [cancelButtonText]="textService.cancelButton"
            (clickRepeat)="getMXConnectUrl()"
            (clickCancel)="closeDialog()"
          ></ent-page-success>
        </ng-template>
      </ng-template>
      <ng-template #error>
        <ng-container
          *ngIf="accountMetadata || existingAccountError; then accountConnectedError; else getUrlError"
        ></ng-container>
        <ng-template #getUrlError>
          <ent-page-retry
            [errorText]="textService.errorSubText"
            [errorHeaderText]="textService.errorHeader"
            [buttonText]="textService.errorButton"
            (clickRetry)="getMXConnectUrl()"
          ></ent-page-retry>
        </ng-template>
        <ng-template #accountConnectedError>
          <ent-page-retry
            [errorText]="existingAccountError ? textService.existingAccountErrorSubText : textService.connectErrorSubText"
            [errorHeaderText]="existingAccountError ? textService.existingAccountErrorHeader : textService.connectErrorHeader"
            [buttonText]="textService.connectErrorButton"
            (clickRetry)="saveAccount()"
          ></ent-page-retry>
        </ng-template>
      </ng-template>
    </ng-template>
  </bb-modal-body-ui>
  <ent-confirmation-modal
    [isOpen]="showConfirmationDialog"
    [title]="textService.confirmationTitle"
    [confirmMessage]="textService.confirmationMessage"
    [yesBtnText]="textService.confirmationYesButton"
    [noBtnText]="textService.confirmationNoButton"
    (no)="toggleConfirmationDialog()"
    (yes)="closeDialog()"
  ></ent-confirmation-modal>
</bb-modal-ui>
