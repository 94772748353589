/* eslint-disable @typescript-eslint/naming-convention */
import { MasterWidget, ConnectWidget } from "@mxenabled/web-widget-sdk";
import { distinctUntilChanged, interval, map, take } from "rxjs";

const INTERVAL_TIMER = 250;
const FAILED_COUNT = 20; // 5 seconds

export class MxWidgetService {
  static forInsightsWidget(url: string, containerId: string) {
    return MxWidgetService.loadContainer(containerId).pipe(
      map((container) =>
        container
          ? new MasterWidget({
              url,
              // url: window.localStorage.getItem("mx-url"), // THIS IS FOR EASIER DEV TESTING
              container,
              iframeTitle: "ent-mx-insights-iframe",
              style: { width: "100%", height: "100%" },
            })
          : undefined,
      ),
    );
  }

  static forConnect(url: string, containerId: string) {
    return MxWidgetService.loadContainer(containerId).pipe(
      map((container) =>
        container
          ? new ConnectWidget({
              url,
              // url: window.localStorage.getItem("mx-url"), // THIS IS FOR EASIER DEV TESTING
              container,
              iframeTitle: "ent-mx-connect-iframe",
              style: { width: "100%", height: "43rem" },
            })
          : undefined,
      ),
    );
  }

  static loadContainer(containerId: string) {
    return interval(INTERVAL_TIMER).pipe(
      take(FAILED_COUNT),
      map(() => document.getElementById(containerId)),
      distinctUntilChanged(),
    );
  }
}
