import { Injectable } from "@angular/core";
import { PhoneAddress, UserProfileManagementService } from "@backbase/user-http-ang";
import { LoadingService } from "../loading.service";

import { LOADING_KEYS } from "../../models";

export enum TOGGLE_TYPE {
  TEXT_MESSAGE = "text_message",
  PHONE_CALL = "phone_call",
}

export enum PHONE_TYPE {
  MOBILE = "Mobile",
  HOME = "Home",
  WORK = "Work",
}

export enum OPT_IN_STATE {
  UNSPECIFIED = "0",
  OPTED_IN = "1",
  OPTED_OUT = "2",
}

@Injectable({ providedIn: "root" })
export class NotificationPreferenceHTTPService {
  constructor(public loadingService: LoadingService, public userProfileHTTPService: UserProfileManagementService) {
    // add in the base path to fix upgrade 2023.06 issue where this base path was removed
    // userProfileHTTPService.configuration.basePath = "/api/user-manager";
  }
  public phoneNumbers?: PhoneAddress[] = undefined;
  public mobileNumber?: PhoneAddress = undefined;
  public homeNumber?: PhoneAddress = undefined;
  getPhoneNumbers = () => {
    this.loadingService.startLoading(LOADING_KEYS.INITIAL_LOADING);
    return new Promise((resolve, reject) => {
      this.userProfileHTTPService.getOwnPhoneAddresses().subscribe({
        next: (response) => {
          this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
          this.phoneNumbers = response["phone-addresses"];
          this.mobileNumber = response["phone-addresses"]?.filter(
            (phoneNumber) => phoneNumber.type.toUpperCase() === PHONE_TYPE.MOBILE.toUpperCase(),
          )[0];
          this.homeNumber = response["phone-addresses"]?.filter(
            (phoneNumber) => phoneNumber.type.toUpperCase() === PHONE_TYPE.HOME.toUpperCase(),
          )[0];
          return resolve(response);
        },
        error: (err) => {
          this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
          this.phoneNumbers = undefined;
          return reject(err);
        },
      });
    });
  };
  toggleNotificationPreference = (phoneNumber: PhoneAddress, toggleType: TOGGLE_TYPE) => {
    this.loadingService.startLoading(LOADING_KEYS.SUBMIT_LOADING);
    return new Promise((resolve, reject) => {
      const { type, primary, number, additions } = phoneNumber;
      let smsOptIn = additions?.["smsOptIn"];
      let callOptIn = additions?.["callOptIn"];
      const smsOptInSelection = smsOptIn === OPT_IN_STATE.OPTED_IN;
      const callOptInSelection = callOptIn === OPT_IN_STATE.OPTED_IN;

      if (toggleType === TOGGLE_TYPE.TEXT_MESSAGE) {
        smsOptIn = smsOptInSelection ? OPT_IN_STATE.OPTED_OUT : OPT_IN_STATE.OPTED_IN;
        callOptIn = callOptInSelection ? OPT_IN_STATE.OPTED_IN : OPT_IN_STATE.OPTED_OUT;
      }
      if (toggleType === TOGGLE_TYPE.PHONE_CALL) {
        callOptIn = callOptInSelection ? OPT_IN_STATE.OPTED_OUT : OPT_IN_STATE.OPTED_IN;
        smsOptIn = smsOptInSelection ? OPT_IN_STATE.OPTED_IN : OPT_IN_STATE.OPTED_OUT;
      }
      this.userProfileHTTPService
        .updateOwnPhoneAddress({
          phoneAddressKey: type,
          phoneAddress: {
            type,
            primary,
            number,
            additions: {
              smsOptIn: smsOptIn ?? OPT_IN_STATE.UNSPECIFIED,
              callOptIn: callOptIn ?? OPT_IN_STATE.UNSPECIFIED,
            },
          },
        })
        .subscribe({
          next: (response) => {
            this.loadingService.stopLoading(LOADING_KEYS.SUBMIT_LOADING);
            return resolve(response);
          },
          error: (err) => {
            this.loadingService.stopLoading(LOADING_KEYS.SUBMIT_LOADING);
            return reject(err);
          },
        });
    });
  };

  disableAllNotificationPreferences = () => {
    const phones = (this.phoneNumbers || []).filter((phone) => phone.type !== PHONE_TYPE.WORK);
    this.loadingService.startLoading(LOADING_KEYS.SUBMIT_LOADING);
    return Promise.all(
      phones.map((phone) => {
        return new Promise((resolve, reject) => {
          const { type, primary, number } = phone;
          this.userProfileHTTPService
            .updateOwnPhoneAddress({
              phoneAddressKey: type,
              phoneAddress: {
                type,
                primary,
                number,
                additions: {
                  smsOptIn: OPT_IN_STATE.OPTED_OUT,
                  callOptIn: OPT_IN_STATE.OPTED_OUT,
                },
              },
            })
            .subscribe({
              next: (response) => {
                this.loadingService.stopLoading(LOADING_KEYS.SUBMIT_LOADING);
                resolve(response);
              },
              error: (err) => {
                this.loadingService.stopLoading(LOADING_KEYS.SUBMIT_LOADING);
                reject(err);
              },
            });
        });
      }),
    );
  };
}
