<ng-container [formGroup]="controlFormGroup">
  <div class="mb-3 bb-dynamic-input form-group">
    <fieldset>
      <legend><label class="bb-dynamic-input__label form-label"> {{ selectLabel }} </label></legend>
      <select
        [formControlName]="formFieldName"
        [required]="isRequired"
        [id]="'test__' + testId"
        class="form-control bb-dropdown__select"
      >
        <option *ngFor="let option of options" [ngValue]="option.value">{{ option.label }}</option>
      </select>
      <bb-input-validation-message-ui [showErrors]="isInvalid">
        <span>{{ error }}</span>
      </bb-input-validation-message-ui>
    </fieldset>
  </div>
</ng-container>
