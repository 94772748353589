import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ProductsBaseTextService {
  loadingText = $localize`:@@products.explore-products.loading:Loading...`;
  errorText = $localize`:@@products.explore-products.error.text:Not to worry, you have options. You can reach out to 800-525-9623 or stop by a Service Center to add another account.`;
  errorHeaderText = $localize`:@@products.explore-products.error.header:We're unable to add new products`;
  backLinkLabel = $localize`:@@products.explore-products.back-link.label: Back to My Accounts`;

  informationTitleLabel = $localize`:@@products.product-information.title.label: What you’ll get`;
  informationSubtitleLabel = $localize`:@@products.product-information.subtitle.label: Here are some of the top benefits:`;
  informationBackButtonLabel = $localize`:@@products.product-information.back.button.label: Back to Explore Products`;
  informationNextButtonLabel = $localize`:@@products.product-information.next.button.label: Add account`;
  informationLegendLabel = $localize`:@@products.product-information.legend.label: Learn more about`;
  informationConvertButtonLabel = $localize`:@@products.product-information.convert.button.label:Convert existing account`;
  informationGoodToKnowLabel = $localize`:@@products.product-information.good-to-know.label:Good to know`;

  convertCheckingTitleLabel = $localize`:@@products.checking-account-selection.title.label:Which account do you want to convert?`;
  convertCheckingSubtitleLabel = $localize`:@@products.checking-account-selection.subtitle.label:Choose the No Strings Checking accounts you'd like to convert to a Broncos Checking`;

  considerationsTitleLabel = $localize`:@@products.product-considerations.title.label: You’ll get a Broncos debit card`;
  considerationsSubtitleLabel = $localize`:@@products.product-considerations.subtitle.label:Your official Broncos debit card with a new card number will be sent to your mailing address for you and any joint owners who have debit cards`;
  considerationsNewBroncosSubtitleLabel = $localize`:@@products.product-considerations.new-broncos.subtitle.label:We’ll send your official Broncos debit card to your mailing address`;
  considerationsCourtesyOverdraftText = $localize`:@@products.product-considerations.new-broncos.courtesy.overdraft.label: If you want to add overdraft transfers or opt-in to courtesy pay, visit us at the nearest Service Center or call us at 800-525-9623.`;
  considerationsOverdraftText = $localize`:@@products.product-considerations.new-broncos.overdraft.label: What's overdraft transfers?`;
  considerationsOverdraftModalText = $localize`:@@products.product-considerations.new-broncos.overdraft.text: At Ent, we want to help you manage your money with ease. That's why we offer free overdraft transfers. If you fall short on funds, we'll move money from your savings account to cover it at no cost. We care about you and your financial peace of mind.`;
  considerationsCourtesyText = $localize`:@@products.product-considerations.new-broncos.courtesy.label: What's courtesy pay?`;
  considerationsCourtesyModalText = $localize`:@@products.product-considerations.new-broncos.courtesy.text: Opt-in to Ent’s Courtesy Pay service to protect your checking account from overdrafts on everyday debit card transactions.\r
• Courtesy Pay covers debit card overdrafts of up to $300 for a $30 per item fee.\r
• If you decide not to opt-in to Courtesy Pay, debit card transactions that could overdraw your account will be declined.\r
• Courtesy Pay is automatically offered on your check items and automatic bill payments based on available account balance.`;

  membershipTitleLabel = $localize`:@@products.membership-selection.title.label:Which of your memberships would you like to add this to?`;
  membershipSubtitleLabel = $localize`:@@products.membership-selection.subtitle.label:What’s a membership?`;
  membershipModalBodyLabel = $localize`:@@products.membership-selection.support-modal.body.label:At Ent, you're a member, not just an account holder. You have the flexibility to choose which existing membership you want to connect this new account to.\r\n \r\n In the past, you had to log in separately to access your other memberships or setup Switch Accounts. With the new Digital Banking, you can view all your memberships in one place, on the Accounts screen.`;
  membershipModalCloseButtonLabel = $localize`:@@products.membership-selection.support-modal.close.label:Got it`;

  depositTitleLabel = $localize`:@@products.product-deposit.title.label: Make your initial deposit`;
  depositSubtitleLabel = $localize`:@@products.product-deposit.subtitle.label: Add money to your account to start saving`;
  depositAmountLabel = $localize`:@@products.product-deposit.amount.label: Deposit`;
  depositAmountInfo = $localize`:@@products.product-deposit.amount.info.label: Minimum deposit $`;
  depositFromLabel = $localize`:@@products.product-deposit.from.label:From`;
  depositSelectAccountLabel = $localize`:@@products.product-deposit.select-account.label:Select an account`;
  depositNoAccountsLabel = $localize`:@@products.product-deposit.no-accounts.label:No accounts to transfer from`;

  reviewTitleLabel = $localize`:@@products.product-review.title.label:Does everything look right?`;
  reviewNextButtonLabel = $localize`:@@products.product-review.next.button.label:Open account`;
  reviewSubtitlesuffixLabel = $localize`:@@products.product-review.subtitle.suffix.label:  account`;
  reviewConvertingSubtitle = $localize`:@@products.product-review.convert-checking.subtitle.label:Review the details for converting your No Strings Checking to a Broncos Checking account`;

  successText = $localize`:@@products.product-base.success.text.label:Congrats on your new account! Each deposit gets you closer to your goals.`;
  checkingConversionSuccessText = $localize`:@@products.product-base.convertion.success.text.label:Remember to update recurring payments linked to your card, and set up direct deposit if you haven’t already.`;
  successHeader = $localize`:@@products.product-base.success.header.label:All done!`;
  successButtonLabel = $localize`:@@products.product-base.success.button.label:Done`;

  errorTextLabel = $localize`:@@products.product-base.error.text.label:We ran into a problem on our end. Go back or refresh the screen to see if that helps.`;
  errorHeaderLabel = $localize`:@@products.product-base.error.header.label:That didn't go as planned`;
  retryButtonLabel = $localize`:@@products.product-base.error.button.label:Refresh`;
  alloyErrorHeaderLabel = $localize`:@@products.product-base.error.alloy.header.label:We're here to help`;
  alloyErrorTextLabel = $localize`:@@products.product-base.error.alloy.text.label:We couldn’t add your new account. Visit us at the nearest Service Center or call us at 800-525-9623 to learn more.`;
  alloyButtonLabel = $localize`:@@products.product-base.alloy.error.button.label:Got it`;

  backButtonLabel = $localize`:@@products.product-base.back.button.label:Back`;
  nextButtonLabel = $localize`:@@products.product-base.next.button.label:Continue`;
  cancelButtonLabel = $localize`:@@products.product-base.cancel.button.label:Cancel`;
  modalCloseButtonLabel = $localize`:@@products.product-base.modal.close.label:Got it`;

  amounErrorMessage = $localize`:@@products.product-base.form.error.amount-message.label:Enter the amount you’d like to deposit.`;
  accountErrorMessage = $localize`:@@products.product-base.form.error.account-message.label:Select an account to fund this deposit.`;

  getInsuficientFundsErrorMessage = (depositAmount) =>
    $localize`:@@products.product-base.form.error.insuficient-funds-message.label:Select an account with at least $${depositAmount}. There isn’t enough money to cover this deposit.`;
  getAmountMinErrorMessage = (minimumDeposit) =>
    $localize`:@@products.product-base.form.error.amount-min-message.label:Enter the amount you’d like to deposit. The minimum deposit is $${minimumDeposit}.`;
  getReviewSubtitleLabel = (accountMask) =>
    $localize`:@@products.product-review.subtitle.label: Review the details of your new ${accountMask}`;
}
