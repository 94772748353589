import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { DateTime } from "luxon";
import { PaymentMode, PaymentOrdersPost } from "@backbase/payment-order-http-ang";
import {
  FORM_STATE,
  ArrangementsHTTPService,
  LoadingService,
  PaymentOrderHTTPService,
  ExternalTransfersHTTPService,
  PRODUCT_KINDS,
  PAYMENT_OPTION,
  COMPONENT_STATE,
} from "@ent/data";
import { NotificationService } from "@backbase/ui-ang/notification";
import { PaymentFormModel } from "../models/payment-model";
import { decorateExternalAccountPaymentOrder } from "../models/helpers";
import { PaymentTextService } from "./services/payment.text.service";
import { BaseWrapperComponent } from "../components/base-wrapper/base-wrapper.component";
import { APIErrorTextService } from "../services/api-error.text.service";

@Component({
  selector: "ent-payments",
  templateUrl: "payments.component.html",
})
export class PaymentsComponent extends BaseWrapperComponent implements OnInit {
  formState = FORM_STATE.EDITING;
  formStates = FORM_STATE;
  componentState = COMPONENT_STATE.LOADING;
  isSubmitError = new BehaviorSubject<boolean>(false);
  isLoading = false;
  formModel: PaymentFormModel;
  today = DateTime.now().startOf("day");

  constructor(
    router: Router,
    paymentOrderHTTPService: PaymentOrderHTTPService,
    loadingService: LoadingService,
    arrangementHTTPService: ArrangementsHTTPService,
    public readonly externalTransfersHTTPService: ExternalTransfersHTTPService,
    notificationService: NotificationService,
    apiErrorTextService: APIErrorTextService,
    public readonly textService: PaymentTextService,
  ) {
    super(
      router,
      loadingService,
      paymentOrderHTTPService,
      arrangementHTTPService,
      notificationService,
      apiErrorTextService,
    );
  }

  loadProducts = async (fromProductKinds: PRODUCT_KINDS[], toProductKinds: PRODUCT_KINDS[]) => {
    this.componentState = COMPONENT_STATE.LOADING;

    const externalTransfersPromise = this.externalTransfersHTTPService.getExternalTransferAccounts().catch(() => {
      this.notificationService.showNotification({
        header: this.textService.externalTransferErrorHeader,
        message: this.textService.externalTransferErrorBody,
        dismissible: true,
        modifier: "error",
        ttl: 0,
      });
      return Promise.resolve([]);
    });
    try {
      await Promise.all([
        this.arrangementHTTPService.getFromProducts(fromProductKinds),
        this.arrangementHTTPService.getToProducts(toProductKinds),
        externalTransfersPromise,
      ]);
      this.componentState = COMPONENT_STATE.LOADED;
    } catch (err) {
      this.componentState = COMPONENT_STATE.ERROR;
    }
  };

  getInitialFormModel = () => ({
    fromAccount: undefined,
    toAccount: undefined,
    amount: 0,
    paymentOption: PAYMENT_OPTION.MONTHLY_DUE,
    paymentMode: PaymentMode.SINGLE,
    executionDate: this.today.toISO(),
    memo: undefined,
    frequency: "ONCE",
  });

  ngOnInit(): void {
    this.initialize();
    this.loadProducts(
      [
        PRODUCT_KINDS.LOAN,
        PRODUCT_KINDS.CREDIT_CARD,
        PRODUCT_KINDS.DEBIT_CARD,
        PRODUCT_KINDS.INVESTMENT_ACCOUNT,
        PRODUCT_KINDS.TERM_DEPOSIT,
      ],
      [
        PRODUCT_KINDS.CURRENT_ACCOUNT,
        PRODUCT_KINDS.CREDIT_CARD,
        PRODUCT_KINDS.DEBIT_CARD,
        PRODUCT_KINDS.INVESTMENT_ACCOUNT,
        PRODUCT_KINDS.SAVINGS_ACCOUNT,
        PRODUCT_KINDS.TERM_DEPOSIT,
      ],
    );
  }

  restartForm() {
    super.restartForm();
    this.loadProducts(
      [
        PRODUCT_KINDS.LOAN,
        PRODUCT_KINDS.CREDIT_CARD,
        PRODUCT_KINDS.DEBIT_CARD,
        PRODUCT_KINDS.INVESTMENT_ACCOUNT,
        PRODUCT_KINDS.TERM_DEPOSIT,
      ],
      [
        PRODUCT_KINDS.CURRENT_ACCOUNT,
        PRODUCT_KINDS.CREDIT_CARD,
        PRODUCT_KINDS.DEBIT_CARD,
        PRODUCT_KINDS.INVESTMENT_ACCOUNT,
        PRODUCT_KINDS.SAVINGS_ACCOUNT,
        PRODUCT_KINDS.TERM_DEPOSIT,
      ],
    );
  }

  createPaymentOrderFormRequest(formModel: PaymentFormModel): PaymentOrdersPost {
    let paymentOrderRequest = super.createPaymentOrderFormRequest(formModel);
    if (formModel.fromAccount?.isExternal) {
      paymentOrderRequest = decorateExternalAccountPaymentOrder(formModel, paymentOrderRequest);
    }
    paymentOrderRequest.transferTransactionInformation.instructedAmount.amountType = formModel.paymentOption;
    return paymentOrderRequest;
  }
}
