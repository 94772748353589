<bb-modal-ui [isOpen]="isOpen" (cancel)="close.emit()" id="test__add-contact-modal">
  <bb-modal-header-ui [title]="textService.title"></bb-modal-header-ui>
  <bb-modal-body-ui *ngIf="isOpen">
    <ng-template bbCustomModalBody>
      <ng-container *ngIf="isAddingContactLoading">
        <bb-loading-indicator-ui loaderSize="lg" [text]="textService.loadingText"></bb-loading-indicator-ui>
      </ng-container>
      <ng-container *ngIf="!isAddingContactLoading">
        <div class="hide-back-btn">
          <bb-contact-form
            [type]="$any('accountNumber')"
            (cancel)="close.emit()"
            (save)="save.emit($event)"
          ></bb-contact-form>
        </div>
      </ng-container>
    </ng-template>
  </bb-modal-body-ui>
</bb-modal-ui>
