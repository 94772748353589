import { Pipe, PipeTransform } from "@angular/core";
import { FormArray } from "@angular/forms";
import { VALID_CHANNELS } from "../notification-card.component";

@Pipe({
  name: "canDisplayChannels",
  pure: true,
})
export class CanDisplayChannelsPipe implements PipeTransform {
  readonly validChannels = VALID_CHANNELS;
  transform(formArray: FormArray): boolean {
    return formArray.controls.some(
      (channelControl) => channelControl.value.userCanChange && this.validChannels[channelControl.value.channel],
    );
  }
}
