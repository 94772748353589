import { Component, Input, TemplateRef } from "@angular/core";
import { COMPONENT_STATE } from "@ent/data";

@Component({
  selector: "ent-stateful-component",
  templateUrl: "./stateful-component.component.html",
})
export class StatefulComponentComponent {
  @Input()
  loadingText!: string;

  @Input()
  errorHeaderText!: string;

  @Input()
  errorText!: string;

  @Input()
  componentState!: COMPONENT_STATE;

  @Input()
  contentTemplate!: TemplateRef<Component>;

  @Input()
  loadingTemplate?: TemplateRef<Component>;

  @Input()
  errorTemplate?: TemplateRef<Component>;

  componentStates = COMPONENT_STATE;
}
