import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { UserManagementService } from "@backbase/user-http-ang";
import { HeaderModule } from "@backbase/ui-ang/header";
import { EntUIModule } from "@ent/ui";
import { IFrameComponent } from "./components/iframe/iframe.component";
import { WrapperComponent } from "./components/wrapper/wrapper.component";

@NgModule({
  declarations: [IFrameComponent, WrapperComponent],
  imports: [
    RouterModule.forChild([
      {
        path: "",
        component: WrapperComponent,
      },
    ]),
    HeaderModule,
    EntUIModule,
    CommonModule,
  ],
  providers: [UserManagementService],
})
export class BillpayModule {}
