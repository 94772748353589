import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "ent-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["datepicker.component.scss"],
})
export class DatepickerComponent {
  @Input()
  controlFormGroup!: FormGroup;

  @Input()
  formFieldName!: string;

  @Input()
  label!: string;

  @Input() minDate?: string;

  @Input()
  error?: string;

  @Input()
  isInvalid!: boolean;

  @Input()
  overrideDateFormat?: string;

  @Input()
  markDisabled?: (ngbDate: { year: number; month: number; day: number }) => boolean;
}
