import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "any",
})
export class MoneyInsightsTextService {
  enrollmentTitleText = $localize`:@@mx-user-enrollment.page-title:Money Insight`;
  enrollmentSubtitleText = $localize`:@@mx-user-enrollment.page-subtitle:Spend and save smarter - with Ent's Money Insight!`;
  enrollmentDescriptionText = $localize`:@@mx-user-enrollment.page-description:Let Money Insight help you keep track of your money today while helping you plan for an even better tomorrow.`;
  enrollmentLearnText = $localize`:@@mx-user-enrollment.page-learn:Learn More`;
  enrollmentTermsText = $localize`:@@mx-user-enrollment.page-terms:I have read and accept the `;
  enrollmentTermsLinkText = $localize`:@@mx-user-enrollment.page-terms-link:Terms and Conditions`;
  enrollmentContinueText = $localize`:@@mx-user-enrollment.page-continue:Continue`;
  enrollmentValidationErrorText = $localize`:@@mx-user-enrollment.validation-error:Please read and accept the terms and conditions.`;
  moneyInsightTitle = $localize`:@@money-insights.nav.item.title:Money Insight - Retail Banking`;
  loadingText = $localize`:@@money-insights.loading:Loading Mx details...`; // todo: change to actual text
  moneyInsightErrorSubText = $localize`:@@money-insights.error.text:Error loading Mx details`; // todo: change to actual text
  moneyInsightErrorHeader = $localize`:@@money-insights.error.header:Error`; // todo: change to actual text
}
