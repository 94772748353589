<ent-open-share-card
  [heading]="card?.description"
  [title]="title"
  [subtitle]="subtitle"
  [iconClass]="iconClass"
  [supportModalText]="supportText"
  [supportModalCloseButtonText]="textService.membershipModalCloseButtonLabel"
>
  <div card-content [formGroup]="form">
    <bb-input-radio-group-ui [formControlName]="formFieldName" [preselect]="true">
      <bb-input-radio-ui
        *ngFor="let account of accounts"
        [value]="account.value"
        [label]="account.label"
      ></bb-input-radio-ui>
    </bb-input-radio-group-ui>
  </div>
  <div footer class="footer">
    <div class="buttons-row pt-5">
      <button bbButton [color]="'link'" (click)="onBack()">
        <span>{{textService.backButtonLabel}}</span>
      </button>
      <div class="d-flex">
        <div *ngIf="!hideCancelButton" class="pr-2">
          <button bbButton [color]="'secondary'" (click)="onCancel()">{{textService.cancelButtonLabel }}</button>
        </div>
        <div class="pr-2">
          <button bbButton [color]="'primary'" (click)="onNext()">{{textService.nextButtonLabel}}</button>
        </div>
      </div>
    </div>
  </div>
</ent-open-share-card>
