import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { PaymentCardNumberFormat } from "@backbase/ui-ang/payment-card-number-pipe";
import { ProductSelectorComponent as BBProductSelectorComponent } from "@backbase/ui-ang/product-selector";
import { FlatProductItem } from "@ent/data";

@Component({
  selector: "ent-account-selector-form-control",
  templateUrl: "./account-selector.component.html",
})
export class AccountSelectorFormControlComponent implements AfterViewInit {
  @Input()
  dropdownPrependTemplate?: TemplateRef<Component>;

  @Input()
  dropdownEmptyTemplate?: TemplateRef<Component>;

  @Input()
  products!: FlatProductItem[];

  @Input()
  testId!: string;

  @Input()
  selectedProduct?: FlatProductItem;

  @Input()
  label!: string;

  @Input()
  amountKey!: keyof FlatProductItem;

  @Input()
  isInvalid = false;

  @Input()
  productSelectorName = "";

  @Input()
  noItemSelectedText!: string;

  @Input()
  noItemsFoundText!: string;

  @Input()
  balanceText!: string;

  @Input()
  error?: string;

  @Output()
  selectProduct = new EventEmitter<FlatProductItem>();

  @Output()
  productSelectorIsOpenChange = new EventEmitter();

  @ViewChild("productSelector") child!: BBProductSelectorComponent | undefined;

  isDropdownOpen = false;

  numberFormat: PaymentCardNumberFormat = {
    length: 1,
    maskRange: [0, 0],
    segments: 0,
  };

  onSelectProduct = (productItem: FlatProductItem) => this.selectProduct.emit(productItem);

  ngAfterViewInit() {
    if (this.child) {
      this.child.bbDropdown?.openChange.subscribe((isOpen) => {
        this.isDropdownOpen = isOpen;
        this.productSelectorIsOpenChange.emit(isOpen);
      });
    }
  }
}
