<bb-security-settings-password></bb-security-settings-password>
<div
  *ngIf="remoteConfigParameters.callCenterPassword"
  class="bb-layout__container d-block bb-block--xl mt-5 container--fixed-width mx-auto"
>
  <ent-stateful-component
    [componentState]="componentState | async"
    [loadingText]="textService.loadingText"
    [errorHeaderText]="textService.callCenterPasswordErrorHeader"
    [errorText]="textService.callCenterPasswordErrorSubText"
    [contentTemplate]="loadedContent"
  >
  </ent-stateful-component>
</div>

<ng-template #loadedContent>
  <ent-call-center-password
    [currentPassword]="this.currentPassword | async"
    [currentReminder]="this.currentReminder | async"
    (formSubmit)="this.updateCallCenterPassword($event.password, $event.reminder)"
  ></ent-call-center-password>
</ng-template>
