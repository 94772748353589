import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ContactFormViewModel } from "@backbase/internal-contact-manager-journey-ui";
import { MemberTransferContactsModalTextService } from "../services/member-transfer-contacts-modal.text.service";

@Component({
  selector: "ent-add-contact-modal",
  templateUrl: "./add-contact-modal.component.html",
  styleUrls: ["add-contact-modal.component.scss"],
})
export class AddContactModalComponent {
  @Input()
  isOpen: boolean;

  @Input()
  isAddingContactLoading: boolean;

  @Output()
  close = new EventEmitter();

  @Output()
  save = new EventEmitter<ContactFormViewModel>();

  constructor(public readonly textService: MemberTransferContactsModalTextService) {}
}
