import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertSetting, AlertSettingPayload, AlertSettings } from "../../models";

@Injectable({
  providedIn: "root",
})
export class AlertSettingsHttpService {
  private alertSettingsUrl = "/api/engagement/client-api/v1/notification-preferences";

  constructor(private http: HttpClient) {}

  getAlertSettings(entityId: string, entityType: string): Promise<AlertSettings> {
    const options = {
      params: {
        entityId,
        entityType,
      },
    };
    return new Promise((resolve, reject) => {
      this.http.get<AlertSettings>(this.alertSettingsUrl, options).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }

  updateAlertSetting(id: string, body: AlertSettingPayload): Promise<AlertSetting> {
    const url = `${this.alertSettingsUrl}/${id}`;
    return new Promise((resolve, reject) => {
      this.http.put<AlertSetting>(url, body).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }

  createAlertSetting(body: AlertSettingPayload): Promise<AlertSetting> {
    return new Promise((resolve, reject) => {
      this.http.post<AlertSetting>(this.alertSettingsUrl, body).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }
}
