import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  AccountMetadata,
  ConnectWidgetUrl,
  MicrodepositAccountMetadata,
  MoneyInsightUrl,
  SaveAccountResponse,
} from "../../models/money-insight-url";

@Injectable({
  providedIn: "root",
})
export class MoneyInsightHttpService {
  private url = "/api/ent-mx-integration/client-api/v1/mxmoneymap";
  private enrollUrl = "/api/ent-mx-integration/client-api/v1/enroll";
  private connectUrl = "/api/ent-mx-integration/client-api/v1/connect-widget-url";
  private saveAccountUrl = "/api/ent-mx-integration/client-api/v1/external-account";
  private saveMicrodepositAccountUrl = "/api/ent-mx-integration/client-api/v1/microdeposit";
  private flowCompleteUrl = "/api/ent-mx-integration/client-api/v1/connect-widget-closed";
  private microdepositVerificationsUrl = "/api/ent-mx-integration/client-api/v1/microdeposit/verification";
  private microdepositFailedVerificationUrl = "/api/ent-mx-integration/client-api/v1/microdeposit/error";

  httpOptions = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getMoneyInsightUrl(): Promise<MoneyInsightUrl> {
    return new Promise((resolve, reject) => {
      this.http.get<MoneyInsightUrl>(this.url).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }

  enrollMoneyInsightUser(): Promise<MoneyInsightUrl> {
    return new Promise((resolve, reject) => {
      this.http.get<MoneyInsightUrl>(this.enrollUrl).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }

  getConnectUrl(externalAccountId?: string): Promise<ConnectWidgetUrl> {
    const options = externalAccountId
      ? {
          params: {
            externalAccountId,
          },
        }
      : {};
    return new Promise((resolve, reject) => {
      this.http.get<ConnectWidgetUrl>(this.connectUrl, options).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }

  saveAccount(accountMetadata: AccountMetadata): Promise<SaveAccountResponse> {
    return new Promise((resolve, reject) => {
      this.http.post<SaveAccountResponse>(this.saveAccountUrl, accountMetadata, this.httpOptions).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }

  saveMicrodepositAccount(accountMetadata: MicrodepositAccountMetadata): Promise<SaveAccountResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .post<SaveAccountResponse>(this.saveMicrodepositAccountUrl, accountMetadata, this.httpOptions)
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  completeFlow() {
    return new Promise((resolve, reject) => {
      this.http.put(this.flowCompleteUrl, {}, this.httpOptions).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }

  verifyMicrodeposits(body: any, externalAccountId: string) {
    const options = {
      params: {
        externalAccountId,
      },
    };
    return new Promise((resolve, reject) => {
      this.http.put(this.microdepositVerificationsUrl, body, options).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }

  registerFailedVerification(body: any, externalAccountId: string) {
    const options = {
      params: {
        externalAccountId,
      },
    };
    return new Promise((resolve, reject) => {
      this.http.put(this.microdepositFailedVerificationUrl, body, options).subscribe({
        next: (response) => resolve(response),
        error: (error) => reject(error),
      });
    });
  }
}
