import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { Subscription, filter, fromEvent } from "rxjs";
import { BillpayEventFactory } from "../../events/billpay-event.factory";
import { BillpayAppEventType } from "../../models/billpay-event";
import { BILLPAY_DEFAULT_PATH, BILLPAY_URL_SESSION_STORAGE_KEY } from "../../models/constants";

@Component({
  selector: "ent-billpay-iframe",
  templateUrl: "./iframe.component.html",
  styleUrls: ["./iframe.component.scss"],
})
export class IFrameComponent implements AfterViewInit, OnDestroy {
  @Input() legalEntityId: string;
  @ViewChild("frame") frame: ElementRef;
  messageEventSub: Subscription;

  constructor(private readonly billpayEventFactory: BillpayEventFactory) {}

  ngOnDestroy(): void {
    window.sessionStorage.removeItem(BILLPAY_URL_SESSION_STORAGE_KEY);
    this.messageEventSub.unsubscribe();
  }

  ngAfterViewInit() {
    const billpayPath = window.sessionStorage.getItem(BILLPAY_URL_SESSION_STORAGE_KEY) ?? BILLPAY_DEFAULT_PATH;
    const billpayUrl = new URL(`${window.location.origin}${billpayPath}`);
    // const billpayUrl = new URL(`http://localhost:5173${billpayPath}`); // This is for local development;
    billpayUrl.searchParams.set("legalEntityId", this.legalEntityId);
    this.frame.nativeElement.src = billpayUrl;
    this.messageEventSub = fromEvent(window, "message")
      .pipe(filter(this.filterWindowMessages))
      .subscribe(this.handleBillpayEvent);
  }

  private filterWindowMessages = (event: MessageEvent) =>
    event?.data && event.data.source && event.data.source.includes("ent/billpay-web");

  private handleBillpayEvent = (event: MessageEvent<BillpayAppEventType<any>>) => {
    const billpayEvent = event.data;
    this.billpayEventFactory.getEventHandler(billpayEvent).handleEvent(billpayEvent);
  };
}
