import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, map, of } from "rxjs";
import {
  ArrangementsHTTPService,
  ContactsHTTPService,
  convertToFlatProductItem,
  FlatProductItem,
  formatDateTimeString,
  getEndTypeFromDuration,
  getLocalDateTimeFromUTC,
  getMinimumEndDateFromStartDate,
  getOccurrenceFromPaymentMode,
  getPaymentModeFromOccurrence,
  OCCURRENCE,
} from "@ent/data";
import { DateTime } from "luxon";
import { BaseFormComponent } from "../../components/base-form/base-form.component";
import { IFormModel } from "../../models/form-model";
import { MEMBER_TRANSFER_FORM_ERROR_KEY } from "../../models/member-transfer-errors";
import { memberTransferFormSchema } from "../../models/member-transfer-model";
import { IFormTextService } from "../../services/form.text.service";
import { MemberTransferFormTextService } from "../services/member-transfer-form.text.service";

@Component({
  selector: "ent-member-transfer-form",
  templateUrl: "./member-transfer-form.component.html",
})
export class MemberTransferFormComponent extends BaseFormComponent implements OnInit {
  fromProducts: Observable<FlatProductItem[]> = of([]);
  contactProducts: Observable<FlatProductItem[]> = of([]);

  constructor(
    public readonly memberTransferFormTextService: MemberTransferFormTextService,
    public readonly arrangementsHTTPService: ArrangementsHTTPService,
    public readonly contactsHTTPService: ContactsHTTPService,
  ) {
    super(arrangementsHTTPService);
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      fromAccount: new FormControl(undefined),
      toAccount: new FormControl(undefined),
      amount: new FormControl(undefined),
      occurrence: new FormControl(OCCURRENCE.NOW),
      frequency: new FormControl(undefined),
      executionDate: new FormControl(this.todayISO),
      endType: new FormControl(undefined),
      endDate: new FormControl(undefined),
      repeat: new FormControl(undefined),
      memo: new FormControl(undefined),
    });
    this.resetForm(this.formModel);
    this.setupFormEvents();
    this.runYupValidation();
    this.fromProducts = this.arrangementsHTTPService.fromProductsObservable?.pipe(
      map((productItems) => productItems.map(convertToFlatProductItem)),
    );
    this.contactProducts = this.contactsHTTPService.contactsArrangements?.pipe(
      map((productItems) => productItems.map(convertToFlatProductItem)),
    );
  }

  setupFormEvents = () => {
    const { frequency, executionDate, fromAccount, occurrence, endType } = this.form.controls;

    fromAccount.valueChanges.subscribe(this.onFromAccountChange);
    occurrence.valueChanges.subscribe(this.onOccurrenceChange);
    endType.valueChanges.subscribe(this.onEndTypeChange);
    frequency.valueChanges.subscribe(this.onFrequencyChange);
    executionDate.valueChanges.subscribe(this.onExecutionDateChange);
    this.form.valueChanges.subscribe(() => {
      this.runYupValidation();
    });
  };

  resetForm = (defaultModel?: IFormModel) => {
    this.fromAccount = defaultModel?.fromAccount;
    this.toAccount = defaultModel?.toAccount;
    this.form.reset({
      fromAccount: defaultModel?.fromAccount?.id,
      toAccount: defaultModel?.toAccount?.id,
      amount: !defaultModel?.amount ? undefined : defaultModel.amount,
      occurrence: getOccurrenceFromPaymentMode(defaultModel?.paymentMode, defaultModel?.executionDate),
      frequency: defaultModel?.frequency,
      executionDate: DateTime.fromISO(defaultModel?.executionDate ?? this.todayISO).toISO(),
      endType: defaultModel?.endType,
      endDate: defaultModel ? DateTime.fromISO(defaultModel.endDate).toISO() : undefined,
      repeat: defaultModel?.repeat,
      memo: defaultModel?.memo,
    });
  };

  runYupValidation = () => {
    this.errors = undefined;
    const { executionDate, endDate, amount, fromAccount, toAccount, occurrence, frequency, repeat, memo, endType } =
      this.form.controls;
    try {
      const executionDateValue = DateTime.fromISO(executionDate.value);
      const endDateValue = DateTime.fromISO(endDate.value);
      memberTransferFormSchema.validateSync(
        {
          fromAccountId: fromAccount.value ?? undefined,
          fromAccountBalance: this.fromAccount?.availableBalance,
          toAccountId: toAccount.value ?? undefined,
          amount: Number.isFinite(Number.parseFloat(amount.value)) ? amount.value : undefined,
          occurrence: occurrence.value ?? undefined,
          frequency: frequency.value ?? undefined,
          executionDate: executionDateValue.isValid ? executionDateValue.toISO() : undefined,
          endType: endType.value ?? undefined,
          endDate: endDateValue.isValid ? endDateValue.toISO() : undefined,
          repeat: Number.isInteger(Number.parseInt(repeat.value, 10)) ? repeat.value : undefined,
          memo: memo.value ?? undefined,
        },
        { abortEarly: false },
      );
    } catch (err) {
      this.setErrors(err.errors);
    }
  };

  setErrors = (errors: MEMBER_TRANSFER_FORM_ERROR_KEY[] = []) => {
    let amount: string | undefined;
    let executionDate: string | undefined;
    let endDate: string | undefined;
    if (errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.AMOUNT_MAX)) {
      amount = this.memberTransferFormTextService.amountFromMaxError(this.fromAccount);
    } else if (errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.AMOUNT_REQUIRED)) {
      amount = this.memberTransferFormTextService.amountRequiredError;
    }
    if (errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_MIN)) {
      executionDate = this.memberTransferFormTextService.executionDatePastError;
    } else if (errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.EXECUTION_DATE_REQUIRED)) {
      executionDate = this.memberTransferFormTextService.executionDateRequiredError;
    }
    if (errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.END_DATE_MIN)) {
      const minDate = DateTime.fromISO(
        getMinimumEndDateFromStartDate(
          this.form.controls.frequency.value,
          getLocalDateTimeFromUTC(this.form.controls.executionDate.value)?.toISO(),
        ),
      );
      endDate = this.memberTransferFormTextService.endDateLessThanMinDateError(minDate.toFormat("LLLL dd, yyyy"));
    } else if (errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.END_DATE_REQUIRED)) {
      endDate = this.memberTransferFormTextService.endDateRequiredError;
    }
    this.errors = {
      fromAccount: errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.FROM_REQUIRED)
        ? this.memberTransferFormTextService.fromAccountRequiredError
        : undefined,
      toAccount: errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.TO_REQUIRED)
        ? this.memberTransferFormTextService.toAccountRequiredError
        : undefined,
      amount,
      executionDate,
      endDate,
      repeat: errors.includes(MEMBER_TRANSFER_FORM_ERROR_KEY.REPEAT_REQUIRED)
        ? this.memberTransferFormTextService.repeatRequiredError
        : undefined,
    };
  };

  onValidateForm = () => {
    const { amount, executionDate, occurrence, memo, frequency, endType, repeat, endDate } = this.form.controls;
    this.form.markAllAsTouched();
    this.runYupValidation();
    if (this.errors) {
      return;
    }
    this.validateForm.emit({
      amount: amount.value,
      executionDate: formatDateTimeString(getLocalDateTimeFromUTC(executionDate.value)?.toISO(), "yyyy-LL-dd"),
      paymentMode: getPaymentModeFromOccurrence(occurrence.value),
      memo: memo.value,
      toAccount: this.toAccount,
      fromAccount: this.fromAccount,
      frequency: frequency.value,
      repeat: this.getRepeat(endType.value, repeat.value),
      endDate: this.getEndDate(endType.value, getLocalDateTimeFromUTC(endDate.value)?.toISO()),
      endType: getEndTypeFromDuration(repeat.value, getLocalDateTimeFromUTC(endDate.value)?.toISO()),
    });
  };

  getFormTextService = (): IFormTextService => {
    return this.memberTransferFormTextService;
  };
}
