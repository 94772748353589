import { NgModule } from "@angular/core";
import { HeaderModule } from "@backbase/ui-ang/header";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "@backbase/ui-ang/button";
import { ReactiveFormsModule } from "@angular/forms";
import { IconModule } from "@backbase/ui-ang/icon";
import { SwitchModule } from "@backbase/ui-ang/switch";
import { CollapsibleCardModule } from "@backbase/ui-ang/collapsible-card";
import { UserProfileManagementService } from "@backbase/user-http-ang";
import { LoadingService, NotificationPreferenceHTTPService } from "@ent/data";
import { EntUIModule } from "@ent/ui";
import { NotificationPreferenceComponent } from "./notification-preference.component";

@NgModule({
  declarations: [NotificationPreferenceComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: "", component: NotificationPreferenceComponent, pathMatch: "full" }]),
    ReactiveFormsModule,
    EntUIModule,
    HeaderModule,
    IconModule,
    ButtonModule,
    CollapsibleCardModule,
    SwitchModule,
  ],
  providers: [NotificationPreferenceHTTPService, UserProfileManagementService, LoadingService],
})
export class NotificationPreferenceModule {}
