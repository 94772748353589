import { Injectable } from "@angular/core";
import { PRODUCTS_FORM_STATE } from "@ent/data";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductsBaseStepsService {
  steps$ = new BehaviorSubject<PRODUCTS_FORM_STATE[]>([]);
  currentStep$ = new BehaviorSubject<PRODUCTS_FORM_STATE>(undefined);

  addStep(step: PRODUCTS_FORM_STATE) {
    this.steps$.next([...this.steps$.value, step]);
  }

  getCurrentStep(): Observable<PRODUCTS_FORM_STATE> {
    return this.currentStep$.asObservable();
  }

  setCurrentStep(step: PRODUCTS_FORM_STATE): void {
    this.currentStep$.next(step);
  }

  clearStepper(): void {
    this.steps$.next([]);
    this.currentStep$.next(undefined);
  }

  goNext(): void {
    const stepsArray = this.steps$.value;
    const nextIndex = stepsArray.indexOf(this.currentStep$.value) + 1;
    const lastIndex = stepsArray.length - 1;
    this.currentStep$.next(stepsArray[nextIndex] || stepsArray[lastIndex]);
  }

  goBack(): void {
    const stepsArray = this.steps$.value;
    const previousIndex = stepsArray.indexOf(this.currentStep$.value) - 1;
    this.currentStep$.next(stepsArray[previousIndex] || stepsArray[0]);
  }
}
