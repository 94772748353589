<div [formGroup]="controlFormGroup">
  <label class="bb-dynamic-input__label form-label"> {{ textService.frequencySelectorLabel }} </label>
  <ent-dropdown-single-select
    [options]="frequencyOptionsData"
    [controlFormGroup]="controlFormGroup"
    formFieldName="frequencyPicker"
  >
  </ent-dropdown-single-select>

  <ng-container [ngSwitch]="controlFormGroup.controls.frequencyPicker.value">
    <ng-container *ngSwitchCase="frequencyOptions.WEEKLY">
      <label class="bb-dynamic-input__label form-label"> {{ textService.weeklySelectorLabel }} </label>
      <ent-dropdown-single-select
        [options]="weekdaysOptions"
        [controlFormGroup]="controlFormGroup"
        formFieldName="dayOfWeekPicker"
      ></ent-dropdown-single-select>
    </ng-container>
    <ng-container *ngSwitchCase="frequencyOptions.MONTHLY">
      <fieldset>
        <legend>
          <label class="bb-dynamic-input__label form-label"> {{ textService.monthlySelectorLabel }} </label>
        </legend>
        <input type="number" min="1" max="31" formControlName="dayOfMonthPicker" class="form-control mb-3" />
        <bb-input-validation-message-ui [showErrors]="!!controlFormGroup.controls.dayOfMonthPicker.errors">
          <span>{{ textService.dayOfMonthErrorText }}</span>
        </bb-input-validation-message-ui>
      </fieldset>
    </ng-container>
  </ng-container>
  <div>
    <label class="bb-dynamic-input__label form-label"> {{ textService.timeSelectorLabel }} </label>
    <bb-input-timepicker-ui
      id="timePicker-validation"
      [readonly]="false"
      [minuteStep]="60"
      [required]="true"
      formControlName="timePicker"
      clock="12h"
    >
    </bb-input-timepicker-ui>
    <bb-input-validation-message-ui
      [showErrors]="controlFormGroup.controls.timePicker.dirty && controlFormGroup.controls.timePicker.invalid"
    >
      <span>{{ textService.timeErrorText }}</span>
    </bb-input-validation-message-ui>
    <div class="bb-text-support">{{ textService.timeSupportText }}</div>
  </div>
</div>
