import * as yup from "yup";
import { FORM_CONTROL_NAMES } from "@ent/data";
import { PRODUCT_FORM_ERROR_KEY } from "./product-errors";

export const productFormSchema = yup.object().shape({
  [FORM_CONTROL_NAMES.DEPOSIT_AMOUNT]: yup
    .number()
    .required(PRODUCT_FORM_ERROR_KEY.AMOUNT_REQUIRED)
    .when("minimumDeposit", (...rest) => {
      const [minimumDeposit, schema] = rest;
      if (minimumDeposit) return schema.min(minimumDeposit, PRODUCT_FORM_ERROR_KEY.AMOUNT_MIN);
      return schema.min(0.01, PRODUCT_FORM_ERROR_KEY.AMOUNT_REQUIRED);
    })
    .when("fromAccountBalance", (...rest) => {
      const [fromAccountBalance, schema] = rest;
      return schema.max(fromAccountBalance ?? Number.MAX_SAFE_INTEGER, PRODUCT_FORM_ERROR_KEY.AMOUNT_MAX);
    }),
  [FORM_CONTROL_NAMES.DEPOSIT_ACCOUNT]: yup.string().required(PRODUCT_FORM_ERROR_KEY.ACCOUNT_REQUIRED),
});
