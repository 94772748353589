<form [formGroup]="form">
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <div class="bb-stack bb-block bb-block--xl" id="test__reset-pin-modal--cvv-body">
        <div class="bb-stack__item">
          <h4>{{ textService.cvvHeader }}</h4>
          <p>
            <small class="text-muted">{{ textService.cvvSubText }}</small>
          </p>
          <label for="cvvCode" [attr.data-role]="'label'">{{ textService.cvvLabelText }}</label>
          <div
            class="input-group"
            [ngClass]="{ 'input__group--invalid': form.controls.cvv.errors && form.controls.cvv.touched }"
          >
            <input
              id="cvvCode"
              [type]="isShown ? 'text' : 'password'"
              class="form-control"
              mask="000"
              [hiddenInput]="true"
              autocomplete="off"
              formControlName="cvv"
              [attr.data-role]="'input'"
            />
            <div class="input-group-append ent__input-append">
              <button
                bbButton
                (click)="toggleIsShown()"
                color="link"
                class="bb-input-password__visibility-toggle-button"
                data-role="bb-input-password-visibility-toggle-button"
              >
                <em class="fa-light" [ngClass]="{ 'fa-eye': !isShown, 'fa-eye-slash': isShown }"></em>
              </button>
            </div>
          </div>
          <bb-input-validation-message-ui
            id="cvv-code-errors"
            [showErrors]="form.controls.cvv.errors && form.controls.cvv.touched"
          >
            <span
              *ngIf="
                !form.controls.cvv.errors?.mismatch &&
                (form.controls.cvv.errors?.required || form.controls.cvv.errors?.minlength)
              "
            >
              {{ textService.cvvLengthError }}
            </span>
            <span *ngIf="form.controls.cvv.errors?.mismatch">{{ textService.cvvMismatchError }}</span>
          </bb-input-validation-message-ui>
        </div>
        <div class="bb-stack__item">
          <div
            data-role="modal-card-img"
            class="bb-block bb-block--md bb-payment-card-back bb-payment-card-icon-dialog"
          ></div>
        </div>
      </div>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui>
    <ng-template bbCustomModalFooter>
      <div class="bb-button-bar" id="test__reset-pin-modal--cvv-footer">
        <button
          bbButton
          class="bb-button-bar__button"
          color="primary"
          type="submit"
          (click)="onConfirm()"
          data-role="confirm-action-btn"
        >
          {{ textService.pinContinueText }}
        </button>
        <button
          bbButton
          color="secondary"
          class="bb-button-bar__button"
          (click)="onCancel()"
          data-role="cancel-action-btn"
        >
          {{ textService.pinCancelText }}
        </button>
      </div>
    </ng-template>
  </bb-modal-footer-ui>
</form>
