import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PageErrorComponent } from "../page-error/page-error.component";

@Component({
  selector: "ent-page-retry",
  templateUrl: "./page-retry.component.html",
})
export class PageRetryComponent extends PageErrorComponent {
  @Input()
  buttonText!: string;

  @Input()
  backButtonText!: string;

  @Output()
  clickRetry = new EventEmitter();

  @Output()
  clickBack = new EventEmitter();
}
