<div
  class="bb-block bb-block--xl"
  [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading"
>
  <form [formGroup]="notificationForm">
    <bb-collapsible-ui class="bb-card" [isOpen]="isCardExpanded">
      <ng-template bbCollapsibleHeader>
        <div [ngClass]="isCardExpanded ? 'card-header' : 'card-body'" data-role="collapsible-header">
          <div class="bb-stack">
            <bb-header-ui
              class="bb-stack__item"
              headingType="h3"
              [heading]="textService.labels[alertSetting.generalNotificationId].heading"
              data-role="notification-card-header"
              [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__header'"
            ></bb-header-ui>
            <bb-switch-ui
              [disabled]="isSaving"
              (click)="toggleCard()"
              formControlName="active"
              class="bb-stack__item bb-stack__item--push-right"
              [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__header--toggle'"
            >
            </bb-switch-ui>
          </div>
          <div
            class="bb-text-support"
            data-role="notification-card-sub-header"
            [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__description'"
          >
            {{ textService.labels[alertSetting.generalNotificationId].description }}
          </div>
        </div>
      </ng-template>
      <ng-template bbCollapsibleBody>
        <div
          class="card-body"
          [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__card'"
        >
          <ng-container *ngIf="amountControl">
            <label
              id="currency-input-label"
              for="currency-input"
              class="bb-label"
              [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__amount-label'"
            >
              {{ textService.labels[alertSetting.generalNotificationId].currencyInputLabel }}
            </label>
            <div class="row bb-block bb-block--md">
              <div class="col-lg-6 col-12">
                <ng-template #amountTemplate>
                  <bb-amount-ui
                    class="bb-highlight"
                    [amount]="amountControl.value"
                    [currency]="currencyInputSettings.currency"
                    [trailingZeroes]="false"
                    [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__amount-input'"
                  ></bb-amount-ui>
                </ng-template>
                <bb-input-inline-edit-ui
                  id="currency-input"
                  data-role="balance-input"
                  [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__amount-edit-btn'"
                  [inputText]="amountControl.value"
                  [currency]="currencyInputSettings.currency"
                  [pattern]="currencyInputSettings.pattern"
                  [minValue]="currencyInputSettings.minValue"
                  [ariaLabelEdit]="textService.currencyInputAriaLabel"
                  [inputInlineTemplate]="amountTemplate"
                  [readonly]="isSaving"
                  (accept)="onInlineEditAccept($event)"
                ></bb-input-inline-edit-ui>
              </div>
              <div
                class="bb-text-support"
                [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__balance-label'"
              >
                {{ textService.labels[alertSetting.generalNotificationId].balanceDescription }}
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="alertSetting.generalNotificationId === 'balance-on-schedule'"
            [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__frequency-select--container'"
          >
            <div class="row bb-block bb-block--md">
              <div class="col-lg-6 col-12">
                <ent-frequency-selector
                  [controlFormGroup]="frequencyFormGroup"
                  [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__frequency-select'"
                ></ent-frequency-selector>
              </div>
            </div>
          </ng-container>
          <div *ngIf="channels | canDisplayChannels">
            <label class="bb-label bb-stack">
              <span data-role="channel-title">{{ textService.channelsLabel }}</span>
              <bb-icon-ui
                [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__channel-title'"
                name="info"
                role="tooltip"
                size="sm"
                data-role="channel-title-icon"
                triggers="hover focus"
                class="mx-2"
                [aria-label]="textService.channelsAriaLabel"
                [bbTooltip]="textService.channelsAriaLabel"
              ></bb-icon-ui>
            </label>
            <div formArrayName="channels" class="bb-block bb-block--sm">
              <ng-container *ngFor="let control of channels.controls; index as i">
                <div
                  *ngIf="control.value.userCanChange && validChannels[control.value.channel]"
                  [formGroupName]="i"
                  class="my-2"
                  [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__' + control.value.channel"
                >
                  <bb-input-checkbox-ui
                    [attr.data-testid]="'manage-notifications-' + textService.labels[alertSetting.generalNotificationId].heading + '__' + control.value.channel + '--checkbox'"
                    [label]="textService.channelLabels[control.value.channel]"
                    formControlName="enabled"
                    (change)="submitForm()"
                    (click)="!isSaving"
                  ></bb-input-checkbox-ui>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </bb-collapsible-ui>
  </form>
</div>
