import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from "@angular/core";
import { KeyValue } from "@angular/common";
import { BehaviorSubject } from "rxjs";
import {
  COMPONENT_STATE,
  PRODUCT_CARD_ITEMS,
  EligibleProduct,
  PRODUCT_SECTIONS,
  PRODUCT_SUBSECTIONS,
  ProductCard,
  ProductCardLayout,
  RetailAppRemoteConfig,
  PRODUCTS_FORM_STATE,
  SUBCODES,
  SymitarHttpService,
} from "@ent/data";
import { DomSanitizer } from "@angular/platform-browser";
import { RemoteConfigService } from "@backbase/remote-config-ang";
import { ExploreProductsTextService } from "./services/explore-products.text.service";
import { ProductsBaseStepsService } from "../../services/products-base.steps.service";

/* eslint-disable @typescript-eslint/naming-convention */
export enum EXPLORE_PRODUCT_TABS {
  "bank",
  "borrow",
}

export const subsectionsOrder = new Map([
  ["checking", 1],
  ["savingsCertificates", 2],
  ["home", 3],
  ["auto", 4],
  ["personal", 5],
  ["cards", 6],
  ["other", 7],
]);

// TODO: change to use unique id once test id is available.
export const cardOrderMap = new Map([
  ["no strings checking", 1],
  ["denver broncos checking", 2],
  ["regular savings", 3],
  ["ent high(er) yield savings", 4],
  ["money market", 5],
  ["flex cd", 6],
  ["short-term cd", 7],
  ["long-term cd", 8],
  ["mortgage", 8],
  ["auto loan", 9],
  ["personal loan", 10],
]);

/* eslint-enable @typescript-eslint/naming-convention */
@Component({
  selector: "ent-explore-products",
  templateUrl: "./explore-products.component.html",
})
export class ExploreProductsComponent implements OnInit {
  shouldShowBroncos = this.remoteConfigService.getValue("web_broncos_checking_enabled"); // TODO: remove after broncos release.
  tabs = EXPLORE_PRODUCT_TABS;
  sections = PRODUCT_SECTIONS;
  subsections = PRODUCT_SUBSECTIONS;
  formStates = PRODUCTS_FORM_STATE;
  selectedTab = PRODUCT_SECTIONS.BANK;
  productCardItems = PRODUCT_CARD_ITEMS;
  subcode = SUBCODES;
  componentState = new BehaviorSubject(COMPONENT_STATE.LOADED);

  cardsLayout;
  @Input() shareTypes: EligibleProduct[];
  @Input() loanTypes: EligibleProduct[];
  @Input() isLoading = false;
  @Output() stateChange = new EventEmitter();
  @Output() addProduct = new EventEmitter();
  @Output() tabChange = new EventEmitter();

  constructor(
    public textService: ExploreProductsTextService,
    public stepsService: ProductsBaseStepsService,
    private remoteConfigService: RemoteConfigService<RetailAppRemoteConfig>,
    private symitarService: SymitarHttpService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.initFlowSteps();
    this.getSymitarStatus();
  }

  ngOnChanges() {
    this.setupCardLayout();
  }

  getSymitarStatus = async () => {
    this.componentState.next(COMPONENT_STATE.LOADING);
    this.symitarService.getSymitarStatus().subscribe({
      next: (result) => {
        if (result.response.isInMemoMode) {
          this.componentState.next(COMPONENT_STATE.MEMO_POST);
        } else {
          this.componentState.next(COMPONENT_STATE.LOADED);
        }
      },
      error: () => this.componentState.next(COMPONENT_STATE.LOADED),
    });
  };

  setupCardLayout() {
    const eligibleCards = [
      ...this.mapToProductCard(this.shareTypes, this.sections.BANK),
      ...this.mapToProductCard(this.loanTypes, this.sections.BORROW),
    ];
    if (eligibleCards.length) {
      this.cardsLayout = this.buildProductCardLayout(eligibleCards.sort(this.sortCards));
      if (!this.cardsLayout.bank && this.cardsLayout.borrow) this.selectedTab = this.sections.BORROW;
      this.changeToState(COMPONENT_STATE.LOADED);
    } else {
      this.changeToState(COMPONENT_STATE.ERROR);
    }
  }

  changeToState(state: COMPONENT_STATE) {
    this.stateChange.emit(state);
  }

  onTabSelect(index: number) {
    this.selectedTab = this.tabs[index] as PRODUCT_SECTIONS;
    this.tabChange.emit(this.selectedTab);
  }

  sortSubsections(a: KeyValue<never, never>, b: KeyValue<never, never>) {
    return subsectionsOrder.get(a.key) > subsectionsOrder.get(b.key) ? 1 : -1;
  }

  sortCards(a, b) {
    const valueA = cardOrderMap.get(a.description.toLowerCase()) || 99;
    const valueB = cardOrderMap.get(b.description.toLowerCase()) || 99;
    return valueA > valueB ? 1 : -1;
  }

  onAddProduct(event) {
    if (event.url) {
      const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, event.url);
      window.open(sanitizedUrl, "_blank");
    } else {
      this.addProduct.emit(event);
    }
  }

  initFlowSteps() {
    this.stepsService.clearStepper();
    this.stepsService.addStep(this.formStates.EXPLORE);
    this.stepsService.addStep(this.formStates.INFORMATION);
    this.stepsService.setCurrentStep(this.formStates.EXPLORE);
  }

  private getRateLabel = (card: ProductCard): string => {
    const minRate = card.rates[0];
    const maxRate = card.rates.length > 1 ? card.rates[card.rates.length - 1] : undefined;
    if (card.subCode === this.subcode.BRONCOS) return this.textService.broncosRateLabel;
    if (card.type === "10") return this.textService.checkingRateLabel;
    if (!minRate && !maxRate) return "";
    return maxRate ? this.textService.getRateRangeLabel(minRate, maxRate) : this.textService.getRateSuffix(minRate);
  };

  private buildProductCardLayout = (productCardItems: Array<ProductCard>): ProductCardLayout => {
    const layout: ProductCardLayout = {};
    productCardItems.forEach((item) => {
      const { section, subsection } = item;
      if (!layout[section]) layout[section] = {};
      if (!layout[section][subsection]) layout[section][subsection] = [];
      item.rateLabel = this.getRateLabel(item);
      layout[section][subsection].push(item);
    });
    return layout;
  };

  private mapToProductCard(products: Array<EligibleProduct>, section: PRODUCT_SECTIONS) {
    return products.flatMap((product) => {
      const productCardItem = this.productCardItems.find(
        (cardItem) =>
          cardItem.types.has(product.type) &&
          cardItem.section === section &&
          (!product.subCode || cardItem.subCode === product.subCode),
      );
      if (!productCardItem) return [];
      if (!this.shouldShowBroncos && productCardItem.subCode === this.subcode.BRONCOS) return []; // TODO: remove after broncos release.
      return { ...productCardItem, ...product };
    });
  }
}
