import { Injectable } from "@angular/core";
import {
  ContactsGetResponseBodyItem,
  ContactsHttpService as BackbaseContactsHttpService,
  GetContactsRequestParams,
  PostContactsRequestParams,
} from "@backbase/contact-manager-http-ang";
import { BehaviorSubject, catchError } from "rxjs";
import { ArrangementItemWithAdditions } from "../../models/arrangements-with-additions";
import { LOADING_KEYS } from "../../models/loading-keys";
import { LoadingService } from "../loading.service";

@Injectable({
  providedIn: "root",
})
export class ContactsHTTPService {
  constructor(
    private readonly loadingService: LoadingService,
    private readonly contactsHttpService: BackbaseContactsHttpService,
  ) {
    // add in the base path to fix upgrade 2023.06 issue where this base path was removed
    // contactsHttpService.configuration.basePath = "/api/contact-manager";
  }

  public contacts: ContactsGetResponseBodyItem[] = [];
  public contactsArrangements = new BehaviorSubject<ArrangementItemWithAdditions[]>([]);

  getContacts = (params: GetContactsRequestParams) => {
    this.loadingService.startLoading(LOADING_KEYS.INITIAL_LOADING);
    return new Promise((resolve, reject) => {
      this.contactsHttpService.getContacts(params).subscribe({
        next: (response) => {
          this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
          this.contacts = response;
          this.contactsArrangements.next(this.convertContactsToArrangementsWithAdditions(response));
          return resolve(response);
        },
        error: (err) => {
          this.loadingService.stopLoading(LOADING_KEYS.INITIAL_LOADING);
          this.contacts = [];
          return reject(err);
        },
      });
    });
  };

  addContact = (params: PostContactsRequestParams) => {
    this.loadingService.startLoading(LOADING_KEYS.ADD_CONTACT);
    return new Promise((resolve, reject) => {
      this.contactsHttpService
        .postContacts(params)
        .pipe(
          catchError((err) => {
            this.loadingService.stopLoading(LOADING_KEYS.ADD_CONTACT);
            reject(err);
            return err;
          }),
        )
        .subscribe((response) => {
          this.loadingService.stopLoading(LOADING_KEYS.ADD_CONTACT);
          return resolve(response);
        });
    });
  };

  convertContactsToArrangementsWithAdditions = (
    contacts: ContactsGetResponseBodyItem[],
  ): ArrangementItemWithAdditions[] =>
    contacts.reduce((arrangements, contact) => {
      arrangements = [
        ...arrangements,
        ...contact.accounts.map((account) => ({
          id: contact.id,
          name: contact.name,
          displayName: contact.name,
          minimumPayment: 0,
          minimumPaymentDueDate: "",
          productNumber: `${account.name} - ${account.accountNumber}`,
          BBAN: `${account.name} - ${account.accountNumber}`, // eslint-disable-line
          debitCards: [],
          legalEntityIds: [],
          attributes: {
            key: {
              value: "",
              type: "",
            },
          },
        })),
      ];
      return arrangements;
    }, [] as ArrangementItemWithAdditions[]);
}
