import { Injectable } from "@angular/core";

@Injectable({ providedIn: "any" })
export class NotificationPreferenceTextService {
  generalTitle = $localize`:@@notification-preference.general.title: General`;
  profileSectionTitle = $localize`:@@notification-preference.profile.title: Profile`;
  profileSectionSupportText = $localize`:@@notification-preference.profile.support-text:We'll notify you if anything changes in regard to your contact info`;
  manageProfileFormButton = $localize`:@@notification-preference.profile-manage.button: Manage`;
  backButton = $localize`:@@notification-preference.back.button: Back to Notification Preferences`;
  contactPreferenceTitle = $localize`:@@notification-preference.contact-preference.title: Contact Preference`;
  contactPreferenceQuestion = $localize`:@@notification-preference.contact-preference.question:How can we contact you?`;
  contactPreferenceStatement = $localize`:@@notification-preference.contact-preference.statement:I give Ent Credit Union permission to contact me to discuss relevant products & services by:`;
  doNotContactLabel = $localize`:@@notification-preference.contact-preference.no-contact.label:Please do not contact me to discuss relevant products & service info`;
  doNotContactDisclaimer = $localize`:@@notification-preference.contact-preference.no-contact.disclaimer:Ent may still contact you with important information about your membership or accounts.`;
  mobileTextMessageLabel = $localize`:@@notification-preference.contact-preference.moible-text.label:Text Message `;
  mobileTextMessageSwitchLabel = $localize`:@@notification-preference.contact-preference.moible-text.aria-label:set/unset text message aria label`;
  mobileNumberCallLabel = $localize`:@@notification-preference.contact-preference.mobile-call.label:Cell Phone `;
  homeNumberCallLabel = $localize`:@@notification-preference.contact-preference.home-call.label:Home Phone `;
  optInCellSMS = $localize`:@@notification-preference.contact-preference.opt-in-cell-phone-sms.label:Text`;
  optInCellCall = $localize`:@@notification-preference.contact-preference.opt-in-cell-phone-call.label:Cell`;
  optInHomeCall = $localize`:@@notification-preference.contact-preference.opt-in-home-phone-call.label:Home`;
  loadingText = $localize`:@@notification-preference.loading-text:Loading notification preferences..`;
  successNotification = $localize`:@@notification-preference.success.notification: Contact Preference saved`;
  failureNotification = $localize`:@@notification-preference.failure.notification: We ran into a problem trying to save your preference. Please try again.`;
  helperText = $localize`:@@notification-preference.contact-preference.helper-text:I give Ent Credit Union permission to contact me to discuss relevant products & services by:`;
  errorHeader = $localize`:@@notification-preference.error.header:We have a little problem`;
  errorSubText = $localize`:@@notification-preference.error.sub-text: We couldn't load the page. Refresh the screen to try again.`;
}
