import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberOrDefault",
})
export class NumberOrDefaultPipe implements PipeTransform {
  transform<T>(value: T | undefined, property: keyof T, defaultValue?: number): number | undefined {
    if (!value || value[property] === undefined) {
      return defaultValue;
    }

    return value[property] as any;
  }
}
