import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertSetting, CardData } from "@ent/data";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ManageNotificationsTextService } from "../services/manage-notifications.text.service";

export const VALID_CHANNELS = {
  sms: true,
  email: true,
};
@Component({
  selector: "ent-notification-card",
  templateUrl: "./notification-card.component.html",
})
export class NotificationCardComponent implements OnInit {
  @Input() alertSetting: AlertSetting;
  @Input() entityId: string;
  @Input() isSaving = false;
  @Output() cardSubmit = new EventEmitter();
  readonly currencyInputSettings = {
    currency: "USD",
    pattern: "\\d{1,18}",
    minValue: 0,
  };
  validChannels = VALID_CHANNELS;
  isCardExpanded: boolean;
  notificationForm: FormGroup;
  frequencyFormGroup: FormGroup;
  get channels(): FormArray {
    return this.notificationForm?.get("channels") as FormArray;
  }
  get amountControl() {
    return this.notificationForm?.get("conditions")?.get("amount");
  }

  constructor(private readonly formBuilder: FormBuilder, public textService: ManageNotificationsTextService) {}

  ngOnInit(): void {
    this.initForm();
  }

  onInlineEditAccept(amount: string) {
    if (this.notificationForm) {
      this.amountControl.patchValue(Number(amount) || 0);
      this.submitForm();
    }
  }

  toggleCard() {
    this.isCardExpanded = !this.isCardExpanded;
    this.submitForm();
  }

  submitForm() {
    this.cardSubmit.emit({
      formData: this.notificationForm.getRawValue(),
      id: this.alertSetting.id,
    } as CardData);
  }

  private initForm() {
    this.notificationForm = this.formBuilder.group({
      generalNotificationId: [this.alertSetting.generalNotificationId],
      eRef: [this.entityId],
      active: [this.alertSetting.active],
      conditions:
        this.alertSetting.userConditions.conditions?.amount >= 0
          ? this.formBuilder.group({
              amount: [this.alertSetting.userConditions.conditions.amount],
            })
          : [{}],
      channels: this.formBuilder.array(this.getChannelGroups()),
      frequency: this.getFrequencyGroup(),
    });
    this.frequencyFormGroup = this.notificationForm?.get("frequency") as FormGroup;
    this.isCardExpanded = this.alertSetting.active;
  }

  private getChannelGroups(): Array<FormGroup> {
    return this.alertSetting.channels.map((channel) => {
      return this.formBuilder.group({
        channel: [channel.channel],
        enabled: [channel.enabled],
        userCanChange: [channel.userCanChange],
      });
    });
  }

  private getFrequencyGroup(): FormGroup {
    return this.formBuilder.group({
      frequencyPicker: [this.alertSetting.frequency?.frequency],
      dayOfWeekPicker: [this.alertSetting.frequency?.dayOfWeek],
      dayOfMonthPicker: [this.alertSetting.frequency?.dayOfMonth],
      timePicker: [this.alertSetting.frequency?.time, Validators.pattern(/^((\d)|([0-1]\d)|(2[0-3])):(00)/)],
    });
  }
}
