import { Component, Input } from "@angular/core";
import { TextService } from "../../services/text.service";

@Component({
  selector: "ent-card-activation",
  templateUrl: "card-activation.component.html",
})
export class CardActivationComponent {
  @Input()
  isATMCard: boolean;

  constructor(public readonly textService: TextService) {}

  isCardActivationModalOpen = false;

  openCardActivationModal = () => {
    this.isCardActivationModalOpen = true;
  };

  closeCardActivationModal = () => {
    this.isCardActivationModalOpen = false;
  };
}
