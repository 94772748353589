import { Injectable } from "@angular/core";
import { entFormatCurrency, FlatProductItem } from "@ent/data";
import { IFormTextService } from "../../services/form.text.service";

@Injectable({ providedIn: "any" })
export class MemberTransferFormTextService implements IFormTextService {
  fromLabel = $localize`:@@member-transfer.form.from:From`;
  toLabel = $localize`:@@member-transfer.form.to:To`;
  selectAccount = $localize`:@@member-transfer.form.select-account:Select an account`;
  selectRecipient = $localize`:@@member-transfer.form.select-recipient:Select a recipient`;
  noAccounts = $localize`:@@member-transfer.form.no-accounts:No accounts to transfer to`;
  amount = $localize`:@@member-transfer.form.amount:Amount`;
  memoLabel = $localize`:@@member-transfer.form.memo-label:Personal Note`;
  memoHelperText = $localize`:@@member-transfer.form.memo-helper-text:(Optional)`;
  memoPlaceholder = $localize`:@@member-transfer.form.memo-placeholder:Enter a note`;
  occurrenceSelectLabel = $localize`:@@member-transfer.form.when-dropdown-label:When`;
  frequencySelectLabel = $localize`:@@member-transfer.form.frequency-dropdown-label:Frequency`;
  executionDateLabel = $localize`:@@member-transfer.form.execution-date-label: Date`;
  recurringExecutionDateLabel = $localize`:@@member-transfer.form.recurring-execution-date-label: Start Date`;
  nowLabel = $localize`:@@member-transfer.form.dropdown-option-1:Now`;
  laterLabel = $localize`:@@member-transfer.form.dropdown-option-2:Later`;
  recurringLabel = $localize`:@@member-transfer.form.dropdown-option-3:Recurring`;

  frequencyWeeklyLabel = $localize`:@@member-transfer.form.frequency.weekly: Every Week`;
  frequencyBiWeeklyLabel = $localize`:@@member-transfer.form.frequency.bi-weekly: Every 2 weeks`;
  frequencyMonthlyLabel = $localize`:@@member-transfer.form.frequency.monthly: Every Month`;
  frequencyBiMonthlyLabel = $localize`:@@member-transfer.form.frequency.bi-monthly: Every 2 months`;
  frequencyQuarterlyLabel = $localize`:@@member-transfer.form.frequency.quarterly: Every 3 months`;
  frequencySemiAnnuallyLabel = $localize`:@@member-transfer.form.frequency.semi-annually: Every 6 months`;
  frequencyAnnuallyLabel = $localize`:@@member-transfer.form.frequency.annually: Every Year`;

  endTypeHeaderLabel = $localize`:@@member-transfer.form.duration.header: Duration`;
  endTypeNeverEndsLabel = $localize`:@@member-transfer.form.duration.never-ends: Never Ends`;
  endTypeLastTransferDateLabel = $localize`:@@member-transfer.form.duration.last-transfer-date: Last Transfer Date`;
  endTypeNumberOfTransfersLabel = $localize`:@@member-transfer.form.duration.number-of-transfers: Number of Transfers`;

  continueBtn = $localize`:@@member-transfer.form.continue-btn:Continue`;
  clearBtn = $localize`:@@member-transfer.form.clear-btn:Clear All Fields`;

  /** Errors */
  dateRequiredErrorMessage = $localize`:@@member-transfer.form.error.date-required:Please select a date`;
  dateInvalidFormatErrorMessage = $localize`:@@member-transfer.form.error.date-invalid:Invalid date format`;
  datePastErrorMessage = $localize`:@@member-transfer.form.error.date-past:Start date cannot be in the past`;
  fromAccountRequiredError = $localize`:@@member-transfer.form.error.from-account-required-message: Please select an account from the list to transfer from`;
  toAccountRequiredError = $localize`:@@member-transfer.form.error.to-account-required-message: Please select a recipient`;
  insufficientFundsError = $localize`:@@member-transfer.form.error.insufficient-funds-message: There aren't sufficient funds in your checking account. Please enter a different amount`;
  amountRequiredError = $localize`:@@member-transfer.form.error.amount-required-message: Please enter the amount you would like to transfer`;
  executionDatePastError = $localize`:@@member-transfer.form.error.start-date-past-message: Start date cannot be in the past`;
  executionDateRequiredError = $localize`:@@member-transfer.form.error.start-date-required-message: Please select a date`;
  endDateRequiredError = $localize`:@@member-transfer.form.error.end-date-required-message: Please select an end date`;
  repeatRequiredError = $localize`:@@member-transfer.form.error.occurrence-required-error-message: Please select more than one occurrence`;

  endDateLessThanMinDateError = (minDate: string) =>
    $localize`:@@member-transfer.form.error.end-date-less-than-min-date: Please select a date on or later than ${minDate}`;

  amountFromMaxError = (fromAccount?: FlatProductItem) =>
    $localize`:@@member-transfer.form.error.amount-from-insufficient-funds:Dollar amount exceeds transfer source. Max amount is ${entFormatCurrency(
      fromAccount?.availableBalance ?? 0,
    )}:INTERPOLATION:.`;
}
