import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ExploreProductsTextService {
  headingLabel = $localize`:@@products.explore-products.heading.label: Explore products`;

  bankButtonLabel = $localize`:@@products.explore-products.bank.button.label: Add Now`;
  borrowButtonLabel = $localize`:@@products.explore-products.borrow.button.label: Apply`;
  cardButtonLabel = $localize`:@@products.explore-products.card.button.label: Explore Cards`;

  bankLabel = $localize`:@@products.explore-products.bank-tab.label: Bank`;
  borrowLabel = $localize`:@@products.explore-products.borrow-tab.label: Borrow`;

  checkingLabel = $localize`:@@products.explore-products.checking-section.label: Checking`;
  savingsCertificatesLabel = $localize`:@@products.explore-products.savings-certificates-section.label: Savings`;
  homeLabel = $localize`:@@products.explore-products.home-section.label: Home`;
  autoLabel = $localize`:@@products.explore-products.auto-section.label: Auto`;
  personalLabel = $localize`:@@products.explore-products.personal-section.label: Personal`;
  cardsLabel = $localize`:@@products.explore-products.cards-section.label: Cards`;
  otherLabel = $localize`:@@products.explore-products.other-section.label: Other`;

  checkingRateLabel = $localize`:@@products.explore-products.rate.checking.label:No Surprise Fees`;
  broncosRateLabel = $localize`:@@products.explore-products.rate.broncos.label:Broncos Perks`;

  memoPostNotificationHeader = $localize`:@@products.explore-products.memo-post.header: We're doing some routine maintenance`;
  memoPostNotificationMessage = $localize`:@@products.explore-products.memo-post.message: Come back to add new products tomorrow morning. We'll be all set and ready for you then.`;

  getRateSuffix = (rate) => $localize`:@@products.product-card.rate.suffix.label:${rate}% APY`;
  getRateRangeLabel = (minRate, maxRate) =>
    $localize`:@@products.product-card.rate.range.label:${minRate}% - ${maxRate}% APY`;
}
