<ng-content></ng-content>
<i class="fa-light mx-2 help-icon" [ngClass]="iconClass" (click)="toggleModal()" (keydown)="toggleModal()"></i>
<ent-confirmation-modal
  [isOpen]="isModalOpen"
  [title]="modalTitle"
  [bodyTemplate]="modalBody"
  [footerTemplate]="modalFooter"
  (close)="toggleModal()"
></ent-confirmation-modal>
<ng-template #modalFooter>
  <div class="bb-button-bar">
    <button bbButton color="primary" class="bb-button-bar__button" (click)="toggleModal()">
      {{ closeButtonText }}
    </button>
  </div>
</ng-template>
<ng-template #modalBody>
  <div class="modal-text">{{ modalText }}</div>
</ng-template>
