export enum MEMBER_TRANSFER_FORM_ERROR_KEY {
  FROM_REQUIRED = "from.required",
  TO_REQUIRED = "to.required",
  AMOUNT_REQUIRED = "amount.required",
  AMOUNT_MAX = "amount.max",
  OCCURRENCE_REQUIRED = "occurrence.required",
  EXECUTION_DATE_REQUIRED = "executionDate.required",
  EXECUTION_DATE_MIN = "executionDate.min",
  FREQUENCY_REQUIRED = "frequency.required",
  END_TYPE_REQUIRED = "endType.required",
  END_DATE_REQUIRED = "endDate.required",
  END_DATE_MIN = "endDate.min",
  REPEAT_REQUIRED = "repeat.required",
}

export enum ADD_CONTACTS_FORM_ERROR_KEY {
  NAME_REQUIRED = "name.required",
  ACCOUNT_NUMBER_REQUIRED = "accountNumber.required",
}
