import { Component, OnInit } from "@angular/core";
import {
  COMPONENT_STATE,
  CONVERTING_REVIEW_ITEMS,
  EligibleProduct,
  FORM_CONTROL_NAMES,
  FlatProductItem,
  LoanEligibilityPayload,
  NO_STRINGS_CHECKING_KEY,
  PRODUCTS_FORM_STATE,
  PRODUCT_ERROR_KEY,
  PRODUCT_FLOW_CODES,
  PRODUCT_SECTIONS,
  ProductCard,
  ProductsHttpService,
  ReviewItem,
  SUBCODES,
  SelectionItem,
  ShareEligibilityPayload,
} from "@ent/data";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, firstValueFrom, of, switchMap } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductsBaseTextService } from "./services/products-base.text.service";
import { ProductsBaseStepsService } from "./services/products-base.steps.service";
import { IProductFormErrors, PRODUCT_FORM_ERROR_KEY, PRODUCT_FORM_ERRORS } from "./models/product-errors";
import { productFormSchema } from "./models/product-model";

@Component({
  selector: "ent-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
  componentState = new BehaviorSubject(COMPONENT_STATE.LOADED);
  currentStep: Observable<PRODUCTS_FORM_STATE>;
  formStates = PRODUCTS_FORM_STATE;
  subCodes = SUBCODES;
  formControlNames = FORM_CONTROL_NAMES;
  productFlowCodes = PRODUCT_FLOW_CODES;
  isSaving = false;
  isLoading = false;
  myAccountsPath = "../my-accounts";
  shareTypes: EligibleProduct[] = [];
  loanTypes: EligibleProduct[] = [];
  form: FormGroup = new FormGroup({});
  editingCard: ProductCard;
  checkingAccounts: SelectionItem[] = [];
  currentProductFLow = PRODUCT_FLOW_CODES.DEFAULT;
  accountsList: SelectionItem[] = [];
  reviewItems: ReviewItem[] = [];
  errorKey: string;
  depositFromAccounts: Observable<FlatProductItem[]>;
  errors: IProductFormErrors;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productsHttpService: ProductsHttpService,
    public textService: ProductsBaseTextService,
    public stepsService: ProductsBaseStepsService,
  ) {}

  ngOnInit(): void {
    this.setDefaultStep();
    this.getEligibleProducts();
    this.depositFromAccounts = this.productsHttpService.getDepositFromAccounts();
  }

  getEligibleProducts() {
    this.componentState.next(COMPONENT_STATE.LOADING);
    const data$ = this.productsHttpService.getEligibleProducts().pipe(
      switchMap((response) => {
        if (response.shareTypes.length) {
          return of(response);
        }
        return this.productsHttpService.getLoanInfoProducts();
      }),
    );

    data$.subscribe({
      next: (result) => {
        if (this.isValidShareType(result)) {
          this.shareTypes = result.shareTypes;
        } else {
          this.loanTypes = result.loanTypes;
        }
        this.componentState.next(COMPONENT_STATE.LOADED);
      },
      error: () => {
        this.componentState.next(COMPONENT_STATE.ERROR);
      },
    });
  }

  getLoanInfoProducts() {
    this.isLoading = true;
    this.productsHttpService.getLoanInfoProducts().subscribe({
      next: (result) => {
        if (result.loanTypes?.length) {
          this.loanTypes = result.loanTypes;
        } else {
          this.componentState.next(COMPONENT_STATE.ERROR);
        }
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.componentState.next(COMPONENT_STATE.ERROR);
      },
    });
  }

  saveProduct() {
    this.isSaving = true;
    const payload = { ...this.form.getRawValue() };
    const postObserver =
      this.currentProductFLow === this.productFlowCodes.BRONCOS_CONVERTION
        ? this.productsHttpService.convertProduct(payload)
        : this.productsHttpService.createProduct(payload);
    postObserver.subscribe({
      next: () => {
        this.isSaving = false;
        this.stepsService.setCurrentStep(this.formStates.SUCCESS);
      },
      error: (err: HttpErrorResponse) => {
        this.isSaving = false;
        this.displayError(err?.error?.key);
      },
    });
  }

  async getCheckingAccounts() {
    this.checkingAccounts = [];
    if (this.isBroncosAccount()) {
      this.componentState.next(COMPONENT_STATE.LOADING);
      try {
        this.checkingAccounts = (await firstValueFrom(this.productsHttpService.getCheckingAccounts())).map(
          (account) => {
            return {
              value: account.id,
              label: `${account.userPreferences?.alias || NO_STRINGS_CHECKING_KEY} ${account.BBAN.slice(-9)}`,
            };
          },
        );
        this.componentState.next(COMPONENT_STATE.LOADED);
      } catch {
        this.componentState.next(COMPONENT_STATE.LOADED);
      }
    }
  }

  setFormGroup() {
    switch (this.currentProductFLow) {
      case this.productFlowCodes.BRONCOS_CONVERTION:
        this.form = new FormGroup({
          [this.formControlNames.ARRANGEMENT_ID]: new FormControl(this.checkingAccounts[0].value),
        });
        break;
      default:
        this.form = new FormGroup({
          [this.formControlNames.SHARE_TYPE]: new FormControl(this.editingCard?.type),
          [this.formControlNames.SUB_CODE]: new FormControl(this.editingCard?.subCode),
          [this.formControlNames.ACCOUNT_NUMBER]: new FormControl(this.editingCard?.accounts[0]),
          [this.formControlNames.DEPOSIT_AMOUNT]: new FormControl(0),
          [this.formControlNames.DEPOSIT_ACCOUNT]: new FormControl(""),
        });
        break;
    }

    this.form.controls[this.formControlNames.DEPOSIT_ACCOUNT]?.valueChanges.subscribe(() => this.runYupValidation());
    this.form.controls[this.formControlNames.DEPOSIT_AMOUNT]?.valueChanges.subscribe(() => this.runYupValidation());
  }

  navigateTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  setFlowSteps() {
    this.stepsService.steps$.next([]);
    this.stepsService.addStep(this.formStates.EXPLORE);
    this.stepsService.addStep(this.formStates.INFORMATION);
    if (this.editingCard.type === "16") this.stepsService.addStep(this.formStates.DEPOSIT);
    if (this.currentProductFLow === this.productFlowCodes.BRONCOS_CONVERTION && this.checkingAccounts.length > 1)
      this.stepsService.addStep(this.formStates.CHECKING_ACCOUNT_SELECTION);
    if (this.editingCard.considerations?.length) this.stepsService.addStep(this.formStates.CONSIDERATIONS);
    if (this.currentProductFLow !== this.productFlowCodes.BRONCOS_CONVERTION && this.editingCard.accounts?.length > 1)
      this.stepsService.addStep(this.formStates.MEMBERSHIP_SELECTION);
    this.stepsService.addStep(this.formStates.REVIEW);
  }

  setDefaultStep() {
    this.stepsService.setCurrentStep(this.formStates.EXPLORE);
    this.currentStep = this.stepsService.getCurrentStep();
  }

  setSelections() {
    if (this.editingCard.accounts?.length > 1) {
      this.accountsList = this.editingCard.accounts.map((item) => {
        return { label: item, value: item };
      });
    }
  }

  addProduct(card: ProductCard) {
    this.editingCard = card;
    this.getCheckingAccounts();
    this.setSelections();
    this.stepsService.goNext();
  }

  runYupValidation() {
    this.errors = undefined;
    const schemaObject = this.form.getRawValue();
    try {
      productFormSchema.validateSync(
        {
          ...schemaObject,
          minimumDeposit: Number(this.editingCard.minimumDeposit),
          fromAccountBalance: this.editingCard.fromAccountBalance,
        },
        { abortEarly: false },
      );
    } catch (err) {
      this.setErrors(err.errors);
    }
  }

  setErrors(errors: PRODUCT_FORM_ERROR_KEY[] = []) {
    this.errors = errors.reduce((acc, error) => {
      switch (error) {
        case PRODUCT_FORM_ERROR_KEY.AMOUNT_MIN:
          acc[PRODUCT_FORM_ERRORS.AMOUNT] = this.textService.getAmountMinErrorMessage(this.editingCard.minimumDeposit);
          break;
        case PRODUCT_FORM_ERROR_KEY.AMOUNT_REQUIRED:
          acc[PRODUCT_FORM_ERRORS.AMOUNT] = this.textService.amounErrorMessage;
          break;
        case PRODUCT_FORM_ERROR_KEY.AMOUNT_MAX:
          acc[PRODUCT_FORM_ERRORS.FROM_ACCOUNT] = this.textService.getInsuficientFundsErrorMessage(
            this.form.controls[FORM_CONTROL_NAMES.DEPOSIT_AMOUNT].value,
          );
          break;
        case PRODUCT_FORM_ERROR_KEY.ACCOUNT_REQUIRED:
          acc[PRODUCT_FORM_ERRORS.FROM_ACCOUNT] = this.textService.accountErrorMessage;
          break;
        default:
          break;
      }
      return acc;
    }, {});

    if (!Object.keys(this.errors).length) this.errors = undefined;
  }

  onValidateNext() {
    this.runYupValidation();
    if (!this.errors) this.stepsService.goNext();
  }

  onInformationNext(productFlow = PRODUCT_FLOW_CODES.DEFAULT) {
    this.currentProductFLow = productFlow;
    if (productFlow === this.productFlowCodes.BRONCOS_CONVERTION) {
      this.reviewItems = CONVERTING_REVIEW_ITEMS;
    } else {
      this.reviewItems = [];
    }
    this.setFormGroup();
    this.setFlowSteps();
    this.stepsService.goNext();
  }

  onTabChange(selectedTab: PRODUCT_SECTIONS) {
    if (selectedTab === PRODUCT_SECTIONS.BORROW && !this.loanTypes.length) {
      this.getLoanInfoProducts();
    }
  }

  isBroncosAccount(): boolean {
    return this.editingCard.subCode === this.subCodes.BRONCOS;
  }

  changeComponentState(event: COMPONENT_STATE) {
    this.componentState.next(event);
  }

  displayError(errorKey: PRODUCT_ERROR_KEY) {
    this.errorKey = errorKey;
    this.stepsService.setCurrentStep(this.formStates.ERROR);
  }

  isValidShareType(value: ShareEligibilityPayload | LoanEligibilityPayload): value is ShareEligibilityPayload {
    return (value as ShareEligibilityPayload).shareTypes !== undefined;
  }
}
