<ent-stateful-component
  [componentState]="componentState | async"
  [loadingText]="textService.loadingText"
  [errorHeaderText]="textService.errorHeader"
  [errorText]="textService.errorSubText"
  [contentTemplate]="loaded"
></ent-stateful-component>
<ng-template #loaded
  ><ng-container *ngIf="(this.isFormOpen | async); then Form else Closed "> </ng-container
></ng-template>
<ng-template #Closed>
  <div class="card">
    <div class="card-body">
      <div class="bb-block bb-block--lg">
        <bb-header-ui
          headingType="h2"
          [heading]="textService.generalTitle"
          headingClasses="bb-heading-widget__heading my-4"
        >
        </bb-header-ui>
      </div>
      <div class="bb-stack">
        <div class="bb-stack__item bb-stack__item--fill">
          <div class="bb-stack bb-stack--wrap">
            <div class="bb-stack__item">
              <strong class="bb-block">{{ textService.profileSectionTitle }}</strong>
              <p class="bb-subtitle bb-text-support">{{ textService.profileSectionSupportText }}</p>
            </div>
            <div class="bb-stack__item bb-stack__item--fill bb-text-align-end bb-text-align-start--sm-down break-word">
              <bb-button-ui color="secondary" (click)="openForm()"
                >{{ textService.manageProfileFormButton }}</bb-button-ui
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #Form>
  <form [formGroup]="form">
    <div class="bb-block bb-block--lg">
      <button bbbutton (click)="closeForm()" type="button" color="link" class="btn-link btn btn-md">
        <em class="fa-solid fa-arrow-left me-2 mb-1"></em>
        {{ textService.backButton }}
      </button>
      <bb-header-ui
        headingType="h2"
        [heading]="textService.profileSectionTitle"
        headingClasses="bb-heading-widget__heading my-4"
      >
      </bb-header-ui>
      <div class="card">
        <bb-header-ui
          headingType="h3"
          [heading]="textService.contactPreferenceTitle"
          headingClasses="bb-heading-widget__heading my-4 card-body"
        >
        </bb-header-ui>
        <bb-collapsible-card-ui [isOpen]="isAccordianOpen | async" [highlight]="false" [shadow]="false">
          <p bbCollapsibleTitle class="d-flex justify-content-between">
            <span>{{ textService.contactPreferenceQuestion }}</span>
            <span> {{optInInfoString}}</span>
          </p>
          <div class="d-flex flex-column" bbCollapsibleBody>
            <p class="text-muted">{{textService.helperText}}</p>
            <div *ngIf="hasMobileNumber">
              <div class="bb-stack d-flex justify-content-between my-2">
                <div class="bb-stack__item bb-stack__item--fill">
                  <div class="bb-stack bb-stack--wrap">
                    <div class="bb-stack__item me-4">
                      <em class="fa-regular fa-message-dots fa-lg"></em>
                    </div>
                    <div class="bb-stack__item">
                      <span class="bb-block--no-margin">{{ textService.mobileTextMessageLabel }}</span>
                      <span class="bb-block--no-margin bb-text-bold">{{ mobileNumberFormatted }}</span>
                    </div>
                  </div>
                </div>
                <bb-switch-ui
                  [aria-label]="textService.mobileTextMessageSwitchLabel"
                  formControlName="mobileSMSOptInControl"
                  (click)="onTogglePreference(this.mobilePhoneNumber,toggleTypes.TEXT_MESSAGE)"
                  (toggleSwitch)="onTogglePreference(this.mobilePhoneNumber,toggleTypes.TEXT_MESSAGE)"
                >
                </bb-switch-ui>
              </div>
              <div class="bb-stack d-flex justify-content-between my-2">
                <div class="bb-stack__item bb-stack__item--fill">
                  <div class="bb-stack bb-stack--wrap">
                    <div class="bb-stack__item me-4">
                      <em class="fa-regular fa-lg fa-phone"></em>
                    </div>
                    <div class="bb-stack__item">
                      <span class="bb-block--no-margin">{{ textService.mobileNumberCallLabel }}</span>
                      <span class="bb-block--no-margin bb-text-bold">{{ mobileNumberFormatted }}</span>
                    </div>
                  </div>
                </div>
                <bb-switch-ui
                  [aria-label]="textService.mobileNumberCallLabel"
                  formControlName="mobileCallOptInControl"
                  (click)="onTogglePreference(this.mobilePhoneNumber,toggleTypes.PHONE_CALL)"
                  (toggleSwitch)="onTogglePreference(this.mobilePhoneNumber,toggleTypes.PHONE_CALL)"
                >
                </bb-switch-ui>
              </div>
            </div>
            <div *ngIf="hasHomeNumber" class="bb-stack d-flex justify-content-between mt-0 mb-2 pb-2">
              <div class="bb-stack__item bb-stack__item--fill">
                <div class="bb-stack bb-stack--wrap">
                  <div class="bb-stack__item me-4">
                    <em class="fa-regular fa-lg fa-phone"></em>
                  </div>
                  <div class="bb-stack__item">
                    <span class="bb-block--no-margin">{{ textService.homeNumberCallLabel }}</span>
                    <span class="bb-block--no-margin bb-text-bold">{{ homeNumberFormatted }}</span>
                  </div>
                </div>
              </div>
              <bb-switch-ui
                [aria-label]="textService.homeNumberCallLabel"
                formControlName="homeCallOptInControl"
                (click)="onTogglePreference(this.homePhoneNumber,toggleTypes.PHONE_CALL)"
                (toggleSwitch)="onTogglePreference(this.homePhoneNumber,toggleTypes.PHONE_CALL)"
              >
              </bb-switch-ui>
            </div>
            <div *ngIf="hasMobileNumber || hasHomeNumber">
              <hr class="pb-2 my-0" />
              <div class="bb-stack d-flex justify-content-between my-2">
                <div class="bb-stack__item bb-stack__item--fill">
                  <div class="bb-stack bb-stack--wrap">
                    <div class="bb-stack__item me-3">
                      <em class="fa-regular fa-lg fa-phone-slash"></em>
                    </div>
                    <div class="bb-stack__item">
                      <span class="bb-block--no-margin">{{ textService.doNotContactLabel }}</span>
                    </div>
                  </div>
                </div>
                <bb-switch-ui
                  [aria-label]="textService.mobileTextMessageSwitchLabel"
                  formControlName="allNotificationsOptOutControl"
                  (click)="onToggleAllPreferences()"
                  (toggleSwitch)="onToggleAllPreferences()"
                >
                </bb-switch-ui>
              </div>
              <p class="bb-subtitle bb-text-support">{{ textService.doNotContactDisclaimer }}</p>
            </div>
          </div>
        </bb-collapsible-card-ui>
      </div>
    </div>
  </form>
</ng-template>
