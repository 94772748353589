import { Component, OnChanges } from "@angular/core";
import { BaseSuccessComponent } from "../../components/base-success/base-success.component";
import { TransferSuccessTextService } from "../services/transfer-success.text.service";

@Component({
  selector: "ent-transfer-success",
  templateUrl: "./transfer-success.component.html",
  styleUrls: ["./transfer-success.component.scss"],
})
export class TransferSuccessComponent extends BaseSuccessComponent implements OnChanges {
  constructor(public readonly transferSuccessTextService: TransferSuccessTextService) {
    super();
  }
  getSuccessTextService = () => {
    return this.transferSuccessTextService;
  };
}
